import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import { updateBuyerSellerVersion } from "./types";
const api = new APICore();
function fetchBuyerSellersApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations`;
  return api.get(`${baseUrl}`, params);
}
function fetchBuyerSellerApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params}`;
  return api.get(`${baseUrl}`, {});
}
function validateBuyerSellerApi(params: any) {
  console.log(params);

  let backendApiUrl = "";
  let apiData: any = {
    programmeId: params.programmeId.length ? params.programmeId[0]["id"] : null,
    counterPartyId: params.customerId.length ? params.customerId[0]["id"] : null,
    invoiceConcentration: params.invoiceConcentration ? params.invoiceConcentration : 0,
    instrumentDueDateCalculation: params.instrumentDueDateCalculation ? params.instrumentDueDateCalculation.value : null,
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : null,
    normalBaseRateId: params.normalBaseRateId?.length ? params.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params.normalSpreadRateOfInterest ? params.normalSpreadRateOfInterest : 0,
    adhocBaseRateId: params.adhocBaseRateId?.length ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params.adhocSpreadRateOfInterest ? params.adhocSpreadRateOfInterest : 0,
    overdueSpreadRateOfInterest: params.overdueSpreadRateOfInterest ? params.overdueSpreadRateOfInterest : 0,
    interestType: params.interestType ? params.interestType.value : null,
    consolidationType: params.consolidationType ? params.consolidationType.value : "Monthly",
    graceDays: params.graceDays ? params.graceDays : 0,
    staleDays: params.staleDays ? params.staleDays : 0,
    autoFinance: params.autoFinance?.value === "yes" ? true : false,
    isSeperateAccountForAdvanceInterest: params.isSeperateAccountForAdvanceInterest?.value === "yes" ? true : false,
    advanceInterestDebitAccountNumber: params.advanceInterestDebitAccountNumber ? params.advanceInterestDebitAccountNumber : "",
    graceRateOfInterest: params.graceRateOfInterest ? params.graceRateOfInterest : 0,
    validateCreditAccount: params.validateCreditAccount?.value === "yes" ? true : false,
    autoDebit: params.autoDebit?.value === "yes" ? true : false,
    autoDebitBankAccountNumber: params.autoDebitBankAccountNumber ? params.autoDebitBankAccountNumber : "",
    interestDebitParty: params.interestDebitParty ? params.interestDebitParty.value : "None",
    principalRepaymentBy: params.principalRepaymentBy ? params.principalRepaymentBy.value : "None",
    overdueDebitParty: params.overdueDebitParty ? params.overdueDebitParty.value : "None",
    normalBorrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    // adhocBorrowerLimitId: params.adhocBorrowerLimitId ? params.adhocBorrowerLimitId : null,
    additionalDetails: params.additionalDetails ? params.additionalDetails.trim() : "",
    remarks: params.remarks ? params.remarks.trim() : "",
    excessAdvanceInterestRefundAccountNumber: params.excessAdvanceInterestRefundAccountNumber ? params.excessAdvanceInterestRefundAccountNumber : "",
    excessAdvanceInterestRefundIfscCode: params.excessAdvanceInterestRefundIfscCode ? params.excessAdvanceInterestRefundIfscCode : "",
    repaymentBankAccountNumber: params?.repaymentBankAccountNumber ? params.repaymentBankAccountNumber.trim() : ""
  };
  // if (!params.draft) {
  //   apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
  //   backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations/validate`;
  // } else {
  //   backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations/validate?draft=${params.draft}&group=${params.group}`;
  // }
  // const baseUrl = backendApiUrl;
  // return api.create(`${baseUrl}`, apiData);
}
function createBuyerSellerApi(params: any) {

  let backendApiUrl = "";
  let apiData: any = {
    programmeId: params.programmeId.length ? params.programmeId[0]["id"] : null,
    counterPartyId: params.customerId.length ? params.customerId[0]["id"] : null,
    invoiceConcentrationAmount: params?.isInheritedInvoiceConcentrationAmount ? null : params.invoiceConcentrationAmount ? params.invoiceConcentrationAmount : 0,
    instrumentDueDateCalculation: params.instrumentDueDateCalculation ? params.instrumentDueDateCalculation.value : "None",
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : null,
    normalBaseRateId: params.normalBaseRateId?.length ? params.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params.normalSpreadRateOfInterest ? params.normalSpreadRateOfInterest : 0,
    adhocBaseRateId: params.adhocBaseRateId?.length ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params.adhocSpreadRateOfInterest ? params.adhocSpreadRateOfInterest : 0,
    overdueSpreadRateOfInterest: params.overdueSpreadRateOfInterest ? params.overdueSpreadRateOfInterest : 0,
    interestType: params.interestType ? params.interestType.value : null,
    consolidationType: "Monthly",
    graceDays: params.graceDays ? params.graceDays : 0,
    staleDays: params.staleDays ? params.staleDays : 0,
    autoFinance: params.autoFinance?.value === "yes" ? true : false,
    graceRateOfInterest: params.graceRateOfInterest ? params.graceRateOfInterest : 0,
    validateCreditAccount: params.validateCreditAccount?.value === "yes" ? true : false,
    autoDebit: params.autoDebit ? true : false,
    autoDebitBankAccountNumber: params.autoDebitBankAccountNumber ? params.autoDebitBankAccountNumber : "",
    umrnNumber: params?.umrnNumber ? params?.umrnNumber : "",
    ifscCode: params?.ifscCode ? params?.ifscCode : "",
    interestDebitParty: params.interestDebitParty ? params.interestDebitParty.value : "None",
    principalRepaymentBy: params.principalRepaymentBy ? params.principalRepaymentBy.value : "None",
    overdueDebitParty: params.overdueDebitParty ? params.overdueDebitParty.value : "None",
    borrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    additionalDetails: params.additionalDetails ? params.additionalDetails.trim() : "",
    remarks: params.remarks ? params.remarks.trim() : "",
    excessAdvanceInterestRefundAccountNumber: params.excessAdvanceInterestRefundAccountNumber ? params.excessAdvanceInterestRefundAccountNumber : "",
    excessAdvanceInterestRefundIfscCode: params.excessAdvanceInterestRefundIfscCode ? params.excessAdvanceInterestRefundIfscCode : "",
    repaymentBankAccountNumber: params?.repaymentBankAccountNumber ? params.repaymentBankAccountNumber.trim() : "",

    tenorDays: params.tenorDays ? params.tenorDays : 0,
    isInheritedInvoiceConcentrationAmount: params.isInheritedInvoiceConcentrationAmount ? params.isInheritedInvoiceConcentrationAmount : false,

    isInheritedStaleDays: params.isInheritedStaleDays ? params.isInheritedStaleDays : false,
    isInheritedTenorDays: params.isInheritedTenorDays ? params.isInheritedTenorDays : false,

    isInheritedDailyTransactionLimit: params.isInheritedDailyTransactionLimit ? params.isInheritedDailyTransactionLimit : false,
    dailyTransactionLimit: params?.isInheritedDailyTransactionLimit ? null : params.dailyTransactionLimit ? params.dailyTransactionLimit : 0,

    isInheritedPaymentBatchingStrategy: true,
    paymentBatchingStrategy: params.paymentBatchingStrategy ? params.paymentBatchingStrategy?.value : "NoBatching",
    maxFinancingPercentage: params.maxFinancingPercentage ? params.maxFinancingPercentage : "0",

    isInheritedDueDateStrategy: params.isInheritedDueDateStrategy ? params.isInheritedDueDateStrategy : false,
    isInheritedInterestType: params.isInheritedInterestType ? params.isInheritedInterestType : false,
    isInheritedGraceDays: params.isInheritedGraceDays ? params.isInheritedGraceDays : false,
    isInheritedGraceRateOfInterest: params.isInheritedGraceRateOfInterest ? params.isInheritedGraceRateOfInterest : false,
    isInheritedInterestDebitParty: params.isInheritedInterestDebitParty ? params.isInheritedInterestDebitParty : false,
    isInheritedOverdueDebitParty: params.isInheritedOverdueDebitParty ? params.isInheritedOverdueDebitParty : false,
    isInheritedPrincipalRepaymentBy: params.isInheritedPrincipalRepaymentBy ? params.isInheritedPrincipalRepaymentBy : false,
    isInheritedMaxFinancingPercentage: params.isInheritedMaxFinancingPercentage ? params.isInheritedMaxFinancingPercentage : false,

    isInheritedAppropriationGroup: params.isInheritedAppropriationGroup ? params.isInheritedAppropriationGroup : false,
    appropriationGroup: params?.appropriationGroup?.value ? params?.appropriationGroup?.value : null,

    partialDisbursementAllowed: params.partialDisbursementAllowed && params.partialDisbursementAllowed?.value == "Yes" ? true : false,

    isInheritedPartialDisbursementAllowed: params.isInheritedPartialDisbursementAllowed ? params.isInheritedPartialDisbursementAllowed : false,

    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "FIFO",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    marginAmountAccountNumber: params?.marginAmountAccountNumber,
    marginAmountIfscCode: params?.marginAmountIfscCode,
    loanAccountNumber: params?.relationshipAccountNumber ? params?.relationshipAccountNumber : null,
    isAutoGeneratedLoanAccountNumber: params?.isAutoGeneratedLoanAccountNumber,
    isInheritedRepaymentSettlementStrategy: params.isInheritedRepaymentSettlementStrategy ? params.isInheritedRepaymentSettlementStrategy : false,
    isInheritedUniqueLoanAndVirtualAccountNumber: params.isInheritedUniqueLoanAndVirtualAccountNumber ? params.isInheritedUniqueLoanAndVirtualAccountNumber : false
  };
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}
function fetchBuyerSellerVersionApi(params: any) {
  let filterdata = {
    pgno: params.pgno,
    pgsz: params.pgsz,
    sort: params.sort
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.id}/versions`;
  return api.get(`${baseUrl}`, filterdata);
}



function deleteBuyerSellerApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params}`;
  return api.delete(`${baseUrl}`);
}


function fetchBuyerSellerLatestVersionApi(params: any) {
  let flag = {
    modifyLatest: params?.status === "New" ? false : true
  }
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params?.externalId}/latestVersion`;
  return api.get(`${baseUrl}`, flag);
}

function approveBuyerSellerpi(params: updateBuyerSellerVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalCustomerId;
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${externalBaseRateId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectBuyerSellerpi(params: updateBuyerSellerVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalCustomerId;
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${externalBaseRateId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function buyerSellerBlockApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}
function updateBuyerSellerApi(params: any) {
  console.log(params);
  let backendApiUrl = "";
  let apiData: any = {
    programmeId: params.programmeId.length ? params.programmeId[0]["id"] : null,
    counterPartyId: params.customerId.length ? params.customerId[0]["id"] : null,
    invoiceConcentrationAmount: params?.isInheritedInvoiceConcentrationAmount ? null : params.invoiceConcentrationAmount ? params.invoiceConcentrationAmount : 0,
    dailyTransactionLimit: params?.isInheritedDailyTransactionLimit ? null : params.dailyTransactionLimit ? params.dailyTransactionLimit : 0,
    instrumentDueDateCalculation: params.instrumentDueDateCalculation?.value ? params?.instrumentDueDateCalculation?.value : null,
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : null,
    normalBaseRateId: params.normalBaseRateId?.length && params.normalBaseRateId[0] ? params.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params?.normalSpreadRateOfInterest ? params?.normalSpreadRateOfInterest : 0,
    adhocBaseRateId: params.adhocBaseRateId?.length && params.adhocBaseRateId[0] ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params?.adhocSpreadRateOfInterest ? params?.adhocSpreadRateOfInterest : 0,
    overdueSpreadRateOfInterest: params?.overdueSpreadRateOfInterest ? params?.overdueSpreadRateOfInterest : 0,
    interestType: params?.interestType ? params?.interestType?.value : null,
    consolidationType: "Monthly",
    graceDays: params?.graceDays ? params?.graceDays : 0,

    tenorDays: params.tenorDays ? params.tenorDays : 0,

    staleDays: params?.staleDays ? params?.staleDays : 0,
    autoFinance: params.autoFinance,
    isSeperateAccountForAdvanceInterest: params.isSeperateAccountForAdvanceInterest,
    advanceInterestDebitAccountNumber: params.advanceInterestDebitAccountNumber ? params.advanceInterestDebitAccountNumber : "",
    graceRateOfInterest: params?.graceRateOfInterest ? params?.graceRateOfInterest : 0,
    validateCreditAccount: params.validateCreditAccount,
    autoDebit: params.autoDebit ? true : false,
    autoDebitBankAccountNumber: params?.autoDebitBankAccountNumber ? params?.autoDebitBankAccountNumber : "",
    umrnNumber: params?.umrnNumber ? params?.umrnNumber : "",
    ifscCode: params?.ifscCode ? params?.ifscCode : "",

    interestDebitParty: params.isInheritedInterestDebitParty
      ? params.interestDebitParty
        ? params.interestDebitParty.value
        : null
      : params.interestDebitParty
        ? params.interestDebitParty.value
        : "None",
    overdueDebitParty: params.isInheritedOverdueDebitParty ? null : params?.overdueDebitParty ? params?.overdueDebitParty?.value : "None",
    principalRepaymentBy: params.isInheritedPrincipalRepaymentBy ? null : params?.principalRepaymentBy ? params?.principalRepaymentBy?.value : "None",

    borrowerLimitId: params?.normalBorrowerLimitId ? params?.normalBorrowerLimitId : null,
    additionalDetails: params?.additionalDetails ? params?.additionalDetails.trim() : "",

    remarks: params?.remarks ? params?.remarks.trim() : "",

    excessAdvanceInterestRefundAccountNumber:
      params.excessAdvanceInterestRefundAccountNumber ?
        params.excessAdvanceInterestRefundAccountNumber : "",

    excessAdvanceInterestRefundIfscCode: params.excessAdvanceInterestRefundIfscCode ? params.excessAdvanceInterestRefundIfscCode : "",
    repaymentBankAccountNumber: params?.repaymentBankAccountNumber ? params.repaymentBankAccountNumber.trim() : "",

    isInheritedInvoiceConcentrationAmount: params.isInheritedInvoiceConcentrationAmount ? params.isInheritedInvoiceConcentrationAmount : false,

    isInheritedStaleDays: params.isInheritedStaleDays ? params.isInheritedStaleDays : false,
    isInheritedTenorDays: params.isInheritedTenorDays ? params.isInheritedTenorDays : false,

    isInheritedDailyTransactionLimit: params.isInheritedDailyTransactionLimit ? params.isInheritedDailyTransactionLimit : false,
    isInheritedPaymentBatchingStrategy: params.isInheritedPaymentBatchingStrategy ? params.isInheritedPaymentBatchingStrategy : false,
    paymentBatchingStrategy: params.paymentBatchingStrategy ? params.paymentBatchingStrategy?.value : "NoBatching",
    maxFinancingPercentage: params.maxFinancingPercentage ? params.maxFinancingPercentage : "0",
    //
    isInheritedDueDateStrategy: params.isInheritedDueDateStrategy ? params.isInheritedDueDateStrategy : false,
    isInheritedInterestType: params.isInheritedInterestType ? params.isInheritedInterestType : false,
    isInheritedGraceDays: params.isInheritedGraceDays ? params.isInheritedGraceDays : false,
    isInheritedGraceRateOfInterest: params.isInheritedGraceRateOfInterest ? params.isInheritedGraceRateOfInterest : false,
    isInheritedInterestDebitParty: params.isInheritedInterestDebitParty ? params.isInheritedInterestDebitParty : false,
    isInheritedOverdueDebitParty: params.isInheritedOverdueDebitParty ? params.isInheritedOverdueDebitParty : false,
    isInheritedPrincipalRepaymentBy: params.isInheritedPrincipalRepaymentBy ? params.isInheritedPrincipalRepaymentBy : false,
    isInheritedMaxFinancingPercentage: params.isInheritedMaxFinancingPercentage ? params.isInheritedMaxFinancingPercentage : false,

    isInheritedAppropriationGroup: params.isInheritedAppropriationGroup ? params.isInheritedAppropriationGroup : false,
    appropriationGroup: params?.appropriationGroup?.value ? params?.appropriationGroup?.value : null,

    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "FIFO",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    isInheritedRepaymentSettlementStrategy: params.isInheritedRepaymentSettlementStrategy ? params.isInheritedRepaymentSettlementStrategy : false,
    isInheritedUniqueLoanAndVirtualAccountNumber: params.isInheritedUniqueLoanAndVirtualAccountNumber ? params.isInheritedUniqueLoanAndVirtualAccountNumber : false,

    marginAmountAccountNumber: params?.marginAmountAccountNumber,
    marginAmountIfscCode: params?.marginAmountIfscCode,

    loanAccountNumber: params?.relationshipAccountNumber ? params?.relationshipAccountNumber : params?.loanAccountNumber ? params?.loanAccountNumber : "",
    isAutoGeneratedLoanAccountNumber: params?.isAutoGeneratedLoanAccountNumber,

    //partialDisbursementAllowed
    partialDisbursementAllowed: params.partialDisbursementAllowed && params.partialDisbursementAllowed?.value == "Yes" ? true : false,
    isInheritedPartialDisbursementAllowed: params.isInheritedPartialDisbursementAllowed ? params.isInheritedPartialDisbursementAllowed : false
  };
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.externalId}?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function buyerSellerFreezeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function buyerSellerUnFreezeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function fetchBuyerSellerpartialDisbursementAllowedApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params}/partialDisbursementAllowed`;
  return api.get(`${baseUrl}`, {});
}

function fetchBuyerSellerVerionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function createRelationAccountNumberApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customer-relations/${params}/autogenerate-loan-account-number`;
  return api.get(`${baseUrl}`, {});
}

function fetchBuyerSellerConfigValueApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params?.id}/config`;
  return api.create(`${baseUrl}`, params.value);
}
export {
  fetchBuyerSellersApi,
  fetchBuyerSellerApi,
  fetchBuyerSellerVersionApi,
  validateBuyerSellerApi,
  createBuyerSellerApi,
  approveBuyerSellerpi,
  rejectBuyerSellerpi,
  buyerSellerBlockApi,
  updateBuyerSellerApi,
  buyerSellerFreezeApi,
  buyerSellerUnFreezeApi,
  fetchBuyerSellerpartialDisbursementAllowedApi,
  fetchBuyerSellerConfigValueApi,
  createRelationAccountNumberApi,
  fetchBuyerSellerVerionDetailsApi,
  fetchBuyerSellerLatestVersionApi,
  deleteBuyerSellerApi
};
