import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchAllDailyBalanceApi, fetchDailyBalanceApi } from "./api";
import { DailyBalanceFetchRequest, DailyBalanceReduxTypes } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchAllInstrumentsDailyBalance({ payload, type }: DailyBalanceFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAllDailyBalanceApi, payload);
    yield put({
      type: DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchInstrumentsDailyBalance({ payload, type }: DailyBalanceFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchDailyBalanceApi, payload);
    yield put({
      type: DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchAllIntrumenetDailyBalance() {
  yield takeEvery(DailyBalanceReduxTypes.FETCH_DAILY_BALANCES, fetchAllInstrumentsDailyBalance);
}

export function* watchFetchIntrumenetDailyBalance() {
  yield takeEvery(DailyBalanceReduxTypes.FETCH_DAILY_BALANCE, fetchInstrumentsDailyBalance);
}

function* instrumentDailyBalanceSaga() {
  yield all([fork(watchFetchAllIntrumenetDailyBalance), fork(watchFetchIntrumenetDailyBalance)]);
}

export default instrumentDailyBalanceSaga;
