import { Country } from "redux/country/types";
import { FetchApiResponse } from "redux/types";

export enum StateReduxTypes {
    FETCH_STATE = '@@auth/FETCH_STATE',
    FETCH_STATE_SUCCESS = '@@auth/FETCH_STATE_SUCCESS',
    FETCH_STATE_ERROR = '@@auth/FETCH_STATE_ERROR',

    RESET = '@@auth/RESET',
}

export enum CityReduxTypes {
    // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    FETCH_CITY = '@@auth/FETCH_CITY',
    FETCH_CITY_SUCCESS = '@@auth/FETCH_CITY_SUCCESS',
    FETCH_CITY_ERROR = '@@auth/FETCH_CITY_ERROR',

    RESET = '@@auth/RESET',
}

export type StateActionDispatch = {
    type:
    | StateReduxTypes.FETCH_STATE
    | StateReduxTypes.RESET;
    payload: any;
};

export type StateReducerDispatch = {
    type:
    | StateReduxTypes.FETCH_STATE_SUCCESS
    | StateReduxTypes.FETCH_STATE_ERROR
    | StateReduxTypes.RESET;
    payload: {
        data?: FetchApiResponse<State>;
        error?: string;
    };
};

export type StateReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<State>;
    error?: string|null;
}

export type State = {
    id: string,
    name: string,
    active: boolean,
    country: Country,
    createdOn: Date,
    updatedOn: Date
}

export type FetchStateByCountryIdRequest = {
    payload: string;
    type: string;
}

export type CityActionDispatch = {
    type:
    | CityReduxTypes.FETCH_CITY,
    payload: any;
};

export type CityReducerDispatch = {
    type:
    | CityReduxTypes.FETCH_CITY_SUCCESS
    | CityReduxTypes.FETCH_CITY_ERROR
    | CityReduxTypes.RESET;
    payload: {
        data?: FetchApiResponse<City>;
        error?: string;
    };
};

export type CityReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<State>;
    error?: string|null;
}

export type City = {
    id: string,
    name: string,
    active: boolean,
    country:Country,
}
export type FetchCityByStateIdRequest = {
    payload: string;
    type: string;
}

