import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  CreateCustomerRequest,
  CustomerFetchRequest,
  CustomerReduxTypes,
  CustomerVersionChangeRequest,
  DeduplicateRequest,
  fetchApiRequst,
  FetchCustomerByExternalIdRequest,
  panGstDetailreduxType,
  UpdateCustomerRequest
} from "./types";
import {
  approveCusatomerApi,
  createCustomerApi,
  customerBlockApi,
  customerFreezeApi,
  customerUnFreezeApi,
  deDuplicateCustomerApi,
  deleteCustomerApi,
  fetchCustomerApi,
  fetchCustomerLatestVersionApi,
  fetchCustomersApi,
  fetchCustomerVersionApi,
  fetchCustomerVersionDetailsApi,
  fetchGstDetailByGstNumberApi,
  fetchTaskIDByPanNumberApi,
  fetchTaskresultByTaskIDApi,
  fetchTaskStatusByTaskIdApi,
  rejectCusatomerApi,
  updateCustomerApi,
  validateCustomerApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchCustomers({ payload, type }: CustomerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomersApi, payload);

    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMERS_ERROR,
      payload: { error: error }
    });
  }
}

function* validateCustomer({ payload, type }: CreateCustomerRequest): SagaIterator {
  try {
    const response = yield call(validateCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.VALIDATE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.VALIDATE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* createCustomer({ payload, type }: CreateCustomerRequest): SagaIterator {
  try {
    const response = yield call(createCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.CREATE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.CREATE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCustomer({ payload, type }: UpdateCustomerRequest): SagaIterator {
  try {
    const response = yield call(updateCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.UPDATE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.UPDATE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomer({ payload, type }: FetchCustomerByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteCustomer({ payload, type }: FetchCustomerByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(deleteCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.DELETE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.DELETE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchTaskIDByPanNumber({ payload, type }: fetchApiRequst): SagaIterator {
  try {
    const response = yield call(fetchTaskIDByPanNumberApi, payload);

    yield put({
      type: panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchTaskStatusByTaskID({ payload, type }: fetchApiRequst): SagaIterator {
  try {
    const response = yield call(fetchTaskStatusByTaskIdApi, payload);

    yield put({
      type: panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchTaskResultByTaskId({ payload, type }: fetchApiRequst): SagaIterator {
  try {
    const response = yield call(fetchTaskresultByTaskIDApi, payload);

    yield put({
      type: panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchGstDetailByGstNumber({ payload, type }: fetchApiRequst): SagaIterator {
  try {
    const response = yield call(fetchGstDetailByGstNumberApi, payload);

    yield put({
      type: panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerVersions({ payload, type }: UpdateCustomerRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerVersionApi, payload);

    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerLatestVersions({ payload, type }: UpdateCustomerRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerLatestVersionApi, payload);

    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerVersionsDetails({ payload, type }: UpdateCustomerRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerVersionDetailsApi, payload);

    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveCustomer({ payload, type }: CustomerVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveCusatomerApi, payload);

    yield put({
      type: CustomerReduxTypes.APPROVE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.APPROVE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomer({ payload, type }: CustomerVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectCusatomerApi, payload);

    yield put({
      type: CustomerReduxTypes.REJECT_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.REJECT_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* deDuplicateCustomer({ payload, type }: DeduplicateRequest): SagaIterator {
  try {
    const response = yield call(deDuplicateCustomerApi, payload);

    yield put({
      type: CustomerReduxTypes.DEDUPLICATE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.DEDUPLICATE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* customerBlock({ payload, type }: DeduplicateRequest): SagaIterator {
  try {
    const response = yield call(customerBlockApi, payload);

    yield put({
      type: CustomerReduxTypes.BLOCK_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.BLOCK_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* customerFreeze({ payload, type }: DeduplicateRequest): SagaIterator {
  try {
    const response = yield call(customerFreezeApi, payload);

    yield put({
      type: CustomerReduxTypes.FREEZE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.FREEZE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

function* customerUnFreeze({ payload, type }: DeduplicateRequest): SagaIterator {
  try {
    const response = yield call(customerUnFreezeApi, payload);

    yield put({
      type: CustomerReduxTypes.UNFREEZE_CUSTOMER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerReduxTypes.UNFREEZE_CUSTOMER_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchTaskIDByPanNumber() {
  yield takeEvery(panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER, fetchTaskIDByPanNumber);
}

export function* watchTaskStatusByTaskId() {
  yield takeEvery(panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID, fetchTaskStatusByTaskID);
}

export function* watchTaskResultByTaskId() {
  yield takeEvery(panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID, fetchTaskResultByTaskId);
}

export function* watchGstDetailByGstNumber() {
  yield takeEvery(panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER, fetchGstDetailByGstNumber);
}

export function* watchFetchCustomers() {
  yield takeEvery(CustomerReduxTypes.FETCH_CUSTOMERS, fetchCustomers);
}

export function* watchFetchCustomer() {
  yield takeEvery(CustomerReduxTypes.FETCH_CUSTOMER, fetchCustomer);
}

export function* watchFetchCustomerVersions() {
  yield takeEvery(CustomerReduxTypes.FETCH_CUSTOMER_VERSION, fetchCustomerVersions);
}

export function* watchApproveCutomerVersion() {
  yield takeEvery(CustomerReduxTypes.APPROVE_CUSTOMER, approveCustomer);
}
export function* watchRejectCutomerVersion() {
  yield takeEvery(CustomerReduxTypes.REJECT_CUSTOMER, rejectCustomer);
}

export function* watchValidteCustomer() {
  yield takeEvery(CustomerReduxTypes.VALIDATE_CUSTOMER, validateCustomer);
}

export function* watchCreateCustomer() {
  yield takeEvery(CustomerReduxTypes.CREATE_CUSTOMER, createCustomer);
}

export function* watchUpdateCustomer() {
  yield takeEvery(CustomerReduxTypes.UPDATE_CUSTOMER, updateCustomer);
}

export function* watchDeDuplicateCustomer() {
  yield takeEvery(CustomerReduxTypes.DEDUPLICATE_CUSTOMER, deDuplicateCustomer);
}

export function* watchCustomerBlock() {
  yield takeEvery(CustomerReduxTypes.BLOCK_CUSTOMER, customerBlock);
}

export function* watchCustomerFreeze() {
  yield takeEvery(CustomerReduxTypes.FREEZE_CUSTOMER, customerFreeze);
}

export function* watchCustomerUnFreeze() {
  yield takeEvery(CustomerReduxTypes.UNFREEZE_CUSTOMER, customerUnFreeze);
}

export function* watchFetchCustomerVersionsDetails() {
  yield takeEvery(CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS, fetchCustomerVersionsDetails);
}

export function* watchFetchCustomerLatestVersion() {
  yield takeEvery(CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION, fetchCustomerLatestVersions);
}

export function* watchDeleteCustomer() {
  yield takeEvery(CustomerReduxTypes.DELETE_CUSTOMER, deleteCustomer);
}

function* customerSaga() {
  yield all([
    fork(watchTaskIDByPanNumber),
    fork(watchTaskStatusByTaskId),
    fork(watchTaskResultByTaskId),
    fork(watchFetchCustomers),
    fork(watchFetchCustomer),
    fork(watchFetchCustomerVersions),
    fork(watchApproveCutomerVersion),
    fork(watchRejectCutomerVersion),
    fork(watchGstDetailByGstNumber),
    fork(watchValidteCustomer),
    fork(watchCreateCustomer),
    fork(watchUpdateCustomer),
    fork(watchDeDuplicateCustomer),
    fork(watchCustomerBlock),
    fork(watchCustomerFreeze),
    fork(watchCustomerUnFreeze),
    fork(watchFetchCustomerVersionsDetails),
    fork(watchFetchCustomerLatestVersion),
    fork(watchDeleteCustomer)
  ]);
}

export default customerSaga;
