import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../config";
import { CreateState, UpdateState } from "./types";

const api = new APICore();

function fetchStatesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states`;
  return api.get(`${baseUrl}`, params);
}

function fetchStateByIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createStateApi(params: CreateState) {
  let backendApiUrl = "";

  let apiData: any = {
    countryId: params.countryId ? params.countryId : "",
    name: params.name ? params.name.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/states`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/states?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
  // const baseUrl = `${config.BACKEND_BASE_URL}/states`;
  // return api.create(`${baseUrl}`, params);
}

function updateStateApi(params: any) {
  // console.log("params", params);

  let backendApiUrl = "";

  let apiData: any = {
    countryId: params.countryId ? params.countryId : params?.country?.id,
    name: params.name ? params.name.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/states/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/states/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
  // const baseUrl = `${config.BACKEND_BASE_URL}/states/${params.externalId}`;
  // return api.update(`${baseUrl}`, data);
}

function fetchStateVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}


function deleteStateApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params}`;
  return api.delete(`${baseUrl}`);
}


function fetchStateLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchStateVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, params);
}

function approveStateApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectStateApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function blockStateApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeStateApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeStateApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/states/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchStatesApi,
  fetchStateByIdApi,
  createStateApi,
  updateStateApi,
  fetchStateVersionApi,
  approveStateApi,
  rejectStateApi,
  blockStateApi,
  freezeStateApi,
  unFreezeStateApi,
  fetchStateVersionDetailsApi,
  fetchStateLatestVersionApi,
  deleteStateApi
};
