import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FetchPaymentBatchesRequest, PaymentBatchReduxTypes, reProcessingPaymentBatchRequest } from "./types";
import {
  approvePaymentBatchesApi,
  fetchPaymentBatchApi,
  fetchPaymentBatchAuditActionsApi,
  fetchPaymentBatchesApi,
  fetchVersionPaymentBatchesApi,
  markeRemarkReProcessingPaymentBatchesApi,
  rejectPaymentBatchesApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchPaymentBatches({ payload, type }: FetchPaymentBatchesRequest): SagaIterator {
  try {
    const response = yield call(fetchPaymentBatchesApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchPaymentBatch({ payload, type }: FetchPaymentBatchesRequest): SagaIterator {
  try {
    const response = yield call(fetchPaymentBatchApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchVersionPaymentBatches({ payload, type }: FetchPaymentBatchesRequest): SagaIterator {
  try {
    const response = yield call(fetchVersionPaymentBatchesApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchPaymentBatchAuditActions({payload,type}:FetchPaymentBatchesRequest):SagaIterator{
  try {
    const response = yield call(fetchPaymentBatchAuditActionsApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* markeRemarkReProcessingPaymentBatches({ payload, type }: reProcessingPaymentBatchRequest): SagaIterator {
  try {
    const response = yield call(markeRemarkReProcessingPaymentBatchesApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* aprovePaymentBatch({ payload, type }: reProcessingPaymentBatchRequest): SagaIterator {
  try {
    const response = yield call(approvePaymentBatchesApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectPaymentBatch({ payload, type }: reProcessingPaymentBatchRequest): SagaIterator {
  try {
    const response = yield call(rejectPaymentBatchesApi, payload);
    yield put({
      type: PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchPaymentBatches() {
  yield takeEvery(PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES, fetchPaymentBatches);
}

export function* watchFetchPaymentBatch() {
  yield takeEvery(PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH, fetchPaymentBatch);
}

export function* watchFetchVersionPaymentBatches() {
  yield takeEvery(PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES, fetchVersionPaymentBatches);
}

export function* watchfetchStagingBatchAuditActions() {
  yield takeEvery(PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS, fetchPaymentBatchAuditActions);
}

export function* watchAprovePaymentBatch() {
  yield takeEvery(PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH, aprovePaymentBatch);
}

export function* watchRejectPaymentBatch() {
  yield takeEvery(PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH, rejectPaymentBatch);
}

export function* watchmarkeRemarkReProcessingPaymentBatch() {
  yield takeEvery(PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH, markeRemarkReProcessingPaymentBatches);
}




function* paymentBatchSaga() {
  yield all([
    fork(watchFetchPaymentBatches),
    fork(watchFetchPaymentBatch),
    fork(watchfetchStagingBatchAuditActions),
    fork(watchmarkeRemarkReProcessingPaymentBatch),
    fork(watchFetchVersionPaymentBatches),
    fork(watchAprovePaymentBatch),
    fork(watchRejectPaymentBatch)
  ]);
}

export default paymentBatchSaga;
