import { FilterMetadata } from "redux/types";
import {
  CreaterelationshipManager,
  RelationshipManagerActionDispatch,
  RelationshipManagerReduxTypes,
  UpdateRelationshipManager,
  updateRelationshipManagerVersion
} from "./types";

export const fetchRelationshipManager = (payload: FilterMetadata): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER,
  payload: payload
});

export const fetchRelationshipManagerId = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ID,
  payload: payload
});

// relationshipManager

export const createrelationshipManager = (payload: CreaterelationshipManager): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER,
  payload: payload
});

export const updateRelationshipManager = (payload: UpdateRelationshipManager): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER,
  payload: payload
});


export const deleteRelationshipManager = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER,
  payload: payload
});


export const resetUpdateRelationshipManager = (): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.RESET_UPDATE_RELATIONSHIP_MANAGER,
  payload: {}
});

export const resetRelationshipManager = (): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.RESET,
  payload: {}
});

export const seachFilterRekationshipManager = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER,
  payload: payload
});

export const fetcRelationshipManagerVersions = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION,
  payload: payload
});

export const fetcRelationshipManagerLatestVersion = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION,
  payload: payload
});

export const approveRelationshipManagerVersions = (payload: updateRelationshipManagerVersion): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.APPROVE_RELATIONSHIP_MANAGER,
  payload: payload
});
export const rejectRelationshipManagerVersions = (payload: updateRelationshipManagerVersion): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER,
  payload: payload
});

export const fetchRMVersion = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION,
  payload: payload
});

export const fetchRMVersionDetails = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS,
  payload: payload
});

export const approveRM = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.APPROVE_RELATIONSHIP_MANAGER,
  payload: payload
});

export const rejectRM = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER,
  payload: payload
});

export const blockRM = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER,
  payload: payload
});

export const freezeRM = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER,
  payload: payload
});

export const unFreezeRM = (payload: any): RelationshipManagerActionDispatch => ({
  type: RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER,
  payload: payload
});
