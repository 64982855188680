import { Currency } from "redux/currency/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum HolidayBatchReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_HOLIDAY_BATCH = "@@auth/FETCH_HOLIDAY_BATCH",
  FETCH_HOLIDAY_BATCH_SUCCESS = "@@auth/FETCH_HOLIDAY_BATCH_SUCCESS",
  FETCH_HOLIDAY_BATCH_ERROR = "@@auth/FETCH_HOLIDAY_BATCH_ERROR",

  FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID = "@@auth/FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID",
  FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_SUCCESS = "@@auth/FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_SUCCESS",
  FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_ERROR = "@@auth/FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_ERROR",

  CREATE_HOLIDAY_BATCH = "@@auth/CREATE_HOLIDAY_BATCH",
  CREATE_HOLIDAY_BATCH_SUCCESS = "@@auth/CREATE_HOLIDAY_BATCH_SUCCESS",
  CREATE_HOLIDAY_BATCH_ERROR = "@@auth/CREATE_HOLIDAY_BATCH_ERROR",

  UPDATE_HOLIDAY_BATCH = "@@auth/UPDATE_HOLIDAY_BATCH",
  UPDATE_HOLIDAY_BATCH_SUCCESS = "@@auth/UPDATE_HOLIDAY_BATCH_SUCCESS",
  UPDATE_HOLIDAY_BATCH_ERROR = "@@auth/UPDATE_HOLIDAY_BATCH_ERROR",

  PUBLISH_HOLIDAY_BATCH = "@@auth/PUBLISH_HOLIDAY_BATCH",
  PUBLISH_HOLIDAY_BATCH_SUCCESS = "@@auth/PUBLISH_HOLIDAY_BATCH_SUCCESS",
  PUBLISH_HOLIDAY_BATCH_ERROR = "@@auth/PUBLISH_HOLIDAY_BATCH_ERROR",

  FETCH_HOLIDAY_BATCH_VERSION = "@@auth/FETCH_HOLIDAY_BATCH_VERSION",
  FETCH_HOLIDAY_BATCH_VERSION_SUCCESS = "@@auth/FETCH_HOLIDAY_BATCH_VERSION_SUCCESS",
  FETCH_HOLIDAY_BATCH_VERSION_ERROR = "@@auth/FETCH_HOLIDAY_BATCH_VERSION_ERROR",

  FETCH_HOLIDAY_BATCH_LATEST_VERSION = "@@auth/FETCH_HOLIDAY_BATCH_LATEST_VERSION",
  FETCH_HOLIDAY_BATCH_LATEST_VERSION_SUCCESS = "@@auth/FETCH_HOLIDAY_BATCH_LATEST_VERSION_SUCCESS",
  FETCH_HOLIDAY_BATCH_LATEST_VERSION_ERROR = "@@auth/FETCH_HOLIDAY_BATCH_LATEST_VERSION_ERROR",

  FETCH_HOLIDAY_BATCH_VERSION_DETAILS = "@@auth/FETCH_HOLIDAY_BATCH_VERSION_DETAILS",
  FETCH_HOLIDAY_BATCH_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_HOLIDAY_BATCH_VERSION_DETAILS_SUCCESS",
  FETCH_HOLIDAY_BATCH_VERSION_DETAILS_ERROR = "@@auth/FETCH_HOLIDAY_BATCH_VERSION_DETAILS_ERROR",

  APPROVE_HOLIDAY_BATCH = "@@auth/APPROVE_HOLIDAY_BATCH",
  APPROVE_HOLIDAY_BATCH_SUCCESS = "@@auth/APPROVE_HOLIDAY_BATCH_SUCCESS",
  APPROVE_HOLIDAY_BATCH_ERROR = "@@auth/APPROVE_HOLIDAY_BATCH_ERROR",

  REJECT_HOLIDAY_BATCH = "@@auth/REJECT_HOLIDAY_BATCH",
  REJECT_HOLIDAY_BATCH_SUCCESS = "@@auth/REJECT_HOLIDAY_BATCH_SUCCESS",
  REJECT_HOLIDAY_BATCH_ERROR = "@@auth/REJECT_HOLIDAY_BATCH_ERROR",

  BLOCK_HOLIDAY_BATCH = "@@auth/BLOCK_HOLIDAY_BATCH",
  BLOCK_HOLIDAY_BATCH_SUCCESS = "@@auth/BLOCK_HOLIDAY_BATCH_SUCCESS",
  BLOCK_HOLIDAY_BATCH_ERROR = "@@auth/BLOCK_HOLIDAY_BATCH_ERROR",

  FREEZE_HOLIDAY_BATCH = "@@auth/FREEZE_HOLIDAY_BATCH",
  FREEZE_HOLIDAY_BATCH_SUCCESS = "@@auth/FREEZE_HOLIDAY_BATCH_SUCCESS",
  FREEZE_HOLIDAY_BATCH_ERROR = "@@auth/FREEZE_HOLIDAY_BATCH_ERROR",

  UNFREEZE_HOLIDAY_BATCH = "@@auth/UNFREEZE_HOLIDAY_BATCH",
  UNFREEZE_HOLIDAY_BATCH_SUCCESS = "@@auth/UNFREEZE_HOLIDAY_BATCH_SUCCESS",
  UNFREEZE_HOLIDAY_BATCH_ERROR = "@@auth/UNFREEZE_HOLIDAY_BATCH_ERROR",

  FILTER_HOLIDAY_BATCH = "@@auth/FILTER_HOLIDAY_BATCH",
  FILTER_HOLIDAY_BATCH_SUCCESS = "@@auth/FILTER_HOLIDAY_BATCH_SUCCESS",
  FILTER_HOLIDAY_BATCH_ERROR = "@@auth/FILTER_HOLIDAY_BATCH_ERROR",

  RESET = "@@auth/RESET"
}

export type HolidayBacthctionDispatch = {
  type:
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_LATEST_VERSION
    | HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.RESET;
  payload: any;
};

export type HolidayBatchReducerDispatch = {
  type:
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_SUCCESS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_ERROR
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_SUCCESS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_ERROR
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_SUCCESS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_ERROR
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_LATEST_VERSION
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_LATEST_VERSION_SUCCESS
    | HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_LATEST_VERSION_ERROR
    | HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_SUCCESS
    | HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_ERROR
    | HolidayBatchReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<HolidayBatch>;
    error?: string;
  };
};

export type HolidayBatchReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<HolidayBatch>;
  error?: string | null;
};

export type HolidayBatchFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type HolidayBatch = {
  id: string;
  externalId: string;
  status: string;
  country: string;
  currency: Currency;
  date: string;
  comments: string;
  userId: string;
  active: boolean;
  inactiveReason: string;
  checkerRemarks: string;
  isDisabled: boolean;
  draft: boolean;
  makerRemarks: string;
  batchFilePath: string;
};

export type CreateHolidayBatch = {
  country: string;
  currency: string;
  date: string;
  comments: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreateHolidayBatchRequest = {
  payload: CreateHolidayBatch;
  type: string;
};
export type UpdateHolidayBatchRequest = {
  payload: HolidayBatch;
  type: string;
};
export type CreateHolidayBatchReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<HolidayBatch>;
  error?: string | null;
};

export type FetchHolidayBatchByExternalIdRequest = {
  payload: string;
  type: string;
};
export type holidayBatchVersionChangeRequest = {
  payload: updateHolidayBatchVersion;
  type: string;
};
export type updateHolidayBatchVersion = {
  checkerRemarks: string;
  externalId: string;
};
