import { CreatePaymentBatchReduxState, PaymentBatchReducerDispatch, PaymentBatchReduxState, PaymentBatchReduxTypes } from "./types";

const PAYMENT_BATCH_LIST_INIT_STATE = {
  loading: true,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const CREATE_PAYMENT_BATCH_LIST_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const PaymentBatchListReducer = (state: PaymentBatchReduxState = PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PaymentBatchReducer = (state: PaymentBatchReduxState = PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PaymentBatchAuditActionsReducer=(state: PaymentBatchReduxState = PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch)=>{
  switch (action.type) {
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS: {
      return {
        ...state,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const MarkeRemarkReProcessingPaymentBatchReducer = (state: CreatePaymentBatchReduxState = CREATE_PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PaymentBatchVersionListReducer = (state: PaymentBatchReduxState = PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApprovalPaymentBatchReducer = (state: CreatePaymentBatchReduxState = CREATE_PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectPaymentBatchReducer = (state: CreatePaymentBatchReduxState = CREATE_PAYMENT_BATCH_LIST_INIT_STATE, action: PaymentBatchReducerDispatch) => {
  switch (action.type) {
    case PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case PaymentBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  PaymentBatchListReducer,
  ApprovalPaymentBatchReducer,
  RejectPaymentBatchReducer,
  PaymentBatchVersionListReducer,
  PaymentBatchReducer,
  MarkeRemarkReProcessingPaymentBatchReducer,
  PaymentBatchAuditActionsReducer
};
