import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { fetchCityByStateIdApi, fetchStateByCountryIdApi } from './api';
import { CityReduxTypes, StateReduxTypes } from './type';


/**
 * Login the user
 * @param {*} payload - username and password
 */

 function* fetchStateByCountryId({payload}:any): SagaIterator {    
    try {
        const response = yield call(fetchStateByCountryIdApi,payload);
        yield put({
            type: StateReduxTypes.FETCH_STATE_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
        yield put({
            type: StateReduxTypes.FETCH_STATE_ERROR,
            payload: { error: error },
        });
    }
}

function* fetchCityByStateId({payload}:any): SagaIterator {
    
    try {
        const response = yield call(fetchCityByStateIdApi,payload);
        yield put({
            type: CityReduxTypes.FETCH_CITY_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
        yield put({
            type: CityReduxTypes.FETCH_CITY_ERROR,
            payload: { error: error },
        });
    }
}


export function* watchFetchCityByStateId() {
    yield takeEvery(CityReduxTypes.FETCH_CITY, fetchCityByStateId);
}
export function* watchFetchStateByCountryId() {
    yield takeEvery(StateReduxTypes.FETCH_STATE, fetchStateByCountryId);
}

function* currencySaga() {
    yield all([
        fork(watchFetchCityByStateId),
        fork(watchFetchStateByCountryId),
    ]);
}

export default currencySaga;
