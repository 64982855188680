import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  CreateDisbursementAuthRequest,
  FetchDisbursementAuthByExternalIdRequest,
  DisbursementAuthFetchRequest,
  DisbursementAuthReduxTypes,
  DisbursementAuthVersionChangeRequest,
  UpdateDisbursementAuthRequest
} from "./types";
import {
  approveDisbursementAuthApi,
  blockDisbursementAuthApi,
  createDisbursementAuthApi,
  fetchDisbursementAuthApi,
  fetchDisbursementAuthAuditActionsApi,
  fetchDisbursementAuthByExternalIdApi,
  fetchDisbursementAuthVersionApi,
  freezeDisbursementAuthApi,
  publishDisbursementAuthApi,
  rejectDisbursementAuthApi,
  unFreezeDisbursementAuthApi,
  updateDisbursementAuthApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchDisbursementAuth({ payload, type }: DisbursementAuthFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchDisbursementAuthApi, payload);
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}



function* FetchDisbursementAuthByExternalId({ payload, type }: FetchDisbursementAuthByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchDisbursementAuthByExternalIdApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchDisbursementAuthAuditActions({payload,type}:DisbursementAuthFetchRequest):SagaIterator{
  try {
    const response = yield call(fetchDisbursementAuthAuditActionsApi, payload);
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* createDisbursementAuth({ payload, type }: CreateDisbursementAuthRequest): SagaIterator {
  try {
    const response = yield call(createDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* updateDisbursementAuth({ payload, type }: UpdateDisbursementAuthRequest): SagaIterator {
  try {
    const response = yield call(updateDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* publishDisbursementAuth({ payload, type }: UpdateDisbursementAuthRequest): SagaIterator {
  try {
    const response = yield call(publishDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchDisbursementAuthVersion({ payload, type }: UpdateDisbursementAuthRequest): SagaIterator {
  try {
    const response = yield call(fetchDisbursementAuthVersionApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* approveDisbursementAuth({ payload, type }: DisbursementAuthVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectHoliodayBatch({ payload, type }: DisbursementAuthVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* blockHoliodayBatch({ payload, type }: DisbursementAuthVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeHoliodayBatch({ payload, type }: DisbursementAuthVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeHoliodayBatch({ payload, type }: DisbursementAuthVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeDisbursementAuthApi, payload);

    yield put({
      type: DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_ERROR,
      payload: { error: error }
    });
  }
}
export function* watchFetchDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH, fetchDisbursementAuth);
}

export function* watchFetchDisbursementAuthByExternalId() {
  yield takeEvery(DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID, FetchDisbursementAuthByExternalId);
}

export function* watchFetchDisbursementAuthAuditActions() {
  yield takeEvery(DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS, fetchDisbursementAuthAuditActions);
}

export function* watchCreateDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH, createDisbursementAuth);
}

export function* watchUpdateDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH, updateDisbursementAuth);
}

export function* watchBlockDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH, blockHoliodayBatch);
}

export function* watchFetchDisbursementAuthVersion() {
  yield takeEvery(DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION, fetchDisbursementAuthVersion);
}

export function* watchApproveDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH, approveDisbursementAuth);
}

export function* watchRejectDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH, rejectHoliodayBatch);
}

export function* watchFreezeDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH, freezeHoliodayBatch);
}

export function* watchUnFreezeDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH, unFreezeHoliodayBatch);
}
export function* watchPublishDisbursementAuth() {
  yield takeEvery(DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH, publishDisbursementAuth);
}




function* DisbursementAuthSaga() {
  yield all([
    fork(watchFetchDisbursementAuth),
    fork(watchCreateDisbursementAuth),
    fork(watchFetchDisbursementAuthByExternalId),
    fork(watchFetchDisbursementAuthAuditActions),
    fork(watchUpdateDisbursementAuth),
    fork(watchFetchDisbursementAuthVersion),
    fork(watchApproveDisbursementAuth),
    fork(watchRejectDisbursementAuth),
    fork(watchBlockDisbursementAuth),
    fork(watchFreezeDisbursementAuth),
    fork(watchUnFreezeDisbursementAuth),
    fork(watchPublishDisbursementAuth),
  ]);
}

export default DisbursementAuthSaga;
