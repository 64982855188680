import { FetchApiResponse, FilterMetadata } from "redux/types";
import { instrument } from "../instrumentBatch/types";

export enum InstrumentReduxTypes {
  FETCH_INSTRUMENTS = "@@auth/FETCH_INSTRUMENTS",
  FETCH_INSTRUMENTS_SUCCESS = "@@auth/FETCH_INSTRUMENTS_SUCCESS",
  FETCH_INSTRUMENTS_ERROR = "@@auth/FETCH_INSTRUMENTS_ERROR",

  FETCH_INSTRUMENT = "@@auth/FETCH_INSTRUMENT",
  FETCH_INSTRUMENT_SUCCESS = "@@auth/FETCH_INSTRUMENT_SUCCESS",
  FETCH_INSTRUMENT_ERROR = "@@auth/FETCH_INSTRUMENT_ERROR",

  RESET = "@@auth/RESET"
}

export type InstrumentActionDispatch = {
  type: InstrumentReduxTypes.FETCH_INSTRUMENT | InstrumentReduxTypes.FETCH_INSTRUMENTS | InstrumentReduxTypes.RESET;
  payload: any;
};

export type InstrumentReducerDispatch = {
  type:
    | InstrumentReduxTypes.FETCH_INSTRUMENT
    | InstrumentReduxTypes.FETCH_INSTRUMENT_SUCCESS
    | InstrumentReduxTypes.FETCH_INSTRUMENT_ERROR
    | InstrumentReduxTypes.FETCH_INSTRUMENTS
    | InstrumentReduxTypes.FETCH_INSTRUMENTS_SUCCESS
    | InstrumentReduxTypes.FETCH_INSTRUMENTS_ERROR
    | InstrumentReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<instrument>;
    error?: string;
  };
};

export type InstrumentReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<instrument>;
  error?: string | null;
};

export type InstrumentsFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type InstrumentFetchRequest = {
  payload: FilterMetadata;
  type: string;
};
