import config from "config";
import { APICore } from "helpers/api/apiCore";
import * as forge from "node-forge";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function login(params: { username: string; password: string }) {
  //public key for encryption

  var _publicKey =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkWMVe4Gs+Xr0V6kwxPFEozbikr6xaP5q/9eA/fvFGkx8kns63WoY2Dl/9FmuNmB/rVbvhryDvWlVRDZqRWcISqJwRsBJ6ObziIr+97DRRrDmPnHmVsxdUo5nEqJIhc47UfSpq6XmE3+w+Kk+iWYqCU1Wz53hGl3n9AWcgWFvE6QGct7mcHa7PD70spu5YUMi4K/ppdqepgzulzh10bZ/PlzmNTYO5io64Iz6jItus6CGMLpC6Ser1m6IQI9UduzNpk4g50jYTSBD5gLkK4uA/45KvFaBO+27QufBMpx82zVdwdNwcpr60V3ElfnR4zIE/JW9wwrMSE5LSiVxyAUCvQIDAQAB";
  var pem: any = `-----BEGIN PUBLIC KEY-----\n${_publicKey}\n-----END PUBLIC KEY-----`;
  var publicKey = forge.pki.publicKeyFromPem(pem);
  // var loginReq = JSON.stringify({ handle: "vay@email.com", handleType: "email", password: "secret" })
  var loginReq = JSON.stringify({ handle: params.username, handleType: "email", password: params.password });
  var buffer = forge.util.createBuffer(loginReq, "utf8");
  var bytes = buffer.getBytes();
  var encrypted = publicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");
  var b64Encoded = forge.util.encode64(encrypted);

  var loginData = {
    encryptedPayload: b64Encoded
  };
  // const __baseUrl = 'https://ed57-2405-201-2-ae21-b874-9900-a9d0-df86.in.ngrok.io/login';
  const __baseUrl = `${config.BACKEND_BASE_URL}/login`;
  return api.create(`${__baseUrl}`, loginData);
  // const baseUrl = '/login/';
  // return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = `${config.BACKEND_BASE_URL}/logout`;
  return api.update(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {  
  
  const baseUrl = `${config.BACKEND_BASE_URL}/users/reset-password`;
  return api.update(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = "/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

function refreshTokenApi() {
  const baseUrl = `${config.BACKEND_BASE_URL}/login`;
  return api.update(`${baseUrl}`, {});
}

function loginHistoryApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/session-history`;
  return api.get(`${baseUrl}`, params);
}

function changePasswordApi(params: any) {

  var _publicKey =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkWMVe4Gs+Xr0V6kwxPFEozbikr6xaP5q/9eA/fvFGkx8kns63WoY2Dl/9FmuNmB/rVbvhryDvWlVRDZqRWcISqJwRsBJ6ObziIr+97DRRrDmPnHmVsxdUo5nEqJIhc47UfSpq6XmE3+w+Kk+iWYqCU1Wz53hGl3n9AWcgWFvE6QGct7mcHa7PD70spu5YUMi4K/ppdqepgzulzh10bZ/PlzmNTYO5io64Iz6jItus6CGMLpC6Ser1m6IQI9UduzNpk4g50jYTSBD5gLkK4uA/45KvFaBO+27QufBMpx82zVdwdNwcpr60V3ElfnR4zIE/JW9wwrMSE5LSiVxyAUCvQIDAQAB";
  var pem: any = `-----BEGIN PUBLIC KEY-----\n${_publicKey}\n-----END PUBLIC KEY-----`;
  var publicKey = forge.pki.publicKeyFromPem(pem);
  // var loginReq = JSON.stringify({ handle: "vay@email.com", handleType: "email", password: "secret" })
  var loginReq = params.password;
  var buffer = forge.util.createBuffer(loginReq, "utf8");
  var bytes = buffer.getBytes();
  var encrypted = publicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");
  var b64Encoded = forge.util.encode64(encrypted);

  var passwordData = {
    password: b64Encoded
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/users/creds/${params.id}`;
  return api.update(`${baseUrl}`, passwordData);
}


export { login, logout, signup, forgotPassword, forgotPasswordConfirm, refreshTokenApi, loginHistoryApi, changePasswordApi };
