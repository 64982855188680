import { CreateApiResponse, FetchApiResponse } from "redux/types"

export enum ProgrammeSummaryReduxTypes {
    PROGRAMME_SUMMARY_REPORT = "@@auth/PROGRAMME_SUMMARY_REPORT",
    PROGRAMME_SUMMARY_REPORT_SUCCESS = "@@auth/PROGRAMME_SUMMARY_REPORT_SUCCESS",
    PROGRAMME_SUMMARY_REPORT_ERROR = "@@auth/PROGRAMME_SUMMARY_REPORT_ERROR"
}

export type ProgrammeSummaryActionDispatch = {
    type: | ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT
    payload: any
}
export type ProgrammeSummaryReducerDispatch = {
    type: | ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT
    | ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_SUCCESS
    | ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<ProgrammeSummary>
        error?: string
    }
}

export type ProgrammeSummary = {
    id: string
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}

export type CreateProgrammeSummary = {
    programId: string
}

export type CreateProgrammeSummaryRequest = {
    payload: CreateProgrammeSummary,
    type: string
}

export type CreateProgrammeSummaryReduxState = {
    loading?: boolean,
    data?: CreateApiResponse<ProgrammeSummary>
    error?: string | null
}