export enum AuthActionTypes {
    API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER = '@@auth/LOGIN_USER',
    LOGOUT_USER = '@@auth/LOGOUT_USER',
    SIGNUP_USER = '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD = '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE = '@@auth/FORGOT_PASSWORD_CHANGE',
   
    REFRESH_TOKEN = '@@auth/REFRESH_TOKEN',
    REFRESH_TOKEN_SUCCESS = '@@auth/REFRESH_TOKEN_SUCCESS',
    REFRESH_TOKEN_ERROR='@@auth/REFRESH_TOKEN_ERROR',


    LOGIN_HISTORY = '@@auth/LOGIN_HISTORY',
    LOGIN_HISTORY_SUCCESS = '@@auth/LOGIN_HISTORY_SUCCESS',
    LOGIN_HISTORY_ERROR='@@auth/LOGIN_HISTORY_ERROR',


    CHANGE_PASSWORD = '@@auth/CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS = '@@auth/CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_ERROR='@@auth/CHANGE_PASSWORD_ERROR',
    RESET_CHANGE_PASSWORD = '@@auth/RESET_CHANGE_PASSWORD',
    RESET_PASSWORD="@@auth/RESET_PASSWORD",
    RESET_PASSWORD_SUCCESS="@@auth/RESET_PASSWORD_Success",
    RESET_PASSWORD_ERROR="@@auth/RESET_PASSWORD_ERROR",

    FORCE_LOGOUT = '@@auth/FORCE_LOGOUT',
    FORCE_LOGOUT_SUCCESS = '@@auth/FORCE_LOGOUT_SUCCESS',
    FORCE_LOGOUT_ERROR='@@auth/FORCE_LOGOUT_ERROR',
    RESET_FORCE_LOGOUT = '@@auth/RESET_FORCE_LOGOUT',



    RESET = '@@auth/RESET',
}
