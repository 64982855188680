import { CreateStagingBatchReduxState, StagingBatchReducerDispatch, StagingBatchReduxState, StagingBatchReduxTypes, StagingBatchReprocessReduxState } from "./types";

const STAGING_BATCH_LIST_INIT_STATE = {
  loading: false,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const CREATE_STAGING_BATCH_LIST_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const StagingBatchListReducer = (state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_STAGING_BATCH_LIST:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingBatchChildListReducer = (state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_FETCH_STAGING_BATCHES_CHILD: {
      return {
        data:{},
        error: {},
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_STAGING_BATCH_LIST:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingBatchReducer = (state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_STAGING_BATCH:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingBatchAuditActionsReducer=(state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch)=>{
  switch (action.type) {
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS: {
      return {
        ...state,
        loading: true
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_STAGING_BATCH_AUDIT_ACTIONS:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const STAGING_BATCH_REPROCESS_INIT_STATE = {
  loading: false,
  data: "",
  error: null
};

const StagingBatchReprocessingReducer = (state: StagingBatchReprocessReduxState = STAGING_BATCH_REPROCESS_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_REPROCESSING:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingBatchSkipValidationReducer = (state: StagingBatchReprocessReduxState = STAGING_BATCH_REPROCESS_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_SKIP_VALIDATION:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DisburseStagingBatchesReducer = (state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_DISBURSEMENT:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingBatchVersionReducer = (state: StagingBatchReduxState = STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const MakerRemarkStagingBatchSkipValidationReducer = (state: CreateStagingBatchReduxState = CREATE_STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.MARKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_SKIP_VALIDATION:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const MakerRemarkDisburseStagingBatchesReducer = (state: CreateStagingBatchReduxState = CREATE_STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_DISBURSEMENT:
      return {
        ...state,
        data: null,
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveStagingBatchesReducer = (state: CreateStagingBatchReduxState = CREATE_STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.APROVE_STAGING_BATCHES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.APROVE_STAGING_BATCHES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.APROVE_STAGING_BATCHES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_APROVE_STAGING_BATCHES:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

const RejectStagingBatchesReducer = (state: CreateStagingBatchReduxState = CREATE_STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch) => {
  switch (action.type) {
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_REJECT_STAGING_BATCHES:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const UpdateStagingBatchLimitTypeReducer=(state: CreateStagingBatchReduxState = CREATE_STAGING_BATCH_LIST_INIT_STATE, action: StagingBatchReducerDispatch)=>{
  switch(action.type){
    case StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingBatchReduxTypes.RESET_UPDATE_STAGING_BATCH_LIMIT_TYPE:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
}
export {
  StagingBatchListReducer,
  StagingBatchReducer,
  StagingBatchSkipValidationReducer,
  StagingBatchReprocessingReducer,
  DisburseStagingBatchesReducer,
  MakerRemarkStagingBatchSkipValidationReducer,
  MakerRemarkDisburseStagingBatchesReducer,
  StagingBatchVersionReducer,
  AproveStagingBatchesReducer,
  RejectStagingBatchesReducer,
  StagingBatchChildListReducer,
  UpdateStagingBatchLimitTypeReducer,
  StagingBatchAuditActionsReducer
};
