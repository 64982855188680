
const config: any = {
  BACKEND_BASE_URL: process.env.REACT_APP_BACKEND_BASE_URL,
  BACKEND_TXN_BASE_URL: process.env.REACT_APP_BACKEND_TXN_BASE_URL,
  EOD_BASE_URL: process.env.REACT_APP_EOD_BASE_URL,
  REPAYMENT_BASE_URL: process.env.REACT_APP_REPAYMENT_BASE_URL,
  EOD_JOB_WAIT_TIME_IN_MINUTES: process.env.REACT_APP_EOD_JOB_WAIT_TIME_IN_MINUTES,
  EOD_PULLER_JOB_START_TIME_HOUR: process.env.REACT_APP_EOD_PULLER_JOB_START_TIME_HOUR, //use 24 hours format for setting the starting time of the job
  EOD_PULLER_JOB_START_TIME_MINUTE: process.env.REACT_APP_EOD_PULLER_JOB_START_TIME_MINUTE, //use 24 hours format for setting the starting time of the job
  GSTIN_FEATURE_FLAG: process.env.REACT_APP_GSTIN_FEATURE_FLAG,
  // BACKEND_BASE_URL:"https://503d-2405-201-2-a2f4-fcfd-c8b7-664b-86b1.in.ngrok.io", //oswald
  // BACKEND_BASE_URL:"https://edc3-103-93-193-90.in.ngrok.io", //Harish Sir
  DISABLED_MENU: process.env.REACT_APP_DISABLED_MENU || "",
  ALLOWED_TIMEZONES: process.env.REACT_APP_ALLOWED_TIMEZONES?.split(",") || [],
  ALLOWED_LOCALES: process.env.REACT_APP_ALLOWED_LOCALES?.split(",") || []
};

export default config;
