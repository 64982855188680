import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  BaseRateFetchRequest,
  BaseRateReduxTypes,
  baseRateVersionChangeRequest,
  CreateBaseRateRequest,
  FetchBaseRateByExternalIdRequest,
  UpdateBaseRateRequest
} from "./types";
import {
  approveBaseRateApi,
  blockBaseRateApi,
  createBaseRateApi,
  deleteBaseRateApi,
  fetchBaseRateApi,
  fetchBaseRateLatestVersionApi,
  fetchBaseRatesApi,
  fetchBaseRateVersionApi,
  fetchBaseRateVersionDetailsApi,
  freezeBaseRateApi,
  rejectBaseRateApi,
  unFreezeBaseRateApi,
  updateBaseRateApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchBaseRate({ payload, type }: BaseRateFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchBaseRatesApi, payload);
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(BaseRateReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATES_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchFBaseRateByExternalId({ payload, type }: FetchBaseRateByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

export function* createBaseRate({ payload, type }: CreateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(createBaseRateApi, payload);
    // console.log("saga",payload);

    yield put({
      type: BaseRateReduxTypes.CREATE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.CREATE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* updtaeBaseRate({ payload, type }: UpdateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(updateBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.UPDATE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.UPDATE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteBaseRate({ payload, type }: UpdateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(deleteBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.DELETE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.DELETE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBaseRateVersion({ payload, type }: UpdateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(fetchBaseRateVersionApi, payload);

    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}



function* fetchBaseRateLatestVersion({ payload, type }: UpdateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(fetchBaseRateLatestVersionApi, payload);

    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBaseRateVersionDetaiils({ payload, type }: UpdateBaseRateRequest): SagaIterator {
  try {
    const response = yield call(fetchBaseRateVersionDetailsApi, payload);

    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveBaseRate({ payload, type }: baseRateVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.APPROVE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.APPROVE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectBaseRate({ payload, type }: baseRateVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.REJECT_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.REJECT_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* blockBaseRate({ payload, type }: baseRateVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.BLOCK_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.BLOCK_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeBaseRate({ payload, type }: baseRateVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.FREEZE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.FREEZE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeBaseRate({ payload, type }: baseRateVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeBaseRateApi, payload);

    yield put({
      type: BaseRateReduxTypes.UNFREEZE_BASE_RATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BaseRateReduxTypes.UNFREEZE_BASE_RATE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchBaseRates() {
  yield takeEvery(BaseRateReduxTypes.FETCH_BASE_RATES, fetchBaseRate);
}

export function* watchCreateBaseRate() {
  yield takeEvery(BaseRateReduxTypes.CREATE_BASE_RATE, createBaseRate);
}

export function* watchFetchBaseRate() {
  yield takeEvery(BaseRateReduxTypes.FETCH_BASE_RATE, FetchFBaseRateByExternalId);
}

export function* watchUpdateBaseRate() {
  yield takeEvery(BaseRateReduxTypes.UPDATE_BASE_RATE, updtaeBaseRate);
}

export function* watchFetchBaseRateVersion() {
  yield takeEvery(BaseRateReduxTypes.FETCH_BASE_RATE_VERSION, fetchBaseRateVersion);
}

export function* watchApproveBaseRateVersion() {
  yield takeEvery(BaseRateReduxTypes.APPROVE_BASE_RATE, approveBaseRate);
}

export function* watchRejectBaseRateVersion() {
  yield takeEvery(BaseRateReduxTypes.REJECT_BASE_RATE, rejectBaseRate);
}

export function* watchBlockBaseRate() {
  yield takeEvery(BaseRateReduxTypes.BLOCK_BASE_RATE, blockBaseRate);
}
export function* watchFreezeBaseRate() {
  yield takeEvery(BaseRateReduxTypes.FREEZE_BASE_RATE, freezeBaseRate);
}

export function* watchUnFreezeBaseRate() {
  yield takeEvery(BaseRateReduxTypes.UNFREEZE_BASE_RATE, unFreezeBaseRate);
}

export function* watchFetchBaseRateVersionDetaiils() {
  yield takeEvery(BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS, fetchBaseRateVersionDetaiils);
}


export function* watchFetchBaseRateLatestVersion() {
  yield takeEvery(BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION, fetchBaseRateLatestVersion);
}



export function* watchDeleteBaseRate() {
  yield takeEvery(BaseRateReduxTypes.DELETE_BASE_RATE, deleteBaseRate);
}









function* baseRateSaga() {
  yield all([
    fork(watchFetchBaseRates),
    fork(watchFetchBaseRate),
    fork(watchCreateBaseRate),
    fork(watchUpdateBaseRate),
    fork(watchFetchBaseRateVersion),
    fork(watchApproveBaseRateVersion),
    fork(watchRejectBaseRateVersion),
    fork(watchBlockBaseRate),
    fork(watchFreezeBaseRate),
    fork(watchUnFreezeBaseRate),
    fork(watchFetchBaseRateVersionDetaiils),
    fork(watchFetchBaseRateLatestVersion),
    fork(watchDeleteBaseRate)
  ]);
}

export default baseRateSaga;
