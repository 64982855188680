import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import {
  aproveCustomerContactsApi,
  blockCustomerContactApi,
  createCustomerContactsApi,
  deleteCustomerContactsApi,
  fetchCustomerContactsApi,
  fetchCustomerContactsIDApi,
  fetchCustomerContactsVersionApi,
  fetchCustomerContactsVersionDatailApi,
  freezeCustomerContactApi,
  rejectCustomerContactsApi,
  unFreezeCustomerContactApi,
  updateCustomerContact
} from "./api";
import {
  CreateCustomerContactRequest,
  CustomerContactFetchRequest,
  CustomerContactsReduxTypes,
  FetchCustomerContactByIdRequest,
  UpdateCustomerContactrsequest
} from "./types";

function* createCustomerContacts({ payload, type }: CreateCustomerContactRequest): SagaIterator {
  try {
    const response = yield call(createCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteCustomerContacts({ payload, type }: CreateCustomerContactRequest): SagaIterator {
  try {
    const response = yield call(deleteCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerContacts({ payload, type, customerId }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerContactId({ payload, type }: FetchCustomerContactByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsIDApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(updateCustomerContact, payload);

    yield put({
      type: CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerContactsVersion({ payload, type }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsVersionApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerContactsVersionDatails({ payload, type }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsVersionDatailApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(aproveCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(blockCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCustomerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}
//

function* createPartnerContacts({ payload, type }: CreateCustomerContactRequest): SagaIterator {
  try {
    const response = yield call(createCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

//
function* fetchPartnerContacts({ payload, type, customerId }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchPartnerContactById({ payload, type }: FetchCustomerContactByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsIDApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdatePartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(updateCustomerContact, payload);

    yield put({
      type: CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchPartnerContactVersion({ payload, type }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsVersionApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aprovePartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(aproveCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_PARTNER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_PARTNER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectPartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.REJECT_PARTNER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.REJECT_PARTNER_ERROR,
      payload: { error: error }
    });
  }
}

function* blockPartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(blockCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.BLOCK_PARTNER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.BLOCK_PARTNER_ERROR,
      payload: { error: error }
    });
  }
}
function* freezePartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FREEZE_PARTNER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FREEZE_PARTNER_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezePartnerContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_PARTNER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_PARTNER_ERROR,
      payload: { error: error }
    });
  }
}

//
function* createKeyManagmentContacts({ payload, type }: CreateCustomerContactRequest): SagaIterator {
  try {
    const response = yield call(createCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchKeyManagementContacts({ payload, type, customerId }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchKeyManagementContactById({ payload, type }: FetchCustomerContactByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsIDApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateKeyManagementContact({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(updateCustomerContact, payload);

    yield put({
      type: CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchKeyManagementVersion({ payload, type }: CustomerContactFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerContactsVersionApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveKeyManagement({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(aproveCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectKeyManagement({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerContactsApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* blockKeyManagement({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(blockCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeKeyManagement({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeKeyManagement({ payload, type }: UpdateCustomerContactrsequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerContactApi, payload);

    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchCreateCreateCustomerContact() {
  yield takeEvery(CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT, createCustomerContacts);
}

export function* watchFetchCustomerContact() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS, fetchCustomerContacts);
}

export function* watchFetchCustomerId() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT, fetchCustomerContactId);
}
export function* watchUpdateCustomerId() {
  yield takeEvery(CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS, UpdateCustomerContact);
}

export function* watchFetchCustomerVersion() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION, fetchCustomerContactsVersion);
}

//

export function* watchCreatePartnerContact() {
  yield takeEvery(CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT, createPartnerContacts);
}

export function* watchFetchPartnerContacts() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS, fetchPartnerContacts);
}

export function* watchFetchPartnerContactById() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT, fetchPartnerContactById);
}

export function* watchUpdatePatnerContact() {
  yield takeEvery(CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS, UpdatePartnerContact);
}

export function* watchFetchPatnerVersion() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_PARTNER_VERSION, fetchPartnerContactVersion);
}
export function* watchaprovePatner() {
  yield takeEvery(CustomerContactsReduxTypes.ARPOVE_PARTNER, aprovePartnerContact);
}

export function* watchrejectPatner() {
  yield takeEvery(CustomerContactsReduxTypes.REJECT_PARTNER, rejectPartnerContact);
}

export function* watchBlockPatner() {
  yield takeEvery(CustomerContactsReduxTypes.BLOCK_PARTNER, blockPartnerContact);
}

export function* watchFreezePatner() {
  yield takeEvery(CustomerContactsReduxTypes.FREEZE_PARTNER, freezePartnerContact);
}

export function* watchunFreezePatner() {
  yield takeEvery(CustomerContactsReduxTypes.UNFREEZE_PARTNER, unFreezePartnerContact);
}

//
export function* watchCreateKeyManagmentContact() {
  yield takeEvery(CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT, createKeyManagmentContacts);
}
export function* watchFetchKeyManagmentContacts() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS, fetchKeyManagementContacts);
}

export function* watchFetchKeyManagmentContactById() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT, fetchKeyManagementContactById);
}

export function* watchUpdateKeyManagmentContact() {
  yield takeEvery(CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS, UpdateKeyManagementContact);
}

export function* watchaproveContact() {
  yield takeEvery(CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT, aproveCustomerContact);
}

export function* watchrejectContact() {
  yield takeEvery(CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT, rejectCustomerContact);
}

export function* watchBlockContact() {
  yield takeEvery(CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT, blockCustomerContact);
}

export function* watchFreezeContact() {
  yield takeEvery(CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT, freezeCustomerContact);
}

export function* watchUnFreezeContact() {
  yield takeEvery(CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT, unFreezeCustomerContact);
}

//

export function* watchFetchKeyManagmentVersion() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION, fetchKeyManagementVersion);
}
export function* watchaproveKeyManagment() {
  yield takeEvery(CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT, aproveKeyManagement);
}

export function* watchrejectKeyManagment() {
  yield takeEvery(CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT, rejectKeyManagement);
}

export function* watchBlockKeyManagment() {
  yield takeEvery(CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT, blockKeyManagement);
}

export function* watchFreezeKeyManagment() {
  yield takeEvery(CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT, freezeKeyManagement);
}

export function* watchUnFreezeKeyManagment() {
  yield takeEvery(CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT, unFreezeKeyManagement);
}

//

export function* watchFetchCustomerContactsVersionDatails() {
  yield takeEvery(CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS, fetchCustomerContactsVersionDatails);
}

export function* watchDeleteCustomerContacts() {
  yield takeEvery(CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT, deleteCustomerContacts);
}

function* CustomerContactSaga() {
  yield all([
    fork(watchCreateCreateCustomerContact),
    fork(watchFetchCustomerContact),
    fork(watchFetchCustomerId),
    fork(watchUpdateCustomerId),
    fork(watchCreatePartnerContact),
    fork(watchCreateKeyManagmentContact),
    fork(watchFetchKeyManagmentContacts),
    fork(watchFetchKeyManagmentContactById),
    fork(watchUpdateKeyManagmentContact),
    fork(watchFetchPartnerContacts),
    fork(watchFetchPartnerContactById),
    fork(watchUpdatePatnerContact),
    fork(watchFetchCustomerVersion),
    fork(watchaproveContact),
    fork(watchrejectContact),
    fork(watchBlockContact),
    fork(watchFreezeContact),

    fork(watchFetchKeyManagmentVersion),
    fork(watchaproveKeyManagment),
    fork(watchrejectKeyManagment),
    fork(watchBlockKeyManagment),
    fork(watchFreezeKeyManagment),

    fork(watchFetchPatnerVersion),
    fork(watchaprovePatner),
    fork(watchrejectPatner),
    fork(watchBlockPatner),
    fork(watchFreezePatner),
    fork(watchUnFreezeKeyManagment),
    fork(watchunFreezePatner),
    fork(watchUnFreezeContact),

    fork(watchFetchCustomerContactsVersionDatails),
    fork(watchDeleteCustomerContacts)
  ]);
}
export default CustomerContactSaga;
