import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { BranchFetchRequest, BranchReduxTypes, branchVersionChangeRequest, CreateBranchRequest, FetchBranchByExternalIdRequest, UpdateBranchRequest } from "./types";
import {
  approveBranchApi,
  blockBranchApi,
  createBranchApi,
  deleteBranchApi,
  fetchbranchApi,
  fetchBranchByExternalIdApi,
  fetchBranchLatestVersionApi,
  fetchBranchVersionApi,
  fetchBranchVersionApiDetails,
  freezeBranchApi,
  rejectBranchApi,
  unFreezeBranchApi,
  updateBranchApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchBranch({ payload, type }: BranchFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchbranchApi, payload);
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchBranchByExternalId({ payload, type }: FetchBranchByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchBranchByExternalIdApi, payload);

    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createBranch({ payload, type }: CreateBranchRequest): SagaIterator {
  try {
    const response = yield call(createBranchApi, payload);
    yield put({
      type: BranchReduxTypes.CREATE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.CREATE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* updateBranch({ payload, type }: UpdateBranchRequest): SagaIterator {
  try {
    const response = yield call(updateBranchApi, payload);

    yield put({
      type: BranchReduxTypes.UPDATE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.UPDATE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBranchVersion({ payload, type }: UpdateBranchRequest): SagaIterator {
  try {
    const response = yield call(fetchBranchVersionApi, payload);

    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBranchVersionDetails({ payload, type }: UpdateBranchRequest): SagaIterator {
  try {
    const response = yield call(fetchBranchVersionApiDetails, payload);

    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBranchLatestVersion({ payload, type }: UpdateBranchRequest): SagaIterator {
  try {
    const response = yield call(fetchBranchLatestVersionApi, payload);

    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* approveBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveBranchApi, payload);

    yield put({
      type: BranchReduxTypes.APPROVE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.APPROVE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(deleteBranchApi, payload);

    yield put({
      type: BranchReduxTypes.DELETE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.DELETE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}


function* rejectBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectBranchApi, payload);

    yield put({
      type: BranchReduxTypes.REJECT_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.REJECT_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* blockBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockBranchApi, payload);

    yield put({
      type: BranchReduxTypes.BLOCK_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.BLOCK_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeBranchApi, payload);

    yield put({
      type: BranchReduxTypes.FREEZE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.FREEZE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

function* UnfreezeBranch({ payload, type }: branchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeBranchApi, payload);

    yield put({
      type: BranchReduxTypes.UNFREEZE_BRANCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BranchReduxTypes.UNFREEZE_BRANCH_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchBranch() {
  yield takeEvery(BranchReduxTypes.FETCH_BRANCH, fetchBranch);
}

export function* watchFetchBranchByExternalID() {
  yield takeEvery(BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID, FetchBranchByExternalId);
}

export function* watchUpdateBranch() {
  yield takeEvery(BranchReduxTypes.UPDATE_BRANCH, updateBranch);
}

export function* watchCreatehBranch() {
  yield takeEvery(BranchReduxTypes.CREATE_BRANCH, createBranch);
}

export function* watchFetchBranchVersion() {
  yield takeEvery(BranchReduxTypes.FETCH_BRANCH_VERSION, fetchBranchVersion);
}

export function* watchApproveBranchVersion() {
  yield takeEvery(BranchReduxTypes.APPROVE_BRANCH, approveBranch);
}

export function* watchRejectBranchVersion() {
  yield takeEvery(BranchReduxTypes.REJECT_BRANCH, rejectBranch);
}

export function* watchBlockBranch() {
  yield takeEvery(BranchReduxTypes.BLOCK_BRANCH, blockBranch);
}

export function* watchFreezeBranch() {
  yield takeEvery(BranchReduxTypes.FREEZE_BRANCH, freezeBranch);
}

export function* watchUnFreezeBranch() {
  yield takeEvery(BranchReduxTypes.UNFREEZE_BRANCH, UnfreezeBranch);
}

export function* watchfetchBranchVersionDetails() {
  yield takeEvery(BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS, fetchBranchVersionDetails);
}

export function* watchFetchBranchLatestVersion() {
  yield takeEvery(BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION, fetchBranchLatestVersion);
}

export function* watchDeleteBranch() {
  yield takeEvery(BranchReduxTypes.DELETE_BRANCH, deleteBranch);
}




function* currencySaga() {
  yield all([
    fork(watchFetchBranch),
    fork(watchCreatehBranch),
    fork(watchFetchBranchByExternalID),
    fork(watchFetchBranchVersion),
    fork(watchApproveBranchVersion),
    fork(watchRejectBranchVersion),
    fork(watchUpdateBranch),
    fork(watchBlockBranch),
    fork(watchFreezeBranch),
    fork(watchUnFreezeBranch),
    fork(watchfetchBranchVersionDetails),
    fork(watchFetchBranchLatestVersion),
    fork(watchDeleteBranch)
  ]);
}

export default currencySaga;
