import config from "config";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();
function createDisbursementAdviceApi(params: any) {

    let apiData: any = {
        startDate: params.startDate,
        endDate: params.endDate,
        programExternalId: params.programmeId.length ? params.programmeId : null,
        counterpartyExternalId: params.customerId.length ? params.customerId : null,
        anchorExternalId: params.anchorId.length ? params.anchorId : null
    }

    let backendApiUrl = `${config.BACKEND_BASE_URL}/report/disbursement-advice`
    return api.create(`${backendApiUrl}`, apiData)
}
export { createDisbursementAdviceApi }