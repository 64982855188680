
import { FilterMetadata } from "redux/types";
import { BuyerSellerActionDispatch, BuyerSellerReduxTypes, updateBuyerSellerVersion } from "./types";

export const fetchBuyerSellers = (payload: FilterMetadata): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH_BUYER_SELLERS,
    payload: payload,
});

export const fetchBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH_BUYER_SELLER,
    payload: payload,
});

export const resetSingleBuyerSeller = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET_BUYER_SELLER,
    payload: {},
});

export const fetchBuyerSellerVersions = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION,
    payload: payload,
});


export const fetchBuyerSellerLatestVersion = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION,
    payload: payload,
});


export const fetchBuyerSellerVersionsDetails = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS,
    payload: payload,
});

export const validateBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER,
    payload: payload,
});
export const createBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.CREATE_BUYER_SELLER,
    payload: payload,
});

export const resetBuyerSeller = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET,
    payload: {},
});
export const resetBuyerSellerValidation = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET,
    payload: {},
});
export const resetBuyerSellerCreationData = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET_CREATED_BUYER_SELLER_REDUCER,
    payload: {},
});


export const approveBuyerSellerVersions = (payload: updateBuyerSellerVersion): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.APROVE_BUYER_SELLER,
    payload: payload,
});


export const deleteBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.DELETE_BUYER_SELLER,
    payload: payload,
});

export const rejectBuyerSellerVersions = (payload: updateBuyerSellerVersion): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.REJECT_BUYER_SELLER,
    payload: payload,
});


export const blockBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.BLOCK_BUYER_SELLER,
    payload: payload,
});


export const updateBuyerSeller = (payload: any): BuyerSellerActionDispatch => (

    {
        type: BuyerSellerReduxTypes.UPDATE_BUYER_SELLER,
        payload: payload,
    });

export const resetBuyerSellerUpdateData = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER,
    payload: {},
});



export const freezeBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FREEZE_BUYER_SELLER,
    payload: payload,
});


export const unFreezeBuyerSeller = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER,
    payload: payload,
});



export const fetchPartialDisbursementAllowed = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER,
    payload: payload,
});

export const resetPartialDisbursementAllowed = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER,
    payload: {},
});


export const fetchConfigValue = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER,
    payload: payload,
});

export const createRelationAccountNumber = (payload: any): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS,
    payload: payload,
});

export const resetConfigValue = (): BuyerSellerActionDispatch => ({
    type: BuyerSellerReduxTypes.RESET_CONFIG_VALUE_BUYER_SELLER,
    payload: {},
});