import { Currency } from "redux/currency/types";
import { Customer } from "redux/customer/types";
import { ProductType } from "redux/productType/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum LimitConfigReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_LIMIT_CONFIG = "@@auth/FETCH_LIMIT_CONFIG",
  FETCH_LIMIT_CONFIG_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_SUCCESS",
  FETCH_LIMIT_CONFIG_ERROR = "@@auth/FETCH_LIMIT_CONFIG_ERROR",

  FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID = "@@auth/FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID",
  FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_SUCCESS",
  FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_ERROR = "@@auth/FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_ERROR",

  CREATE_LIMIT_CONFIG = "@@auth/CREATE_LIMIT_CONFIG",
  CREATE_LIMIT_CONFIG_SUCCESS = "@@auth/CREATE_LIMIT_CONFIG_SUCCESS",
  CREATE_LIMIT_CONFIG_ERROR = "@@auth/CREATE_LIMIT_CONFIG_ERROR",


  DELETE_LIMIT_CONFIG = "@@auth/DELETE_LIMIT_CONFIG",
  DELETE_LIMIT_CONFIG_SUCCESS = "@@auth/DELETE_LIMIT_CONFIG_SUCCESS",
  DELETE_LIMIT_CONFIG_ERROR = "@@auth/DELETE_LIMIT_CONFIG_ERROR",


  FETCH_SINGLE_LIMIT_CONFIG = "@@auth/FETCH_SINGLE_LIMIT_CONFIG",
  FETCH_SINGLE_LIMIT_CONFIG_SUCCESS = "@@auth/FETCH_SINGLE_LIMIT_CONFIG_SUCCESS",
  FETCH_SINGLE_LIMIT_CONFIG_ERROR = "@@auth/FETCH_SINGLE_LIMIT_CONFIG_ERROR",

  UPDATE_LIMIT_CONFIG = "@@auth/UPDATE_LIMIT_CONFIG",
  UPDATE_LIMIT_CONFIG_SUCCESS = "@@auth/UPDATE_LIMIT_CONFIG_SUCCESS",
  UPDATE_LIMIT_CONFIG_ERROR = "@@auth/UPDATE_LIMIT_CONFIG_ERROR",

  FETCH_LIMIT_CONFIG_HISTROY = "@@auth/FETCH_LIMIT_CONFIG_HISTROY",
  FETCH_LIMIT_CONFIG_HISTROY_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_HISTROY_SUCCESS",
  FETCH_LIMIT_CONFIG_HISTROY_ERROR = "@@auth/UPDATE_LIMIT_CONFIG_HISTROY_ERROR",

  FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY = "@@auth/FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY",
  FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_SUCCESS = "@@auth/FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_SUCCESS",
  FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_ERROR = "@@auth/UPDATE_AGGREGATE_LIMIT_CONFIG_HISTROY_ERROR",

  FETCH_LIMIT_CONFIG_VERSION = "@@auth/FETCH_LIMIT_CONFIG_VERSION",
  FETCH_LIMIT_CONFIG_VERSION_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_VERSION_SUCCESS",
  FETCH_LIMIT_CONFIG_VERSION_ERROR = "@@auth/FETCH_LIMIT_CONFIG_VERSION_ERROR",

  FETCH_LIMIT_CONFIG_LATEST_VERSION = "@@auth/FETCH_LIMIT_CONFIG_LATEST_VERSION",
  FETCH_LIMIT_CONFIG_LATEST_VERSION_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_LATEST_VERSION_SUCCESS",
  FETCH_LIMIT_CONFIG_LATEST_VERSION_ERROR = "@@auth/FETCH_LIMIT_CONFIG_LATEST_VERSION_ERROR",

  FETCH_LIMIT_CONFIG_VERSION_DETAILS = "@@auth/FETCH_LIMIT_CONFIG_VERSION_DETAILS",
  FETCH_LIMIT_CONFIG_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_LIMIT_CONFIG_VERSION_DETAILS_SUCCESS",
  FETCH_LIMIT_CONFIG_VERSION_DETAILS_ERROR = "@@auth/FETCH_LIMIT_CONFIG_VERSION_DETAILS_ERROR",

  APPROVE_LIMIT_CONFIG = "@@auth/APPROVE_LIMIT_CONFIG",
  APPROVE_LIMIT_CONFIG_SUCCESS = "@@auth/APPROVE_LIMIT_CONFIG_SUCCESS",
  APPROVE_LIMIT_CONFIG_ERROR = "@@auth/APPROVE_LIMIT_CONFIG_ERROR",

  REJECT_LIMIT_CONFIG = "@@auth/REJECT_LIMIT_CONFIG",
  REJECT_LIMIT_CONFIG_SUCCESS = "@@auth/REJECT_LIMIT_CONFIG_SUCCESS",
  REJECT_LIMIT_CONFIG_ERROR = "@@auth/REJECT_LIMIT_CONFIG_ERROR",

  BLOCK_LIMIT_CONFIG = "@@auth/BLOCK_LIMIT_CONFIG",
  BLOCK_LIMIT_CONFIG_SUCCESS = "@@auth/BLOCK_LIMIT_CONFIG_SUCCESS",
  BLOCK_LIMIT_CONFIG_ERROR = "@@auth/BLOCK_LIMIT_CONFIG_ERROR",

  FREEZE_LIMIT_CONFIG = "@@auth/FREEZE_LIMIT_CONFIG",
  FREEZE_LIMIT_CONFIG_SUCCESS = "@@auth/FREEZE_LIMIT_CONFIG_SUCCESS",
  FREEZE_LIMIT_CONFIG_ERROR = "@@auth/FREEZE_LIMIT_CONFIG_ERROR",

  UNFREEZE_LIMIT_CONFIG = "@@auth/UNFREEZE_LIMIT_CONFIG",
  UNFREEZE_LIMIT_CONFIG_SUCCESS = "@@auth/UNFREEZE_LIMIT_CONFIG_SUCCESS",
  UNFREEZE_LIMIT_CONFIG_ERROR = "@@auth/UNFREEZE_LIMIT_CONFIG_ERROR",

  RESET = "@@auth/RESET"
}

export type LimitConfigActionDispatch = {
  type:
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID
    | LimitConfigReduxTypes.CREATE_LIMIT_CONFIG
    | LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG
    | LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY
    | LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS
    | LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG
    | LimitConfigReduxTypes.REJECT_LIMIT_CONFIG
    | LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG
    | LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG
    | LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG
    | LimitConfigReduxTypes.DELETE_LIMIT_CONFIG

    | LimitConfigReduxTypes.RESET;

  payload: any;
};

export type LimitConfigReducerDispatch = {
  type:
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID_ERROR
    | LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG
    | LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_ERROR
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_ERROR
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_ERROR
    | LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_ERROR
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_SUCCESS
    | LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_ERROR
    | LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY
    | LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_SUCCESS
    | LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_ERROR
    | LimitConfigReduxTypes.DELETE_LIMIT_CONFIG
    | LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_SUCCESS
    | LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_ERROR

    | LimitConfigReduxTypes.RESET;

  payload: {
    data?: FetchApiResponse<LimitConfig>;
    error?: string;
  };
};

export type LimitConfig = {
  id: string;
  externalId: string;
  customer: Customer;
  levelType: string;
  productNature: string;
  productType: ProductType;
  currency: Currency;
  cbsAccountNumber: string;
  normalSanctionedAmount: number;
  normalOperativeAmount: number;
  normalEffectiveFrom: string;
  normalEffectiveUpto: string;
  normalEffective: boolean;
  adhocSanctionedAmount: number;
  adhocOperativeAmount: number;
  adhocEffectiveFrom: string;
  adhocEffectiveUpto: string;
  adhocEffective: boolean;
  userId: string;
  status: string;
  freeze: boolean;
  checkerRemarks: string;
  active: boolean;
  draft: boolean;
  inactiveReason: string;
  isDisabled: boolean;
  blocked: string;
};

export type LimitConfigReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<LimitConfig>;
  error?: string | null;
};

export type LimitConfigFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateLimitConfig = {
  customerExternalId: Customer;
  levelType: string;
  productNature: string;
  productTypeId: ProductType;
  parentAggregateId: string;
  currencyId: Currency;
  cbsAccountNumber: string;
  normalSanctionedAmount: number;
  normalOperativeAmount: number;
  normalEffectiveFrom: string;
  normalEffectiveUpto: string;
  normalEffective: boolean;
  adhocSanctionedAmount: number;
  adhocOperativeAmount: number;
  adhocEffectiveFrom: string;
  adhocEffectiveUpto: string;
  adhocEffective: boolean;
};

export type CreateLimitConfigReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<LimitConfig>;
  error?: string | null;
};

export type CreateLimitConfigRequest = {
  payload: CreateLimitConfig;
  type: string;
};

export type UpdateLimitConfigRequest = {
  payload: LimitConfig;
  type: string;
};

export type updateLimitConfigVersion = {
  checkerRemarks: string;
  limitExternalId: string;
  customerExternalId: string;
};
export type limitConfigVersionChangeRequest = {
  payload: updateLimitConfigVersion;
  type: string;
};
