import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateHolidayBatch, HolidayBatch, updateHolidayBatchVersion } from "./types";

const api = new APICore();

function fetchHolidayBatchApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch`;
  return api.get(`${baseUrl}`, params);
}

function fetchHolidayBatchByExternalIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createHolidayBatchApi(params: any) {
  // console.log("paramsC", params);

  let backendApiUrl = "";

  let formData = new FormData();
  formData.append("file", params);
  // return api.create(baseUrl, formData)

  backendApiUrl = `${config.BACKEND_BASE_URL}/holiday-batch?draft=${true}`;

  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, formData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch`;
  // return api.create(`${baseUrl}`, params);
}

function updateHolidayBatchApi(params: any) {
  // console.log("params", params);

  let backendApiUrl = "";

  let formData = new FormData();
  formData.append("file", params?.holidayBatch[0]);

  backendApiUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}?draft=${params?.draft}`;

  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, formData);
  // const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}`;
  // return api.update(`${baseUrl}`, data);
}

function publishHolidayBatchApi(params: any) {
  let backendApiUrl = "";

  const apiData = { makerRemarks: params.makerRemarks ? params.makerRemarks.trim() : "" };
  backendApiUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}/publish`;

  const baseUrl = backendApiUrl;

  return api.updatePatch(`${baseUrl}`, apiData);
}

function fetchHolidayBatchVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function fetchHolidayBatchVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveHolidayBatchApi(params: updateHolidayBatchVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${externalHolidayId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectHolidayBatchApi(params: updateHolidayBatchVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${externalHolidayId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockHolidayBatchApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeHolidayBatchApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeHolidayBatchApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holiday-batch/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchHolidayBatchApi,
  createHolidayBatchApi,
  fetchHolidayBatchByExternalIdApi,
  updateHolidayBatchApi,
  fetchHolidayBatchVersionApi,
  approveHolidayBatchApi,
  rejectHolidayBatchApi,
  blockHolidayBatchApi,
  freezeHolidayBatchApi,
  unFreezeHolidayBatchApi,
  publishHolidayBatchApi,
  fetchHolidayBatchVersionDetailsApi
};
