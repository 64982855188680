import { CreateHolidayBatchReduxState, HolidayBatchReducerDispatch, HolidayBatchReduxState, HolidayBatchReduxTypes } from "./types";

const HOLIDAY_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const HolidayBatchListReducer = (state: HolidayBatchReduxState = HOLIDAY_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const HolidayBatchFilterReducer = (state: HolidayBatchReduxState = HOLIDAY_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_HOLIDAY_BATCH_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const HolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const HolidayBatchVersionDetailsReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CreateHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PublishHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchHolidayBatchVesrions = (state: CreateHolidayBatchReduxState = HOLIDAY_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeHolidayBatchReducer = (state: CreateHolidayBatchReduxState = CREATE_HOLIDAY_BATCH_INIT_STATE, action: HolidayBatchReducerDispatch) => {
  switch (action.type) {
    case HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayBatchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  HolidayBatchListReducer,
  CreateHolidayBatchReducer,
  HolidayBatchReducer,
  UpdateHolidayBatchReducer,
  PublishHolidayBatchReducer,
  FetchHolidayBatchVesrions,
  ApproveHolidayBatchReducer,
  RejectHolidayBatchReducer,
  BlockHolidayBatchReducer,
  FreezeHolidayBatchReducer,
  UnFreezeHolidayBatchReducer,
  HolidayBatchFilterReducer,
  HolidayBatchVersionDetailsReducer
};
