import { FilterMetadata } from "redux/types";
import { FinancingProgramActionDispatch, FinancingProgramReduxTypes, updateFinancingProgramVersion } from "./types";

export const fetchFinancingPrograms = (payload: FilterMetadata): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS,
  payload: payload
});

export const fetchFinancingProgramsConfig = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG,
  payload: payload
});

export const generateLoanAccountNumberFinancingProgram = (payload: any): FinancingProgramActionDispatch => (
  {
    type: FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS,
    payload: payload
  }
);

export const fetchFinancingProgramsById = (payload: FilterMetadata): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS,
  payload: payload
});

export const fetchFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM,
  payload: payload
});

export const fetchFinancingProgramVersions = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION,
  payload: payload
});

export const fetchFinancingProgramLatestVersion = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION,
  payload: payload
});

export const fetchFinancingProgramVersionsDetails = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS,
  payload: payload
});

export const resetFinancingProgram = (): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.RESET,
  payload: {}
});

export const deleteFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM,
  payload: payload
});

export const approveFinancingProgramVersions = (payload: updateFinancingProgramVersion): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM,
  payload: payload
});
export const rejectFinancingVersions = (payload: updateFinancingProgramVersion): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM,
  payload: payload
});

export const resetFinaningProgram = (): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.RESET,
  payload: {}
});
export const resetFinancingProgramCreationData = (): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.RESET_CREATED_FINANCING_PROGRAM_REDUCER,
  payload: {}
});

export const resetFinancingProgramUpdateData = (): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.RESET_UPDATED_FINANCING_PROGRAM_REDUCER,
  payload: {}
});

export const validateFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM,
  payload: payload
});

export const createFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM,
  payload: payload
});
export const updateFinancingProgram = (payload: any): FinancingProgramActionDispatch => (
  console.log(payload),
  {
    type: FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM,
    payload: payload
  }
);

export const blockFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.BLOCK,
  payload: payload
});

export const freezeFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM,
  payload: payload
});

export const unFreezeFinancingProgram = (payload: any): FinancingProgramActionDispatch => ({
  type: FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM,
  payload: payload
});
