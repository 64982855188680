import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum RelationshipManagerReduxTypes {
  FETCH_RELATIONSHIP_MANAGER = "@@auth/FETCH_RELATIONSHIP_MANAGER",
  FETCH_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/FETCH_RELATIONSHIP_MANAGER_SUCCESS",
  FETCH_RELATIONSHIP_MANAGER_ERROR = "@@auth/FETCH_RELATIONSHIP_MANAGER_ERROR",

  CREATE_RELATIONSHIP_MANAGER = "@@auth/CREATE_RELATIONSHIP_MANAGER",
  CREATE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/CREATE_FINANCIER_USER_SUCCESS",
  CREATE_RELATIONSHIP_MANAGER_ERROR = "@@auth/CREATE_FINANCIER_USER_ERROR",
  FETCH_RELATIONSHIP_MANAGER_BY_ID = "@@auth/FETCH_RELATIONSHIP_MANAGER_BY_ID",
  FETCH_RELATIONSHIP_MANAGER_BY_ID_SUCCESS = "@@auth/FETCH_RELATIONSHIP_MANAGER_BY_ID_SUCCESS",
  FETCH_RELATIONSHIP_MANAGER_BY_ID_ERROR = "@@auth/FETCH_RELATIONSHIP_MANAGER_BY_ID_ERROR",

  UPDATE_RELATIONSHIP_MANAGER = "@@auth/UPDATE_RELATIONSHIP_MANAGER_",
  UPDATE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/UPDATE_RELATIONSHIP_MANAGER_SUCCESS",
  UPDATE_RELATIONSHIP_MANAGER_ERROR = "@@auth/UPDATE_RELATIONSHIP_MANAGER_ERROR",
  RESET_UPDATE_RELATIONSHIP_MANAGER = "@@auth/RESET_UPDATE_RELATIONSHIP_MANAGER",

  FETCH_RELATIONSHIP_MANAGER_VERSION = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION",
  FETCH_RELATIONSHIP_MANAGER_VERSION_SUCCESS = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION_SUCCESS",
  FETCH_RELATIONSHIP_MANAGER_VERSION_ERROR = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION_ERROR",

  FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS",
  FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_SUCCESS",
  FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_ERROR = "@@auth/FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_ERROR",

  FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION = "@@auth/FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION",
  FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_SUCCESS = "@@auth/FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_SUCCESS",
  FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_ERROR = "@@auth/FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_ERROR",

  DELETE_RELATIONSHIP_MANAGER = "@@auth/DELETE_RELATIONSHIP_MANAGER",
  DELETE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/DELETE_RELATIONSHIP_MANAGER_SUCCESS",
  DELETE_FRELATIONSHIP_MANAGER_ERROR = "@@auth/DELETE_FRELATIONSHIP_MANAGER_ERROR",

  APPROVE_RELATIONSHIP_MANAGER = "@@auth/APPROVE_RELATIONSHIP_MANAGER",
  APROVE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/APROVE_RELATIONSHIP_MANAGER_SUCCESS",
  APROVE_FRELATIONSHIP_MANAGER_ERROR = "@@auth/APROVE_FRELATIONSHIP_MANAGER_ERROR",

  REJECT_RELATIONSHIP_MANAGER = "@@auth/REJECT_RELATIONSHIP_MANAGER",
  REJECT_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/REJECT_RELATIONSHIP_MANAGER_SUCCESS",
  REJECT_RELATIONSHIP_MANAGER_ERROR = "@@auth/REJECT_RELATIONSHIP_MANAGER_ERROR",

  BLOCK_RELATIONSHIP_MANAGER = "@@auth/BLOCK_RELATIONSHIP_MANAGER",
  BLOCK_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/BLOCK_RELATIONSHIP_MANAGER_SUCCESS",
  BLOCK_RELATIONSHIP_MANAGER_ERROR = "@@auth/BLOCK_RELATIONSHIP_MANAGER_ERROR",

  FETCH_RELATIONSHIP_MANAGER_ID = "@@auth/FETCH_RELATIONSHIP_MANAGER_ID",

  SEARCH_FILTER_RELATIONSHIP_MANAGER = "@@auth/SEARCH_FILTER_RELATIONSHIP_MANAGER",
  SEARCH_FILTER_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/SEARCH_FILTER_RELATIONSHIP_MANAGER_SUCCESS",
  SEARCH_FILTER_RELATIONSHIP_MANAGER_ERROR = "@@auth/SEARCH_FILTER_RELATIONSHIP_MANAGER_ERROR",

  FREEZE_RELATIONSHIP_MANAGER = "@@auth/FREEZE_RELATIONSHIP_MANAGER",
  FREEZE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/FREEZE_RELATIONSHIP_MANAGER_SUCCESS",
  FREEZE_RELATIONSHIP_MANAGER_ERROR = "@@auth/FREEZE_RELATIONSHIP_MANAGER_ERROR",

  UNFREEZE_RELATIONSHIP_MANAGER = "@@auth/UNFREEZE_RELATIONSHIP_MANAGER",
  UNFREEZE_RELATIONSHIP_MANAGER_SUCCESS = "@@auth/UNFREEZE_RELATIONSHIP_MANAGER_SUCCESS",
  UNFREEZE_RELATIONSHIP_MANAGER_ERROR = "@@auth/UNFREEZE_RELATIONSHIP_MANAGER_ERROR",

  RESET = "@@auth/RESET"
}
export type RelationshipManagerActionDispatch = {
  type:
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ID
    | RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID
    | RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.RESET_UPDATE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS
    | RelationshipManagerReduxTypes.APPROVE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER

    | RelationshipManagerReduxTypes.RESET;
  payload: any;
};
export type RelationshipManagerReducerDispatch = {
  type:
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_SUCCESS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ID
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_ERROR
    | RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.RESET_UPDATE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_SUCCESS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_ERROR
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_SUCCESS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_ERROR
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_SUCCESS
    | RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_ERROR
    | RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.APPROVE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.APROVE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.APROVE_FRELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_ERROR
    | RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER
    | RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER_SUCCESS
    | RelationshipManagerReduxTypes.DELETE_FRELATIONSHIP_MANAGER_ERROR

    | RelationshipManagerReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<RelationshipManager>;
    error?: string;
  };
};

export type RelationShipManagerReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<RelationshipManager>;
  error?: string | null;
};

export type RelationShipManagerFetchRequest = {
  payload: FilterMetadata;
  type: string;
};
export type RelationshipManager = {
  id: string;
  externalId: string;
  name: string;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};

export type CreaterelationshipManager = {
  name: string;
  email: string;
  mobileNumber: string;
  branchId: string;

  id: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreaterelationshipManagerReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<CreaterelationshipManager>;
  error?: string | null;
};

export type CreaterelationshipManagerRequest = {
  payload: CreaterelationshipManager;
  type: string;
};

export type FetchRelationshipManagerByExternalIdRequest = {
  payload: string;
  type: string;
};
export type a = {
  payload: RelationshipManager;
  type: string;
};

export type UpdateRelationshipManager = {
  name: string;
  email: string;
  mobileNumber: string;
  branchId: string;
  id: string;
  externalId: string;
  draft: boolean;
  makerRemarks: string;
};

export type updateRelationshipManagerVersion = {
  checkerRemarks: string;
  externalUserId: string;
};

export type UpdateRelationshipManagerRequest = {
  payload: UpdateRelationshipManager;
  type: string;
};

export type CreateRelationshipManagerReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<RelationshipManager>;
  error?: string | null;
};
