import { DisbursementReducerDispatch, DisbursementReduxState, DisbursementReduxTypes } from "./type";

const DISBURSEMENT_INIT_STATE = {
  loading: false,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const DisbursementsReducer = (state: DisbursementReduxState = DISBURSEMENT_INIT_STATE, action: DisbursementReducerDispatch) => {
  switch (action.type) {
    case DisbursementReduxTypes.FETCH_DISBURSEMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case DisbursementReduxTypes.FETCH_DISBURSEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementReduxTypes.FETCH_DISBURSEMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementReduxTypes.RESET_DISBURSEMENT:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
export { DisbursementsReducer }