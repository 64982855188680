import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateProductTypeRequestRequest, ProductReduxTypes, ProductTypeFetchRequest, UpdateProductRequest } from "./types";
import {
  aproveProductTypeApi,
  blockProductTypeApi,
  createProductTypeApi,
  deleteProductTypeApi,
  fetchProductsTypeApi,
  fetchProductsTypeLatestVersionApi,
  fetchProductsTypeVersionApi,
  fetchProductsTypeVersionDetailsApi,
  fetchProductTypeApi,
  freezeProductTypeApi,
  rejectProductTypeApi,
  unFreezeProductTypeApi,
  updateProductTypeApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchProductsTypes({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchProductsTypeApi, payload);
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCTS_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCTS_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* createProductType({ payload, type }: CreateProductTypeRequestRequest): SagaIterator {
  try {
    const response = yield call(createProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.CREATE_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.CREATE_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteProductType({ payload, type }: CreateProductTypeRequestRequest): SagaIterator {
  try {
    const response = yield call(deleteProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.DELETE_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.DELETE_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchProductTypes({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchProductTypeApi, payload);
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateProductType({ payload, type }: UpdateProductRequest): SagaIterator {
  try {
    const response = yield call(updateProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.UPDATE_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.UPDATE_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

//

function* fetchProductsTypesVerion({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchProductsTypeVersionApi, payload);
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchProductsTypesLatestVerion({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchProductsTypeLatestVersionApi, payload);
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchProductsTypesVerionDetails({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchProductsTypeVersionDetailsApi, payload);
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveProductType({ payload, type }: CreateProductTypeRequestRequest): SagaIterator {
  try {
    const response = yield call(aproveProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.APROVE_PRODUCTS_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.APROVE_PRODUCTS_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectProductTypes({ payload, type }: ProductTypeFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectProductTypeApi, payload);
    yield put({
      type: ProductReduxTypes.REJECT_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ProductReduxTypes.REJECT_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* blockProductType({ payload, type }: UpdateProductRequest): SagaIterator {
  try {
    const response = yield call(blockProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.BLOCK_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.BLOCK_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeProductType({ payload, type }: UpdateProductRequest): SagaIterator {
  try {
    const response = yield call(freezeProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.FREEZE_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.FREEZE_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeProductType({ payload, type }: UpdateProductRequest): SagaIterator {
  try {
    const response = yield call(unFreezeProductTypeApi, payload);

    yield put({
      type: ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchProductsType() {
  yield takeEvery(ProductReduxTypes.FETCH_PRODUCTS_TYPE, fetchProductsTypes);
}

export function* watchFetchProductType() {
  yield takeEvery(ProductReduxTypes.FETCH_PRODUCT_TYPE, fetchProductTypes);
}

export function* watchUpdateProductType() {
  yield takeEvery(ProductReduxTypes.UPDATE_PRODUCT_TYPE, UpdateProductType);
}

export function* watchCreateProductType() {
  yield takeEvery(ProductReduxTypes.CREATE_PRODUCT_TYPE, createProductType);
}

//
export function* watchFetchProductsTypeVersion() {
  yield takeEvery(ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION, fetchProductsTypesVerion);
}

export function* watchAproveProductType() {
  yield takeEvery(ProductReduxTypes.APROVE_PRODUCTS_TYPE, aproveProductType);
}

export function* watchRejectProductType() {
  yield takeEvery(ProductReduxTypes.REJECT_PRODUCT_TYPE, rejectProductTypes);
}

export function* watchBlockProductType() {
  yield takeEvery(ProductReduxTypes.BLOCK_PRODUCT_TYPE, blockProductType);
}

export function* watchFreezeProductType() {
  yield takeEvery(ProductReduxTypes.FREEZE_PRODUCT_TYPE, freezeProductType);
}

export function* watchUnFreezeProductType() {
  yield takeEvery(ProductReduxTypes.UNFREEZE_PRODUCT_TYPE, unFreezeProductType);
}

export function* watchFetchProductsTypesVerionDetails() {
  yield takeEvery(ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS, fetchProductsTypesVerionDetails);
}

export function* watchFetchProductsTypesLatestVerion() {
  yield takeEvery(ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION, fetchProductsTypesLatestVerion);
}

export function* watchDeleteProductType() {
  yield takeEvery(ProductReduxTypes.DELETE_PRODUCT_TYPE, deleteProductType);
}

function* productTypeSaga() {
  yield all([
    fork(watchFetchProductsType),
    fork(watchFetchProductType),
    fork(watchUpdateProductType),
    fork(watchCreateProductType),

    fork(watchFetchProductsTypeVersion),
    fork(watchAproveProductType),
    fork(watchRejectProductType),
    fork(watchBlockProductType),

    fork(watchFreezeProductType),
    fork(watchUnFreezeProductType),
    fork(watchFetchProductsTypesVerionDetails),
    fork(watchFetchProductsTypesLatestVerion),
    fork(watchDeleteProductType)
  ]);
}

export default productTypeSaga;
