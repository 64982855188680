import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata, RemoveInstrumentdata } from "redux/types";

const api = new APICore();

function fetchStagingInstrumentsApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instruments`;
  return api.get(`${baseUrl}`, params);
}

function fetchStagingInstrumentApi(params: any) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instruments/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

function reProcessingStagingInstrumentsApi(params: Array<any>) {
  let data = {
    stagingInstrumentIds: params
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instruments/reprocess`;
  return api.updatePatch(`${baseUrl}`, data);
}

function skipValidationsForStagingInstrumentsApi(params: any) {
  let data = {
    stagingInstrumentIds: params["ids"],
    skipValidations: params["validations"]
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instruments`;

  return api.update(`${baseUrl}`, data);
}

function fetchSkipableValidationStagingInstrumentsApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instrument-validations`;

  return api.get(`${baseUrl}`, params);
}

function fetchValidationErrorStagingInstrumentsIdApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instrument-validations`;
  return api.get(`${baseUrl}`, params);
}

function removeInstrumentsStaningBatchApi(params: any) {
  let data = {
    stagingInstrumentIds: params?.stagingInstrumentIds
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-instruments/remove-instruments`;
  return api.updatePatch(`${baseUrl}`, data);
}
export {
  fetchStagingInstrumentsApi,
  reProcessingStagingInstrumentsApi,
  fetchStagingInstrumentApi,
  skipValidationsForStagingInstrumentsApi,
  fetchSkipableValidationStagingInstrumentsApi,
  fetchValidationErrorStagingInstrumentsIdApi,
  removeInstrumentsStaningBatchApi
};
