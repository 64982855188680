import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum FinancierUserReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_FINANCIER_USERS = "@@auth/FETCH_FINANCIER_USERS",
  FETCH_FINANCIER_USERS_SUCCESS = "@@auth/FETCH_FINANCIER_USERS_SUCCESS",
  FETCH_FINANCIER_USERS_ERROR = "@@auth/FETCH_FINANCIER_USERS_ERROR",

  FETCH_FINANCIER_USER = "@@auth/FETCH_FINANCIER_USER",
  FETCH_FINANCIER_USER_SUCCESS = "@@auth/FETCH_FINANCIER_USER_SUCCESS",
  FETCH_FINANCIER_USER_ERROR = "@@auth/FETCH_FINANCIER_USER_ERROR",

  FETCH_FINANCIER_USER_VERSION = "@@auth/FETCH_FINANCIER_USER_VERSION",
  FETCH_FINANCIER_USER_VERSION_SUCCESS = "@@auth/FETCH_FINANCIER_USER_VERSION_SUCCESS",
  FETCH_FINANCIER_USER_VERSION_ERROR = "@@auth/FETCH_FINANCIER_USER_VERSION_ERROR",

  FETCH_FINANCIER_USER_VERSION_DETAILS = "@@auth/FETCH_FINANCIER_USER_VERSION_DETAILS",
  FETCH_FINANCIER_USER_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_FINANCIER_USER_VERSION_DETAILS_SUCCESS",
  FETCH_FINANCIER_USER_VERSION_DETAILS_ERROR = "@@auth/FETCH_FINANCIER_USER_VERSION_DETAILS_ERROR",



  FETCH_FINANCIER_USER_LATEST_VERSION = "@@auth/FETCH_FINANCIER_USER_LATEST_VERSION",
  FETCH_FINANCIER_USER_LATEST_VERSION_SUCCESS = "@@auth/FETCH_FINANCIER_USER_LATEST_VERSION_SUCCESS",
  FETCH_FINANCIER_USER_LATEST_VERSION_ERROR = "@@auth/FETCH_FINANCIER_USER_LATEST_VERSION_ERROR",


  APPROVE_FINANCIER_USER = "@@auth/APPROVE_FINANCIER_USER",
  APPROVE_FINANCIER_USER_SUCCESS = "@@auth/APPROVE_FINANCIER_USER_SUCCESS",
  APPROVE_FINANCIER_USER_ERROR = "@@auth/APPROVE_FINANCIER_USER_ERROR",

  REJECT_FINANCIER_USER = "@@authREJECT_FINANCIER_USER",
  REJECT_FINANCIER_USER_SUCCESS = "@@authREJECT_FINANCIER_USER_SUCCESS",
  REJECT_FINANCIER_USER_ERROR = "@@authREJECT_FINANCIER_USER_ERROR",

  CREATE_FINANCIER_USER = "@@auth/CREATE_FINANCIER_USER",
  CREATE_FINANCIER_USER_SUCCESS = "@@auth/CREATE_FINANCIER_USER_SUCCESS",
  CREATE_FINANCIER_USER_ERROR = "@@auth/CREATE_FINANCIER_USER_ERROR",

  PASSWORD_FINANCIER_USER = "@@auth/PASSWORD_FINANCIER_USER",
  PASSWORD_FINANCIER_USER_SUCCESS = "@@auth/PASSWORD_FINANCIER_USER_SUCCESS",
  PASSWORD_FINANCIER_USER_ERROR = "@@auth/PASSWORD_FINANCIER_USER_ERROR",

  UPDATE_PASSWORD_FINANCIER_USER = "@@auth/UPDATE_PASSWORD_FINANCIER_USER",
  UPDATE_PASSWORD_FINANCIER_USER_SUCCESS = "@@auth/UPDATE_PASSWORD_FINANCIER_USER_SUCCESS",
  UPDATE_PASSWORD_FINANCIER_USER_ERROR = "@@auth/UPDATE_PASSWORD_FINANCIER_USER_ERROR",

  UPDATE_FINANCIER_USER = "@@auth/UPDATE_FINANCIER_USER",
  UPDATE_FINANCIER_USER_SUCCESS = "@@auth/UPDATE_FINANCIER_USER_SUCCESS",
  UPDATE_FINANCIER_USER_ERROR = "@@auth/UPDATE_FINANCIER_USER_ERROR",


  DELETE_FINANCIER_USER = "@@auth/DELETE_FINANCIER_USER",
  DELETE_FINANCIER_USER_SUCCESS = "@@auth/DELETE_FINANCIER_USER_SUCCESS",
  DELETE_FINANCIER_USER_ERROR = "@@auth/DELETE_FINANCIER_USER_ERROR",

  BLOCK_FINANCIER_USER = "@@auth/BLOCK_FINANCIER_USER",
  BLOCK_FINANCIER_USER_SUCCESS = "@@auth/BLOCK_FINANCIER_USER_SUCCESS",
  BLOCK_FINANCIER_USER_ERROR = "@@auth/BLOCK_FINANCIER_USER_ERROR",

  FREEZE_FINANCIER_USER = "@@auth/FREEZE_FINANCIER_USER",
  FREEZE_FINANCIER_USER_SUCCESS = "@@auth/FREEZE_FINANCIER_USER_SUCCESS",
  FREEZE_FINANCIER_USER_ERROR = "@@auth/FREEZE_FINANCIER_USER_ERROR",

  UNFREEZE_FINANCIER_USER = "@@auth/UNFREEZE_FINANCIER_USER",
  UNFREEZE_FINANCIER_USER_SUCCESS = "@@auth/UNFREEZE_FINANCIER_USER_SUCCESS",
  UNFREEZE_FINANCIER_USER_ERROR = "@@auth/UNFREEZE_FINANCIER_USER_ERROR",

  RESET = "@@auth/RESET"
}

export type FinancierUserActionDispatch = {
  type:
    | FinancierUserReduxTypes.FETCH_FINANCIER_USERS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION

    | FinancierUserReduxTypes.CREATE_FINANCIER_USER
    | FinancierUserReduxTypes.UPDATE_FINANCIER_USER
    | FinancierUserReduxTypes.APPROVE_FINANCIER_USER
    | FinancierUserReduxTypes.REJECT_FINANCIER_USER
    | FinancierUserReduxTypes.BLOCK_FINANCIER_USER
    | FinancierUserReduxTypes.DELETE_FINANCIER_USER

    | FinancierUserReduxTypes.FREEZE_FINANCIER_USER
    | FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER
    | FinancierUserReduxTypes.PASSWORD_FINANCIER_USER
    | FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER
    | FinancierUserReduxTypes.RESET;
  payload: any;
};

export type FinancierUserReducerDispatch = {
  type:
    | FinancierUserReduxTypes.FETCH_FINANCIER_USERS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USERS_SUCCESS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USERS_ERROR
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_SUCCESS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_ERROR
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_SUCCESS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_ERROR
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION

    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_SUCCESS
    | FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_ERROR

    | FinancierUserReduxTypes.CREATE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.CREATE_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.UPDATE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.UPDATE_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.APPROVE_FINANCIER_USER
    | FinancierUserReduxTypes.APPROVE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.APPROVE_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.REJECT_FINANCIER_USER
    | FinancierUserReduxTypes.REJECT_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.REJECT_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.BLOCK_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.BLOCK_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.FREEZE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.FREEZE_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.DELETE_FINANCIER_USER
    | FinancierUserReduxTypes.DELETE_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.DELETE_FINANCIER_USER_ERROR

    | FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_SUCCESS
    | FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_ERROR
    | FinancierUserReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<FinancierUser>;
    error?: string;
  };
};

export type FinancierUserReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<FinancierUser>;
  error?: string | null;
};

export type FinancierUserFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FinancierUser = {
  id: string;
  externalId: string;
  status: string;
  email: string;
  name: string;
  mobile: string;
  financierId: string;
  roles: string[];
  // Indicates whether record is blocked or not.
  isDisabled: boolean;
  // Indicates whether the record is soft deleted or not.
  active: boolean;
  inactiveReason: string;
  checkerRemarks: string;
  blocked: boolean;
  freeze: boolean;
};

export type CreateFinancierUserRequest = {
  payload: CreateFinancierUser;
  type: string;
};

export type UpdateFinancierUserRequest = {
  payload: UpdateFinancierUser;
  type: string;
};

export type CreateFinancierUserReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<FinancierUser>;
  error?: string | null;
};

export type CreateFinancierUser = {
  email: string;
  name: string;
  mobile: string;
  roles: string[];
  draft: boolean;
  makerRemarks: string;
  timezone: DropDown;
  locale: DropDown;
};
export type DropDown = {
  value: string,
  label: string
}
export type UpdateFinancierUser = {
  email: string;
  name: string;
  mobile: string;
  roles: string[];
  externalUserId: string;
  draft: boolean;
  makerRemarks: string;
  timezone: DropDown;
  locale: DropDown;
};

export type FetchFinancierUserByExternalIdRequest = {
  payload: string;
  type: string;
};

export type financierUserVersionChangeRequest = {
  payload: updateFinancierUserVersion;
  type: string;
};
export type updateFinancierUserVersion = {
  checkerRemarks: string;
  externalId: string;
};
