import { FinancierUserReduxTypes, FinancierUserReducerDispatch, FinancierUserReduxState, CreateFinancierUserReduxState } from "./types";

const FINANCIER_USER_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const FinancierUserListReducer = (state: FinancierUserReduxState = FINANCIER_USER_LIST_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FETCH_FINANCIER_USERS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_FINANCIER_USER_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.CREATE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.CREATE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FINANCIER_USER_INIT_STATE = {
  data: {},
  loading: true,
  error: null
};

const FinancierUserReducer = (state: CreateFinancierUserReduxState = FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER: {
      return {
        ...state,
        // data: action.payload.data,
        loading: true
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.UPDATE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.UPDATE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchFinancierUserVesrions = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchFinancierUserVesrionDetails = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchFinancierUserLatestVesrionReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.APPROVE_FINANCIER_USER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case FinancierUserReduxTypes.APPROVE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.APPROVE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.REJECT_FINANCIER_USER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case FinancierUserReduxTypes.REJECT_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.REJECT_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.BLOCK_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.BLOCK_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.FREEZE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.FREEZE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeleteFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.DELETE_FINANCIER_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancierUserReduxTypes.DELETE_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.DELETE_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CreatePasswordFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdatePasswordFinancierUserReducer = (state: CreateFinancierUserReduxState = CREATE_FINANCIER_USER_INIT_STATE, action: FinancierUserReducerDispatch) => {
  switch (action.type) {
    case FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancierUserReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  FinancierUserListReducer,
  CreateFinancierUserReducer,
  FinancierUserReducer,
  UpdateFinancierUserReducer,
  FetchFinancierUserVesrions,
  ApproveFinancierUserReducer,
  RejectFinancierUserReducer,
  BlockFinancierUserReducer,
  FreezeFinancierUserReducer,
  UnFreezeFinancierUserReducer,
  CreatePasswordFinancierUserReducer,
  UpdatePasswordFinancierUserReducer,
  FetchFinancierUserVesrionDetails,
  FetchFinancierUserLatestVesrionReducer,
  DeleteFinancierUserReducer
};
