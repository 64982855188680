import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { CreateCustomerCummunication, UpdateCustomerCummunications } from "./types";

const api = new APICore();

function fetchCustomerCummicationsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications`;
  return api.get(`${baseUrl}`, params);
}

function fetchCustomerCummicationsIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}`;
  return api.get(`${baseUrl}`, {});
}

function CreateCustomerCummicationsIdApi(params: any) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    communicationType: params.communicationType ? params.communicationType.value : "",
    value: params.value.trim(),
    usedInReport: params.usedInReport ? params.usedInReport.value : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // let updateCustomerCummuniationsData: CreateCustomerCummunication = {

  // };

  // const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications`;
  // return api.create(`${baseUrl}`, updateCustomerCummuniationsData);
}

function UpdateCustomerCummicationsIdApi(params: any) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    communicationType: params.communicationType ? params.communicationType.value : "",
    value: params.value.trim(),
    usedInReport: params.usedInReport ? params.usedInReport.value : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalUserId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalUserId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  //   const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalUserId}`;
  //   return api.update(`${baseUrl}`, updateCustomerCummuniationsData);
}

function fetchCustomerCummicationsVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/versions`;
  return api.get(`${baseUrl}`, {});
}

function fetchCustomerCummicationsLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchCustomerCummicationsVersionDetailsApi(params: any) {
  console.log(params);
  
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/versions/${params.internalID}`;
  return api.get(`${baseUrl}`, {});
}


function deleteCustomerCummicationsApi(params: any) {
 
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalCustomerId}/approve`;
  return api.delete(`${baseUrl}`);
}

function aproveCustomerCummicationsApi(params: any) {
  // console.log(params);

  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalCustomerId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectCustomerCummicationsApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.externalCustomerId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCustomerCummicationsApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeCustomerCummicationsApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeCustomerCummicationsApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/communications/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  aproveCustomerCummicationsApi,
  rejectCustomerCummicationsApi,
  blockCustomerCummicationsApi,
  fetchCustomerCummicationsVersionApi,
  fetchCustomerCummicationsApi,
  fetchCustomerCummicationsIdApi,
  CreateCustomerCummicationsIdApi,
  UpdateCustomerCummicationsIdApi,
  freezeCustomerCummicationsApi,
  unFreezeCustomerCummicationsApi,
  fetchCustomerCummicationsVersionDetailsApi,
  fetchCustomerCummicationsLatestVersionApi,
  deleteCustomerCummicationsApi
};
