import config from "config";
import { FilterMetadata } from "redux/types";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();

function fetchGlAccountApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts`;
  return api.get(`${baseUrl}`, params);
}

function createGlAccountApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    accountType: params.accountType ? params.accountType : "",
    accountNumber: params.accountNumber ? params.accountNumber : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/gl-accounts`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/gl-accounts?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts`;
  // return api.create(`${baseUrl}`, params);
}

function fetchGlAccountApiById(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params}`;
  return api.get(`${baseUrl}`, {});
}

function updateGlAccountApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    accountType: params.accountType ? params.accountType.trim() : "",
    accountNumber: params.accountNumber ? params.accountNumber.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function fetchGlAccountTenorApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts`;
  return api.get(`${baseUrl}`, params);
}

function fetchGlAccountVersionApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function deleteGlAccountApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchGlAccountLatestVersionApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchGlAccountVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveGlAccountApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectGlAccountApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function blockGlAccountApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeGlAccountApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeGlAccountApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/gl-accounts/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchGlAccountApi,
  createGlAccountApi,
  fetchGlAccountApiById,
  updateGlAccountApi,
  fetchGlAccountTenorApi,
  fetchGlAccountVersionApi,
  approveGlAccountApi,
  rejectGlAccountApi,
  blockGlAccountApi,
  freezeGlAccountApi,
  unFreezeGlAccountApi,
  fetchGlAccountVersionDetailsApi,
  fetchGlAccountLatestVersionApi,
  deleteGlAccountApi
};
