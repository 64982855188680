import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchInstumentBatchesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/instrument-batches`;
  return api.get(`${baseUrl}`, params);
}

function uploadInstrumnetBatchesApi(params: any) {
  let formData = new FormData();
  formData.append("file", params?.batchInstrumnets[0]);
  formData.append("programme_id", params?.programId?.externalId);
  // TODO: To be removed after backend parameter is removed
  formData.append("limit_type", "Normal");

  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/instrument-batches/disburse/file`;
  return api.create(baseUrl, formData);
}

function fetchInstumentsBatchApi(params: any) {
  // console.log('params');
  // console.log('decodeURI(params)',decodeURI(params));

  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/instrument-batches/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}
function fetchInstrumentBatchAuditActionsApi(params:FilterMetadata){
  const baseUrl=`${config.BACKEND_TXN_BASE_URL}/action-audit`;
  return api.get(`${baseUrl}`,params)
}
export { fetchInstumentBatchesApi, uploadInstrumnetBatchesApi, fetchInstumentsBatchApi, fetchInstrumentBatchAuditActionsApi};
