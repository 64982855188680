import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum StagingInstrumentsReduxTypes {
  FETCH_STAGING_INSTRUMENTS = "@@auth/FETCH_STAGING_INSTRUMENTS",
  FETCH_STAGING_INSTRUMENTS_SUCCESS = "@@auth/FETCH_STAGING_INSTRUMENTS_SUCCESS",
  FETCH_STAGING_INSTRUMENTS_ERROR = "@@auth/FETCH_STAGING_INSTRUMENTS_ERROR",

  FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION = "@@auth/FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION",
  FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_SUCCESS = "@@auth/FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_SUCCESS",
  FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_ERROR = "@@auth/FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_ERROR",
  RESET_STAGING_INSTRUMENTS_REMOVE_VERSION = "@@auth/RESET_STAGING_INSTRUMENTS_REMOVE_VERSION",

  FETCH_REMOVE_STAGING_INSTRUMENTS = "@@auth/FETCH_REMOVE_STAGING_INSTRUMENTS",
  FETCH_REMOVE_STAGING_INSTRUMENTS_SUCCESS = "@@auth/FETCH_REMOVE_STAGING_INSTRUMENTS_SUCCESS",
  FETCH_REMOVE_STAGING_INSTRUMENTS_ERROR = "@@auth/FETCH_REMOVE_STAGING_INSTRUMENTS_ERROR",
  RESET_REMOVE_STAGING_INSTRUMENTS = "@@auth/RESET_REMOVE_STAGING_INSTRUMENTS",

  FETCH_STAGING_INSTRUMENT = "@@auth/FETCH_STAGING_INSTRUMENT",
  FETCH_STAGING_INSTRUMENT_SUCCESS = "@@auth/FETCH_STAGING_INSTRUMENT_SUCCESS",
  FETCH_STAGING_INSTRUMENT_ERROR = "@@auth/FETCH_STAGING_INSTRUMENT_ERROR",

  REPROCESS_STAGING_INSTRUMENTS = "@@auth/REPROCESS_STAGING_INSTRUMENTS",
  REPROCESS_STAGING_INSTRUMENTS_SUCCESS = "@@auth/REPROCESS_STAGING_INSTRUMENTS_SUCCESS",
  REPROCESS_STAGING_INSTRUMENTS_ERROR = "@@auth/REPROCESS_STAGING_INSTRUMENTS_ERROR",

  SKIP_VALIDATION_STAGING_INSTRUMENTS = "@@auth/SKIP_VALIDATION_STAGING_INSTRUMENTS",
  SKIP_VALIDATION_STAGING_INSTRUMENTS_SUCCESS = "@@auth/SKIP_VALIDATION_STAGING_INSTRUMENTS_SUCCESS",
  SKIP_VALIDATION_STAGING_INSTRUMENTS_ERROR = "@@auth/SKIP_VALIDATION_STAGING_INSTRUMENTS_BATCHES_ERROR",

  FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID = "@@auth/FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID",
  FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS = "@@auth/FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS",
  FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR = "@@auth/FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR",

  FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID = "@@auth/FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID",
  FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_SUCCESS = "@@auth/FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_SUCCESS",
  FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_ERROR = "@@auth/FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_ERROR",

  REMOVE_INSTRUMENT_STAGING = "@@auth/REMOVE_INSTRUMENT_STAGING_BATCH",
  REMOVE_INSTRUMENT_STAGING_SUCCESS = "@@auth/REMOVE_INSTRUMENT_STAGING_SUCCESS",
  REMOVE_INSTRUMENT_STAGING_ERROR = "@@auth/REMOVE_INSTRUMENT_STAGING_ERROR",
  RESET = "@@auth/RESET"
}

export type StagingInstrumentsActionDispatch = {
  type:
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION
    | StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT
    | StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID
    | StagingInstrumentsReduxTypes.RESET_REMOVE_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.RESET_STAGING_INSTRUMENTS_REMOVE_VERSION
    | StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING
    | StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_ERROR
    | StagingInstrumentsReduxTypes.RESET;
  payload: any;
};

export type StagingInstrumentsReducerDispatch = {
  type:
    | StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_ERROR
    | StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID
    | StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_ERROR
    | StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_SUCCESS
    | StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_ERROR
    | StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_SUCCESS
    | StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_ERROR
    | StagingInstrumentsReduxTypes.FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID
    | StagingInstrumentsReduxTypes.FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID_ERROR
    | StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_ERROR
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_SUCCESS
    | StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_ERROR
    | StagingInstrumentsReduxTypes.RESET_STAGING_INSTRUMENTS_REMOVE_VERSION
    | StagingInstrumentsReduxTypes.RESET_REMOVE_STAGING_INSTRUMENTS
    | StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING
    | StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_SUCCESS
    | StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_ERROR
    | StagingInstrumentsReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<stagingInstruments>;
    error?: string;
  };
};

export type StagingInstrumentsReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<stagingInstruments>;
  error?: string | null;
};

export type SkipableValidationReduxState = {
  loading: boolean;
  dasta?: FetchApiResponse<skipableValidation>;
  error?: string | null;
};

export type stagingInstrumnetValidationErrorReduxState = {
  loading: boolean;
  dasta?: FetchApiResponse<StagingInstrumentValidationError>;
  error?: string | null;
};

export type stagingInstruments = {
  id: string;
  externalId: string;
  instrumentBatchId: string;
  stagingBatchId: string;
  financingProgramExternalId: string;
  counterPartyExternalId: string;
  currencyShortCode: string;
  corporateCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  dueDate: string;
  receivedDate: string;
  instrumentAmount: number;
  additionalDetails: string;
  docType: string;
  ifscCode: string;
  accountNumber: string;
  userRemarks: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  active: boolean;
  createdOn: number;
  updatedOn: number;
};

export type FetchStagingInstrumentsRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FetchStagingInstrumentRequest = {
  payload: FilterMetadata;
  type: string;
};

export type reProcessingStagingInstrumentsRequest = {
  payload: Array<any>;
  type: string;
};
export type skipValidationRequest = {
  payload: any;
  type: string;
};
export type RemoveInstrumentStagingRequest = {
  payload: Array<any>;
  type: string;
};

export type skipableValidation = {
  id: string;
  title: string;
  stagingInstrumentId: string;
  validationStatus: string;
  errorText: string;
  errorDescription: string;
  skippable: boolean;
  skip: boolean;
  version: number;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};
export type StagingInstrumentValidationError = {
  id: string;
  title: string;
  stagingInstrumentId: string;
  validationStatus: string;
  errorText: string;
  errorDescription: string;
  skippable: boolean;
  skip: boolean;
  version: number;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};
