import { State } from "redux/state/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CityReduxTypes {
  FETCH_CITIES = "@@auth/FETCH_CITIES",
  FETCH_CITIES_SUCCESS = "@@auth/FETCH_CITIES_SUCCESS",
  FETCH_CITIES_ERROR = "@@auth/FETCH_CITIES_ERROR",

  FETCH_CITY_BY_ID = "@@auth/FETCH_CITY_BY_ID",
  FETCH_CITY_BY_ID_SUCCESS = "@@auth/FETCH_CITY_BY_ID_SUCCESS",
  FETCH_CITY_BY_ID_ERROR = "@@auth/FETCH_CITY_BY_ID_ERROR",

  CREATE_CITY = "@@auth/CREATE_CITY",
  CREATE_CITY_SUCCESS = "@@auth/CREATE_CITY_SUCCESS",
  CREATE_CITY_ERROR = "@@auth/CREATE_CITY_ERROR",

  UPDATE_CITY = "@@auth/UPDATE_CITY",
  UPDATE_CITY_SUCCESS = "@@auth/UPDATE_CITY_SUCCESS",
  UPDATE_CITY_ERROR = "@@auth/UPDATE_CITY_ERROR",
  RESET_UPDATE_CITY = "@@auth/RESET_UPDATE_CITY",

  FETCH_CITY_VERSION = "@@auth/FETCH_CITY_VERSION",
  FETCH_CITY_VERSION_SUCCESS = "@@auth/FETCH_CITY_VERSION_SUCCESS",
  FETCH_CITY_VERSION_ERROR = "@@auth/FETCH_CITY_VERSION_ERROR",

  FETCH_CITY_VERSION_DETAILS = "@@auth/FETCH_CITY_VERSION_DETAILS",
  FETCH_CITY_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_CITY_VERSION_DETAILS_SUCCESS",
  FETCH_CITY_VERSION_DETAILS_ERROR = "@@auth/FETCH_CITY_VERSION_DETAILS_ERROR",

  FETCH_CITY_LATEST_VERSION = "@@auth/FETCH_CITY_LATEST_VERSION",
  FETCH_CITY_LATEST_VERSION_SUCCESS = "@@auth/FETCH_CITY_LATEST_VERSION_SUCCESS",
  FETCH_CITY_LATEST_VERSION_ERROR = "@@auth/FETCH_CITY_LATEST_VERSION_ERROR",

  DELETE_CITY = "@@auth/DELETE_CITY",
  DELETE_CITY_SUCCESS = "@@auth/DELETE_CITY_SUCCESS",
  DELETE_CITY_ERROR = "@@auth/DELETE_CITY_ERROR",

  APPROVE_CITY = "@@auth/APPROVE_CITY",
  APPROVE_CITY_SUCCESS = "@@auth/APPROVE_CITY_SUCCESS",
  APPROVE_CITY_ERROR = "@@auth/APPROVE_CITY_ERROR",

  REJECT_CITY = "@@auth/REJECT_CITY",
  REJECT_CITY_SUCCESS = "@@auth/REJECT_CITY_SUCCESS",
  REJECT_CITY_ERROR = "@@auth/REJECT_CITY_ERROR",

  BLOCK_CITY = "@@auth/BLOCK_CITY",
  BLOCK_CITY_SUCCESS = "@@auth/BLOCK_CITY_SUCCESS",
  BLOCK_CITY_ERROR = "@@auth/BLOCK_CITY_ERROR",

  FREEZE_CITY = "@@auth/FREEZE_CITY",
  FREEZE_CITY_SUCCESS = "@@auth/FREEZE_CITY_SUCCESS",
  FREEZE_CITY_ERROR = "@@auth/FREEZE_CITY_ERROR",

  UNFREEZE_CITY = "@@auth/UNFREEZE_CITY",
  UNFREEZE_CITY_SUCCESS = "@@auth/UNFREEZE_CITY_SUCCESS",
  UNFREEZE_CITY_ERROR = "@@auth/UNFREEZE_CITY_ERROR",

  RESET = "@@auth/RESET"
}

export type CityActionDispatch = {
  type:
    | CityReduxTypes.FETCH_CITIES
    | CityReduxTypes.FETCH_CITY_BY_ID
    | CityReduxTypes.CREATE_CITY
    | CityReduxTypes.UPDATE_CITY
    | CityReduxTypes.RESET_UPDATE_CITY
    | CityReduxTypes.FETCH_CITY_VERSION
    | CityReduxTypes.FETCH_CITY_VERSION_DETAILS
    | CityReduxTypes.FETCH_CITY_LATEST_VERSION
    | CityReduxTypes.DELETE_CITY
    | CityReduxTypes.APPROVE_CITY
    | CityReduxTypes.REJECT_CITY
    | CityReduxTypes.BLOCK_CITY
    | CityReduxTypes.FREEZE_CITY
    | CityReduxTypes.UNFREEZE_CITY
    | CityReduxTypes.RESET;
  payload: any;
};

export type CityReducerDispatch = {
  type:
    | CityReduxTypes.FETCH_CITIES
    | CityReduxTypes.FETCH_CITIES_SUCCESS
    | CityReduxTypes.FETCH_CITIES_ERROR
    | CityReduxTypes.FETCH_CITY_BY_ID_SUCCESS
    | CityReduxTypes.FETCH_CITY_BY_ID
    | CityReduxTypes.FETCH_CITY_BY_ID_ERROR
    | CityReduxTypes.CREATE_CITY_SUCCESS
    | CityReduxTypes.CREATE_CITY_ERROR
    | CityReduxTypes.UPDATE_CITY_SUCCESS
    | CityReduxTypes.UPDATE_CITY_ERROR
    | CityReduxTypes.RESET_UPDATE_CITY
    | CityReduxTypes.FETCH_CITY_VERSION_SUCCESS
    | CityReduxTypes.FETCH_CITY_VERSION_ERROR
    | CityReduxTypes.FETCH_CITY_VERSION_DETAILS
    | CityReduxTypes.FETCH_CITY_VERSION_DETAILS_SUCCESS
    | CityReduxTypes.FETCH_CITY_VERSION_DETAILS_ERROR
    | CityReduxTypes.FETCH_CITY_LATEST_VERSION
    | CityReduxTypes.FETCH_CITY_LATEST_VERSION_SUCCESS
    | CityReduxTypes.FETCH_CITY_LATEST_VERSION_ERROR
    | CityReduxTypes.APPROVE_CITY
    | CityReduxTypes.APPROVE_CITY_SUCCESS
    | CityReduxTypes.APPROVE_CITY_ERROR
    | CityReduxTypes.DELETE_CITY
    | CityReduxTypes.DELETE_CITY_SUCCESS
    | CityReduxTypes.DELETE_CITY_ERROR
    | CityReduxTypes.REJECT_CITY
    | CityReduxTypes.REJECT_CITY_SUCCESS
    | CityReduxTypes.REJECT_CITY_ERROR
    | CityReduxTypes.BLOCK_CITY_SUCCESS
    | CityReduxTypes.BLOCK_CITY_ERROR
    | CityReduxTypes.FREEZE_CITY_SUCCESS
    | CityReduxTypes.FREEZE_CITY_ERROR
    | CityReduxTypes.UNFREEZE_CITY_SUCCESS
    | CityReduxTypes.UNFREEZE_CITY_ERROR
    | CityReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<City>;
    error?: string;
  };
};

export type CityReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<City>;
  error?: string | null;
};

export type City = {
  id: string;
  name: string;
  state: State;
  active: boolean;
  createdOn: Date;
  updatedOn: Date;
  externalId: string;
};

export type CitiesFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateCityReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<City>;
  error?: string | null;
};

export type CreateCity = {
  name: string;
  stateId: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateCity = {
  name: string;
  stateId: string;
  id: string;
  makerRemarks: string;
  draft: string;
};

export type CreateCityRequest = {
  payload: CreateCity;
  type: string;
};

export type UpdateCityRequest = {
  payload: UpdateCity;
  type: string;
};

export type FetchCityByIdRequest = {
  payload: string;
  type: string;
};
