import { CustomerCummunicationsFetchByIdRequest, CustomerCummunicationsFetchRequest, CustomerCummunicationsReduxType, UpdateCustomerCummunicationsRequestSaga } from "./types";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  aproveCustomerCummicationsApi,
  blockCustomerCummicationsApi,
  CreateCustomerCummicationsIdApi,
  deleteCustomerCummicationsApi,
  fetchCustomerCummicationsApi,
  fetchCustomerCummicationsIdApi,
  fetchCustomerCummicationsLatestVersionApi,
  fetchCustomerCummicationsVersionApi,
  fetchCustomerCummicationsVersionDetailsApi,
  freezeCustomerCummicationsApi,
  rejectCustomerCummicationsApi,
  unFreezeCustomerCummicationsApi,
  UpdateCustomerCummicationsIdApi
} from "./apis";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCustomerCummunication({ payload, type }: CustomerCummunicationsFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerCummunicationById({ payload, type }: CustomerCummunicationsFetchByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerCummicationsIdApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_ERROR,
      payload: { error: error }
    });
  }
}

function* CreateCustomerCummunication({ payload, type }: CustomerCummunicationsFetchByIdRequest): SagaIterator {
  try {
    const response = yield call(CreateCustomerCummicationsIdApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteCustomerCummunication({ payload, type }: CustomerCummunicationsFetchByIdRequest): SagaIterator {
  try {
    const response = yield call(deleteCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateCustomerCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(UpdateCustomerCummicationsIdApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerCummunicationVersion({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(fetchCustomerCummicationsVersionApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerCummunicationLatestVersion({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(fetchCustomerCummicationsLatestVersionApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerCummunicationVersionDetails({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(fetchCustomerCummicationsVersionDetailsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCustomerCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(aproveCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomerCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(rejectCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(blockCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(freezeCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCummunication({ payload, type }: UpdateCustomerCummunicationsRequestSaga): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerCummicationsApi, payload);

    yield put({
      type: CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchfetchCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS, fetchCustomerCummunication);
}

export function* watchfetchCustomerCummunicationById() {
  yield takeEvery(CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION, fetchCustomerCummunicationById);
}
export function* watchCreateCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS, CreateCustomerCummunication);
}

export function* watchUpdateCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS, UpdateCustomerCummunication);
}

export function* watchfechCustomerCummunicationVersion() {
  yield takeEvery(CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION, fetchCustomerCummunicationVersion);
}

///

export function* watchAproveCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS, aproveCustomerCummunication);
}

export function* watchRejectCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS, rejectCustomerCummunication);
}

export function* watchBlockCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS, blockCummunication);
}

export function* watchFreezeCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS, freezeCummunication);
}

export function* watchUnFreezeCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS, unFreezeCummunication);
}

export function* watchFetchCustomerCummunicationVersionDetails() {
  yield takeEvery(CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS, fetchCustomerCummunicationVersionDetails);
}

export function* watchFetchCustomerCummunicationLatestVersion() {
  yield takeEvery(CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION, fetchCustomerCummunicationLatestVersion);
}

export function* watchDeleteCustomerCummunication() {
  yield takeEvery(CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS, deleteCustomerCummunication);
}

function* fetchCustomerCummunicationSaga() {
  yield all([
    fork(watchfetchCustomerCummunication),
    fork(watchfetchCustomerCummunicationById),
    fork(watchCreateCustomerCummunication),
    fork(watchUpdateCustomerCummunication),
    fork(watchfechCustomerCummunicationVersion),
    fork(watchAproveCustomerCummunication),
    fork(watchRejectCustomerCummunication),
    fork(watchBlockCustomerCummunication),
    fork(watchFreezeCustomerCummunication),
    fork(watchUnFreezeCustomerCummunication),
    fork(watchFetchCustomerCummunicationVersionDetails),
    fork(watchFetchCustomerCummunicationLatestVersion),
    fork(watchDeleteCustomerCummunication)
  ]);
}

export default fetchCustomerCummunicationSaga;
