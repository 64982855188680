import { Country } from "redux/country/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum RepaymentReduxTypes {
  FETCH_REPAYMENTS_REQUEST = "@@auth/FETCH_REPAYMENTS_REQUEST",
  FETCH_REPAYMENTS_REQUEST_SUCCESS = "@@auth/FETCH_REPAYMENTS_REQUEST_SUCCESS",
  FETCH_REPAYMENTS_REQUEST_ERROR = "@@auth/FETCH_REPAYMENTS_REQUEST_ERROR",

  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES",
  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_SUCCESS = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_SUCCESS",
  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_ERROR = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_ERROR",

  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE",
  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_SUCCESS = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_SUCCESS",
  FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_ERROR = "@@auth/FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_ERROR",

  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES",
  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_SUCCESS = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_SUCCESS",
  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR",

  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE",
  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_SUCCESS = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_SUCCESS",
  FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_ERROR = "@@auth/FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_ERROR",

  FETCH_REPAYMENTS_REQUEST_FILTERS_REQUEST = "@@auth/FETCH_REPAYMENTS_FILTERS_REQUEST",
  FETCH_REPAYMENTS_REQUEST_FILTERS_SUCCESS = "@@auth/FETCH_REPAYMENTS_REQUEST_FILTERS_SUCCESS",
  FETCH_REPAYMENTS_REQUEST_FILTERS_ERROR = "@@auth/FETCH_REPAYMENTS_REQUEST_FILTERS_ERROR",

  FETCH_REPAYMENT_REQUEST = "@@auth/FETCH_REPAYMENT_REQUEST",
  FETCH_REPAYMENT_REQUEST_SUCCESS = "@@auth/FETCH_REPAYMENT_REQUEST_SUCCESS",
  FETCH_REPAYMENT_REQUEST_ERROR = "@@auth/FETCH_REPAYMENT_REQUEST_ERROR",

  CREATE_REPAYMENT_REQUEST = "@@auth/CREATE_REPAYMENT_REQUEST",
  CREATE_REPAYMENT_REQUEST_SUCCESS = "@@auth/CREATE_REPAYMENT_REQUEST_SUCCESS",
  CREATE_REPAYMENT_REQUEST_ERROR = "@@auth/CREATE_REPAYMENT_REQUEST_ERROR",
  RESET_CREATE_REPAYMENT_REQUEST = "@@auth/RESET_CREATE_REPAYMENT_REQUEST",

  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE",
  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_SUCCESS = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_SUCCESS",
  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_ERROR = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_ERROR",
  RESET_CREATE_REPAYMENT_REQUEST_ACTION_SATTLE = "@@auth/RESET_CREATE_REPAYMENT_REQUEST_ACTION_SATTLE",

  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY",
  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_SUCCESS = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_SUCCESS",
  CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_ERROR = "@@auth/CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_ERROR",
  RESET_CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY = "@@auth/RESET_CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY",

  UPLOAD_REPAYMENT_REQUEST_SETTLEMENT = "@@auth/UPLOAD_REPAYMENT_REQUEST_SETTLEMENT",
  UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_SUCCESS = "@@auth/UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_SUCCESS",
  UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_ERROR = "@@auth/UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_ERROR",
  RESET_UPLOAD_REPAYMENT_REQUEST_SETTLEMENT = "@@auth/RESET_UPLOAD_REPAYMENT_REQUEST_SETTLEMENT",

  FETCH_REPAYMENT_REQUEST_VERSION = "@@auth/FETCH_REPAYMENT_REQUEST_VERSION",
  FETCH_REPAYMENT_REQUEST_VERSION_SUCCESS = "@@auth/FETCH_REPAYMENT_REQ_REQUESTUEST_VERSION_SUCCESS",
  FETCH_REPAYMENT_REQUEST_VERSION_ERROR = "@@auth/FETCH_REPAYMENT_VERSION_ERROR",

  APPROVE_REPAYMENT_REQUEST = "@@auth/APPROVE_REPAYMENT_REQUEST",
  APPROVE_REPAYMENT_REQUEST_SUCCESS = "@@auth/APPROVE_REPAYMENT_REQUEST_SUCCESS",
  APPROVE_REPAYMENT_REQUEST_ERROR = "@@auth/APPROVE_REPAYMENT_REQUEST_ERROR",

  REJECT_REPAYMENT_REQUEST = "@@auth/REJECT_REPAYMENT_REQUEST",
  REJECT_REPAYMENT_REQUEST_SUCCESS = "@@auth/REJECT_REPAYMENT_REQUEST_SUCCESS",
  REJECT_REPAYMENT_REQUEST_ERROR = "@@auth/REJECT_REPAYMENT_REQUEST_ERROR",

  RESET = "@@auth/RESET"
}

export type RepaymentActionDispatch = {
  type:
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY

    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_REQUEST
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST
    | RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION
    | RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST
    | RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST
    | RepaymentReduxTypes.RESET_CREATE_REPAYMENT_REQUEST
    | RepaymentReduxTypes.RESET_UPLOAD_REPAYMENT_REQUEST_SETTLEMENT
    | RepaymentReduxTypes.RESET;
  payload: any;
};

export type RepaymentReducerDispatch = {
  type:
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_ERROR
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_SUCCESS
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_ERROR
    | RepaymentReduxTypes.RESET_CREATE_REPAYMENT_REQUEST_ACTION_SATTLE
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_REQUEST
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ERROR
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_SUCCESS
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ERROR
    | RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_SUCCESS
    | RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT
    | RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_ERROR
    | RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_SUCCESS
    | RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_ERROR
    | RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_SUCCESS
    | RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_ERROR
    | RepaymentReduxTypes.RESET_CREATE_REPAYMENT_REQUEST
    | RepaymentReduxTypes.RESET_UPLOAD_REPAYMENT_REQUEST_SETTLEMENT
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_SUCCESS
    | RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_ERROR

    | RepaymentReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Repayment>;
    error?: string;
  };
};

export type RepaymentReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Repayment>;
  error?: string | null;
};

export type Repayment = {
  id: string;
  transactionReferenceNumber: string;
  transactionReferenceType: string;
  virtualAccountNumber: string;
  loanAccountNumber: string;

  amount: string;
};

export type CreateRepaymentRedux = {
  loading?: boolean;
  data?: CreateApiResponse<Repayment>;
  error?: string | null;
};

export type RepaymentFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateRepaymentRequest = {
  payload: CreateRepayment;
  type: string;
};

export type UpdateRepaymentRequest = {
  payload: UpdateRepayment;
  type: string;
};

export type CreateRepayment = {
  countryId: string;
  name: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateRepayment = {
  externalId: string;
  countryId: Country;
  name: string;
  makerRemarks: string;
  draft: boolean;
};

export type FetchRepaymentByIdRequest = {
  payload: string;
  type: string;
};
