import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum ProductReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

  CREATE_PRODUCT_TYPE = "@@auth/CREATE_PRODUCT_TYPE",
  CREATE_PRODUCT_TYPE_SUCCESS = "@@auth/CREATE_PRODUCT_TYPE_SUCCESS",
  CREATE_PRODUCT_TYPE_ERROR = "@@auth/CREATE_PRODUCT_TYPE_ERROR",

  FETCH_PRODUCTS_TYPE = "@@auth/FETCH_PRODUCTS_TYPE",
  FETCH_PRODUCTS_TYPE_SUCCESS = "@@auth/FETCH_PRODUCTS_TYPE_SUCCESS",
  FETCH_PRODUCTS_TYPE_ERROR = "@@auth/FETCH_PRODUCTS_TYPE_ERROR",

  FETCH_PRODUCT_TYPE = "@@auth/FETCH_PRODUCT_TYPE",
  FETCH_PRODUCT_TYPE_SUCCESS = "@@auth/FETCH_PRODUCT_TYPE_SUCCESS",
  FETCH_PRODUCT_TYPE_ERROR = "@@auth/FETCH_PRODUCT_TYPE_ERROR",

  UPDATE_PRODUCT_TYPE = "@@auth/UPDATE_PRODUCT_TYPE",
  UPDATE_PRODUCT_TYPE_SUCCESS = "@@auth/UPDATE_PRODUCT_TYPE_SUCCESS",
  UPDATE_PRODUCT_TYPE_ERROR = "@@auth/UPDATE_PRODUCT_TYPE_ERROR",
  RESET_UPDATE_PRODUCT_TYPE = "@@auth/RESET_UPDATE_PRODUCT_TYPE",

  DELETE_PRODUCT_TYPE = "@@auth/DELETE_PRODUCT_TYPE",
  DELETE_PRODUCT_TYPE_SUCCESS = "@@auth/DELETE_PRODUCT_TYPE_SUCCESS",
  DELETE_PRODUCT_TYPE_ERROR = "@@auth/DELETE_PRODUCT_TYPE_ERROR",

  BLOCK_PRODUCT_TYPE = "@@auth/BLOCK_PRODUCT_TYPE",
  BLOCK_PRODUCT_TYPE_SUCCESS = "@@auth/BLOCK_PRODUCT_TYPE_SUCCESS",
  BLOCK_PRODUCT_TYPE_ERROR = "@@auth/BLOCK_PRODUCT_TYPE_ERROR",

  APROVE_PRODUCTS_TYPE = "@@auth/APROVE_PRODUCTS_TYPE",
  APROVE_PRODUCTS_TYPE_SUCCESS = "@@auth/APROVE_PRODUCTS_TYPE_SUCCESS",
  APROVE_PRODUCTS_TYPE_ERROR = "@@auth/APROVE_PRODUCTS_TYPE_ERROR",

  REJECT_PRODUCT_TYPE = "@@auth/REJECT_PRODUCT_TYPE",
  REJECT_PRODUCT_TYPE_SUCCESS = "@@auth/REJECT_PRODUCT_TYPE_SUCCESS",
  REJECT_PRODUCT_TYPE_ERROR = "@@auth/REJECT_PRODUCT_TYPE_ERROR",

  FETCH_PRODUCT_TYPE_VERSION = "@@auth/FETCH_PRODUCT_TYPE_VERSION",
  FETCH_PRODUCT_TYPE_VERSION_SUCCESS = "@@auth/FETCH_PRODUCT_TYPE_VERSION_SUCCESS",
  FETCH_PRODUCT_TYPE_VERSION_ERROR = "@@auth/FETCH_PRODUCT_TYPE_VERSION_ERROR",

  FETCH_PRODUCT_TYPE_VERSION_DETAILS = "@@auth/FETCH_PRODUCT_TYPE_VERSION_DETAILS",
  FETCH_PRODUCT_TYPE_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_PRODUCT_TYPE_VERSION_DETAILS_SUCCESS",
  FETCH_PRODUCT_TYPE_VERSION_DETAILS_ERROR = "@@auth/FETCH_PRODUCT_TYPE_VERSION_DETAILS_ERROR",

  FETCH_PRODUCT_TYPE_LATEST_VERSION = "@@auth/FETCH_PRODUCT_TYPE_LATEST_VERSION",
  FETCH_PRODUCT_TYPE_LATEST_VERSION_SUCCESS = "@@auth/FETCH_PRODUCT_TYPE_LATEST_VERSION_SUCCESS",
  FETCH_PRODUCT_TYPE_LATEST_VERSION_ERROR = "@@auth/FETCH_PRODUCT_TYPE_LATEST_VERSION_ERROR",

  FREEZE_PRODUCT_TYPE = "@@auth/FREEZE_PRODUCT_TYPE",
  FREEZE_PRODUCT_TYPE_SUCCESS = "@@auth/FREEZE_PRODUCT_TYPE_SUCCESS",
  FREEZE_PRODUCT_TYPE_ERROR = "@@auth/FREEZE_PRODUCT_TYPE_ERROR",

  UNFREEZE_PRODUCT_TYPE = "@@auth/UNFREEZE_PRODUCT_TYPE",
  UNFREEZE_PRODUCT_TYPE_SUCCESS = "@@auth/UNFREEZE_PRODUCT_TYPE_SUCCESS",
  UNFREEZE_PRODUCT_TYPE_ERROR = "@@auth/UNFREEZE_PRODUCT_TYPE_ERROR",

  RESET = "@@auth/RESET"
}

export type ProductTypeActionDispatch = {
  type:
    | ProductReduxTypes.FETCH_PRODUCTS_TYPE
    | ProductReduxTypes.FETCH_PRODUCT_TYPE
    | ProductReduxTypes.UPDATE_PRODUCT_TYPE
    | ProductReduxTypes.CREATE_PRODUCT_TYPE
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS
    | ProductReduxTypes.BLOCK_PRODUCT_TYPE
    | ProductReduxTypes.REJECT_PRODUCT_TYPE
    | ProductReduxTypes.APROVE_PRODUCTS_TYPE
    | ProductReduxTypes.FREEZE_PRODUCT_TYPE
    | ProductReduxTypes.UNFREEZE_PRODUCT_TYPE
    | ProductReduxTypes.RESET_UPDATE_PRODUCT_TYPE
    | ProductReduxTypes.DELETE_PRODUCT_TYPE
    | ProductReduxTypes.RESET;
  payload: any;
};

export type ProductTypeReducerDispatch = {
  type:
    | ProductReduxTypes.FETCH_PRODUCTS_TYPE
    | ProductReduxTypes.FETCH_PRODUCTS_TYPE_SUCCESS
    | ProductReduxTypes.FETCH_PRODUCTS_TYPE_ERROR
    | ProductReduxTypes.FETCH_PRODUCT_TYPE
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.UPDATE_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.UPDATE_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.RESET_UPDATE_PRODUCT_TYPE
    | ProductReduxTypes.CREATE_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.CREATE_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_SUCCESS
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_ERROR
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_SUCCESS
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_ERROR
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_SUCCESS
    | ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_ERROR
    | ProductReduxTypes.BLOCK_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.BLOCK_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.FREEZE_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.FREEZE_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.REJECT_PRODUCT_TYPE
    | ProductReduxTypes.REJECT_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.REJECT_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.APROVE_PRODUCTS_TYPE_SUCCESS
    | ProductReduxTypes.APROVE_PRODUCTS_TYPE
    | ProductReduxTypes.APROVE_PRODUCTS_TYPE_ERROR
    | ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.DELETE_PRODUCT_TYPE
    | ProductReduxTypes.DELETE_PRODUCT_TYPE_SUCCESS
    | ProductReduxTypes.DELETE_PRODUCT_TYPE_ERROR
    | ProductReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<ProductType>;
    error?: string;
  };
};

export type ProducttypeReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<ProductType>;
  error?: string | null;
};

export type ProductTypeFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type ProductType = {
  id: string;
  externalId: string;
  financierID: string;
  productType: string;
  instrumentType: string;
  chargePayerCode: string;
  chargePayer: string;
  exposureOnCode: string;
  exposureOn: string;
  programmeNature: string;
  anchorType: string;
  appropriationGroup: string;
};

export type CreateProductTypeeduxState = {
  loading?: boolean;
  data?: CreateApiResponse<ProductType>;
  error?: string | null;
};

export type UpdateProductType = {
  id: string;
  productType: string;
  instrumentType: string;
  chargePayerCode: string;
  chargePayer: string;
  exposureOnCode: string;
  exposureOn: string;
  programmeNature: string;
  anchorType: string;
};

export type UpdateProductRequest = {
  payload: UpdateProductType;
  type: string;
};

export type createProduct = {
  productType: string;
  instrumentType: string;
  chargePayerCode: string;
  chargePayer: string;
  exposureOnCode: string;
  exposureOn: string;
  programmeNature: string;
  anchorType: string;
};

export type CreateProductTypeRequestRequest = {
  payload: createProduct;
  type: string;
};
