import { City } from "redux/city/types";
import { Country } from "redux/country/types";
import { FinancierUser } from "redux/financierUser/types";
import { State } from "redux/state/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum BranchReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_BRANCH = "@@auth/FETCH_BRANCH",
  FETCH_BRANCH_SUCCESS = "@@auth/FETCH_BRANCH_SUCCESS",
  FETCH_BRANCH_ERROR = "@@auth/FETCH_BRANCH_ERROR",

  FETCH_BRANCH_BY_EXTERNAL_ID = "@@auth/FETCH_BRANCH_BY_EXTERNAL_ID",
  FETCH_BRANCH_BY_EXTERNAL_ID_SUCCESS = "@@auth/FETCH_BRANCH_BY_EXTERNAL_ID_SUCCESS",
  FETCH_BRANCH_BY_EXTERNAL_ID_ERROR = "@@auth/FETCH_BRANCH_BY_EXTERNAL_ID_ERROR",

  FETCH_BRANCH_VERSION = "@@auth/FETCH_BRANCH_VERSION",
  FETCH_BRANCH_VERSION_SUCCESS = "@@auth/FETCH_BRANCH_VERSION_SUCCESS",
  FETCH_BRANCH_VERSION_ERROR = "@@auth/FETCH_BRANCH_VERSION_ERROR",

  FETCH_BRANCH_VERSION_DETAILS = "@@auth/FETCH_BRANCH_VERSION_DETAILS",
  FETCH_BRANCH_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_BRANCH_VERSION_DETAILS_SUCCESS",
  FETCH_BRANCH_VERSION_DETAILS_ERROR = "@@auth/FETCH_BRANCH_VERSION_DETAILS_ERROR",

  FETCH_BRANCH_LATEST_VERSION = "@@auth/FETCH_BRANCH_LATEST_VERSION",
  FETCH_BRANCH_LATEST_VERSION_SUCCESS = "@@auth/FETCH_BRANCH_LATEST_VERSION_SUCCESS",
  FETCH_BRANCH_LATEST_VERSION_ERROR = "@@auth/FETCH_BRANCH_LATEST_VERSION_ERROR",

  APPROVE_BRANCH = "@@auth/APPROVE_BRANCH",
  APPROVE_BRANCH_SUCCESS = "@@auth/APPROVE_BRANCH_SUCCESS",
  APPROVE_BRANCH_ERROR = "@@auth/APPROVE_BRANCH_ERROR",

  REJECT_BRANCH = "@@authREJECT_BRANCH",
  REJECT_BRANCH_SUCCESS = "@@authREJECT_BRANCH_SUCCESS",
  REJECT_BRANCH_ERROR = "@@authREJECT_BRANCH_ERROR",

  CREATE_BRANCH = "@@auth/CREATE_BRANCH",
  CREATE_BRANCH_SUCCESS = "@@auth/CREATE_BRANCH_SUCCESS",
  CREATE_BRANCH_ERROR = "@@auth/CREATE_BRANCH_ERROR",

  UPDATE_BRANCH = "@@auth/UPDATE_BRANCH",
  UPDATE_BRANCH_SUCCESS = "@@auth/UPDATE_BRANCH_SUCCESS",
  UPDATE_BRANCH_ERROR = "@@auth/UPDATE_BRANCH_ERROR",
  RESET_UPDATE_BRANCH = "@@auth/RESET_UPDATE_BRANCH",

  DELETE_BRANCH = "@@auth/DELETE_BRANCH",
  DELETE_BRANCH_SUCCESS = "@@auth/DELETE_BRANCH_SUCCESS",
  DELETE_BRANCH_ERROR = "@@auth/DELETE_BRANCH_ERROR",

  BLOCK_BRANCH = "@@auth/BLOCK_BRANCH",
  BLOCK_BRANCH_SUCCESS = "@@auth/BLOCK_BRANCH_SUCCESS",
  BLOCK_BRANCH_ERROR = "@@auth/BLOCK_BRANCH_ERROR",

  FREEZE_BRANCH = "@@auth/FREEZE_BRANCH",
  FREEZE_BRANCH_SUCCESS = "@@auth/FREEZE_BRANCH_SUCCESS",
  FREEZE_BRANCH_ERROR = "@@auth/FREEZE_BRANCH_ERROR",

  UNFREEZE_BRANCH = "@@auth/UNFREEZE_BRANCH",
  UNFREEZE_BRANCH_SUCCESS = "@@auth/UNFREEZE_BRANCH_SUCCESS",
  UNFREEZE_BRANCH_ERROR = "@@auth/UNFREEZE_BRANCH_ERROR",

  RESET = "@@auth/RESET"
}

export type BranchActionDispatch = {
  type:
    | BranchReduxTypes.FETCH_BRANCH
    | BranchReduxTypes.FETCH_BRANCH_VERSION
    | BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION
    | BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS
    | BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID
    | BranchReduxTypes.CREATE_BRANCH
    | BranchReduxTypes.UPDATE_BRANCH
    | BranchReduxTypes.DELETE_BRANCH

    | BranchReduxTypes.RESET_UPDATE_BRANCH
    | BranchReduxTypes.APPROVE_BRANCH
    | BranchReduxTypes.REJECT_BRANCH
    | BranchReduxTypes.BLOCK_BRANCH
    | BranchReduxTypes.FREEZE_BRANCH
    | BranchReduxTypes.UNFREEZE_BRANCH
    | BranchReduxTypes.RESET;
  payload: any;
};

export type BranchReducerDispatch = {
  type:
    | BranchReduxTypes.FETCH_BRANCH_SUCCESS
    | BranchReduxTypes.FETCH_BRANCH_ERROR
    | BranchReduxTypes.FETCH_BRANCH_VERSION_SUCCESS
    | BranchReduxTypes.FETCH_BRANCH_VERSION_ERROR
    | BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS
    | BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_SUCCESS
    | BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_ERROR
    | BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION
    | BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_SUCCESS
    | BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_ERROR
    | BranchReduxTypes.FETCH_BRANCH
    | BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID
    | BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_SUCCESS
    | BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_ERROR
    | BranchReduxTypes.CREATE_BRANCH_SUCCESS
    | BranchReduxTypes.CREATE_BRANCH_ERROR
    | BranchReduxTypes.UPDATE_BRANCH_SUCCESS
    | BranchReduxTypes.UPDATE_BRANCH_ERROR
    | BranchReduxTypes.RESET_UPDATE_BRANCH
    | BranchReduxTypes.APPROVE_BRANCH
    | BranchReduxTypes.APPROVE_BRANCH_SUCCESS
    | BranchReduxTypes.APPROVE_BRANCH_ERROR
    | BranchReduxTypes.REJECT_BRANCH
    | BranchReduxTypes.REJECT_BRANCH_SUCCESS
    | BranchReduxTypes.REJECT_BRANCH_ERROR
    | BranchReduxTypes.BLOCK_BRANCH_SUCCESS
    | BranchReduxTypes.BLOCK_BRANCH_ERROR
    | BranchReduxTypes.FREEZE_BRANCH_SUCCESS
    | BranchReduxTypes.FREEZE_BRANCH_ERROR
    | BranchReduxTypes.UNFREEZE_BRANCH_SUCCESS
    | BranchReduxTypes.UNFREEZE_BRANCH_ERROR
    | BranchReduxTypes.DELETE_BRANCH
    | BranchReduxTypes.DELETE_BRANCH_SUCCESS
    | BranchReduxTypes.DELETE_BRANCH_ERROR

    | BranchReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Branch>;
    error?: string;
  };
};

export type BranchReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Branch>;
  error?: string | null;
};

export type BranchFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type Branch = {
  id: string;
  externalId: string;
  cbsCode: string;
  name: string;
  address1: string;
  address2: string;
  city: City;
  state: State;
  zip: string;
  country: Country;
  countryZone: string;
  timeZone: string;
  user: FinancierUser;
  status: string;
  inactiveReason: string;
  checkerRemarks: string;
  isDisabled: boolean;
  active: boolean;
};

export type CreateBranchRequest = {
  payload: CreateBranchPayLoad;
  type: string;
};

export type UpdateBranchRequest = {
  payload: UpdateBranch;
  type: string;
};
export type CreateBranchReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Branch>;
  error?: string | null;
};

export type CreateBranchPayLoad = {
  cbsCode: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  countryZone: string;
  timezone: string;
};

export type FetchBranchByExternalIdRequest = {
  payload: string;
  type: string;
};
export type branchVersionChangeRequest = {
  payload: updateBranchVersion;
  type: string;
};
export type updateBranchVersion = {
  checkerRemarks: string;
  externalId: string;
};

export type UpdateBranch = {
  cbsCode: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  countryZone: string;
  timeZone: string;
  externalBranchId: string;
};
