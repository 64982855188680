import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { FetchInstumentBatchRequest, FetchInstumentBatchesRequest, InstrumentBatchReduxTypes, IntrumentBatchUploadRequest } from './types';
import { fetchInstrumentBatchAuditActionsApi, fetchInstumentBatchesApi, fetchInstumentsBatchApi, uploadInstrumnetBatchesApi, } from './api';



/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchInstrumnetBatches({ payload, type }: FetchInstumentBatchesRequest): SagaIterator {
    try {
        const response = yield call(fetchInstumentBatchesApi, payload);
        yield put({
            type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        
        yield put({
            type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_ERROR,
            payload: { error: error },
        });
    }
}

function* uploadInstrumnetBatches({ payload, type }: IntrumentBatchUploadRequest): SagaIterator {
    // console.log('saga',payload);
    try {
        const response = yield call(uploadInstrumnetBatchesApi, payload);
        yield put({
            type: InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        
        yield put({
            type: InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_ERROR,
            payload: { error: error },
        });
    }
}


function* fetchInstrumentBatch({ payload, type }: FetchInstumentBatchRequest): SagaIterator {
    // console.log('saga',payload);
    
    try {
        const response = yield call(fetchInstumentsBatchApi, payload);
        yield put({
            type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        
        yield put({
            type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_ERROR,
            payload: { error: error },
        });
    }
}

function* fetchInstrumentBatchAuditActions({payload,type}:FetchInstumentBatchesRequest):SagaIterator{
    try {
      const response = yield call(fetchInstrumentBatchAuditActionsApi, payload);
      yield put({
        type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_SUCCESS,
        payload: { data: response.data.data }
      });
    } catch (error: any) {
      yield put({
        type: InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_ERROR,
        payload: { error: error }
      });
    }
  }

export function* watchFetchInstrumentBatches() {
    yield takeEvery(InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES, fetchInstrumnetBatches);
}

export function* watchUploadInstrumentBatches() {
    yield takeEvery(InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES, uploadInstrumnetBatches);
}
export function* watchFetchInstrumentBatch() {
    yield takeEvery(InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH,fetchInstrumentBatch);
}
export function* watchfetchInstrumentBatchAuditActions() {
    yield takeEvery(InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS, fetchInstrumentBatchAuditActions);
  }

function* instrumentBatchSaga() {
    yield all([
        fork(watchFetchInstrumentBatches),
        fork(watchUploadInstrumentBatches),
        fork(watchFetchInstrumentBatch),
        fork(watchfetchInstrumentBatchAuditActions)
       
    ]);
}

export default instrumentBatchSaga;
