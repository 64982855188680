import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { InterestAccrualFetchRequest, InstrumentInterestAccrualReduxTypes } from "./types";
import { fetchAllInterestAccrualApi, fetchInterestAccrualApi } from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchAllIntrumentInterestAccrual({ payload, type }: InterestAccrualFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAllInterestAccrualApi, payload);
    yield put({
      type: InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchIntrumentInterestAccrual({ payload, type }: InterestAccrualFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchInterestAccrualApi, payload);
    yield put({
      type: InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchAllIntrumentInterestAccrual() {
  yield takeEvery(InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS, fetchAllIntrumentInterestAccrual);
}

export function* watchFetchIntrumentInterestAccrual() {
  yield takeEvery(InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL, fetchIntrumentInterestAccrual);
}

function* instrumentInterestAccrualSaga() {
  yield all([fork(watchFetchAllIntrumentInterestAccrual), fork(watchFetchIntrumentInterestAccrual)]);
}

export default instrumentInterestAccrualSaga;
