import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../config";
import { CreateCountry, UpdateCountry } from "./types";

const api = new APICore();

function fetchCountriesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries`;
  return api.get(`${baseUrl}`, params);
}

function fetchCountryByIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params}`;
  return api.get(`${baseUrl}`, params);
}


function deleteCountryApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params}`;
  return api.delete(`${baseUrl}`);
}

function createCountryApi(params: CreateCountry) {
  let backendApiUrl = "";

  let apiData: any = {
    countryCode: params.countryCode ? params.countryCode : "+91",
    name: params.name ? params.name.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/countries`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/countries?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateCountryApi(params: UpdateCountry) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    name: params.name ? params.name.trim() : "",
    countryCode: params.countryCode ? params.countryCode : "+91"
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/countries/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/countries/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params.id}`;
  // return api.update(`${baseUrl}`, data);
}

function fetchCountriesVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params.id}/versions`;
  return api.get(`${baseUrl}`, params);
}

function fetchCountriesLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchCountriesVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveCountry(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectCountry(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function blockCountryApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeCountryApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeCountryApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/countries/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchCountriesApi,
  fetchCountryByIdApi,
  createCountryApi,
  updateCountryApi,
  fetchCountriesVersionApi,
  approveCountry,
  rejectCountry,
  blockCountryApi,
  freezeCountryApi,
  unFreezeCountryApi,
  fetchCountriesVersionDetailsApi,
  fetchCountriesLatestVersionApi,
  deleteCountryApi
};
