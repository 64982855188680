import { CreateRepaymentRedux, RepaymentReducerDispatch, RepaymentReduxState, RepaymentReduxTypes } from "./types";

const STATE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const RepaymentSettlementListReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentSettlementFiltesReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SATTLEMENT_FILTERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentRequestIdSettlementFiltesReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SATTLEMENT_FILTERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_STATE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const RepaymentSettlementReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT: {
      return {
        ...state,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export { RepaymentSettlementListReducer, RepaymentSettlementReducer, RepaymentSettlementFiltesReducer, RepaymentRequestIdSettlementFiltesReducer };
