import { Currency } from "redux/currency/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum DisbursementAuthReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_DISBURSEDMENT_AUTH = "@@auth/FETCH_DISBURSEDMENT_AUTH",
  FETCH_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/FETCH_DISBURSEDMENT_AUTH_SUCCESS",
  FETCH_DISBURSEDMENT_AUTH_ERROR = "@@auth/FETCH_DISBURSEDMENT_AUTH_ERROR",

  FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID = "@@auth/FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID",
  FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_SUCCESS = "@@auth/FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_SUCCESS",
  FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_ERROR = "@@auth/FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_ERROR",


  FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS = "@@auth/FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS",
  FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_SUCCESS = "@@auth/FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_SUCCESS",
  FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_ERROR = "@@auth/FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_ERROR",

  CREATE_DISBURSEDMENT_AUTH = "@@auth/CREATE_DISBURSEDMENT_AUTH",
  CREATE_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/CREATE_DISBURSEDMENT_AUTH_SUCCESS",
  CREATE_DISBURSEDMENT_AUTH_ERROR = "@@auth/CREATE_DISBURSEDMENT_AUTH_ERROR",
  RESET_CREATE_DISBURSEDMENT_AUTH = "@@auth/RESET_CREATE_DISBURSEDMENT_AUTH",


  UPDATE_DISBURSEDMENT_AUTH = "@@auth/UPDATE_DISBURSEDMENT_AUTH",
  UPDATE_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/UPDATE_DISBURSEDMENT_AUTH_SUCCESS",
  UPDATE_DISBURSEDMENT_AUTH_ERROR = "@@auth/UPDATE_DISBURSEDMENT_AUTH_ERROR",

  PUBLISH_DISBURSEDMENT_AUTH = "@@auth/PUBLISH_DISBURSEDMENT_AUTH",
  PUBLISH_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/PUBLISH_DISBURSEDMENT_AUTH_SUCCESS",
  PUBLISH_DISBURSEDMENT_AUTH_ERROR = "@@auth/PUBLISH_DISBURSEDMENT_AUTH_ERROR",

  FETCH_DISBURSEDMENT_AUTH_VERSION = "@@auth/FETCH_DISBURSEDMENT_AUTH_VERSION",
  FETCH_DISBURSEDMENT_AUTH_VERSION_SUCCESS = "@@auth/FETCH_DISBURSEDMENT_AUTH_VERSION_SUCCESS",
  FETCH_DISBURSEDMENT_AUTH_VERSION_ERROR = "@@auth/FETCH_DISBURSEDMENT_AUTH_VERSION_ERROR",

  APPROVE_DISBURSEDMENT_AUTH = "@@auth/APPROVE_DISBURSEDMENT_AUTH",
  APPROVE_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/APPROVE_DISBURSEDMENT_AUTH_SUCCESS",
  APPROVE_DISBURSEDMENT_AUTH_ERROR = "@@auth/APPROVE_DISBURSEDMENT_AUTH_ERROR",

  REJECT_DISBURSEDMENT_AUTH = "@@auth/REJECT_DISBURSEDMENT_AUTH",
  REJECT_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/REJECT_DISBURSEDMENT_AUTH_SUCCESS",
  REJECT_DISBURSEDMENT_AUTH_ERROR = "@@auth/REJECT_DISBURSEDMENT_AUTH_ERROR",

  BLOCK_DISBURSEDMENT_AUTH = "@@auth/BLOCK_DISBURSEDMENT_AUTH",
  BLOCK_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/BLOCK_DISBURSEDMENT_AUTH_SUCCESS",
  BLOCK_DISBURSEDMENT_AUTH_ERROR = "@@auth/BLOCK_DISBURSEDMENT_AUTH_ERROR",

  FREEZE_DISBURSEDMENT_AUTH = "@@auth/FREEZE_DISBURSEDMENT_AUTH",
  FREEZE_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/FREEZE_DISBURSEDMENT_AUTH_SUCCESS",
  FREEZE_DISBURSEDMENT_AUTH_ERROR = "@@auth/FREEZE_DISBURSEDMENT_AUTH_ERROR",

  UNFREEZE_DISBURSEDMENT_AUTH = "@@auth/UNFREEZE_DISBURSEDMENT_AUTH",
  UNFREEZE_DISBURSEDMENT_AUTH_SUCCESS = "@@auth/UNFREEZE_DISBURSEDMENT_AUTH_SUCCESS",
  UNFREEZE_DISBURSEDMENT_AUTH_ERROR = "@@auth/UNFREEZE_DISBURSEDMENT_AUTH_ERROR",

 
  RESET = "@@auth/RESET"
}

export type DisbursementAuthActionDispatch = {
  type:
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS
    | DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.RESET_CREATE_DISBURSEDMENT_AUTH
    
    | DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.RESET;
  payload: any;
};

export type DisbursementAuthReducerDispatch = {
  type:
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.RESET_CREATE_DISBURSEDMENT_AUTH

    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_SUCCESS
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_ERROR
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_SUCCESS
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_ERROR
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_SUCCESS
    | DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_ERROR
    | DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_SUCCESS
    | DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_ERROR
    | DisbursementAuthReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<DisbursementAuth>;
    error?: string;
  };
};

export type DisbursementAuthReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<DisbursementAuth>;
  error?: string | null;
};

export type DisbursementAuthFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type DisbursementAuth = {
  id: string;
  externalId: string;
  status: string;
  country: string;
  currency: Currency;
  date: string;
  comments: string;
  userId: string;
  active: boolean;
  inactiveReason: string;
  checkerRemarks: string;
  isDisabled: boolean;
  draft: boolean;
  makerRemarks: string;
  batchFilePath: string;
};

export type CreateDisbursementAuth = {
  country: string;
  currency: string;
  date: string;
  comments: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreateDisbursementAuthRequest = {
  payload: CreateDisbursementAuth;
  type: string;
};
export type UpdateDisbursementAuthRequest = {
  payload: DisbursementAuth;
  type: string;
};
export type CreateDisbursementAuthReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<DisbursementAuth>;
  error?: string | null;
};

export type FetchDisbursementAuthByExternalIdRequest = {
  payload: string;
  type: string;
};
export type DisbursementAuthVersionChangeRequest = {
  payload: updateDisbursementAuthVersion;
  type: string;
};
export type updateDisbursementAuthVersion = {
  checkerRemarks: string;
  externalId: string;
};
