import { APICore } from "helpers/api/apiCore";
import config from "../../../config";
import {  FilterMetadata } from "redux/types";

const api = new APICore();

function fetchExcessMoneysApi(params:FilterMetadata) {
    const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-excess-money`;
    return api.get(`${baseUrl}`, params);
}

function fetchExcessMoneyByIdApi(params:any) {
    const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-excess-money/repayment/${params?.repaymentRequestID}`;
    return api.get(`${baseUrl}`, {});
}

export {
    fetchExcessMoneysApi,
    fetchExcessMoneyByIdApi
};