import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchAllDailyBalanceHistoryApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/instrument-balances-daily-history`;
  return api.get(`${baseUrl}`, params);
}

function fetchDailyBalanceHistoryApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/instrument-balances-daily-history/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

export { fetchAllDailyBalanceHistoryApi, fetchDailyBalanceHistoryApi };
