import { FilterMetadata } from 'redux/types';
import { BranchActionDispatch, BranchReduxTypes, CreateBranchPayLoad, UpdateBranch, updateBranchVersion } from './types';




export const fetchBranch = (payload: FilterMetadata): BranchActionDispatch => ({
    type: BranchReduxTypes.FETCH_BRANCH,
    payload: payload,
});

export const fetchBranchByExternalId = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID,
    payload: payload,
});



export const fetchBranchVersions = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.FETCH_BRANCH_VERSION,
    payload: payload,
});


export const fetchBranchLatestVersions = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION,
    payload: payload,
});

export const fetchBranchVersionsDetails = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS,
    payload: payload,
});

export const approveBranchVersions = (payload: updateBranchVersion): BranchActionDispatch => ({
    type: BranchReduxTypes.APPROVE_BRANCH,
    payload: payload,
});
export const rejectBranchVersions = (payload: updateBranchVersion): BranchActionDispatch => ({
    type: BranchReduxTypes.REJECT_BRANCH,
    payload: payload,
});

export const createBranch = (payload:CreateBranchPayLoad): BranchActionDispatch => ({
    type: BranchReduxTypes.CREATE_BRANCH,
    payload: payload,
});

export const updateBranch = (payload: UpdateBranch): BranchActionDispatch => ({
    type: BranchReduxTypes.UPDATE_BRANCH,
    payload: payload,
});

export const deleteBranch = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.DELETE_BRANCH,
    payload: payload,
});

export const resetUpdateBranch = (): BranchActionDispatch => ({
    type: BranchReduxTypes.RESET_UPDATE_BRANCH,
    payload: {},
});


export const blockBranch = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.BLOCK_BRANCH,
    payload: payload,
});

export const freezeBranch = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.FREEZE_BRANCH,
    payload: payload,
});

export const unFreezeBranch = (payload: any): BranchActionDispatch => ({
    type: BranchReduxTypes.UNFREEZE_BRANCH,
    payload: payload,
});


export const resetBranch = (): BranchActionDispatch => ({
    type: BranchReduxTypes.RESET,
    payload: {},
});
