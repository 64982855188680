import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CitiesFetchRequest, ChargeReduxTypes, CreateChargeRequest, FetchChargeByIdRequest, UpdateChargeRequest } from "./types";
import {
  approveChargeApi,
  blockChargeApi,
  createChargeApi,
  fetchChargesApi,
  fetchChargeApi,
  fetchChargeVersionApi,
  rejectChargeApi,
  updateChargeApi,
  freezeChargeApi,
  unFreezeChargeApi,
  fetchChargeVersionDetailsApi,
  fetchChargeLatestVersionApi,
  deleteChargeApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchChargesApi, payload);
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchChargeByID({ payload, type }: FetchChargeByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* createCharge({ payload, type }: CreateChargeRequest): SagaIterator {
  try {
    const response = yield call(createChargeApi, payload);

    yield put({
      type: ChargeReduxTypes.CREATE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ChargeReduxTypes.CREATE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCharge({ payload, type }: UpdateChargeRequest): SagaIterator {
  try {
    const response = yield call(updateChargeApi, payload);

    yield put({
      type: ChargeReduxTypes.UPDATE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ChargeReduxTypes.UPDATE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteCharge({ payload, type }: UpdateChargeRequest): SagaIterator {
  try {
    const response = yield call(deleteChargeApi, payload);

    yield put({
      type: ChargeReduxTypes.DELETE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: ChargeReduxTypes.DELETE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchChargesVersion({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchChargeVersionApi, payload);
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchChargesVersionDetails({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchChargeVersionDetailsApi, payload);
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchChargesLatestVersion({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchChargeLatestVersionApi, payload);
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* approveCharge({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(approveChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.APPROVE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.APPROVE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCharges({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.REJECT_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.REJECT_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCharge({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(blockChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.BLOCK_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.BLOCK_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCharge({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.FREEZE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.FREEZE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCharge({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeChargeApi, payload);
    yield put({
      type: ChargeReduxTypes.UNFREEZE_CHARGE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: ChargeReduxTypes.UNFREEZE_CHARGE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCities() {
  yield takeEvery(ChargeReduxTypes.FETCH_CHARGES, fetchCities);
}
export function* watchCreateCharge() {
  yield takeEvery(ChargeReduxTypes.CREATE_CHARGE, createCharge);
}
export function* watchFetchChargeById() {
  yield takeEvery(ChargeReduxTypes.FETCH_CHARGE, fetchChargeByID);
}
export function* watchUpdateCharge() {
  yield takeEvery(ChargeReduxTypes.UPDATE_CHARGE, updateCharge);
}
export function* watchVerisonCharge() {
  yield takeEvery(ChargeReduxTypes.FETCH_CHARGE_VERSION, fetchChargesVersion);
}
export function* watchApprovetCharge() {
  yield takeEvery(ChargeReduxTypes.APPROVE_CHARGE, approveCharge);
}
export function* watchRejectCharge() {
  yield takeEvery(ChargeReduxTypes.REJECT_CHARGE, rejectCharges);
}

export function* watchBlockCharge() {
  yield takeEvery(ChargeReduxTypes.BLOCK_CHARGE, blockCharge);
}

export function* watchFreezeCharge() {
  yield takeEvery(ChargeReduxTypes.FREEZE_CHARGE, freezeCharge);
}

export function* watchUnFreezeCharge() {
  yield takeEvery(ChargeReduxTypes.UNFREEZE_CHARGE, unFreezeCharge);
}

export function* watchFetchChargesVersionDetails() {
  yield takeEvery(ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS, fetchChargesVersionDetails);
}


export function* watchFetchChargesLatestVersion() {
  yield takeEvery(ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION, fetchChargesLatestVersion);
}

export function* watchDeleteCharge() {
  yield takeEvery(ChargeReduxTypes.DELETE_CHARGE, deleteCharge);
}





function* chargeSaga() {
  yield all([
    fork(watchFetchCities),
    fork(watchCreateCharge),
    fork(watchFetchChargeById),
    fork(watchUpdateCharge),
    fork(watchVerisonCharge),
    fork(watchApprovetCharge),
    fork(watchRejectCharge),
    fork(watchBlockCharge),
    fork(watchFreezeCharge),
    fork(watchUnFreezeCharge),
    fork(watchFetchChargesVersionDetails),
    fork(watchFetchChargesLatestVersion),
    fork(watchDeleteCharge)
  ]);
}

export default chargeSaga;
