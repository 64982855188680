import { Currency } from "redux/currency/types"
import { Customer } from "redux/customer/types"
import { ProductType } from "redux/productType/types"
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types"

export enum ProductLimitConfigReduxTypes {
    // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    FETCH_PRODUCT_LIMIT_CONFIG = '@@auth/FETCH_PRODUCT_LIMIT_CONFIG',
    FETCH_PRODUCT_LIMIT_CONFIG_SUCCESS = '@@auth/FETCH_PRODUCT_LIMIT_CONFIG_SUCCESS',
    FETCH_PRODUCT_LIMIT_CONFIG_ERROR = '@@auth/FETCH_PRODUCT_LIMIT_CONFIG_ERROR',

    CREATE_PRODUCT_LIMIT_CONFIG = '@@auth/CREATE_PRODUCT_LIMIT_CONFIG',
    CREATE_PRODUCT_LIMIT_CONFIG_SUCCESS = '@@auth/CREATE_PRODUCT_LIMIT_CONFIG_SUCCESS',
    CREATE_PRODUCT_LIMIT_CONFIG_ERROR = '@@auth/CREATE_PRODUCT_LIMIT_CONFIG_ERROR',

    UPDATE_PRODUCT_LIMIT_CONFIG = '@@auth/UPDATE_PRODUCT_LIMIT_CONFIG',
    UPDATE_PRODUCT_LIMIT_CONFIG_SUCCESS = '@@auth/UPDATE_PRODUCT_LIMIT_CONFIG_SUCCESS',
    UPDATE_PRODUCT_LIMIT_CONFIG_ERROR = '@@auth/UPDATE_PRODUCT_LIMIT_CONFIG_ERROR',

    FETCH_SINGLE_PRODUCT_LIMIT_CONFIG = '@@auth/FETCH_SINGLE_PRODUCT_LIMIT_CONFIG',
    FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_SUCCESS = '@@auth/FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_SUCCESS',
    FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_ERROR = '@@auth/FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_ERROR',
    
    RESET = '@@auth/RESET',
    RESET_SINGLE_LIMIT_CONFIG = '@@auth/RESET_SINGLE_LIMIT_CONFIG',

    RESET_CREATE_PRODUCT_LIMIT_CONFIG= '@@auth/RESET_CREATE_PRODUCT_LIMIT_CONFIG',
}

export type ProductLimitConfigActionDispatch = {
    type: 
        | ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG
        | ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG
        | ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG
        | ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG
        | ProductLimitConfigReduxTypes.RESET
        | ProductLimitConfigReduxTypes.RESET_SINGLE_LIMIT_CONFIG

        | ProductLimitConfigReduxTypes.RESET_CREATE_PRODUCT_LIMIT_CONFIG
    payload: any
}

export type ProductLimitConfigReducerDispatch = {
    type:
    | ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_SUCCESS
    | ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_ERROR
    | ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_SUCCESS
    | ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_ERROR
    | ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_SUCCESS
    | ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_ERROR
    | ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_SUCCESS
    | ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_ERROR
    | ProductLimitConfigReduxTypes.RESET
    | ProductLimitConfigReduxTypes.RESET_SINGLE_LIMIT_CONFIG
    | ProductLimitConfigReduxTypes.RESET_CREATE_PRODUCT_LIMIT_CONFIG
    payload: {
        data?: FetchApiResponse<ProductLimitConfig>;
        error?: string;
    };
}

export type ProductLimitConfig = {
    id: string;
    externalId: string;
    customer: Customer;
    levelType: string;
    productNature: string;
    productType: ProductType;
    currency: Currency;
    cbsAccountNumber: string;
    normalSanctionedAmount: number;
    normalOperativeAmount: number;
    normalEffectiveFrom: string;
    normalEffectiveUpto: string;
    normalEffective: boolean;
    adhocSanctionedAmount: number;
    adhocOperativeAmount: number;
    adhocEffectiveFrom: string;
    adhocEffectiveUpto: string;
    adhocEffective: boolean;
    userId: string;
    status: string;
    freeze: boolean;
    checkerRemarks: string;
    active: boolean;
    draft: boolean;
    inactiveReason: string;
    isDisabled: boolean;
};

export type ProductLimitConfigReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<ProductLimitConfig>;
    error?: string | null;
}

export type ProductLimitConfigFetchRequest = {
    payload: FilterMetadata;
    type: string;
};


export type CreateProductLimitConfig = {
    customerExternalId: Customer;
    levelType: string;
    productNature: string;
    productTypeId: string;
    parentAggregateId: string,
    currencyId: Currency,
    cbsAccountNumber: string;
    normalSanctionedAmount: number;
    normalOperativeAmount: number;
    normalEffectiveFrom: string;
    normalEffectiveUpto: string;
    normalEffective: boolean;
    adhocSanctionedAmount: number;
    adhocOperativeAmount: number;
    adhocEffectiveFrom: string;
    adhocEffectiveUpto: string;
    adhocEffective: boolean;
}

export type CreateProductLimitConfigReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<ProductLimitConfig>;
    error?: string | null;
}

export type CreateProductLimitConfigRequest = {
    payload: CreateProductLimitConfig;
    type: string;
}

export type UpdateProductLimitConfigRequest = {
    payload: ProductLimitConfig;
    type: string;
}