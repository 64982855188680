import { FilterMetadata } from "redux/types";
import { CreateState, StateActionDispatch, StateReduxTypes, UpdateState } from "./types";

export const fetchStates = (payload: FilterMetadata): StateActionDispatch => ({
    type: StateReduxTypes.FETCH_STATES,
    payload: payload
});

export const createState = (payload:CreateState): StateActionDispatch => ({
    type: StateReduxTypes.CREATE_STATE,
    payload: payload,
});

export const fetchStateById= (payload: any): StateActionDispatch => ({
    type: StateReduxTypes.FETCH_STATE_BY_ID,
    payload: payload,
});

export const updateState = (payload:UpdateState): StateActionDispatch => ({
    type: StateReduxTypes.UPDATE_STATE,
    payload: payload,
});



export const deleteState = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.DELETE_STATE,
    payload: payload,
});

export const resetUpdateState = (): StateActionDispatch => ({
    type: StateReduxTypes.RESET_UPDATE_STATE,
    payload: {},
});

export const fetchStateVersion = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.FETCH_STATE_VERSION,
    payload: payload,
});


export const fetchStateLatestVersion = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.FETCH_STATE_LATEST_VERSION,
    payload: payload,
});


export const fetchStateVersionDetails = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.FETCH_STATE_VERSION_DETAILS,
    payload: payload,
});

export const approveState= (payload: any): StateActionDispatch => ({
    type: StateReduxTypes.APPROVE_STATE,
    payload: payload,
});

export const rejectState = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.REJECT_STATE,
    payload: payload,
});


export const blockState = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.BLOCK_STATE,
    payload: payload,
});


export const freezeState = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.FREEZE_STATE,
    payload: payload,
});


export const unFreezeState = (payload:any): StateActionDispatch => ({
    type: StateReduxTypes.UNFREEZE_STATE,
    payload: payload,
});



export const resetState = (): StateActionDispatch => ({
    type: StateReduxTypes.RESET,
    payload: {},
});

