import { APICore } from "helpers/api/apiCore";
import config from "config";

const api = new APICore();
function createClientSummaryApi(params: any) {
    let apiData: any = {
        customerExternalId: params.customerId.length ? params.customerId : null
    }
    let backendApiUrl = `${config.BACKEND_BASE_URL}/report/client-summary`
    return api.create(`${backendApiUrl}`, apiData)
}
export { createClientSummaryApi }