import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CustomerCummunicationsReduxType {
  FETCH_CUSTOMER_CUMMUNICATIONS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS",
  FETCH_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  FETCH_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_ERROR",

  FETCH_CUSTOMER_CUMMUNICATION = "@auth/FETCH_CUSTOMER_CUMMUNICATION",
  FETCH_CUSTOMER_CUMMUNICATION_SUCCESS = "@auth/FETCH_CUSTOMER_CUMMUNICATION_SUCCESS",
  FETCH_CUSTOMER_CUMMUNICATION_ERROR = "@auth/FETCH_CUSTOMER_CUMMUNICATION_ERROR",

  CREATE_CUSTOMER_CUMMUNICATIONS = "@auth/CREATE_CUSTOMER_CUMMUNICATIONS",
  CREATE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/CREATE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  CREATE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/CREATE_CUSTOMER_CUMMUNICATIONS_ERROR",

  DELETE_CUSTOMER_CUMMUNICATIONS = "@auth/DELETE_CUSTOMER_CUMMUNICATIONS",
  DELETE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/DELETE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  DELETE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/DELETE_CUSTOMER_CUMMUNICATIONS_ERROR",





  UPDATE_CUSTOMER_CUMMUNICATIONS = "@auth/UPDATE_CUSTOMER_CUMMUNICATIONS",
  UPDATE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/UPDATE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  UPDATE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/UPDATE_CUSTOMER_CUMMUNICATIONS_ERROR",

  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION",
  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_SUCCESS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_SUCCESS",
  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_ERROR = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_ERROR",

  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS",
  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_SUCCESS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_SUCCESS",
  FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_ERROR = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_ERROR",

  FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION",
  FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_SUCCESS = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_SUCCESS",
  FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_ERROR = "@auth/FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_ERROR",

  APROVE_CUSTOMER_CUMMUNICATIONS = "@auth/APROVE_CUSTOMER_CUMMUNICATIONS",
  APROVE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/APROVE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  APROVE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/APROVE_CUSTOMER_CUMMUNICATIONS_ERROR",

  REJECT_CUSTOMER_CUMMUNICATIONS = "@auth/REJECT_CUSTOMER_CUMMUNICATIONS",
  REJECT_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/REJECT_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  REJECT_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/REJECT_CUSTOMER_CUMMUNICATIONS_ERROR",

  BLOCK_CUSTOMER_CUMMUNICATIONS = "@auth/BLOCK_CUSTOMER_CUMMUNICATIONS",
  BLOCK_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/BLOCK_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  BLOCK_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/BLOCK_CUSTOMER_CUMMUNICATIONS_ERROR",

  FREEZE_CUSTOMER_CUMMUNICATIONS = "@auth/FREEZE_CUSTOMER_CUMMUNICATIONS",
  FREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/FREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  FREEZE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/FREEZE_CUSTOMER_CUMMUNICATIONS_ERROR",

  UNFREEZE_CUSTOMER_CUMMUNICATIONS = "@auth/UNFREEZE_CUSTOMER_CUMMUNICATIONS",
  UNFREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS = "@auth/UNFREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS",
  UNFREEZE_CUSTOMER_CUMMUNICATIONS_ERROR = "@auth/UNFREEZE_CUSTOMER_CUMMUNICATIONS_ERROR",

  RESET = "@@auth/RESET"
}

export type CustomerCummunicationsActionDispatch = {
  type:
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION
    | CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS
    | CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS

    | CustomerCummunicationsReduxType.RESET;
  payload: any;
};

export type CustomerCummunicationsReducerDispatch = {
  type:
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_SUCCESS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_ERROR
    | CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_SUCCESS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_ERROR
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_SUCCESS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_ERROR
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_SUCCESS
    | CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_ERROR
    | CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_ERROR
    | CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS
    | CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_SUCCESS
    | CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_ERROR

    | CustomerCummunicationsReduxType.RESET;
  payload: {
    data?: FetchApiResponse<CustomerCummunications>;
    error?: string;
  };
};

export type CustomerCummunications = {
  id: string;
  customerId: string;
  type: string;
  value: string;
  usedInReport: string;
  version: string;
  active: boolean;
  isStandardOpen: boolean;
};

export type CreateCustomerCummunication = {
  type: string;
  value: string;
  usedInReport: string;
};

export type CustomerCummunicationsFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CustomerCummunicationsReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<CustomerCummunications>;
  error?: string | null;
};

export type CustomerCummunicationsFetchByIdRequest = {
  payload: string;
  type: string;
};

export type UpdateCustomerCummunications = {
  type: string;
  value: string;
  usedInReport: string;
  externalUserId: string;
  customerId: string;
};

export type UpdateCustomerCummunicationsRequestSaga = {
  payload: UpdateCustomerCummunications;
  type: string;
};
