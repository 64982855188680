import { CreateLimitConfigReduxState, LimitConfigReducerDispatch, LimitConfigReduxState, LimitConfigReduxTypes } from "./types";

const LIMIT_CONFIG_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const LimitConfigListReducer = (state: LimitConfigReduxState = LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_LIMIT_CONFIG_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};


const deleteLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const FetchLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG: {
      return {
        ...state,
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const FetchLimitConfigVersionDetailsReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const FetchLimitConfigLatestVersionReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const FetchLimitConfigHistroyReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY: {
      return {
        ...state,
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};
const FetchAggregateLimitConfigHistroyReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY: {
      return {
        ...state,
        loading: true
      };
    }
    case LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const UpdateLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case LimitConfigReduxTypes.RESET: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const FetchLimitConfigVesrions = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeLimitConfigReducer = (state: CreateLimitConfigReduxState = CREATE_LIMIT_CONFIG_INIT_STATE, action: LimitConfigReducerDispatch) => {
  switch (action.type) {
    case LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  LimitConfigListReducer,
  CreateLimitConfigReducer,
  FetchLimitConfigReducer,
  UpdateLimitConfigReducer,
  FetchLimitConfigVesrions,
  ApproveLimitConfigReducer,
  RejectLimitConfigReducer,
  BlockLimitConfigReducer,
  FreezeLimitConfigReducer,
  UnFreezeLimitConfigReducer,
  FetchLimitConfigHistroyReducer,
  FetchAggregateLimitConfigHistroyReducer,
  FetchLimitConfigVersionDetailsReducer,
  FetchLimitConfigLatestVersionReducer,
  deleteLimitConfigReducer
};
