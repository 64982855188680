import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../config";

const api = new APICore();

function fetchChargesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges`;
  return api.get(`${baseUrl}`, params);
}

function fetchChargeApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params}`;
  return api.get(`${baseUrl}`, {});
}

function createChargeApi(params: any) {
  let backendApiUrl = "";
  // console.log("createChargeApi params", params);

  let apiData: any = {
    customerRelationExternalId: params?.buyerSellerId ? params?.buyerSellerId : "",
    chargeType: params?.chargeType ? params?.chargeType : "",
    gstPercentage: params?.gstPercentage ? params?.gstPercentage : "",
    amountWithoutGst: params?.amountWithoutGst ? params?.amountWithoutGst : "",
    gstAmount: params?.gstAmount ? params?.gstAmount : "",
    amount: params?.amount ? params?.amount : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/charges`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/charges?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/charges`;
  // return api.create(`${baseUrl}`, params);
}

function updateChargeApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    customerRelationExternalId: params?.buyerSellerId ? params?.buyerSellerId : "",
    chargeType: params?.chargeType ? params?.chargeType : "",
    gstPercentage: params?.gstPercentage ? params?.gstPercentage : "",
    amountWithoutGst: params?.amountWithoutGst ? params?.amountWithoutGst : "",
    gstAmount: params?.gstAmount ? params?.gstAmount : "",
    amount: params?.amount ? params?.amount : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/charges/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/charges/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function fetchChargeVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}


function deleteChargeApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params}`;
  return api.delete(`${baseUrl}`);
}


function fetchChargeVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function fetchChargeLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}



function approveChargeApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectChargeApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params?.externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockChargeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeChargeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeChargeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/charges/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchChargesApi,
  fetchChargeApi,
  createChargeApi,
  updateChargeApi,
  fetchChargeVersionApi,
  approveChargeApi,
  rejectChargeApi,
  blockChargeApi,
  freezeChargeApi,
  unFreezeChargeApi,
  fetchChargeVersionDetailsApi,
  fetchChargeLatestVersionApi,
  deleteChargeApi
};
