import { InstrumentBatchReducerDispatch, InstrumentBatchReduxState, InstrumentBatchReduxTypes, InstrumentBatchUploadReduxState } from "./types";


const INSTRUMNET_BATCH_LIST_INIT_STATE = {
    loading: true,
    data: {
        metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
                self: null,
                first: null,
                previous: null,
                next: null,
                last: null
            }
        },
        records: []
    },
    error: null
};

const InstrumentBatchListReducer = (state: InstrumentBatchReduxState = INSTRUMNET_BATCH_LIST_INIT_STATE, action: InstrumentBatchReducerDispatch) => {
    switch (action.type) {
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};

const InstrumentBatchReducer = (state: InstrumentBatchReduxState = INSTRUMNET_BATCH_LIST_INIT_STATE, action: InstrumentBatchReducerDispatch) => {
    switch (action.type) {
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};

const InstrumentBatchAuditActionsReducer=(state: InstrumentBatchReduxState = INSTRUMNET_BATCH_LIST_INIT_STATE, action: InstrumentBatchReducerDispatch)=>{
    switch (action.type) {
      case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS: {
        return {
          ...state,
          loading: true
        };
      }
      case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_SUCCESS: {
        return {
          ...state,
          data: action.payload.data,
          loading: false
        };
      }
      case InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_ERROR: {
        return {
          ...state,
          error: action.payload.error,
          loading: false
        };
      }
      case InstrumentBatchReduxTypes.RESET:
        return {
          ...state,
          data: {
            metadata: {
              totalCount: 0,
              currentPageRecords: 0,
              totalPages: 0,
              links: {
                self: null,
                first: null,
                previous: null,
                next: null,
                last: null
              }
            },
            records: []
          },
          loading: false,
          error: false
        };
      default:
        return { ...state };
    }
  };

const INSTRUMENT_BATCH_UPLOAD_INIT_STATE = {
    loading: false,
    data: {},
    error: null
};

const UploadInstrumnetBatchReducer = (state: InstrumentBatchUploadReduxState = INSTRUMENT_BATCH_UPLOAD_INIT_STATE, action: InstrumentBatchReducerDispatch) => {
    switch (action.type) {
        case InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case InstrumentBatchReduxTypes.RESET:
            return {
                ...state,
                data: {},
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
}

export { InstrumentBatchListReducer,UploadInstrumnetBatchReducer,InstrumentBatchReducer, InstrumentBatchAuditActionsReducer}