import { UserRoleReduxTypes, UserRoleReducerDispatch, UserRoleReduxState, CreateUserRoleReduxState } from "./types";

const USER_ROLE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const UserRoleListReducer = (state: UserRoleReduxState = USER_ROLE_LIST_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_USER_ROLES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case UserRoleReduxTypes.FETCH_USER_ROLES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ModulePermissionListReducer = (state: UserRoleReduxState = USER_ROLE_LIST_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_MODULE_PERMISSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case UserRoleReduxTypes.FETCH_MODULE_PERMISSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_USER_ROLE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.CREATE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.CREATE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const USER_ROLE_INIT_STATE = {
  data: {},
  loading: true,
  error: null
};

const UserRoleReducer = (state: CreateUserRoleReduxState = USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_USER_ROLE: {
      return {
        ...state,
        loading: true
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.UPDATE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.UPDATE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchUserRoleVesrions = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchUserRoleVesrionsDetails = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchUserRoleLatestVesrionsReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeleteUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.DELETE_USER_ROLE: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case UserRoleReduxTypes.DELETE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.DELETE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.APPROVE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.APPROVE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.REJECT_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.REJECT_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.BLOCK_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.BLOCK_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.FREEZE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.FREEZE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeUserRoleReducer = (state: CreateUserRoleReduxState = CREATE_USER_ROLE_INIT_STATE, action: UserRoleReducerDispatch) => {
  switch (action.type) {
    case UserRoleReduxTypes.UNFREEZE_USER_ROLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case UserRoleReduxTypes.UNFREEZE_USER_ROLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case UserRoleReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  UserRoleListReducer,
  CreateUserRoleReducer,
  UserRoleReducer,
  UpdateUserRoleReducer,
  FetchUserRoleVesrions,
  ApproveUserRoleReducer,
  RejectUserRoleReducer,
  BlockUserRoleReducer,
  FreezeUserRoleReducer,
  UnFreezeUserRoleReducer,
  ModulePermissionListReducer,
  FetchUserRoleVesrionsDetails,
  FetchUserRoleLatestVesrionsReducer,
  DeleteUserRoleReducer
};
