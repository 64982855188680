import { CreateApiResponse, FetchApiResponse } from "redux/types";

export enum InterestComputationReduxTypes {
    INTEREST_COMPUTATION_REPORT = "@@auth/INTEREST_COMPUTATION_REPORT",
    INTEREST_COMPUTATION_REPORT_SUCCESS = "@@auth/INTEREST_COMPUTATION_REPORT_SUCCESS",
    INTEREST_COMPUTATION_REPORT_ERROR = "@@auth/INTEREST_COMPUTATION_REPORT_ERROR"
}

export type InterestComputationActionDispatch = {
    type: | InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT
    payload: any;
}

export type InterestComputationReducerDispatch = {
    type: | InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT
    | InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_SUCCESS
    | InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<InterestComputation>
        error?: string
    }
}

export type InterestComputation = {
    id: string,
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}

export type CreateInterestComputation = {
    startDate: string,
    endDate: string,
    programId: string,
    counterPartyId: string,
    invoiceNumber: string
}

export type CreateInterestComputationRequest = {
    payload: CreateInterestComputation,
    type: string
}

export type CreateInterestComputationReduxState = {
    loading?: boolean,
    data?: CreateApiResponse<InterestComputation>
    error?: string | null
}