import { FilterMetadata } from "redux/types";
import { LimitConfigActionDispatch, LimitConfigReduxTypes, updateLimitConfigVersion } from "./types";

export const fetchLimitConfig = (payload: FilterMetadata): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG,
  payload: payload
});

export const fetchLimitConfigByCustomerExternalID = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID,
  payload: payload
});

export const createLimitConfig = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.CREATE_LIMIT_CONFIG,
  payload: payload
});

export const resetLimitConfig = (): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.RESET,
  payload: {}
});

export const fetchSingleLimitConfig = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG,
  payload: payload
});

export const updateLimitConfig = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG,
  payload: payload
});

export const deleteLimitConfig = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.DELETE_LIMIT_CONFIG,
  payload: payload
});

export const fetcLimitConfigVersions = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION,
  payload: payload
});

export const fetcLimitConfigLatestVersions = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION,
  payload: payload
});

export const fetcLimitConfigVersionsDetails = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS,
  payload: payload
});

export const fetcLimitConfigHistroy = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY,
  payload: payload
});

export const fetchAggregateLimitConfigHistroy = (payload: any): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY,
  payload: payload
});

export const approveLimitConfig = (payload: updateLimitConfigVersion): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG,
  payload: payload
});
export const rejectLimitConfig = (payload: updateLimitConfigVersion): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.REJECT_LIMIT_CONFIG,
  payload: payload
});

export const blockLimitConfig = (payload: updateLimitConfigVersion): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG,
  payload: payload
});
export const freezeLimitConfig = (payload: updateLimitConfigVersion): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG,
  payload: payload
});

export const unFreezeLimitConfig = (payload: updateLimitConfigVersion): LimitConfigActionDispatch => ({
  type: LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG,
  payload: payload
});
