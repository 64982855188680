import { APICore } from "helpers/api/apiCore";
import config from '../../config';

const api = new APICore();

function fetchStateByCountryIdApi(countryID:any) {
    const baseUrl = `${config.BACKEND_BASE_URL}/states`;
    return api.get(`${baseUrl}`,{});
}

function fetchCityByStateIdApi(stateId:any) {

    const baseUrl = `${config.BACKEND_BASE_URL}/city`;
    return api.get(`${baseUrl}`,{"stateId":stateId});
}


export { fetchStateByCountryIdApi, fetchCityByStateIdApi};