import { SagaIterator } from "redux-saga";
import { CreateDisbursementAdviceRequest, DisbursementAdviceReduxTypes } from "./types";
import { fork, call, put, takeEvery } from "redux-saga/effects";
import { createDisbursementAdviceApi } from "../disbursementAdvice/api";


function* createDisbursementAdvice({ payload }: CreateDisbursementAdviceRequest): SagaIterator {
    try {
        const response = yield call(createDisbursementAdviceApi, payload);
        yield put({
            type: DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_ERROR,
            payload: { error: error }
        })
    }
}
export function* watchCreateDisbursementAdvice() {
    yield takeEvery(DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT, createDisbursementAdvice)
}
function* disbursementAdviceSaga() {
    yield fork(watchCreateDisbursementAdvice)
}

export default disbursementAdviceSaga;