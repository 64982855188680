import { BaseRateReducerDispatch, BaseRateReduxState, BaseRateReduxTypes, CreateBaseRateReduxState } from "./types";

const BASE_RATE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const BaseRateListReducer = (state: BaseRateReduxState = BASE_RATE_LIST_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FETCH_BASE_RATES: {
      return {
        ...state,
        loading: true
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BASE_RATE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const BaseRateReducer = (state: CreateBaseRateReduxState = BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FETCH_BASE_RATE: {
      return {
        ...state,
        // data: action.payload.data,
        loading: true
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_BASE_RATE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.CREATE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.CREATE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.UPDATE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.UPDATE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case BaseRateReduxTypes.RESET_UPDATE_BASE_RATE:
      return {
        ...state,
        error: null,
        loading: false
      };

    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};



const DeleteBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.DELETE_BASE_RATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.DELETE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.DELETE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }


    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchBaseRateVesrions = (state: CreateBaseRateReduxState = BASE_RATE_LIST_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchBaseRateVesrionsDetailsReducer = (state: CreateBaseRateReduxState = BASE_RATE_LIST_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const BaseRateReducerLatestVersionReducer = (state: CreateBaseRateReduxState = BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.APPROVE_BASE_RATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.APPROVE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case BaseRateReduxTypes.APPROVE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.REJECT_BASE_RATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case BaseRateReduxTypes.REJECT_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.REJECT_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.BLOCK_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.BLOCK_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.FREEZE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.FREEZE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeBaseRateReducer = (state: CreateBaseRateReduxState = CREATE_BASE_RATE_INIT_STATE, action: BaseRateReducerDispatch) => {
  switch (action.type) {
    case BaseRateReduxTypes.UNFREEZE_BASE_RATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BaseRateReduxTypes.UNFREEZE_BASE_RATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BaseRateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  BaseRateListReducer,
  CreateBaseRateReducer,
  BaseRateReducer,
  UpdateBaseRateReducer,
  FetchBaseRateVesrions,
  ApproveBaseRateReducer,
  RejectBaseRateReducer,
  BlockBaseRateReducer,
  FreezeBaseRateReducer,
  UnFreezeBaseRateReducer,
  FetchBaseRateVesrionsDetailsReducer,
  BaseRateReducerLatestVersionReducer,
  DeleteBaseRateReducer
};
