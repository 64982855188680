import { CreateProductLimitConfigReduxState, ProductLimitConfigReducerDispatch, ProductLimitConfigReduxState, ProductLimitConfigReduxTypes } from "./types";

const PRODUCT_LIMIT_CONFIG_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const ProductLimitConfigListReducer = (state: ProductLimitConfigReduxState = PRODUCT_LIMIT_CONFIG_INIT_STATE, action: ProductLimitConfigReducerDispatch) => {
  switch (action.type) {
    case ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_PRODUCT_LIMIT_CONFIG_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateProductLimitConfigReducer = (state: CreateProductLimitConfigReduxState = CREATE_PRODUCT_LIMIT_CONFIG_INIT_STATE, action: ProductLimitConfigReducerDispatch) => {
  switch (action.type) {
    case ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.RESET_CREATE_PRODUCT_LIMIT_CONFIG: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const FetchProductLimitConfigReducer = (state: CreateProductLimitConfigReduxState = CREATE_PRODUCT_LIMIT_CONFIG_INIT_STATE, action: ProductLimitConfigReducerDispatch) => {
  switch (action.type) {
    case ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.RESET_SINGLE_LIMIT_CONFIG: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    case ProductLimitConfigReduxTypes.RESET: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const UpdateProductLimitConfigReducer = (state: CreateProductLimitConfigReduxState = CREATE_PRODUCT_LIMIT_CONFIG_INIT_STATE, action: ProductLimitConfigReducerDispatch) => {
  switch (action.type) {
    case ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductLimitConfigReduxTypes.RESET_CREATE_PRODUCT_LIMIT_CONFIG: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

export { ProductLimitConfigListReducer, CreateProductLimitConfigReducer, UpdateProductLimitConfigReducer, FetchProductLimitConfigReducer };
