import { FilterMetadata } from "redux/types";
import { CreateFinancierUser, FinancierUserActionDispatch, FinancierUserReduxTypes, UpdateFinancierUser, updateFinancierUserVersion } from "./types";

export const fetchFinancierUsers = (payload: FilterMetadata): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FETCH_FINANCIER_USERS,
  payload: payload
});

export const createFinancierUsers = (payload: CreateFinancierUser): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.CREATE_FINANCIER_USER,
  payload: payload
});

export const createPasswordFinancierUsers = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.PASSWORD_FINANCIER_USER,
  payload: payload
});

export const updatePasswordFinancierUsers = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER,
  payload: payload
});


export const deleteFinancierUsers = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.DELETE_FINANCIER_USER,
  payload: payload
});

export const fetchFinancierUserByExternalId = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FETCH_FINANCIER_USER,
  payload: payload
});

export const updateFinancierUsers = (payload: UpdateFinancierUser): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.UPDATE_FINANCIER_USER,
  payload: payload
});

export const resetFinancierUsers = (): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.RESET,
  payload: {}
});

export const fetcFinancierUserVersions = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION,
  payload: payload
});

export const fetcFinancierUserVersionsDetails = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS,
  payload: payload
});

export const fetcFinancierUserLatestVersion = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION,
  payload: payload
});

export const approveFinancierUser = (payload: updateFinancierUserVersion): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.APPROVE_FINANCIER_USER,
  payload: payload
});
export const rejectFinancierUser = (payload: updateFinancierUserVersion): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.REJECT_FINANCIER_USER,
  payload: payload
});

export const blockFinancierUser = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.BLOCK_FINANCIER_USER,
  payload: payload
});

export const freezeFinancierUser = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.FREEZE_FINANCIER_USER,
  payload: payload
});

export const unFreezeFinancierUser = (payload: any): FinancierUserActionDispatch => ({
  type: FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER,
  payload: payload
});
