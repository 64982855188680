import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CustomerContactsReduxTypes {
  FETCH_CUSTOMER_CONTACTS = "@@auth/FETCH_CUSTOMER_CONTACTS",
  FETCH_CUSTOMER_CONTACTS_SUCCESS = "@@auth/FETCH_CUSTOMER_CONTACTS_SUCCESS",
  FETCH_CUSTOMER_CONTACTS_ERROR = "@@auth/FETCH_CUSTOMER_CONTACTS_ERROR",

  CREATE_CUSTOEMR_CONTACT = "@@auth/CREATE_CUSTOEMR_CONTACT",
  CREATE_CUSTOEMR_CONTACT_SUCCESS = "@@auth/CREATE_CUSTOEMR_CONTACT_SUCCESS",
  CREATE_CUSTOEMR_CONTACT_ERROR = "@@auth/CREATE_CUSTOEMR_CONTACT_ERROR",

  DELETE_CUSTOEMR_CONTACT = "@@auth/DELETE_CUSTOEMR_CONTACT",
  DELETE_CUSTOEMR_CONTACT_SUCCESS = "@@auth/DELETE_CUSTOEMR_CONTACT_SUCCESS",
  DELETE_CUSTOEMR_CONTACT_ERROR = "@@auth/DELETE_CUSTOEMR_CONTACT_ERROR",


  FETCH_CUSTOMER_CONTACT = "@@auth/FETCH_CUSTOMER_CONTACT",
  FETCH_CUSTOMER_CONTACT_SUCCESS = "@@auth/FETCH_CUSTOMER_CONTACT_SUCCESS",
  FETCH_CUSTOMER_CONTACT_ERROR = "@@auth/FETCH_CUSTOMER_CONTACT_ERROR",

  UPDATE_CUSTOMER_CONTACTS = "@@auth/UPDATE_CUSTOMER_CONTACTS",
  UPDATE_CUSTOMER_CONTACTS_SUCCESS = "@@auth/UPDATE_CUSTOMER_CONTACTS_SUCCESS",
  UPDATE_CUSTOMER_CONTACTS_ERROR = "@@auth/UPDATE_CUSTOMER_CONTACTS_ERROR",

  FETCH_CUSTOMER_CONTACTS_VERSION = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION",
  FETCH_CUSTOMER_CONTACTS_VERSION_SUCCESS = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION_SUCCESS",
  FETCH_CUSTOMER_CONTACTS_VERSION_ERROR = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION_ERROR",

  FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS",
  FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_SUCCESS = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_SUCCESS",
  FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_ERROR = "@@auth/FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_ERROR",

  ARPOVE_CUSTOEMR_CONTACT = "@@auth/ARPOVE_CUSTOEMR_CONTACT",
  ARPOVE_CUSTOEMR_CONTACT_SUCCESS = "@@auth/ARPOVE_CUSTOEMR_CONTACT_SUCCESS",
  ARPOVE_CUSTOEMR_CONTACT_ERROR = "@@auth/ARPOVE_CUSTOEMR_CONTACT_ERROR",

  REJECT_CUSTOEMR_CONTACT = "@@auth/REJECT_CUSTOEMR_CONTACT",
  REJECT_CUSTOEMR_CONTACT_SUCCESS = "@@auth/REJECT_CUSTOEMR_CONTACT_SUCCESS",
  REJECT_CUSTOEMR_CONTACT_ERROR = "@@auth/REJECT_CUSTOEMR_CONTACT_ERROR",

  BLOCK_CUSTOEMR_CONTACT = "@@auth/BLOCK_CUSTOEMR_CONTACT",
  BLOCK_CUSTOEMR_CONTACT_SUCCESS = "@@auth/BLOCK_CUSTOEMR_CONTACT_SUCCESS",
  BLOCK_CUSTOEMR_CONTACT_ERROR = "@@auth/BLOCK_CUSTOEMR_CONTACT_ERROR",

  FREEZE_CUSTOEMR_CONTACT = "@@auth/FREEZE_CUSTOEMR_CONTACT",
  FREEZE_CUSTOEMR_CONTACT_SUCCESS = "@@auth/FREEZE_CUSTOEMR_CONTACT_SUCCESS",
  FREEZE_CUSTOEMR_CONTACT_ERROR = "@@auth/FREEZE_CUSTOEMR_CONTACT_ERROR",

  UNFREEZE_CUSTOEMR_CONTACT = "@@auth/UNFREEZE_CUSTOEMR_CONTACT",
  UNFREEZE_CUSTOEMR_CONTACT_SUCCESS = "@@auth/UNFREEZE_CUSTOEMR_CONTACT_SUCCESS",
  UNFREEZE_CUSTOEMR_CONTACT_ERROR = "@@auth/UNFREEZE_CUSTOEMR_CONTACT_ERROR",

  //

  CREATE_KEY_MANAGAMENT_CONTACT = "@@auth/CREATE_KEY_MANAGAMENT_CONTACT",
  CREATE_KEY_MANAGAMENT_CONTACT_SUCCESS = "@@auth/CREATE_KEY_MANAGAMENT_CONTACT_SUCCESS",
  CREATE_KEY_MANAGAMENT_CONTACT_ERROR = "@@auth/CREATE_KEY_MANAGAMENT_CONTACT_ERROR",

  FETCH_KEY_MANAGEMENT_CONTACTS = "@@auth/FETCH_KEY_MANAGEMENT_CONTACTS",
  FETCH_KEY_MANAGEMENT_CONTACTS_SUCCESS = "@@auth/FETCH_KEY_MANAGEMENT_CONTACTS_SUCCESS",
  FETCH_KEY_MANAGEMENT_CONTACTS_ERROR = "@@auth/FETCH_KEY_MANAGEMENT_CONTACTS_ERROR",

  FETCH_KEY_MANAGEMENT_CONTACT = "@@auth/FETCH_KEY_MANAGEMENT_CONTACT",
  FETCH_KEY_MANAGEMENT_CONTACT_SUCCESS = "@@auth/FETCH_KEY_MANAGEMENT_CONTACT_SUCCESS",
  FETCH_KEY_MANAGEMENT_CONTACT_ERROR = "@@auth/FETCH_KEY_MANAGEMENT_CONTACT_ERROR",

  UPDATE_KEY_MANAGEMENT_CONTACTS = "@@auth/UPDATE_KEY_MANAGEMENT_CONTACTS",
  UPDATE_KEY_MANAGEMENT_CONTACTS_SUCCESS = "@@auth/UPDATE_KEY_MANAGEMENT_CONTACTS_SUCCESS",
  UPDATE_KEY_MANAGEMENT_CONTACTS_ERROR = "@@auth/UPDATE_KEY_MANAGEMENT_CONTACTS_ERROR",

  FETCH_KEY_MANAGEMENT_VERSION = "@@auth/FETCH_KEY_MANAGEMENT_VERSION",
  FETCH_KEY_MANAGEMENT_VERSION_SUCCESS = "@@auth/FETCH_KEY_MANAGEMENT_VERSION_SUCCESS",
  FETCH_KEY_MANAGEMENT_VERSION_ERROR = "@@auth/FETCH_KEY_MANAGEMENT_VERSION_ERROR",

  FETCH_KEY_MANAGEMENTS_VERSION_DATAILS = "@@auth/FETCH_KEY_MANAGEMENTS_VERSION_DATAILS",
  FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_SUCCESS = "@@auth/FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_SUCCESS",
  FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_ERROR = "@@auth/FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_ERROR",


  ARPOVE_KEY_MANAGEMENT = "@@auth/ARPOVE_KEY_MANAGEMENT",
  ARPOVE_KEY_MANAGEMENT_SUCCESS = "@@auth/ARPOVE_KEY_MANAGEMENT_SUCCESS",
  ARPOVE_KEY_MANAGEMENT_ERROR = "@@auth/ARPOVE_KEY_MANAGEMENT_ERROR",

  REJECT_KEY_MANAGEMENT = "@@auth/REJECT_KEY_MANAGEMENT",
  REJECT_KEY_MANAGEMENT_SUCCESS = "@@auth/REJECT_KEY_MANAGEMENT_SUCCESS",
  REJECT_KEY_MANAGEMENT_ERROR = "@@auth/REJECT_KEY_MANAGEMENT_ERROR",

  BLOCK_KEY_MANAGEMENT = "@@auth/BLOCK_KEY_MANAGEMENT",
  BLOCK_KEY_MANAGEMENT_SUCCESS = "@@auth/BLOCK_KEY_MANAGEMENT_SUCCESS",
  BLOCK_KEY_MANAGEMENT_ERROR = "@@auth/BLOCK_KEY_MANAGEMENT_ERROR",

  FREEZE_KEY_MANAGEMENT = "@@auth/FREEZE_KEY_MANAGEMENT",
  FREEZE_KEY_MANAGEMENT_SUCCESS = "@@auth/FREEZE_KEY_MANAGEMENT_SUCCESS",
  FREEZE_KEY_MANAGEMENT_ERROR = "@@auth/FREEZE_KEY_MANAGEMENT_ERROR",

  UNFREEZE_KEY_MANAGEMENT = "@@auth/UNFREEZE_KEY_MANAGEMENT",
  UNFREEZE_KEY_MANAGEMENT_SUCCESS = "@@auth/UNFREEZE_KEY_MANAGEMENT_SUCCESS",
  UNFREEZE_KEY_MANAGEMENT_ERROR = "@@auth/UNFREEZE_KEY_MANAGEMENT_ERROR",
  ///

  CREATE_PARTNER_CONTACT = "@@auth/CREATE_PARTNER_CONTACT",
  CREATE_PARTNER_CONTACT_SUCCESS = "@@auth/CREATE_PARTNER_CONTACT_SUCCESS",
  CREATE_PARTNER_CONTACT_ERROR = "@@auth/CREATE_PARTNER_CONTACT_ERROR",

  FETCH_PARTNER_CONTACTS = "@@auth/FETCH_PARTNER_CONTACTS",
  FETCH_PARTNER_CONTACTS_SUCCESS = "@@auth/FETCH_PARTNER_CONTACTS_SUCCESS",
  FETCH_PARTNER_CONTACTS_ERROR = "@@auth/FETCH_PARTNER_CONTACTS_ERROR",

  FETCH_PARTNER_CONTACT = "@@auth/FETCH_PARTNER_CONTACT",
  FETCH_PARTNER_CONTACT_SUCCESS = "@@auth/FETCH_PARTNER_CONTACT_SUCCESS",
  FETCH_PARTNER_CONTACT_ERROR = "@@auth/FETCH_PARTNER_CONTACT_ERROR",

  UPDATE_PARTNER_CONTACTS = "@@auth/UPDATE_PARTNER_CONTACTS",
  UPDATE_PARTNER_CONTACTS_SUCCESS = "@@auth/UPDATE_PARTNER_CONTACTS_SUCCESS",
  UPDATE_PARTNER_CONTACTS_ERROR = "@@auth/UPDATE_PARTNER_CONTACTS_ERROR",

  FETCH_PARTNER_VERSION = "@@auth/FETCH_PARTNER_VERSION",
  FETCH_PARTNER_VERSION_SUCCESS = "@@auth/FETCH_PARTNER_VERSION_SUCCESS",
  FETCH_PARTNER_VERSION_ERROR = "@@auth/FETCH_PARTNER_VERSION_ERROR",

  FETCH_PARTNER_VERSION_DATAILS = "@@auth/FETCH_PARTNER_VERSION_DATAILS",
  FETCH_PARTNER_VERSION_DATAILS_SUCCESS = "@@auth/FETCH_PARTNER_VERSION_DATAILS_SUCCESS",
  FETCH_PARTNER_VERSION_DATAILS_ERROR = "@@auth/FETCH_PARTNER_VERSION_DATAILS_ERROR",


  ARPOVE_PARTNER = "@@auth/ARPOVE_PARTNER",
  ARPOVE_PARTNER_SUCCESS = "@@auth/ARPOVE_PARTNER_SUCCESS",
  ARPOVE_PARTNER_ERROR = "@@auth/ARPOVE_PARTNER_ERROR",

  REJECT_PARTNER = "@@auth/REJECT_PARTNER",
  REJECT_PARTNER_SUCCESS = "@@auth/REJECT_PARTNER_SUCCESS",
  REJECT_PARTNER_ERROR = "@@auth/REJECT_PARTNER_ERROR",

  BLOCK_PARTNER = "@@auth/BLOCK_PARTNER",
  BLOCK_PARTNER_SUCCESS = "@@auth/BLOCK_PARTNER_SUCCESS",
  BLOCK_PARTNER_ERROR = "@@auth/BLOCK_PARTNER_ERROR",

  FREEZE_PARTNER = "@@auth/FREEZE_PARTNER",
  FREEZE_PARTNER_SUCCESS = "@@auth/FREEZE_PARTNER_SUCCESS",
  FREEZE_PARTNER_ERROR = "@@auth/FREEZE_PARTNER_ERROR",

  UNFREEZE_PARTNER = "@@auth/UNFREEZE_PARTNER",
  UNFREEZE_PARTNER_SUCCESS = "@@auth/UNFREEZE_PARTNER_SUCCESS",
  UNFREEZE_PARTNER_ERROR = "@@auth/UNFREEZE_PARTNER_ERROR",

  RESET = "@@auth/RESET"
}
export type CustomerContactActionDispatch = {
  type:
    | CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS
    | CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT
    | CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS
    | CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT
    | CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT

    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT
    | CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENTS_VERSION_DATAILS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_DATAILS

    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION
    | CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT
    | CustomerContactsReduxTypes.ARPOVE_PARTNER
    | CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT
    | CustomerContactsReduxTypes.REJECT_PARTNER
    | CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT
    | CustomerContactsReduxTypes.BLOCK_PARTNER
    | CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT
    | CustomerContactsReduxTypes.FREEZE_PARTNER
    | CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT
    | CustomerContactsReduxTypes.UNFREEZE_PARTNER
    | CustomerContactsReduxTypes.RESET;
  payload: any;
};
export type CustomerContactReducerDispatch = {
  type:
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_ERROR
    | CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_ERROR
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_ERROR
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_ERROR
    | CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_ERROR
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS_ERROR
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT_ERROR
    | CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_ERROR

    //
    | CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_ERROR
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS
    | CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT
    | CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_ERROR


    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_ERROR
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_ERROR
    | CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_SUCCESS
    | CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_ERROR
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENTS_VERSION_DATAILS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENTS_VERSION_DATAILS_ERROR

   

    //
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_SUCCESS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_ERROR
    | CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_SUCCESS
    | CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_ERROR
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_ERROR

    //
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_SUCCESS
    | CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_ERROR
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_ERROR
    | CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_SUCCESS
    | CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_ERROR
    | CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_SUCCESS
    | CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_ERROR
    | CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_SUCCESS
    | CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_ERROR
    | CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_SUCCESS
    | CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_ERROR
    | CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_SUCCESS
    | CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_ERROR


    //
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_SUCCESS
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_ERROR
    | CustomerContactsReduxTypes.ARPOVE_PARTNER_SUCCESS
    | CustomerContactsReduxTypes.ARPOVE_PARTNER_ERROR
    | CustomerContactsReduxTypes.REJECT_PARTNER_SUCCESS
    | CustomerContactsReduxTypes.REJECT_PARTNER_ERROR
    | CustomerContactsReduxTypes.BLOCK_PARTNER_SUCCESS
    | CustomerContactsReduxTypes.BLOCK_PARTNER_ERROR
    | CustomerContactsReduxTypes.FREEZE_PARTNER_SUCCESS
    | CustomerContactsReduxTypes.FREEZE_PARTNER_ERROR
    | CustomerContactsReduxTypes.UNFREEZE_PARTNER_SUCCESS
    | CustomerContactsReduxTypes.UNFREEZE_PARTNER_ERROR
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_DATAILS
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_DATAILS_SUCCESS
    | CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_DATAILS_ERROR

    | CustomerContactsReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<CustomerContact>;
    error?: string;
  };
};

export type contomerContactsFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateCustomerContactRequest = {
  payload: CreateCustomerContactType;
  type: string;
};

export type CustomerContact = {
  customerId: string;
  id: string;
  name: string;
  designation: string;
  pan: string;
  aadhar: string;
  dateOfBirth: number;
  age: number;
  din: number;
  createdOn: string;
  updatedOn: string;
  active: boolean;
};

export type CreateCustomerContactType = {
  customerId: string;
  name: string;
  designation: string;
  pan: string;
  aadhar: string;
  dateOfBirth: number;
  age: number;
  din: string;
  contactType: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreateCustomerContactReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<CreateCustomerContactType>;
  error?: string | null;
};

export type CustomerContactFetchRequest = {
  payload: FilterMetadata;
  type: string;
  customerId: string;
};

export type FetchCustomerContactByIdRequest = {
  payload: string;
  type: string;
};

export type UpdateCustomerContact = {
  externalUserId: string;
  name: string;
  designation: string;
  pan: string;
  aadhar: string;
  dateOfBirth: string;
  age: string;
  din: string;
};

export type UpdateCustomerContactrsequest = {
  payload: UpdateCustomerContact;
  type: string;
};
