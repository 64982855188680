import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateHolidayRequest, FetchHolidayByExternalIdRequest, HolidayFetchRequest, HolidayReduxTypes, holidayVersionChangeRequest, UpdateHolidayRequest } from "./types";
import {
  approveHolidayApi,
  blockHolidayApi,
  createHolidayApi,
  deleteHolidayApi,
  fetchHolidayApi,
  fetchHolidayByExternalIdApi,
  fetchHolidayLatestVersionApi,
  fetchHolidayVersionApi,
  fetchHolidayVersionDetailsApi,
  freezeHolidayApi,
  rejectHolidayApi,
  unFreezeHolidayApi,
  updateHolidayApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchHoliday({ payload, type }: HolidayFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayApi, payload);
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchHolidayByExternalId({ payload, type }: FetchHolidayByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayByExternalIdApi, payload);

    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createHoliday({ payload, type }: CreateHolidayRequest): SagaIterator {
  try {
    const response = yield call(createHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.CREATE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.CREATE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* updateHoliday({ payload, type }: UpdateHolidayRequest): SagaIterator {
  try {
    const response = yield call(updateHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.UPDATE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.UPDATE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchHolidayVersion({ payload, type }: UpdateHolidayRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayVersionApi, payload);

    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchHolidayVersionDetails({ payload, type }: UpdateHolidayRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayVersionDetailsApi, payload);

    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchHolidayLatestVersion({ payload, type }: UpdateHolidayRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayLatestVersionApi, payload);

    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteHoliday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(deleteHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.DELETE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.APPROVE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* approveHoliday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.APPROVE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.APPROVE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectHolioday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.REJECT_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.REJECT_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* blockHolioday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.BLOCK_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.BLOCK_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeHolioday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.FREEZE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.FREEZE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeHolioday({ payload, type }: holidayVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeHolidayApi, payload);

    yield put({
      type: HolidayReduxTypes.UNFREEZE_HOLIDAY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayReduxTypes.UNFREEZE_HOLIDAY_ERROR,
      payload: { error: error }
    });
  }
}
export function* watchFetchHoliday() {
  yield takeEvery(HolidayReduxTypes.FETCH_HOLIDAY, fetchHoliday);
}

export function* watchFetchHolidayByExternalId() {
  yield takeEvery(HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID, FetchHolidayByExternalId);
}

export function* watchCreateHoliday() {
  yield takeEvery(HolidayReduxTypes.CREATE_HOLIDAY, createHoliday);
}

export function* watchUpdateHoliday() {
  yield takeEvery(HolidayReduxTypes.UPDATE_HOLIDAY, updateHoliday);
}

export function* watchBlockHoliday() {
  yield takeEvery(HolidayReduxTypes.BLOCK_HOLIDAY, blockHolioday);
}

export function* watchFetchHolidayVersion() {
  yield takeEvery(HolidayReduxTypes.FETCH_HOLIDAY_VERSION, fetchHolidayVersion);
}

export function* watchApproveHoliday() {
  yield takeEvery(HolidayReduxTypes.APPROVE_HOLIDAY, approveHoliday);
}

export function* watchRejectHoliday() {
  yield takeEvery(HolidayReduxTypes.REJECT_HOLIDAY, rejectHolioday);
}

export function* watchFreezeHoliday() {
  yield takeEvery(HolidayReduxTypes.FREEZE_HOLIDAY, freezeHolioday);
}

export function* watchUnFreezeHoliday() {
  yield takeEvery(HolidayReduxTypes.UNFREEZE_HOLIDAY, unFreezeHolioday);
}

export function* watchFetchHolidayVersionDetails() {
  yield takeEvery(HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS, fetchHolidayVersionDetails);
}

export function* watchFetchHolidayLatestVersion() {
  yield takeEvery(HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION, fetchHolidayLatestVersion);
}

export function* watchDeleteHoliday() {
  yield takeEvery(HolidayReduxTypes.DELETE_HOLIDAY, deleteHoliday);
}

function* holidaySaga() {
  yield all([
    fork(watchFetchHoliday),
    fork(watchCreateHoliday),
    fork(watchFetchHolidayByExternalId),
    fork(watchUpdateHoliday),
    fork(watchFetchHolidayVersion),
    fork(watchApproveHoliday),
    fork(watchRejectHoliday),
    fork(watchBlockHoliday),
    fork(watchFreezeHoliday),
    fork(watchUnFreezeHoliday),
    fork(watchFetchHolidayVersionDetails),
    fork(watchFetchHolidayLatestVersion),
    fork(watchDeleteHoliday)
  ]);
}

export default holidaySaga;
