import { CityReducerDispatch, CityReduxState, CityReduxTypes, CreateCityReduxState } from "./types";

const CITY_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CityListReducer = (state: CityReduxState = CITY_LIST_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FETCH_CITIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CityReduxTypes.FETCH_CITIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FETCH_CITIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};




const CREATE_CITY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.CREATE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.CREATE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.UPDATE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.UPDATE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET_UPDATE_CITY:
      return {
        ...state,
        error: null,
        loading: false
      };
    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FETCH_CITY_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case CityReduxTypes.FETCH_CITY_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FETCH_CITY_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CityVersionDetailsReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FETCH_CITY_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case CityReduxTypes.FETCH_CITY_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FETCH_CITY_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CityListVersionReducer = (state: CityReduxState = CITY_LIST_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FETCH_CITY_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FETCH_CITIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CityReducerLatestVersionReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FETCH_CITY_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case CityReduxTypes.FETCH_CITY_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FETCH_CITY_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.APPROVE_CITY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CityReduxTypes.APPROVE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.APPROVE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.REJECT_CITY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CityReduxTypes.REJECT_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.REJECT_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.BLOCK_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.BLOCK_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.FREEZE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.FREEZE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.UNFREEZE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.UNFREEZE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteCityReducer = (state: CreateCityReduxState = CREATE_CITY_INIT_STATE, action: CityReducerDispatch) => {
  switch (action.type) {
    case CityReduxTypes.DELETE_CITY: {
      return {
        ...state,
        loading: true
      };
    }

    case CityReduxTypes.DELETE_CITY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CityReduxTypes.DELETE_CITY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CityReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CityListReducer,
  CityReducer,
  CreateCityReducer,
  UpdateCityReducer,
  CityListVersionReducer,
  ApproveCityReducer,
  RejectCityReducer,
  BlockCityReducer,
  FreezeCityReducer,
  UnFreezeCityReducer,
  CityVersionDetailsReducer,
  CityReducerLatestVersionReducer,
  DeleteCityReducer
};
