import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { APICore, setAuthorization } from "helpers/api/apiCore";
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  refreshTokenApi,
  loginHistoryApi,
  changePasswordApi,
} from "./api";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

type UserData = {
  payload: {
    username: string;
    password: string;
    fullname: string;
    email: string;
  };
  type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
export function* login({ payload: { username, password }, type }: UserData): SagaIterator {
  try {
    // console.log("jest api call");

    const response = yield call(loginApi, { username, password });

    // NOTE - You can change this according to response format from your api

    const user = response.data.data;
    // const user = response.data;
    api.setLoggedInUser(user);
    setAuthorization(user);

    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
  sessionStorage.removeItem("vayana_lms_user");
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD_SUCCESS, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD_ERROR, error));
  }
}

function* refreshToken(): SagaIterator {
  try {
    const response = yield call(refreshTokenApi);
    api.setLoggedInUser(response.data.data);
    setAuthorization(response.data.data);
    yield put(authApiResponseSuccess(AuthActionTypes.REFRESH_TOKEN_SUCCESS, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.REFRESH_TOKEN_ERROR, error));
  }
}

function* LoginHistory({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(loginHistoryApi, payload);
    yield put({
      type: AuthActionTypes.LOGIN_HISTORY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AuthActionTypes.LOGIN_HISTORY_ERROR,
      payload: { error: error }
    });
  }
}

function* ChangePassword({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(changePasswordApi, payload);
    yield put({
      type: AuthActionTypes.CHANGE_PASSWORD_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AuthActionTypes.CHANGE_PASSWORD_ERROR,
      payload: { error: error }
    });
  }
}


export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchRefreshToke() {
  yield takeEvery(AuthActionTypes.REFRESH_TOKEN, refreshToken);
}
export function* watchLoginHistory() {
  yield takeEvery(AuthActionTypes.LOGIN_HISTORY, LoginHistory);
}

export function* watchChangePassword() {
  yield takeEvery(AuthActionTypes.CHANGE_PASSWORD, ChangePassword);
}


function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchRefreshToke),
    fork(watchLoginHistory),
    fork(watchChangePassword),

  ]);
}

export default authSaga;
