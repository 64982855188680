import {
  SkipableValidationReduxState,
  StagingInstrumentsReducerDispatch,
  StagingInstrumentsReduxState,
  StagingInstrumentsReduxTypes,
  stagingInstrumnetValidationErrorReduxState
} from "./types";

const STAGING_INSTRUMENT_LIST_INIT_STATE = {
  loading: false,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const StagingInstrumentsListReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {

  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RemoveStagingInstrumentsListReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET_REMOVE_STAGING_INSTRUMENTS:
      return {
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const RemoveStagingInstrumentsReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingInstrumentsRemoveVersionListReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET_STAGING_INSTRUMENTS_REMOVE_VERSION:
      return {
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingInstrumentReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_ERROR: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingInstrumentsReprocessingReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StagingInstrumentsSkipValidationReducer = (state: StagingInstrumentsReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE, action: StagingInstrumentsReducerDispatch) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const SkipableValidationsStagingInstrumentsListReducer = (
  state: SkipableValidationReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE,
  action: StagingInstrumentsReducerDispatch
) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ValidationErrorByStagingInstrumentIdReducer = (
  state: stagingInstrumnetValidationErrorReduxState = STAGING_INSTRUMENT_LIST_INIT_STATE,
  action: StagingInstrumentsReducerDispatch
) => {
  switch (action.type) {
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StagingInstrumentsReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  StagingInstrumentsListReducer,
  StagingInstrumentReducer,
  StagingInstrumentsReprocessingReducer,
  StagingInstrumentsSkipValidationReducer,
  SkipableValidationsStagingInstrumentsListReducer,
  ValidationErrorByStagingInstrumentIdReducer,
  RemoveStagingInstrumentsListReducer,
  StagingInstrumentsRemoveVersionListReducer,
  RemoveStagingInstrumentsReducer
};
