import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum panGstDetailreduxType {
  FETCH_TASK_ID_BY_PAN_NUMBER = "@@auth/FETCH_TASK_ID_BY_PAN_NUMBER",
  FETCH_TASK_ID_BY_PAN_NUMBER_SUCCESS = "@@auth/FETCH_TASK_ID_BY_PAN_NUMBER_SUCCESS",
  FETCH_TASK_ID_BY_PAN_NUMBER_ERROR = "@@auth/FETCH_TASK_ID_BY_PAN_NUMBER_ERROR",

  FETCH_TASK_STATUS_BY_TASK_ID = "@@auth/FETCH_TASK_STATUS_BY_TASK_ID",
  FETCH_TASK_STATUS_BY_TASK_ID_SUCCESS = "@@auth/FETCH_TASK_STATUS_BY_TASK_ID_SUCCESS",
  FETCH_TASK_STATUS_BY_TASK_ID_ERROR = "@@auth/FETCH_TASK_STATUS_BY_TASK_ID_ERROR",

  FETCH_GSP_TASK_RESULT_BY_TASK_ID = "@@auth/FETCH_GSP_TASK_RESULT_BY_TASK_ID",
  FETCH_GSP_TASK_RESULT_BY_TASK_ID_SUCCESS = "@@auth/FETCH_GSP_TASK_RESULT_BY_TASK_ID_SUCCESS",
  FETCH_GSP_TASK_RESULT_BY_TASK_ID_ERROR = "@@auth/FETCH_GSP_TASK_RESULT_BY_TASK_ID_ERROR",

  FETCH_GST_DETAIL_BY_GST_NUMBER = "@@auth/FETCH_GST_DETAIL_BY_GST_NUMBER",
  FETCH_GST_DETAIL_BY_GST_NUMBER_SUCCESS = "@@auth/FETCH_GST_DETAIL_BY_GST_NUMBER_SUCCESS",
  FETCH_GST_DETAIL_BY_GST_NUMBER_ERROR = "@@auth/FETCH_GST_DETAIL_BY_GST_NUMBER_ERROR",

  RESET = "@@auth/RESET"
}

export type PanGstDetailActionDispatch = {
  type:
  | panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER
  | panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID
  | panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID
  | panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER
  | panGstDetailreduxType.RESET;
  payload: any;
};

export type PanGstDetailReducerDispatch = {
  type:
  | panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_SUCCESS
  | panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER
  | panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_ERROR
  | panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_SUCCESS
  | panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_ERROR
  | panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_SUCCESS
  | panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_ERROR
  | panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_SUCCESS
  | panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_ERROR
  | panGstDetailreduxType.RESET;
  payload: {
    data?: FetchApiResponse<PanTaskID>;
    error?: string;
  };
};

export enum CustomerReduxTypes {
  FETCH_CUSTOMERS = "@@auth/FETCH_CUSTOMERS",
  FETCH_CUSTOMERS_SUCCESS = "@@auth/FETCH_CUSTOMERS_SUCCESS",
  FETCH_CUSTOMERS_ERROR = "@@auth/FETCH_CUSTOMERS_ERROR",

  FETCH_CUSTOMER = "@@auth/FETCH_CUSTOMER",
  FETCH_CUSTOMER_SUCCESS = "@@auth/FETCH_CUSTOMER_SUCCESS",
  FETCH_CUSTOMER_ERROR = "@@auth/FETCH_CUSTOMER_ERROR",

  FETCH_CUSTOMER_VERSION = "@@auth/FETCH_CUSTOMER_VERSION",
  FETCH_CUSTOMER_VERSION_SUCCESS = "@@auth/FETCH_CUSTOMER_VERSION_SUCCESS",
  FETCH_CUSTOMER_VERSION_ERROR = "@@auth/FETCH_CUSTOMER_VERSION_ERROR",

  FETCH_CUSTOMER_LATEST_VERSION = "@@auth/FETCH_CUSTOMER_LATEST_VERSION",
  FETCH_CUSTOMER_LATEST_VERSION_SUCCESS = "@@auth/FETCH_CUSTOMER_LATEST_VERSION_SUCCESS",
  FETCH_CUSTOMER_LATEST_VERSION_ERROR = "@@auth/FETCH_CUSTOMER_LATEST_VERSION_ERROR",

  FETCH_CUSTOMER_VERSION_DETAILS = "@@auth/FETCH_CUSTOMER_VERSION_DETAILS",
  FETCH_CUSTOMER_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_CUSTOMER_VERSION_DETAILS_SUCCESS",
  FETCH_CUSTOMER_VERSION_DETAILS_ERROR = "@@auth/FETCH_CUSTOMER_VERSION_DETAILS_ERROR",

  APPROVE_CUSTOMER = "@@auth/APPROVE_CUSTOMER",
  APPROVE_CUSTOMER_SUCCESS = "@@auth/APPROVE_CUSTOMER_SUCCESS",
  APPROVE_CUSTOMER_ERROR = "@@auth/APPROVE_CUSTOMER_ERROR",

  REJECT_CUSTOMER = "@@authREJECT_CUSTOMER",
  REJECT_CUSTOMER_SUCCESS = "@@authREJECT_CUSTOMER_SUCCESS",
  REJECT_CUSTOMER_ERROR = "@@authREJECT_CUSTOMER_ERROR",

  VALIDATE_CUSTOMER = "@@authVALIDATE_CUSTOMER",
  VALIDATE_CUSTOMER_SUCCESS = "@@authVALIDATE_CUSTOMER_SUCCESS",
  VALIDATE_CUSTOMER_ERROR = "@@authVALIDATE_CUSTOMER_ERROR",

  CREATE_CUSTOMER = "@@auth/CREATE_CUSTOMER",
  CREATE_CUSTOMER_SUCCESS = "@@auth/CREATE_CUSTOMER_SUCCESS",
  CREATE_CUSTOMER_ERROR = "@@auth/CREATE_CUSTOMER_ERROR",


  DELETE_CUSTOMER = "@@auth/DELETE_CUSTOMER",
  DELETE_CUSTOMER_SUCCESS = "@@auth/DELETE_CUSTOMER_SUCCESS",
  DELETE_CUSTOMER_ERROR = "@@auth/DELETE_CUSTOMER_ERROR",

  UPDATE_CUSTOMER = "@@auth/UPDATE_CUSTOMER",
  UPDATE_CUSTOMER_SUCCESS = "@@auth/UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_ERROR = "@@auth/UPDATE_CUSTOMER_ERROR",

  DEDUPLICATE_CUSTOMER = "@@auth/DEDUPLICATE_CUSTOMER",
  DEDUPLICATE_CUSTOMER_SUCCESS = "@@auth/DEDUPLICATE_CUSTOMER_SUCCESS",
  DEDUPLICATE_CUSTOMER_ERROR = "@@auth/DEDUPLICATE_CUSTOMER_ERROR",

  BLOCK_CUSTOMER = "@@auth/BLOCK_CUSTOMER",
  BLOCK_CUSTOMER_SUCCESS = "@@auth/BLOCK_CUSTOMER_SUCCESS",
  BLOCK_CUSTOMER_ERROR = "@@auth/BLOCK_CUSTOMER_ERROR",

  FREEZE_CUSTOMER = "@@auth/FREEZE_CUSTOMER",
  FREEZE_CUSTOMER_SUCCESS = "@@auth/FREEZE_CUSTOMER_SUCCESS",
  FREEZE_CUSTOMER_ERROR = "@@auth/FREEZE_CUSTOMER_ERROR",

  UNFREEZE_CUSTOMER = "@@auth/UNFREEZE_CUSTOMER",
  UNFREEZE_CUSTOMER_SUCCESS = "@@auth/UNFREEZE_CUSTOMER_SUCCESS",
  UNFREEZE_CUSTOMER_ERROR = "@@auth/UNFREEZE_CUSTOMER_ERROR",

  RESET = "@@auth/RESET",
  RESET_CREATED_REDUCER = "@@auth/RESET_CREATED_REDUCER",
  RESET_UPDATED_REDUCER = "@@auth/RESET_UPDATED_REDUCER"
}

export type CustomerActionDispatch = {
  type:
  | CustomerReduxTypes.FETCH_CUSTOMERS
  | CustomerReduxTypes.FETCH_CUSTOMER
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION
  | CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS
  | CustomerReduxTypes.APPROVE_CUSTOMER
  | CustomerReduxTypes.REJECT_CUSTOMER
  | CustomerReduxTypes.VALIDATE_CUSTOMER
  | CustomerReduxTypes.CREATE_CUSTOMER
  | CustomerReduxTypes.UPDATE_CUSTOMER
  | CustomerReduxTypes.DEDUPLICATE_CUSTOMER
  | CustomerReduxTypes.RESET_CREATED_REDUCER
  | CustomerReduxTypes.RESET_UPDATED_REDUCER
  | CustomerReduxTypes.BLOCK_CUSTOMER
  | CustomerReduxTypes.FREEZE_CUSTOMER
  | CustomerReduxTypes.UNFREEZE_CUSTOMER
  | CustomerReduxTypes.DELETE_CUSTOMER

  | CustomerReduxTypes.RESET;
  payload: any;
};
export type CustomerReducerDispatch = {
  type:
  | CustomerReduxTypes.FETCH_CUSTOMERS
  | CustomerReduxTypes.FETCH_CUSTOMERS_SUCCESS
  | CustomerReduxTypes.FETCH_CUSTOMERS_ERROR
  | CustomerReduxTypes.FETCH_CUSTOMER
  | CustomerReduxTypes.FETCH_CUSTOMER_SUCCESS
  | CustomerReduxTypes.FETCH_CUSTOMER_ERROR
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_SUCCESS
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_ERROR
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_SUCCESS
  | CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_ERROR
  | CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION
  | CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_SUCCESS
  | CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_ERROR
  | CustomerReduxTypes.APPROVE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.APPROVE_CUSTOMER_ERROR
  | CustomerReduxTypes.REJECT_CUSTOMER_SUCCESS
  | CustomerReduxTypes.REJECT_CUSTOMER_ERROR
  | CustomerReduxTypes.VALIDATE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.VALIDATE_CUSTOMER_ERROR
  | CustomerReduxTypes.CREATE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.CREATE_CUSTOMER_ERROR
  | CustomerReduxTypes.UPDATE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.UPDATE_CUSTOMER_ERROR
  | CustomerReduxTypes.DEDUPLICATE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.DEDUPLICATE_CUSTOMER_ERROR
  | CustomerReduxTypes.BLOCK_CUSTOMER_SUCCESS
  | CustomerReduxTypes.BLOCK_CUSTOMER_ERROR
  | CustomerReduxTypes.FREEZE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.FREEZE_CUSTOMER_ERROR
  | CustomerReduxTypes.UNFREEZE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.UNFREEZE_CUSTOMER_ERROR
  | CustomerReduxTypes.RESET_CREATED_REDUCER
  | CustomerReduxTypes.RESET_UPDATED_REDUCER
  | CustomerReduxTypes.DELETE_CUSTOMER
  | CustomerReduxTypes.DELETE_CUSTOMER_SUCCESS
  | CustomerReduxTypes.DELETE_CUSTOMER_ERROR

  | CustomerReduxTypes.RESET;

  payload: {
    data?: FetchApiResponse<Customer>;
    error?: string;
  };
};

export type Customer = {
  id: string;
  countryCode: string;
  contactNumber: string;
  relationshipManagerId: string;
  cbsId: string;
  tradeName: string;
  fullName: string;
  registeredAddress: registerAddressDetails;
  industryClassification: string;
  riskCategory: string;
  riskRemarks: string;
  emailId: string;
  dateOfIncorporation: string;
  dateOfCommencement: string;
  tdsApplicable: boolean;
  makerRemarks: string;
  pan: string;
  gstIn: string;
  cin: string;
  tan: string;
  aadhar: string;
  registrationNumber: string;
  constitutionOfBusiness: string;
  timezone: string;
  locale: string;
  status: string;
  externalId: string;
  draft: string;
  blocked: string;
  freeze: string;
  createdOn: string
};

export type CreateCustomer = {
  contactNumber: string;
  relationshipManagerId: string;
  cbsId: string;
  tradeName: string;
  fullName: string;
  registeredAddress: registerAddressDetails;
  industryClassification: string;
  riskCategory: string;
  riskRemarks: string;
  emailId: string;
  dateOfIncorporation: string;
  dateOfCommencement: string;
  tdsApplicable: boolean;
  makerRemarks: string;
  pan: string;
  gstIn: string;
  cin: string;
  tan: string;
  aadhar: string;
  registrationNumber: string;
  constitutionOfBusiness: string;
  timezone: string;
  locale: string;
};
export type registerAddressDetails = {
  floorNumber: string;
  doorNumber: string;
  building: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  location: string;
  addressType: string;
};
export type PanTaskID = {
  taskId: string;
};

export type taskStatus = {
  status: string;
};
export type fetchApiRequst = {
  payload: string;
  type: string;
};

export type TaskIdByPanNumberReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<PanTaskID>;
  error?: string | null;
};

export type TaskStatusByTaskIdReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<taskStatus>;
  error?: string | null;
};

export type taskResultByTaskIdReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<taskStatus>;
  error?: string | null;
};

export type CustomerFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CustomerReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Customer>;
  error?: string | null;
};

export type FetchCustomerByExternalIdRequest = {
  payload: string;
  type: string;
};

export type UpdateCustomerRequest = {
  payload: Customer;
  type: string;
};

export type CreateCustomerRequest = {
  payload: CreateCustomer;
  type: string;
};
export type CreateFCustomerReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Customer>;
  error?: string | null;
};

export type CustomerVersionChangeRequest = {
  payload: updateCustomerVersion;
  type: string;
};
export type updateCustomerVersion = {
  checkerRemarks: string;
  externalId: string;
};

export type errors = {
  feild: string;
  message: string;
};
export type ValidationReduxState = {
  loading?: boolean;
  data?: errors;
  error?: string | null;
};

export type DeDuplicate = {
  pan: string;
  emailId: string;
  contactNumber: string;
  externalId : string;
};

export type DeduplicateRequest = {
  payload: DeDuplicate;
  type: string;
};
