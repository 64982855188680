import { AddressActionDispatch, AddressReduxTypes, CreateAddressRequest } from "./types";

export const fetchAddresses = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FETCH_ADDRESSES,
  payload: payload
});

export const createAddress = (payload: CreateAddressRequest): AddressActionDispatch => ({
  type: AddressReduxTypes.CREATE_ADDRESSES,
  payload: payload
});

export const fetchByIdAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FETCH_BYID_ADDRESSES,
  payload: payload
});

export const UpdatwIdAddress = (payload: CreateAddressRequest): AddressActionDispatch => ({
  type: AddressReduxTypes.UPDATE_ADDRESSES,
  payload: payload
});

export const fetchAddressesVersion = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FETCH_ADDRESSES_VERSION,
  payload: payload
});


export const fetchAddressesLatestVersion = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION,
  payload: payload
});



export const fetchAddressesVersionDetails = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS,
  payload: payload
});


export const deleteAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.DELETE_ADDRESSES,
  payload: payload
});


export const aproveAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.APROVE_ADDRESSES,
  payload: payload
});

export const rejectAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.REJECT_ADDRESSES,
  payload: payload
});

export const blockAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.BLOCK_ADDRESSES,
  payload: payload
});

export const freezeAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.FREEZE_ADDRESSES,
  payload: payload
});

export const unFreezeAddress = (payload: any): AddressActionDispatch => ({
  type: AddressReduxTypes.UNFREEZE_ADDRESSES,
  payload: payload
});

export const resetAddress = (): AddressActionDispatch => ({
  type: AddressReduxTypes.RESET,
  payload: {}
});
