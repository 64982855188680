import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import { DeDuplicate, updateCustomerVersion } from "./types";
const api = new APICore();

function fetchTaskIDByPanNumberApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gsp/search-taxpayers-from-pan/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchTaskStatusByTaskIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gsp/tasks/status/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchTaskresultByTaskIDApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gsp/tasks/result/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchGstDetailByGstNumberApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/gsp/gstn/search`;
  return api.get(`${baseUrl}`, params);
}

function fetchCustomersApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers`;
  return api.get(`${baseUrl}`, params);
}
function fetchCustomerApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchCustomerVersionApi(params: any) {
  let filterdata = {
    pgno: params.pgno,
    pgsz: params.pgsz,
    sort: params.sort
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.id}/versions`;
  return api.get(`${baseUrl}`, filterdata);
}

function fetchCustomerLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}


function deleteCustomerApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchCustomerVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveCusatomerApi(params: updateCustomerVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalBaseRateId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectCusatomerApi(params: updateCustomerVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalBaseRateId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function validateCustomerApi(params: any) {
  // console.log("params",params);

  let backendApiUrl = "";
  let registeredAddress: any = null;
  if (
    params.floorNumber ||
    params.doorNumber ||
    params.building ||
    params.street ||
    params.city ||
    params.state ||
    params.zip ||
    params.location ||
    params.addressType ||
    params.registeredAddress?.floorNumber ||
    params.registeredAddress?.doorNumber ||
    params.registeredAddress?.building ||
    params.registeredAddress?.street ||
    params.registeredAddress?.city ||
    params.registeredAddress?.state ||
    params.registeredAddress?.zip ||
    params.registeredAddress?.location ||
    params.registeredAddress?.addressType
  ) {
    registeredAddress = {
      floorNumber: params.floorNumber ? params.floorNumber : "",
      doorNumber: params.doorNumber ? params.doorNumber : "",
      building: params.building ? params.building : "",
      street: params.street ? params.street : "",
      city: params.city ? params.city : "",
      state: params.state ? params.state : "",
      zip: params.zip ? params.zip : "",
      location: params.location ? params.location : "",
      addressType: params.addressType ? params.addressType : "None"
    };
    if (params.registeredAddressId) {
      registeredAddress.id = params.registeredAddressId;
    }
  }
  let apiData: any = {
    countryCode: params.countryCode ? params.countryCode : "+91",
    contactNumber: params.contactNumber ? params.contactNumber : "",
    relationshipManagerId: params.relationshipManagerId?.length && params.relationshipManagerId[0] ? params.relationshipManagerId[0]["id"] : null,
    cbsId: params.cbsId ? params.cbsId : "",
    tradeName: params.tradeName ? params.tradeName : "",
    fullName: params.fullName ? params.fullName : "",
    registeredAddress: registeredAddress,
    industryClassification: params.industryClassification?.length ? params.industryClassification[0]["value"] : "",
    riskCategory: params.riskCategory ? params?.riskCategory?.value : "None",
    riskRemarks: params.riskRemarks ? params.riskRemarks.trim() : "",
    emailId: params.emailId ? params.emailId : "",
    dateOfIncorporation: params.dateOfIncorporation ? params.dateOfIncorporation : "0001-01-01",
    dateOfCommencement: params.dateOfCommencement ? params.dateOfCommencement : "0001-01-01",
    tdsApplicable: params.tdsApplicable ? params.tdsApplicable : false,
    pan: params.pan ? params.pan : "",
    gstIn: params.gstIn ? params.gstIn.value : "",
    cin: params.cin ? params.cin : "",
    tan: params.tan ? params.tan.trim() : "",
    aadhar: params.aadhar ? params.aadhar : "",
    registrationNumber: params.registrationNumber ? params.registrationNumber : "",
    constitutionOfBusiness: params.constitutionOfBusiness ? params.constitutionOfBusiness : "",
    timezone: "Asia/Kolkata",
    locale: "en"
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/validate`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/validate?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function createCustomerApi(params: any) {
  let backendApiUrl = "";
  let registeredAddress = null;
  if (
    params.floorNumber ||
    params.doorNumber ||
    params.building ||
    params.street ||
    params.city?.name ||
    params.state?.name ||
    params.zip ||
    params.location ||
    params.addressType ||
    params.registeredAddress?.floorNumber ||
    params.registeredAddress?.doorNumber ||
    params.registeredAddress?.building ||
    params.registeredAddress?.street ||
    params.registeredAddress?.city ||
    params.registeredAddress?.state ||
    params.registeredAddress?.zip ||
    params.registeredAddress?.location ||
    params.registeredAddress?.addressType
  ) {
    registeredAddress = {
      floorNumber: params.floorNumber ? params.floorNumber.trim() : "",
      doorNumber: params.doorNumber ? params.doorNumber.trim() : "",
      building: params.building ? params.building.trim() : "",
      street: params.street ? params.street.trim() : "",
      city: params.city ? params.city.trim() : "",
      state: params.state ? params.state.trim() : "",
      zip: params.zip ? params.zip.trim() : "",
      location: params.location ? params.location : "",
      addressType: params.addressType ? params.addressType : "RegisteredAddress"
    };
  }
  // registeredAddress = {
  //     floorNumber: params.floorNumber ? params.floorNumber : "1",
  //     doorNumber: params.doorNumber ? params.doorNumber : "3",
  //     building: params.building ? params.building : "Some Building",
  //     street: params.street ? params.street : "Some Street",
  //     city: params.city ? params.city : "Some City",
  //     state: params.state ? params.state : "Some State",
  //     zip: params.zip ? params.zip : "400011",
  //     location: params.location ? params.location : "Some Location",
  //     addressType: params.addressType ? params.addressType : "RegisteredAddress",
  // }

  let apiData: any = {
    countryCode: params.countryCode ? params.countryCode : "+91",
    contactNumber: params.contactNumber ? params.contactNumber : "",
    relationshipManagerId: params.relationshipManagerId?.length ? params.relationshipManagerId[0]["id"] : null,
    cbsId: params.cbsId ? params.cbsId : "",
    tradeName: params.tradeName ? params.tradeName : "",
    fullName: params.fullName ? params.fullName : "",
    registeredAddress: registeredAddress,
    industryClassification: params.industryClassification?.length ? params.industryClassification[0]["value"] : "",
    riskCategory: params.riskCategory ? params?.riskCategory?.value : "None",
    riskRemarks: params.riskRemarks ? params.riskRemarks.trim() : "",
    emailId: params.emailId ? params.emailId : "",
    dateOfIncorporation: params.dateOfIncorporation ? params.dateOfIncorporation : "0001-01-01",
    dateOfCommencement: params.dateOfCommencement ? params.dateOfCommencement : "0001-01-01",
    tdsApplicable: params.tdsApplicable ? params.tdsApplicable : false,
    pan: params.pan ? params.pan.trim() : "",
    gstIn: params.gstIn ? params.gstIn.value.trim() : "",
    cin: params.cin ? params.cin : "",
    tan: params.tan ? params.tan.trim() : "",
    aadhar: params.aadhar ? params.aadhar.trim() : "",
    registrationNumber: params.registrationNumber ? params.registrationNumber : "",
    constitutionOfBusiness: params.constitutionOfBusiness ? params.constitutionOfBusiness : "",
    timezone: "Asia/Kolkata",
    locale: "en"
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateCustomerApi(params: any) {
  let backendApiUrl = "";
  let registeredAddress: any = null;
  if (
    params.floorNumber ||
    params.doorNumber ||
    params.building ||
    params.street ||
    params.city ||
    params.state ||
    params.zip ||
    params.location ||
    params.addressType ||
    params.registeredAddress?.floorNumber ||
    params.registeredAddress?.doorNumber ||
    params.registeredAddress?.building ||
    params.registeredAddress?.street ||
    params.registeredAddress?.city ||
    params.registeredAddress?.state ||
    params.registeredAddress?.zip ||
    params.registeredAddress?.location ||
    params.registeredAddress?.addressType
  ) {
    registeredAddress = {
      floorNumber: params.floorNumber ? params.floorNumber.trim() : "",
      doorNumber: params.doorNumber ? params.doorNumber.trim() : "",
      building: params.building ? params.building.trim() : "",
      street: params.street ? params.street.trim() : "",
      city: params.city.name ? params.city.name : params.city,
      state: params.state.name ? params.state.name : params.state,
      zip: params.zip ? params.zip.trim() : "",
      location: params.location ? params.location.trim() : "",
      addressType: params.addressType ? params.addressType : "RegisteredAddress"
    };
    if (params.registeredAddressId) {
      registeredAddress.externalId = params.registeredAddressId;
    }
  }
  let apiData: any = {
    countryCode: params.countryCode ? params.countryCode : "+91",
    contactNumber: params.contactNumber ? params.contactNumber : "",
    relationshipManagerId: params.relationshipManagerId?.length ? params.relationshipManagerId[0]["id"] : null,
    cbsId: params.cbsId ? params.cbsId : "",
    tradeName: params.tradeName ? params.tradeName : "",
    fullName: params.fullName ? params.fullName : "",
    registeredAddress: registeredAddress,
    industryClassification: params.industryClassification?.length ? params.industryClassification[0]["value"] : "",
    riskCategory: params.riskCategory ? params?.riskCategory?.value : "None",
    // riskCategory: params.riskCategory?.length ? params.riskCategory[0]["value"] : "None",

    riskRemarks: params.riskRemarks ? params.riskRemarks.trim() : "",
    emailId: params.emailId ? params.emailId : "",
    dateOfIncorporation: params.dateOfIncorporation ? params.dateOfIncorporation : "0001-01-01",
    dateOfCommencement: params.dateOfCommencement ? params.dateOfCommencement : "0001-01-01",
    tdsApplicable: params.tdsApplicable ? params.tdsApplicable : false,
    pan: params.pan ? params.pan.trim() : "",
    gstIn: params.gstIn ? params.gstIn.value.trim() : "",
    cin: params.cin ? params.cin : "",
    tan: params.tan ? params.tan.trim() : "",
    aadhar: params.aadhar ? params.aadhar.trim() : "",
    registrationNumber: params.registrationNumber ? params.registrationNumber : "",
    constitutionOfBusiness: params.constitutionOfBusiness ? params.constitutionOfBusiness : "",
    timezone: "Asia/Kolkata",
    locale: "en"
  };
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params?.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params?.externalId}?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function deDuplicateCustomerApi(params: DeDuplicate) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/deduplicate`;
  return api.create(`${baseUrl}`, params);
}

function customerBlockApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function customerFreezeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function customerUnFreezeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchTaskIDByPanNumberApi,
  fetchTaskStatusByTaskIdApi,
  fetchTaskresultByTaskIDApi,
  fetchCustomersApi,
  fetchCustomerApi,
  fetchCustomerVersionApi,
  approveCusatomerApi,
  rejectCusatomerApi,
  fetchGstDetailByGstNumberApi,
  validateCustomerApi,
  createCustomerApi,
  updateCustomerApi,
  deDuplicateCustomerApi,
  customerBlockApi,
  customerFreezeApi,
  customerUnFreezeApi,
  fetchCustomerVersionDetailsApi,
  fetchCustomerLatestVersionApi,
  deleteCustomerApi
};
