import { APICore } from "helpers/api/apiCore";
import { AuthActionTypes } from "./constants";
import { error } from "console";

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false
};

type UserData = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.REFRESH_TOKEN_SUCCESS
    | AuthActionTypes.REFRESH_TOKEN_ERROR
    | AuthActionTypes.LOGIN_HISTORY
    | AuthActionTypes.LOGIN_HISTORY_SUCCESS
    | AuthActionTypes.LOGIN_HISTORY_ERROR
    | AuthActionTypes.CHANGE_PASSWORD
    | AuthActionTypes.CHANGE_PASSWORD_SUCCESS
    | AuthActionTypes.CHANGE_PASSWORD_ERROR
    | AuthActionTypes.RESET_CHANGE_PASSWORD
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.FORCE_LOGOUT
    | AuthActionTypes.FORCE_LOGOUT_SUCCESS
    | AuthActionTypes.FORCE_LOGOUT_ERROR
    | AuthActionTypes.RESET_FORCE_LOGOUT
    | AuthActionTypes.RESET
    | AuthActionTypes.RESET_PASSWORD_SUCCESS
    | AuthActionTypes.RESET_PASSWORD_ERROR
    ;
  payload: {
    actionType?: string;
    data?: UserData | {};
    error?: string;
  };
};

type State = {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
};

const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
  // console.log("jest api call",action);

  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            userSignUp: true,
            loading: false
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true, 
            error:false
          };
        }
        case AuthActionTypes.RESET_PASSWORD_SUCCESS:{
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: true, 
            error:false
          }; 
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            registerError: action.payload.error,
            userSignUp: false,
            loading: false
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {  
          return {
            ...state,
            resetPasswordSuccess: action.payload.data,
            error: action.payload.error,
            loading: false,
            passwordReset: true
          };
          
        }
        case AuthActionTypes.RESET_PASSWORD_ERROR: {      
          return {
            ...state,
            error: action.payload.error,
            resetPasswordSuccess: action.payload.data,
            loading: false,
            passwordReset: false
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.SIGNUP_USER:
      return { ...state, loading: true, userSignUp: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false, 
        resetPasswordSuccess: null       
      };
    default:
      return { ...state };
  }
};

const LoginHistoryReducer = (state: State = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.LOGIN_HISTORY: {
      // console.log("state", state);

      return {
        ...state,
        loading: true
      };
    }
    case AuthActionTypes.LOGIN_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AuthActionTypes.LOGIN_HISTORY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case AuthActionTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ChangePasswordReducer = (state: State = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.CHANGE_PASSWORD: {

      return {
        ...state,
        loading: true
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AuthActionTypes.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AuthActionTypes.RESET_CHANGE_PASSWORD: {
      return {
        data: null,
        error: null,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const ResetPasswordReducer = (state: State = INIT_STATE, action: AuthActionType) => {  
  switch (action.type) {
    case AuthActionTypes.RESET_CHANGE_PASSWORD: {
       console.log(" Reset password reducer .. state", state);

      return {
        ...state,
        loading: true
      };
    }
    case AuthActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        resetPasswordSuccess: action.payload.data,
        loading: false
      };
    }
    case AuthActionTypes.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case AuthActionTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
export { Auth, LoginHistoryReducer, ChangePasswordReducer, ResetPasswordReducer };
