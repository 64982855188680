import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum ExcessMoneyTypes {
    FETCH_EXCESS_MONEYS = "@auth/FETCH_EXCESS_MONEYS",
    FETCH_EXCESS_MONEYS_SUCCESS = "@auth/FETCH_EXCESS_MONEYS_SUCCESS",
    FETCH_EXCESS_MONEYS_FAIL = "@auth/FETCH_EXCESS_MONEYS_FAIL",

    // Particular excess money
    FETCH_EXCESS_MONEY_BY_ID = "@auth/FETCH_EXCESS_MONEY_BY_ID",
    FETCH_EXCESS_MONEY_BY_ID_SUCCESS = "@auth/FETCH_EXCESS_MONEY_BY_ID_SUCCESS",
    FETCH_EXCESS_MONEY_BY_ID_FAIL = "@auth/FETCH_EXCESS_MONEY_BY_ID_FAIL",

    RESET = "@auth/RESET"
}

export type ExcessMoneyActionDispatch = {
    type:
    | ExcessMoneyTypes.FETCH_EXCESS_MONEYS
    | ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID
    | ExcessMoneyTypes.RESET,
    payload: any;
};

export type ExcessMoneyReducerDispatch = {
    type:
    | ExcessMoneyTypes.FETCH_EXCESS_MONEYS
    | ExcessMoneyTypes.FETCH_EXCESS_MONEYS_SUCCESS
    | ExcessMoneyTypes.FETCH_EXCESS_MONEYS_FAIL
    | ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID
    | ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_SUCCESS
    | ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_FAIL
    
    | ExcessMoneyTypes.RESET,
    payload: {
        data?:FetchApiResponse<ExcessMoney>;
        error?: string;
    };
};

// used by reducer
export type ExcessMoneyReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<ExcessMoney>;
    error?: string | null;
};

export type ExcessMoneyByIdRedux = {
  loading?: boolean;
  data?: CreateApiResponse<ExcessMoney>;
  error?: string | null;
};
export type ExcessMoney = {
    status: string,
    programmName: object,
    borrowerName: object,
    exposureOn: string,
    receivedDate:string,
    amount:number,
};

// used by saga
export type ExcessMoneyFetchRequest = {
    payload: FilterMetadata;
    type: string;
};