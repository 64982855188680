import { EodBodJobReduxState, EodBodReducerDispatch, EodBodReduxType, EodLoggedInJobsReducerDispatch } from "./types";

const EOD_JOB_INIT_STATE = {
  loading: false,
  data: {},
  error: null
};

const EodJobLoggedInUsers = (state: EodBodJobReduxState = EOD_JOB_INIT_STATE, action: EodLoggedInJobsReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      }
    }
    case EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      }
    }
    case EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_ERROR: {
      return {
        ...state,
        data: action.payload.error,
        loading: false
      }
    }
    default:
      return { ...state };
  }

}
const EodJobReducer = (state: EodBodJobReduxState = EOD_JOB_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.START_EOD_JOB: {
      return {
        ...state,
        loading: true,
        error:null
      };
    }
    case EodBodReduxType.START_EOD_JOB_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.START_EOD_JOB_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case EodBodReduxType.RESET_EOD_JOB:
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };

    default:
      return { ...state };
  }
};

const EOD_JOB_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CREATE_JOB_LIST_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const GetEodJobStatusReducer = (state: EodBodJobReduxState = EOD_JOB_LIST_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.GET_EOD_JOB_STATUS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case EodBodReduxType.GET_EOD_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.GET_EOD_JOB_STATUS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case EodBodReduxType.RESET_EOD_JOB_STATUS:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BUSINESS_DETAIL_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const GetbusinessDetailReducer = (state: EodBodJobReduxState = BUSINESS_DETAIL_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.GET_BUSINESS_DATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case EodBodReduxType.GET_BUSINESS_DATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.GET_BUSINESS_DATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const ForceLogoutUsersReducer = (state: EodBodJobReduxState = BUSINESS_DETAIL_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.FORCE_LOGOUT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case EodBodReduxType.FORCE_LOGOUT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.FORCE_LOGOUT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case EodBodReduxType.RESET_FORCE_LOGOUT: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const UpdatebusinessDetailReducer = (state: EodBodJobReduxState = BUSINESS_DETAIL_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.UPDATE_BUSINESS_DATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case EodBodReduxType.UPDATE_BUSINESS_DATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.UPDATE_BUSINESS_DATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const InitialUpdatebusinessDetailReducer = (state: EodBodJobReduxState = CREATE_JOB_LIST_INIT_STATE, action: EodBodReducerDispatch) => {
  switch (action.type) {
    case EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case EodBodReduxType.RESET_INITIAL_UPDATE_BUSINESS_DATE:
      return {
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

export { EodJobReducer, GetEodJobStatusReducer, EodJobLoggedInUsers, GetbusinessDetailReducer, ForceLogoutUsersReducer, UpdatebusinessDetailReducer, InitialUpdatebusinessDetailReducer };
