import { Branch } from "redux/branch/types";
import { Currency } from "redux/currency/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum HolidayReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_HOLIDAY = "@@auth/FETCH_HOLIDAY",
  FETCH_HOLIDAY_SUCCESS = "@@auth/FETCH_HOLIDAY_SUCCESS",
  FETCH_HOLIDAY_ERROR = "@@auth/FETCH_HOLIDAY_ERROR",

  FETCH_HOLIDAY_BY_EXTERNAL_ID = "@@auth/FETCH_HOLIDAY_BY_EXTERNAL_ID",
  FETCH_HOLIDAY_BY_EXTERNAL_ID_SUCCESS = "@@auth/FETCH_HOLIDAY_BY_EXTERNAL_ID_SUCCESS",
  FETCH_HOLIDAY_BY_EXTERNAL_ID_ERROR = "@@auth/FETCH_HOLIDAY_BY_EXTERNAL_ID_ERROR",

  CREATE_HOLIDAY = "@@auth/CREATE_HOLIDAY",
  CREATE_HOLIDAY_SUCCESS = "@@auth/CREATE_HOLIDAY_SUCCESS",
  CREATE_HOLIDAY_ERROR = "@@auth/CREATE_HOLIDAY_ERROR",

  UPDATE_HOLIDAY = "@@auth/UPDATE_HOLIDAY",
  UPDATE_HOLIDAY_SUCCESS = "@@auth/UPDATE_HOLIDAY_SUCCESS",
  UPDATE_HOLIDAY_ERROR = "@@auth/UPDATE_HOLIDAY_ERROR",
  RESET_UPDATE_HOLIDAY = "@@auth/RESET_UPDATE_HOLIDAY",

  DELETE_HOLIDAY = "@@auth/DELETE_HOLIDAY",
  DELETE_HOLIDAY_SUCCESS = "@@auth/DELETE_HOLIDAY_SUCCESS",
  DELETE_HOLIDAY_ERROR = "@@auth/DELETE_HOLIDAY_ERROR",
  RESET_DELETE_HOLIDAY = "@@auth/RESET_DELETE_HOLIDAY",

  FETCH_HOLIDAY_VERSION = "@@auth/FETCH_HOLIDAY_VERSION",
  FETCH_HOLIDAY_VERSION_SUCCESS = "@@auth/FETCH_HOLIDAY_VERSION_SUCCESS",
  FETCH_HOLIDAY_VERSION_ERROR = "@@auth/FETCH_HOLIDAY_VERSION_ERROR",

  FETCH_HOLIDAY_LATEST_VERSION = "@@auth/FETCH_HOLIDAY_LATEST_VERSION",
  FETCH_HOLIDAY_LATEST_VERSION_SUCCESS = "@@auth/FETCH_HOLIDAY_LATEST_VERSION_SUCCESS",
  FETCH_HOLIDAY_LATEST_VERSION_ERROR = "@@auth/FETCH_HOLIDAY_LATEST_VERSION_ERROR",

  FETCH_HOLIDAY_VERSION_DETAILS = "@@auth/FETCH_HOLIDAY_VERSION_DETAILS",
  FETCH_HOLIDAY_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_HOLIDAY_VERSION_DETAILS_SUCCESS",
  FETCH_HOLIDAY_VERSION_DETAILS_ERROR = "@@auth/FETCH_HOLIDAY_VERSION_DETAILS_ERROR",

  APPROVE_HOLIDAY = "@@auth/APPROVE_HOLIDAY",
  APPROVE_HOLIDAY_SUCCESS = "@@auth/APPROVE_HOLIDAY_SUCCESS",
  APPROVE_HOLIDAY_ERROR = "@@auth/APPROVE_HOLIDAY_ERROR",

  REJECT_HOLIDAY = "@@auth/REJECT_HOLIDAY",
  REJECT_HOLIDAY_SUCCESS = "@@auth/REJECT_HOLIDAY_SUCCESS",
  REJECT_HOLIDAY_ERROR = "@@auth/REJECT_HOLIDAY_ERROR",

  BLOCK_HOLIDAY = "@@auth/BLOCK_HOLIDAY",
  BLOCK_HOLIDAY_SUCCESS = "@@auth/BLOCK_HOLIDAY_SUCCESS",
  BLOCK_HOLIDAY_ERROR = "@@auth/BLOCK_HOLIDAY_ERROR",

  FREEZE_HOLIDAY = "@@auth/FREEZE_HOLIDAY",
  FREEZE_HOLIDAY_SUCCESS = "@@auth/FREEZE_HOLIDAY_SUCCESS",
  FREEZE_HOLIDAY_ERROR = "@@auth/FREEZE_HOLIDAY_ERROR",

  UNFREEZE_HOLIDAY = "@@auth/UNFREEZE_HOLIDAY",
  UNFREEZE_HOLIDAY_SUCCESS = "@@auth/UNFREEZE_HOLIDAY_SUCCESS",
  UNFREEZE_HOLIDAY_ERROR = "@@auth/UNFREEZE_HOLIDAY_ERROR",

  RESET = "@@auth/RESET"
}

export type HolidayActionDispatch = {
  type:
    | HolidayReduxTypes.FETCH_HOLIDAY
    | HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS
    | HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION
    | HolidayReduxTypes.APPROVE_HOLIDAY
    | HolidayReduxTypes.REJECT_HOLIDAY
    | HolidayReduxTypes.CREATE_HOLIDAY
    | HolidayReduxTypes.UPDATE_HOLIDAY
    | HolidayReduxTypes.DELETE_HOLIDAY
    | HolidayReduxTypes.RESET_UPDATE_HOLIDAY
    | HolidayReduxTypes.BLOCK_HOLIDAY
    | HolidayReduxTypes.FREEZE_HOLIDAY
    | HolidayReduxTypes.UNFREEZE_HOLIDAY
    | HolidayReduxTypes.RESET;
  payload: any;
};

export type HolidayReducerDispatch = {
  type:
    | HolidayReduxTypes.FETCH_HOLIDAY
    | HolidayReduxTypes.FETCH_HOLIDAY_SUCCESS
    | HolidayReduxTypes.FETCH_HOLIDAY_ERROR
    | HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID
    | HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_SUCCESS
    | HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_ERROR
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_SUCCESS
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_ERROR
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_SUCCESS
    | HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_ERROR
    | HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION
    | HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_SUCCESS
    | HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_ERROR
    | HolidayReduxTypes.APPROVE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.APPROVE_HOLIDAY
    | HolidayReduxTypes.APPROVE_HOLIDAY_ERROR
    | HolidayReduxTypes.REJECT_HOLIDAY_SUCCESS
    | HolidayReduxTypes.REJECT_HOLIDAY
    | HolidayReduxTypes.REJECT_HOLIDAY_ERROR
    | HolidayReduxTypes.CREATE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.CREATE_HOLIDAY_ERROR
    | HolidayReduxTypes.UPDATE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.UPDATE_HOLIDAY_ERROR
    | HolidayReduxTypes.RESET_UPDATE_HOLIDAY
    | HolidayReduxTypes.BLOCK_HOLIDAY_SUCCESS
    | HolidayReduxTypes.BLOCK_HOLIDAY_ERROR
    | HolidayReduxTypes.FREEZE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.FREEZE_HOLIDAY_ERROR
    | HolidayReduxTypes.UNFREEZE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.UNFREEZE_HOLIDAY_ERROR
    | HolidayReduxTypes.DELETE_HOLIDAY
    | HolidayReduxTypes.DELETE_HOLIDAY_SUCCESS
    | HolidayReduxTypes.DELETE_HOLIDAY_ERROR
    | HolidayReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Holiday>;
    error?: string;
  };
};

export type HolidayReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Holiday>;
  error?: string | null;
};

export type HolidayFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type Holiday = {
  id: string;
  externalId: string;
  status: string;
  country: string;
  branch: Branch;
  date: string;
  comments: string;
  userId: string;
  active: boolean;
  inactiveReason: string;
  checkerRemarks: string;
  isDisabled: boolean;
  draft: boolean;
  makerRemarks: string;
};

export type CreateHoliday = {
  country: string;
  branch: string;
  date: string;
  comments: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreateHolidayRequest = {
  payload: CreateHoliday;
  type: string;
};
export type UpdateHolidayRequest = {
  payload: Holiday;
  type: string;
};
export type CreateHolidayReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Holiday>;
  error?: string | null;
};

export type FetchHolidayByExternalIdRequest = {
  payload: string;
  type: string;
};
export type holidayVersionChangeRequest = {
  payload: updateHolidayVersion;
  type: string;
};
export type updateHolidayVersion = {
  checkerRemarks: string;
  externalId: string;
};
