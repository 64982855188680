import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { fetchSkipableValidationStagingInstrumentsApi, fetchStagingInstrumentApi, fetchValidationErrorStagingInstrumentsIdApi } from "./api";
import { FetchStagingInstrumentRequest } from "./types";
import { fetchStagingInstrumentsApi, reProcessingStagingInstrumentsApi, skipValidationsForStagingInstrumentsApi, removeInstrumentsStaningBatchApi } from "./api";
import {
  FetchStagingInstrumentsRequest,
  StagingInstrumentsReduxTypes,
  reProcessingStagingInstrumentsRequest,
  skipValidationRequest,
  RemoveInstrumentStagingRequest
} from "./types";
//

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchStagingInstruments({ payload, type }: FetchStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRemoveStagingInstruments({ payload, type }: FetchStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRemoveStagingInstrumentsRemoveVersion({ payload, type }: FetchStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStagingInstrument({ payload, type }: FetchStagingInstrumentRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingInstrumentApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* reProcessingStagingInstruments({ payload, type }: reProcessingStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(reProcessingStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS_ERROR,
      payload: { error: error }
    });
  }
}
function* skipValidationForStagingInstruments({ payload, type }: skipValidationRequest): SagaIterator {
  try {
    const response = yield call(skipValidationsForStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchSkipValidationStagingInstruments({ payload, type }: FetchStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(fetchSkipableValidationStagingInstrumentsApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchSValidationErrorByStagingInstrumentId({ payload, type }: FetchStagingInstrumentsRequest): SagaIterator {
  try {
    const response = yield call(fetchValidationErrorStagingInstrumentsIdApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID_ERROR,
      payload: { error: error }
    });
  }
}
function* removeInstrumentStagingBatch({ payload, type }: RemoveInstrumentStagingRequest): SagaIterator {
  try {
    const response = yield call(removeInstrumentsStaningBatchApi, payload);
    yield put({
      type: StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchRemoveInstrumentsStagingBatch() {
  yield takeEvery(StagingInstrumentsReduxTypes.REMOVE_INSTRUMENT_STAGING, removeInstrumentStagingBatch);
}

export function* watchFetchStagingInstruments() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS, fetchStagingInstruments);
}

export function* watchFetchStagingInstrument() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENT, fetchStagingInstrument);
}

export function* watchReprocessingStagingInstruments() {
  yield takeEvery(StagingInstrumentsReduxTypes.REPROCESS_STAGING_INSTRUMENTS, reProcessingStagingInstruments);
}

export function* watchSkipValidationForStagingInstruments() {
  yield takeEvery(StagingInstrumentsReduxTypes.SKIP_VALIDATION_STAGING_INSTRUMENTS, skipValidationForStagingInstruments);
}

export function* watchFetchSkipValidationStagingInstruments() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_SKIPABLE_VALIDATION_BY_STAGING_INSTRUMENT_ID, fetchSkipValidationStagingInstruments);
}

export function* watchfetchSValidationErrorByStagingInstrumentId() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_VALIDATION_ERROR_BY_STAGING_INSTRUMENT_ID, fetchSValidationErrorByStagingInstrumentId);
}

export function* watchFetchRemoveStagingInstruments() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_REMOVE_STAGING_INSTRUMENTS, fetchRemoveStagingInstruments);
}

export function* watchFetchRemoveStagingInstrumentsRemoveVersion() {
  yield takeEvery(StagingInstrumentsReduxTypes.FETCH_STAGING_INSTRUMENTS_REMOVE_VERSION, fetchRemoveStagingInstrumentsRemoveVersion);
}

function* stagingInstrumentSaga() {
  yield all([
    fork(watchFetchStagingInstruments),
    fork(watchFetchStagingInstrument),
    fork(watchReprocessingStagingInstruments),
    fork(watchSkipValidationForStagingInstruments),
    fork(watchFetchSkipValidationStagingInstruments),
    fork(watchfetchSValidationErrorByStagingInstrumentId),
    fork(watchFetchRemoveStagingInstruments),
    fork(watchFetchRemoveStagingInstrumentsRemoveVersion),
    fork(watchRemoveInstrumentsStagingBatch)
  ]);
}

export default stagingInstrumentSaga;
