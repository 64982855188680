import { CreateCustomerContactReduxState, CustomerContactReducerDispatch, CustomerContactsReduxTypes } from "./types";

const CREATE_CUSTOMER_CONTACT_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const CreateCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: true,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerContactListReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerContactBYIdReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerContactListVersionReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerContactVersionDetailsReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCustomerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

// partner
const CreatePartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchPatnerContactListReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {

    case CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS: {
      return {
        ...state,
        loading: true
      };
    }
    case CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchPartnerContactBYIdReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdatePartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PartnerContactListVersionReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_PARTNER_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AprovePartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.ARPOVE_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.ARPOVE_PARTNER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectPartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.REJECT_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.REJECT_PARTNER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockPartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.BLOCK_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.BLOCK_PARTNER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezePartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FREEZE_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FREEZE_PARTNER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezePartnerContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UNFREEZE_PARTNER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UNFREEZE_PARTNER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

//Key

const CreateKeyManagmentContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const KeyManagementContactListReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {

    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const KeyManagementContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateKeyManagementContactReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const KeyManagementListVersionReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveKeyManagementReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectKeyManagementReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockKeyManagementReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeKeyManagementReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeKeyManagementReducer = (state: CreateCustomerContactReduxState = CREATE_CUSTOMER_CONTACT_INIT_STATE, action: CustomerContactReducerDispatch) => {
  switch (action.type) {
    case CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerContactsReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CreateCustomerContactReducer,
  CustomerContactListReducer,
  CustomerContactBYIdReducer,
  UpdateCustomerContactReducer,
  CreatePartnerContactReducer,
  CreateKeyManagmentContactReducer,
  KeyManagementContactListReducer,
  KeyManagementContactReducer,
  UpdateKeyManagementContactReducer,
  FetchPatnerContactListReducer,
  FetchPartnerContactBYIdReducer,
  UpdatePartnerContactReducer,
  CustomerContactListVersionReducer,
  AproveCustomerContactReducer,
  RejectCustomerContactReducer,
  BlockCustomerContactReducer,
  KeyManagementListVersionReducer,
  AproveKeyManagementReducer,
  RejectKeyManagementReducer,
  BlockKeyManagementReducer,
  PartnerContactListVersionReducer,
  AprovePartnerContactReducer,
  RejectPartnerContactReducer,
  BlockPartnerContactReducer,
  FreezeCustomerContactReducer,
  FreezeKeyManagementReducer,
  FreezePartnerContactReducer,
  UnFreezePartnerContactReducer,
  UnFreezeKeyManagementReducer,
  UnFreezeCustomerContactReducer,
  CustomerContactVersionDetailsReducer,
  DeleteCustomerContactReducer
};
