import { APICore } from "helpers/api/apiCore";
import config from "../../config";

const api = new APICore();

function startEodJobApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/eod-run/trigger-eod-job`;
  return api.create(`${baseUrl}`, params);
}
function eodJobLoggedInUsersApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/eod-run/logged-in-users`;
  return api.get(`${baseUrl}`, params)
}
function getEodJobStatusApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/eod-run`;
  return api.get(`${baseUrl}`, params);
}

function getBusinessDateApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/business-details`;
  return api.get(`${baseUrl}`, params);
}

function updateBusinessDateApi(params: any) {
  const data = {
    businessDate: params.date
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/business-details/set-business_date`;
  return api.update(`${baseUrl}`, data);
}

function initalupdateBusinessDateApi() {


  const baseUrl = `${config.EOD_BASE_URL}/eod-run/trigger-bod`;
  return api.create(`${baseUrl}`, {});
}

function forceLogoutApi() {
  const baseUrl = `${config.BACKEND_BASE_URL}/force-logout`;
  return api.updatePatch(`${baseUrl}`, {});
}

export { startEodJobApi, eodJobLoggedInUsersApi, initalupdateBusinessDateApi, getEodJobStatusApi, getBusinessDateApi, forceLogoutApi, updateBusinessDateApi };
