import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../../config";
import { CreateRepayment, UpdateRepayment } from "./types";

const api = new APICore();

function fetchRepaymentsApi(params: FilterMetadata) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request-instrument-settlement`;
  return api.get(`${baseUrl}`, params);
}

function fetchRepaymentApi(params: any) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request-instrument-settlement/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createRepaymentApi(params: any) {

 
  let formData = new FormData();
  formData.append('file', params?.batchInstrumnets[0]);
  // formData.append('loanAccountNumber', "6a0ac741-81b1-4806-8d4b-90bace477f05-54c4d015-fbaa-4cb8-8f09-139d953c48e8");
  // formData.append('virtualAccountNumber', "100020033532");
  // formData.append('transactionReferenceNumber', "TR040");
  // formData.append('transactionReferenceType', "Utr");
  
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request/repayment/file`
  return api.create(baseUrl, formData)
}

function uploadRepaymentSettlemtnApi(params: any) {

 
  let formData = new FormData();
  formData.append('file', params?.batchInstrumnets[0]);
  
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request-instrument-settlement/settleInstruments/file`
  return api.create(baseUrl, formData)
}

function fetchRepaymentVersionApi(params: any) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-batches/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}

function approveRepaymentApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-batches/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectRepaymentApi(params: any) {
  let remarks = {
    checkerRemarks: params.value.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-batches/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}


export { fetchRepaymentsApi, fetchRepaymentApi, createRepaymentApi, fetchRepaymentVersionApi, approveRepaymentApi, rejectRepaymentApi,uploadRepaymentSettlemtnApi };
