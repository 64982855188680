import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateRepaymentRequest, RepaymentReduxTypes, RepaymentFetchRequest } from "./types";
import {
  approveRepaymentApi,
  createRepaymentApi,
  fetchRepaymentApi,
  fetchRepaymentsApi,
  fetchRepaymentVersionApi,
  rejectRepaymentApi,
  uploadRepaymentSettlemtnApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchRepaymentSettlements({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentSettlementFilters({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SATTLEMENT_FILTERS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentRequestIdSettlementFilters({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SATTLEMENT_FILTERS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentSettlement({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_SATTLEMENT_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchRepaymentSettlements() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS, fetchRepaymentSettlements);
}

export function* watchFetchRepaymentSettlement() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT, fetchRepaymentSettlement);
}

export function* watchFetchRepaymentSettlementFilters() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS, fetchRepaymentSettlementFilters);
}

export function* watchFetchRepaymentRequestIdSettlementFilters() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS, fetchRepaymentRequestIdSettlementFilters);
}

function* RepaymentSettlementSaga() {
  yield all([
    fork(watchFetchRepaymentSettlements),
    fork(watchFetchRepaymentSettlement),
    fork(watchFetchRepaymentSettlementFilters),
    fork(watchFetchRepaymentRequestIdSettlementFilters)
  ]);
}

export default RepaymentSettlementSaga;
