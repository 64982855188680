import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum UserRoleReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_USER_ROLES = "@@auth/FETCH_USER_ROLES",
  FETCH_USER_ROLES_SUCCESS = "@@auth/FETCH_USER_ROLES_SUCCESS",
  FETCH_USER_ROLES_ERROR = "@@auth/FETCH_USER_ROLES_ERROR",

  FETCH_USER_ROLE = "@@auth/FETCH_USER_ROLE",
  FETCH_USER_ROLE_SUCCESS = "@@auth/FETCH_USER_ROLE_SUCCESS",
  FETCH_USER_ROLE_ERROR = "@@auth/FETCH_USER_ROLE_ERROR",

  FETCH_USER_ROLE_VERSION = "@@auth/FETCH_USER_ROLE_VERSION",
  FETCH_USER_ROLE_VERSION_SUCCESS = "@@auth/FETCH_USER_ROLE_VERSION_SUCCESS",
  FETCH_USER_ROLE_VERSION_ERROR = "@@auth/FETCH_USER_ROLE_VERSION_ERROR",

  FETCH_USER_ROLE_VERSION_DETAILS = "@@auth/FETCH_USER_ROLE_VERSION_DETAILS",
  FETCH_USER_ROLE_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_USER_ROLE_VERSION_DETAILS_SUCCESS",
  FETCH_USER_ROLE_VERSION_DETAILS_ERROR = "@@auth/FETCH_USER_ROLE_VERSION_DETAILS_ERROR",

  FETCH_USER_ROLE_LATEST_VERSION = "@@auth/FETCH_USER_ROLE_LATEST_VERSION",
  FETCH_USER_ROLE_LATEST_VERSION_SUCCESS = "@@auth/FETCH_USER_ROLE_LATEST_VERSION_SUCCESS",
  FETCH_USER_ROLE_LATEST_VERSION_ERROR = "@@auth/FETCH_USER_ROLE_LATEST_VERSION_ERROR",

  APPROVE_USER_ROLE = "@@auth/APPROVE_USER_ROLE",
  APPROVE_USER_ROLE_SUCCESS = "@@auth/APPROVE_USER_ROLE_SUCCESS",
  APPROVE_USER_ROLE_ERROR = "@@auth/APPROVE_USER_ROLE_ERROR",

  REJECT_USER_ROLE = "@@authREJECT_USER_ROLE",
  REJECT_USER_ROLE_SUCCESS = "@@authREJECT_USER_ROLE_SUCCESS",
  REJECT_USER_ROLE_ERROR = "@@authREJECT_USER_ROLE_ERROR",

  CREATE_USER_ROLE = "@@auth/CREATE_USER_ROLE",
  CREATE_USER_ROLE_SUCCESS = "@@auth/CREATE_USER_ROLE_SUCCESS",
  CREATE_USER_ROLE_ERROR = "@@auth/CREATE_USER_ROLE_ERROR",

  DELETE_USER_ROLE = "@@auth/DELETE_USER_ROLE",
  DELETE_USER_ROLE_SUCCESS = "@@auth/DELETE_USER_ROLE_SUCCESS",
  DELETE_USER_ROLE_ERROR = "@@auth/DELETE_USER_ROLE_ERROR",

  PASSWORD_USER_ROLE = "@@auth/PASSWORD_USER_ROLE",
  PASSWORD_USER_ROLE_SUCCESS = "@@auth/PASSWORD_USER_ROLE_SUCCESS",
  PASSWORD_USER_ROLE_ERROR = "@@auth/PASSWORD_USER_ROLE_ERROR",

  UPDATE_PASSWORD_USER_ROLE = "@@auth/UPDATE_PASSWORD_USER_ROLE",
  UPDATE_PASSWORD_USER_ROLE_SUCCESS = "@@auth/UPDATE_PASSWORD_USER_ROLE_SUCCESS",
  UPDATE_PASSWORD_USER_ROLE_ERROR = "@@auth/UPDATE_PASSWORD_USER_ROLE_ERROR",

  UPDATE_USER_ROLE = "@@auth/UPDATE_USER_ROLE",
  UPDATE_USER_ROLE_SUCCESS = "@@auth/UPDATE_USER_ROLE_SUCCESS",
  UPDATE_USER_ROLE_ERROR = "@@auth/UPDATE_USER_ROLE_ERROR",

  BLOCK_USER_ROLE = "@@auth/BLOCK_USER_ROLE",
  BLOCK_USER_ROLE_SUCCESS = "@@auth/BLOCK_USER_ROLE_SUCCESS",
  BLOCK_USER_ROLE_ERROR = "@@auth/BLOCK_USER_ROLE_ERROR",

  FREEZE_USER_ROLE = "@@auth/FREEZE_USER_ROLE",
  FREEZE_USER_ROLE_SUCCESS = "@@auth/FREEZE_USER_ROLE_SUCCESS",
  FREEZE_USER_ROLE_ERROR = "@@auth/FREEZE_USER_ROLE_ERROR",

  UNFREEZE_USER_ROLE = "@@auth/UNFREEZE_USER_ROLE",
  UNFREEZE_USER_ROLE_SUCCESS = "@@auth/UNFREEZE_USER_ROLE_SUCCESS",
  UNFREEZE_USER_ROLE_ERROR = "@@auth/UNFREEZE_USER_ROLE_ERROR",

  FETCH_MODULE_PERMISSION = "@@auth/FETCH_MODULE_PERMISSION",
  FETCH_MODULE_PERMISSION_SUCCESS = "@@auth/FETCH_MODULE_PERMISSION_SUCCESS",
  FETCH_MODULE_PERMISSION_ERROR = "@@auth/FETCH_MODULE_PERMISSION_ERROR",

  RESET = "@@auth/RESET"
}

export type UserRoleActionDispatch = {
  type:
    | UserRoleReduxTypes.FETCH_USER_ROLES
    | UserRoleReduxTypes.FETCH_USER_ROLE
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS
    | UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION
    | UserRoleReduxTypes.CREATE_USER_ROLE
    | UserRoleReduxTypes.DELETE_USER_ROLE

    | UserRoleReduxTypes.UPDATE_USER_ROLE
    | UserRoleReduxTypes.APPROVE_USER_ROLE
    | UserRoleReduxTypes.REJECT_USER_ROLE
    | UserRoleReduxTypes.BLOCK_USER_ROLE
    | UserRoleReduxTypes.FREEZE_USER_ROLE
    | UserRoleReduxTypes.UNFREEZE_USER_ROLE
    | UserRoleReduxTypes.FETCH_MODULE_PERMISSION
    | UserRoleReduxTypes.RESET;
  payload: any;
};

export type UserRoleReducerDispatch = {
  type:
    | UserRoleReduxTypes.FETCH_USER_ROLES
    | UserRoleReduxTypes.FETCH_USER_ROLES_SUCCESS
    | UserRoleReduxTypes.FETCH_USER_ROLES_ERROR
    | UserRoleReduxTypes.FETCH_USER_ROLE
    | UserRoleReduxTypes.FETCH_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.FETCH_USER_ROLE_ERROR
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_SUCCESS
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_ERROR
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS_SUCCESS
    | UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS_ERROR
    | UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION
    | UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_SUCCESS
    | UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_ERROR
    | UserRoleReduxTypes.CREATE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.CREATE_USER_ROLE_ERROR
    | UserRoleReduxTypes.DELETE_USER_ROLE
    | UserRoleReduxTypes.DELETE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.DELETE_USER_ROLE_ERROR


    | UserRoleReduxTypes.UPDATE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.UPDATE_USER_ROLE_ERROR
    | UserRoleReduxTypes.APPROVE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.APPROVE_USER_ROLE_ERROR
    | UserRoleReduxTypes.REJECT_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.REJECT_USER_ROLE_ERROR
    | UserRoleReduxTypes.BLOCK_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.BLOCK_USER_ROLE_ERROR
    | UserRoleReduxTypes.FREEZE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.FREEZE_USER_ROLE_ERROR
    | UserRoleReduxTypes.UNFREEZE_USER_ROLE_SUCCESS
    | UserRoleReduxTypes.UNFREEZE_USER_ROLE_ERROR
    | UserRoleReduxTypes.FETCH_MODULE_PERMISSION_SUCCESS
    | UserRoleReduxTypes.FETCH_MODULE_PERMISSION_ERROR
    | UserRoleReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<UserRole>;
    error?: string;
  };
};

export type UserRoleReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<UserRole>;
  error?: string | null;
};

export type UserRoleFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type UserRole = {
  id: string;
  externalId: string;
  status: string;
  email: string;
  name: string;
  mobile: string;
  financierId: string;
  roles: string[];
  // Indicates whether record is blocked or not.
  isDisabled: boolean;
  // Indicates whether the record is soft deleted or not.
  active: boolean;
  inactiveReason: string;
  checkerRemarks: string;
  blocked: boolean;
  freeze: boolean;
};

export type CreateUserRoleRequest = {
  payload: CreateUserRole;
  type: string;
};

export type UpdateUserRoleRequest = {
  payload: UpdateUserRole;
  type: string;
};

export type CreateUserRoleReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<UserRole>;
  error?: string | null;
};

export type CreateUserRole = {
  role: string;

  permissions: string[];
  draft: boolean;
  makerRemarks: string;
};

export type UpdateUserRole = {
  role: string;

  permissions: string[];
  externalUserId: string;
  draft: boolean;
  makerRemarks: string;
};

export type FetchUserRoleByExternalIdRequest = {
  payload: string;
  type: string;
};

export type UserRoleVersionChangeRequest = {
  payload: updateUserRoleVersion;
  type: string;
};
export type updateUserRoleVersion = {
  checkerRemarks: string;
  externalId: string;
};
