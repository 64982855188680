import { Country } from "redux/country/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum RepaymentReduxTypes {
  FETCH_REPAYMENT_SETTLEMENTS = "@@auth/FETCH_REPAYMENT_SETTLEMENTS",
  FETCH_REPAYMENT_SETTLEMENTS_SUCCESS = "@@auth/FETCH_REPAYMENT_SETTLEMENTS_SUCCESS",
  FETCH_REPAYMENT_SETTLEMENTS_ERROR = "@@auth/FETCH_REPAYMENT_SETTLEMENTS_ERROR",

  FETCH_REPAYMENT_SETTLEMENT = "@@auth/FETCH_REPAYMENT_SETTLEMENT",
  FETCH_REPAYMENT_SETTLEMENT_SUCCESS = "@@auth/FETCH_REPAYMENT_SETTLEMENT_SUCCESS",
  FETCH_REPAYMENT_SATTLEMENT_ERROR = "@@auth/FETCH_REPAYMENT_SATTLEMENT_ERROR",

  FETCH_REPAYMENT_SETTLEMENT_FILTERS = "@@auth/FETCH_REPAYMENT_SETTLEMENT_FILTERS",
  FETCH_REPAYMENT_SETTLEMENT_FILTERS_SUCCESS = "@@auth/FETCH_REPAYMENT_SETTLEMENT_FILTERS_SUCCESS",
  FETCH_REPAYMENT_SATTLEMENT_FILTERS_ERROR = "@@auth/FETCH_REPAYMENT_SATTLEMENT_FILTERS_ERROR",


  FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS = "@@auth/FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS",
  FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS_SUCCESS = "@@auth/FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS_SUCCESS",
  FETCH_REPAYMENT_REQUEST_ID_SATTLEMENT_FILTERS_ERROR = "@@auth/FETCH_REPAYMENT_REQUEST_ID_SATTLEMENT_FILTERS_ERROR",

  RESET = "@@auth/RESET"
}

export type RepaymentActionDispatch = {
  type:
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS

    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT
    | RepaymentReduxTypes.RESET;
  payload: any;
};

export type RepaymentReducerDispatch = {
  type:
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS

    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS

    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SETTLEMENT_FILTERS_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ID_SATTLEMENT_FILTERS_ERROR


    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENTS_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_SATTLEMENT_ERROR
    | RepaymentReduxTypes.FETCH_REPAYMENT_SETTLEMENT_FILTERS_SUCCESS
    | RepaymentReduxTypes.FETCH_REPAYMENT_SATTLEMENT_FILTERS_ERROR
    | RepaymentReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Repayment>;
    error?: string;
  };
};

export type RepaymentReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Repayment>;
  error?: string | null;
};

export type Repayment = {
  id: string;
  transactionReferenceNumber: string;
  transactionReferenceType: string;
  virtualAccountNumber: string;
  loanAccountNumber: string;

  amount: string;
};

export type CreateRepaymentRedux = {
  loading?: boolean;
  data?: CreateApiResponse<Repayment>;
  error?: string | null;
};

export type RepaymentFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateRepaymentRequest = {
  payload: CreateRepayment;
  type: string;
};

export type UpdateRepaymentRequest = {
  payload: UpdateRepayment;
  type: string;
};

export type CreateRepayment = {
  countryId: string;
  name: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateRepayment = {
  externalId: string;
  countryId: Country;
  name: string;
  makerRemarks: string;
  draft: boolean;
};

export type FetchRepaymentByIdRequest = {
  payload: string;
  type: string;
};
