import { BranchReducerDispatch, BranchReduxState, BranchReduxTypes, CreateBranchReduxState } from "./types";

const BRANCH_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const BranchListReducer = (state: BranchReduxState = BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FETCH_BRANCH: {
      // console.log("state", state);

      return {
        ...state,
        loading: true
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_BY_EXTERNAL_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BranchVersionDetailsReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeleteBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.DELETE_BRANCH: {
      return {
        ...state,
        loading: true
      };
    }
    case BranchReduxTypes.DELETE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.DELETE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_BRANCH_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.CREATE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.CREATE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.UPDATE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.UPDATE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case BranchReduxTypes.RESET_UPDATE_BRANCH:
      return {
        ...state,
        error: null,
        loading: false
      };
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchBranchVesrions = (state: CreateBranchReduxState = BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FETCH_BRANCH_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.APPROVE_BRANCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case BranchReduxTypes.APPROVE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.APPROVE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BranchReducerLatestVerionReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FETCH_BRANCH_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.REJECT_BRANCH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case BranchReduxTypes.REJECT_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.REJECT_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.BLOCK_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.BLOCK_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.FREEZE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.FREEZE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeBranchReducer = (state: CreateBranchReduxState = CREATE_BRANCH_INIT_STATE, action: BranchReducerDispatch) => {
  switch (action.type) {
    case BranchReduxTypes.UNFREEZE_BRANCH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BranchReduxTypes.UNFREEZE_BRANCH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BranchReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  BranchListReducer,
  CreateBranchReducer,
  BranchReducer,
  FetchBranchVesrions,
  ApproveBranchReducer,
  RejectBranchReducer,
  UpdateBranchReducer,
  BlockBranchReducer,
  FreezeBranchReducer,
  UnFreezeBranchReducer,
  BranchVersionDetailsReducer,
  BranchReducerLatestVerionReducer,
  DeleteBranchReducer
};
