import { APICore } from "helpers/api/apiCore";
import * as forge from "node-forge";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateUserRole, UpdateUserRole, updateUserRoleVersion } from "./types";

const api = new APICore();

function fetchUserRolesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles`;
  return api.get(`${baseUrl}`, params);
}

function fetchModulePersmissionsApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/module-permissions`;
  return api.get(`${baseUrl}`, params);
}

function createUserRoleApi(params: CreateUserRole) {
  let backendApiUrl = "";

  let apiData: any = {
    role: params.role,

    permissions: params.permissions
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/roles`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/roles?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function fetchUserRoleApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchUseVRoleersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function deleteRoleersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchRoleVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function fetchUserRoleLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function updateUserRolesApi(params: UpdateUserRole) {
  let backendApiUrl = "";

  let apiData: any = {
    role: params.role,

    permissions: params.permissions
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/roles/${params.externalUserId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/roles/${params.externalUserId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/users/${params.externalUserId}`;
  // return api.update(`${baseUrl}`, updateUserRoleData);
}

function approveUserRoleApi(params: updateUserRoleVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectUserRoleApi(params: updateUserRoleVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockUserRoleApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${externalUserId}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeUserRoleApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${externalUserId}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeUserRoleApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/roles/${externalUserId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
export {
  fetchUserRolesApi,
  createUserRoleApi,
  fetchUserRoleApi,
  updateUserRolesApi,
  fetchUseVRoleersionApi,
  rejectUserRoleApi,
  approveUserRoleApi,
  blockUserRoleApi,
  freezeUserRoleApi,
  unFreezeUserRoleApi,
  fetchModulePersmissionsApi,
  fetchRoleVersionDetailsApi,
  fetchUserRoleLatestVersionApi,
  deleteRoleersionApi
};
