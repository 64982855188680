import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { CreateCustomerBeneficiaryProgrammeType } from "./types";

const api = new APICore();

function fetchCustomerBeneficiaryProgrammeApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.customerId}`;
  return api.get(`${baseUrl}`, params);
}

function createCustomerBeneficiaryProgrammeApi(params: CreateCustomerBeneficiaryProgrammeType) {
  let backendApiUrl = "";

  let apiData: any = {
    accountnum: params.accountnum.trim(),
    corporateCode: params.corporateCode.trim(),
    default: params.default,
    ifsccode: params.ifsccode.trim(),
    name: params.name.trim()
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.customerId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.customerId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.customerId}`;
  // return api.create(`${baseUrl}`, data);
}

function fetchCustomerBeneficialProgrammeApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}`;
  return api.get(`${baseUrl}`, {});
}

function updateCustomerBeneficialProgrammeApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    accountnum: params.accountnum.trim(),
    corporateCode: params.corporateCode.trim(),
    default: params.default,
    ifsccode: params.ifsccode.trim(),
    name: params.name.trim()
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
  //   const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.externalUserId}/${params.customerId}`;
  //   return api.update(`${baseUrl}`, param);
}

function fetchCustomerBeneficialProgrammeVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/versions`;
  return api.get(`${baseUrl}`, params);
}

function approveCustomerBeneficialProgrammeApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalCustomerId;
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectCustomerBeneficialProgrammeApi(params: any) {
  let remarks = {
    checkerRemarks: params.value.checkerRemarks.trim()
  };
  let externalId = params.externalCustomerId;
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCustomerBeneficialProgrammeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeCustomerBeneficialProgrammeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeCustomerBeneficialProgrammeApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/beneficiaries/financing-programs/${params.id}/${params.customerId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchCustomerBeneficiaryProgrammeApi,
  createCustomerBeneficiaryProgrammeApi,
  fetchCustomerBeneficialProgrammeApi,
  updateCustomerBeneficialProgrammeApi,
  fetchCustomerBeneficialProgrammeVersionApi,
  approveCustomerBeneficialProgrammeApi,
  rejectCustomerBeneficialProgrammeApi,
  blockCustomerBeneficialProgrammeApi,
  freezeCustomerBeneficialProgrammeApi,
  unFreezeCustomerBeneficialProgrammeApi
};
