import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum PaymentInstrumentReduxTypes {
  FETCH_PAYMENT_INSTRUMENTS = "@@auth/FETCH_PAYMENT_INSTRUMENTS",
  FETCH_PAYMENT_INSTRUMENTS_SUCCESS = "@@auth/FETCH_PAYMENT_INSTRUMENTS_SUCCESS",
  FETCH_PAYMENT_INSTRUMENTS_ERROR = "@@auth/FETCH_PAYMENT_INSTRUMENTS_ERROR",

  FETCH_PAYMENT_INSTRUMENT = "@@auth/FETCH_PAYMENT_INSTRUMENT",
  FETCH_PAYMENT_INSTRUMENT_SUCCESS = "@@auth/FETCH_PAYMENT_INSTRUMENT_SUCCESS",
  FETCH_PAYMENT_INSTRUMENT_ERROR = "@@auth/FETCH_PAYMENT_INSTRUMENT_ERROR",

  RESET = "@@auth/RESET"
}

export type PaymentInstrumentActionDispatch = {
  type: PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS 
  | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT 
  | PaymentInstrumentReduxTypes.RESET;
  payload: any;
};

export type PaymentInstrumentReducerDispatch = {
  type:
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_SUCCESS
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_ERROR
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_SUCCESS
    | PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_ERROR
    | PaymentInstrumentReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<PaymentInstrument>;
    error?: string;
  };
};

export type PaymentInstrumentReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<PaymentInstrument>;
  error?: string | null;
};

export type PaymentInstrument = {
  id: string;
  externalId: string;
  paymentBatchId: string;
  stagingInstrumentId: string;
  financingProgramExternalId: string;
  counterPartyExternalId: string;
  instrumentNumber: string;
  dueDate: string;
  ifscCode: string;
  accountNumber: string;
  docType: string;
  additionalDetails: string;
  userRemarks: string;
  currencyShortCode: string;
  instrumentAmount: Number;
  receivedDate: string;
  instrumentDate: string;
  corporateCode: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};

export type FetchPaymentInstrumentsRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FetchPaymentInstrumentRequest = {
  payload: string;
  type: string;
};
