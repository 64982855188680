import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from "layouts";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import { LayoutTypes } from "appConstants";
import { useRedux } from "hooks";

const UserPage = React.lazy(() => import("pages/master/user/List"));
const CreateUserPage = React.lazy(() => import("pages/master/user/CreateForm"));
const UpdateUserPage = React.lazy(() => import("pages/master/user/UpdateForm"));
const DetailUserPage = React.lazy(() => import("pages/master/user/Detail"));

//roles

const UserRolePage = React.lazy(() => import("pages/master/userRoles/List"));
const CreateUserRolePage = React.lazy(() => import("pages/master/userRoles/CreateForm"));
const UpdateUserRolePage = React.lazy(() => import("pages/master/userRoles/UpdateForm"));
const DetailUserRolePage = React.lazy(() => import("pages/master/userRoles/Detail"));

const CurrencyPage = React.lazy(() => import("pages/master/currency/List"));
const CreateCurrencyPage = React.lazy(() => import("pages/master/currency/CreateForm"));
const DetailCurrencyPage = React.lazy(() => import("pages/master/currency/Detail"));
const UpdateCurrencyPage = React.lazy(() => import("pages/master/currency/UpdateForm"));

const CreateBaseRatePage = React.lazy(() => import("pages/master/baseRate/CreateForm"));
const BaseRatePage = React.lazy(() => import("pages/master/baseRate/List"));
const DetailBaseRatePage = React.lazy(() => import("pages/master/baseRate/Detail"));
const UpdateBaseRatePage = React.lazy(() => import("pages/master/baseRate/UpdateForm"));

const HolidayPage = React.lazy(() => import("pages/master/holiday/List"));
const CreateHolidayPage = React.lazy(() => import("pages/master/holiday/CreateForm"));
const DetailHolidayPage = React.lazy(() => import("pages/master/holiday/Detail"));
const UpdateHolidayPage = React.lazy(() => import("pages/master/holiday/UpdateForm"));

const HolidayBatchPage = React.lazy(() => import("pages/master/holiday-batch/List"));
const DetailHolidayBatchPage = React.lazy(() => import("pages/master/holiday-batch/Detail"));
const UpdateHolidayBatchPage = React.lazy(() => import("pages/master/holiday-batch/UpdateForm"));

const BranchPage = React.lazy(() => import("pages/master/branch/List"));
const CreateBranchPage = React.lazy(() => import("pages/master/branch/CreateForm"));
const DetailBranchPage = React.lazy(() => import("pages/master/branch/Detail"));
const UpdateBranchPage = React.lazy(() => import("pages/master/branch/UpdateForm"));

const CountryPage = React.lazy(() => import("pages/master/country/List"));
const CreateCountryPage = React.lazy(() => import("pages/master/country/CreateForm"));
const DeatilCountryPage = React.lazy(() => import("pages/master/country/Detail"));
const UpdateCountryPage = React.lazy(() => import("pages/master/country/UpdateForm"));

const StatePage = React.lazy(() => import("pages/master/state/List"));
const CreateStatePage = React.lazy(() => import("pages/master/state/CreateForm"));
const DetailStatePage = React.lazy(() => import("pages/master/state/Detail"));
const UpdateStatePage = React.lazy(() => import("pages/master/state/UpdateForm"));

const CityPage = React.lazy(() => import("pages/master/city/List"));
const CreateCityPage = React.lazy(() => import("pages/master/city/CreateForm"));
const DetailCityPage = React.lazy(() => import("pages/master/city/Detail"));
const UpdateCityPage = React.lazy(() => import("pages/master/city/UpdateForm"));

const CustomersPage = React.lazy(() => import("pages/registration/customer/List"));
const CreateCustomersPage = React.lazy(() => import("pages/registration/customer/CreateForm"));
const customerDetailPage = React.lazy(() => import("pages/registration/customer/Detail"));
const UpdateCustomerPage = React.lazy(() => import("pages/registration/customer/UpdateForm"));
const unAuthorizedPage = React.lazy(() => import("pages/error/UnAuthorisedError"));

// programme
const programmePage = React.lazy(() => import("pages/registration/financingPrograms/List"));
const CreateProgrammePage = React.lazy(() => import("pages/registration/financingPrograms/CreateForm"));
const ProgrammeDetailPage = React.lazy(() => import("pages/registration/financingPrograms/Detail"));
const UpdateProgrammePage = React.lazy(() => import("pages/registration/financingPrograms/UpdateForm"));

//relationship-Managers

const RMCreatePage = React.lazy(() => import("pages/master/relationshipManagers/CreateForm"));
const RMPage = React.lazy(() => import("pages/master/relationshipManagers/List"));
const RMUpdatePage = React.lazy(() => import("pages/master/relationshipManagers/UpdateForm"));
const RMDetailPage = React.lazy(() => import("pages/master/relationshipManagers/Detail"));

// LOV
const LOVPage = React.lazy(() => import("pages/master/LOV/List"));
const CreateLOVPage = React.lazy(() => import("pages/master/LOV/CreateForm"));
const LOVDetailPage = React.lazy(() => import("pages/master/LOV/Detail"));
const UpdateLOVPage = React.lazy(() => import("pages/master/LOV/UpdateForm"));

// product_type
const productTypePage = React.lazy(() => import("pages/master/productType/List"));
const CreateproductTypePage = React.lazy(() => import("pages/master/productType/CreateForm"));
const ProductTypeDetailPage = React.lazy(() => import("pages/master/productType/Detail"));
const UpdateproductTypePage = React.lazy(() => import("pages/master/productType/UpdateForm"));
// const RMUpdateUserPage = React.lazy(() => import("pages/master/relationshipManagers/RMUpdateForm"));

//charge

const ChargePage = React.lazy(() => import("pages/master/charges/List"));
const CreateChargePage = React.lazy(() => import("pages/master/charges/CreateForm"));
const ChargeDetailPage = React.lazy(() => import("pages/master/charges/Detail"));
const UpdateChargePage = React.lazy(() => import("pages/master/charges/UpdateForm"));

// gl Account

const GlAccountListPage = React.lazy(() => import("pages/master/glaccount/List"));
const CreateGlAccountPage = React.lazy(() => import("pages/master/glaccount/CreateForm"));
const GlAccountDetailPage = React.lazy(() => import("pages/master/glaccount/Detail"));
const UpdateGlAccountPage = React.lazy(() => import("pages/master/glaccount/UpdateForm"));

// lazy load all the views

// auth
const Login = React.lazy(() => import("pages/account/Login"));
const Logout = React.lazy(() => import("pages/account/Logout"));
const Register = React.lazy(() => import("pages/account/Register"));
const Confirm = React.lazy(() => import("pages/account/Confirm"));
const ForgetPassword = React.lazy(() => import("pages/account/ForgetPassword"));
const LockScreen = React.lazy(() => import("pages/account/LockScreen"));

const Logout2 = React.lazy(() => import("pages/account/Logout2"));
const Register2 = React.lazy(() => import("pages/account/Register2"));
const Confirm2 = React.lazy(() => import("pages/account/Confirm2"));
const ForgetPassword2 = React.lazy(() => import("pages/account/ForgetPassword2"));
const LockScreen2 = React.lazy(() => import("pages/account/LockScreen2"));

// dashboard
const DashBoardPage = React.lazy(() => import("pages/dashboard/my/List"));

// pages
const ErrorPageNotFound = React.lazy(() => import("pages/error/PageNotFound"));
const ErrorPageNotFoundAlt = React.lazy(() => import("pages/error/PageNotFoundAlt"));
const ServerError = React.lazy(() => import("pages/error/ServerError"));

// CustomerContactUpdateForm
//customerUpdate
const PromoterDirectorDetailPage = React.lazy(() => import("pages/registration/promoterDirectors/Details"));
const PromoterDirectorUpdateForm = React.lazy(() => import("pages/registration/promoterDirectors/UpdateForm"));

const KeyManagementPersonnelDetailPage = React.lazy(() => import("pages/registration/keyManagementPersonnel/Details"));
const KeyManagementPersonnelUpdateForm = React.lazy(() => import("pages/registration/keyManagementPersonnel/UpdateForm"));

const PartnerDetailPage = React.lazy(() => import("pages/registration/partnerDetail/Details"));
const PartnerUpdateForm = React.lazy(() => import("pages/registration/partnerDetail/UpdateForm"));

// ListCustomerCummications
const UpdateCustomerCummicationsPage = React.lazy(() => import("pages/registration/customerCummications/UpdateForm"));
const CustomerCummicationsDetailPage = React.lazy(() => import("pages/registration/customerCummications/Detail"));

//address
const AddressDetailPage = React.lazy(() => import("pages/registration/customrAddress/Details"));
const AddressUpdateForm = React.lazy(() => import("pages/registration/customrAddress/UpdateForm"));

// UpdateCustomerCummicationsPage

//beneficiary
const beneficiaryDetailPage = React.lazy(() => import("pages/registration/customerBeneficiary/Details"));
const CustomerBeneficiaryUpdateForm = React.lazy(() => import("pages/registration/customerBeneficiary/UpdateForm"));

const beneficiaryProgrammeDetailPage = React.lazy(() => import("pages/registration/customerBeneficiaryProgramme/Details"));
const CustomerBeneficiaryProgrammeUpdateForm = React.lazy(() => import("pages/registration/customerBeneficiaryProgramme/UpdateForm"));

// Limit Configuration
const LimitConfigPage = React.lazy(() => import("pages/registration/limitConfig/List"));
const LimitConfigCreateForm = React.lazy(() => import("pages/registration/limitConfig/CreateForm"));
const LimitConfigDetailPage = React.lazy(() => import("pages/registration/limitConfig/Details"));
const LimitConfigUpdateForm = React.lazy(() => import("pages/registration/limitConfig/UpdateForm"));

//Buyer Seller

const BuyerSellerPage = React.lazy(() => import("pages/registration/buyerSeller/List"));
const CreateBuyerSellerPage = React.lazy(() => import("pages/registration/buyerSeller/CreateForm"));
const BuyerSellerDetailPage = React.lazy(() => import("pages/registration/buyerSeller/Detail"));
const BuyerSellerUpdateForm = React.lazy(() => import("pages/registration/buyerSeller/UpdateForm"));

const instrumentBatchListPage = React.lazy(() => import("pages/transaction/instrumentBatch/List"));
const CreateInVoicePage = React.lazy(() => import("pages/transaction/instrumentBatch/CreateForm"));
const instrumnetBatchDetailPage = React.lazy(() => import("pages/transaction/instrumentBatch/Detail"));
const stagingBatchListPage = React.lazy(() => import("pages/transaction/stagingBatch/List"));
const stagingBatchDetailPage = React.lazy(() => import("pages/transaction/stagingBatch/Detail"));
const stagingInstrumentListPage = React.lazy(() => import("pages/transaction/stagingInstrument/List"));
const paymentBatchListPage = React.lazy(() => import("pages/transaction/paymentBatch/List"));
const paymentBatchDetailPage = React.lazy(() => import("pages/transaction/paymentBatch/Detail"));
const paymentInstrumentDetailPage = React.lazy(() => import("pages/transaction/paymentInstrument/Detail"));
const paymentInstrumentListPage = React.lazy(() => import("pages/transaction/paymentInstrument/List"));

// const InVoiceUpdateForm = React.lazy(() => import("pages/transaction/instrumentBatch/UpdateForm"));

//Strategy
const StrategyBatchList = React.lazy(() => import("pages/transaction/stagingBatch/List"));

const StagingDetailsPage = React.lazy(() => import("pages/transaction/stagingBatch/Detail"));

const instrumentListPage = React.lazy(() => import("pages/transaction/instrument/List"));

const instrumentDetailPage = React.lazy(() => import("pages/transaction/instrument/Details"));

// const InvoicesBatchRelatediniDetailPage = React.lazy(() => import("pages/transaction/Instrument/Details"));
// const InvoicesBatchRelatediniUpdateForm = React.lazy(() => import("pages/transaction/Instrument/UpdateForm"));

const StagingBatchInstrumentListPage = React.lazy(() => import("pages/transaction/stagingInstrument/List"));
const StagingBatchInstrumentDetailPage = React.lazy(() => import("pages/transaction/stagingInstrument/Details"));

//
const InterestAccrualListPage = React.lazy(() => import("pages/transaction/interestAccrual/List"));
const DailyBalancesListPage = React.lazy(() => import("pages/transaction/dailyBalances/List"));
const InterestAccrualDetailPage = React.lazy(() => import("pages/transaction/interestAccrual/Detail"));
const DailyBalancesDetailPage = React.lazy(() => import("pages/transaction/dailyBalances/Detail"));

//repayment-request
const repaymentListPage = React.lazy(() => import("pages/repayment/repayment-request/List"));

const repaymentDetailPage = React.lazy(() => import("pages/repayment/repayment-request/Detail"));

//repayment-sattlement

const repaymentSattlementListPage = React.lazy(() => import("pages/repayment/repayment-sattlement/List"));

const repaymentSattlementDetailPage = React.lazy(() => import("pages/repayment/repayment-sattlement/Detail"));

const RepaymentAdjumentEntriesDetailsPage = React.lazy(() => import("pages/repayment/repayment-request/DetailRepaymentAdjustmentEntries"));
const RepaymentStagedAdjumentEntriesDetailsPage = React.lazy(() => import("pages/repayment/repayment-request/DetailRepaymentStagedAdjustmentEntries"));

//excess-money
const excessMoneyListPage = React.lazy(() => import("pages/repayment/excess-money/List"));
const excessMoneyDetailPage= React.lazy(() => import("pages/repayment/excess-money/Detail"));

const InstrumentBalancesDailyHistoryDetailsPage = React.lazy(() => import("pages/transaction/instrumentBalancesDailyHistory/Detail"));
const InstrumentBalancesDailyHistoryListPage = React.lazy(() => import("pages/transaction/instrumentBalancesDailyHistory/List"));

const DisbursedmentAuthorizationDetailPage = React.lazy(() => import("pages/transaction/disburseAuth/Detail"));
const DisbursedmenAuthorizationtPage = React.lazy(() => import("pages/transaction/disburseAuth/List"));

const CreateDisbursementAdvicePage = React.lazy(() => import("pages/reports/disbursementAdvice/CreateForm"));
const CreateLimitReportPage = React.lazy(() => import("pages/reports/limitReport/CreateForm"));
const CreateClientSummaryPage = React.lazy(() => import("pages/reports/clientSummary/CreateForm"));
const CreateProgrammeSummaryPage = React.lazy(() => import("pages/reports/programmeSummary/CreateForm"));
const CreateInterestComputationPage = React.lazy(() => import("pages/reports/interestComputation/CreateForm"));
const CreateRepaymentAdvicePage = React.lazy(() => import("pages/reports/repaymentAdvice/CreateForm"));

// Login Info
const LoginInfoPage = React.lazy(() => import("pages/systemInfo/LoginInfo"));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector(state => ({
    layout: state.Layout
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "account",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            { path: "register", element: <LoadComponent component={Register} /> },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            { path: "forget-password", element: <LoadComponent component={ForgetPassword} /> },
            { path: "lock-screen", element: <LoadComponent component={LockScreen} /> },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            { path: "register2", element: <LoadComponent component={Register2} /> },
            { path: "confirm2", element: <LoadComponent component={Confirm2} /> },
            { path: "forget-password2", element: <LoadComponent component={ForgetPassword2} /> },
            { path: "lock-screen2", element: <LoadComponent component={LockScreen2} /> },
            { path: "logout2", element: <LoadComponent component={Logout2} /> }
          ]
        },
        {
          path: "*",
          element: <LoadComponent component={ErrorPageNotFound} />
        },
        {
          path: "error-401",
          element: <LoadComponent component={unAuthorizedPage} />
        },
        {
          path: "access-denied",
          element: <LoadComponent component={unAuthorizedPage} />
        },
        {
          path: "error-500",
          element: <LoadComponent component={ServerError} />
        }
      ]
    },
    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={["Admin Maker", "Admin Checker"]} component={Layout} />,
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "my",
              element: <LoadComponent component={DashBoardPage} />
            }
          ]
        },
        {
          path: "master",
          children: [
            {
              path: "users",
              element: <LoadComponent component={UserPage} />
            },
            {
              path: "users/create",
              element: <LoadComponent component={CreateUserPage} />
            },
            {
              path: "users/edit/:id",
              element: <LoadComponent component={UpdateUserPage} />
            },
            {
              path: "users/detail/:id/:internalid",
              element: <LoadComponent component={DetailUserPage} />
            },
            //roles
            {
              path: "roles",
              element: <LoadComponent component={UserRolePage} />
            },
            {
              path: "roles/create",
              element: <LoadComponent component={CreateUserRolePage} />
            },
            {
              path: "roles/edit/:id",
              element: <LoadComponent component={UpdateUserRolePage} />
            },
            {
              path: "roles/detail/:id/:internalid",
              element: <LoadComponent component={DetailUserRolePage} />
            },

            {
              path: "currencies",
              element: <LoadComponent component={CurrencyPage} />
            },
            {
              path: "currencies/create",
              element: <LoadComponent component={CreateCurrencyPage} />
            },
            {
              path: "currencies/detail/:id/:internalid",
              element: <LoadComponent component={DetailCurrencyPage} />
            },
            {
              path: "currencies/edit/:id",
              element: <LoadComponent component={UpdateCurrencyPage} />
            },
            {
              path: "base-rates",
              element: <LoadComponent component={BaseRatePage} />
            },

            {
              path: "base-rates/create",
              element: <LoadComponent component={CreateBaseRatePage} />
            },
            {
              path: "base-rates/detail/:id/:internalid",
              element: <LoadComponent component={DetailBaseRatePage} />
            },
            {
              path: "base-rates/edit/:id",
              element: <LoadComponent component={UpdateBaseRatePage} />
            },
            {
              path: "holidays",
              element: <LoadComponent component={HolidayPage} />
            },
            {
              path: "holidays/create",
              element: <LoadComponent component={CreateHolidayPage} />
            },
            {
              path: "holidays/detail/:id/:internalid",
              element: <LoadComponent component={DetailHolidayPage} />
            },
            {
              path: "holidays/edit/:id",
              element: <LoadComponent component={UpdateHolidayPage} />
            },

            {
              path: "holiday-batch",
              element: <LoadComponent component={HolidayBatchPage} />
            },
            // {
            //   path: "holidays/create",
            //   element: <LoadComponent component={CreateHolidayPage} />
            // },
            {
              path: "holiday-batch/detail/:id/:internalid",
              element: <LoadComponent component={DetailHolidayBatchPage} />
            },
            {
              path: "holiday-batch/edit/:id",
              element: <LoadComponent component={UpdateHolidayBatchPage} />
            },
            {
              path: "branch",
              element: <LoadComponent component={BranchPage} />
            },
            {
              path: "branch/create",
              element: <LoadComponent component={CreateBranchPage} />
            },
            {
              path: "branch/detail/:id/:internalid",
              element: <LoadComponent component={DetailBranchPage} />
            },
            {
              path: "branch/edit/:id",
              element: <LoadComponent component={UpdateBranchPage} />
            },
            {
              path: "country",
              element: <LoadComponent component={CountryPage} />
            },
            {
              path: "country/create",
              element: <LoadComponent component={CreateCountryPage} />
            },
            {
              path: "country/detail/:id/:internalid",
              element: <LoadComponent component={DeatilCountryPage} />
            },
            {
              path: "country/edit/:id",
              element: <LoadComponent component={UpdateCountryPage} />
            },

            {
              path: "relationshipManagers",
              element: <LoadComponent component={RMPage} />
            },
            {
              path: "relationshipManagers/create",
              element: <LoadComponent component={RMCreatePage} />
            },
            {
              path: "relationshipManagers/edit/:id",
              element: <LoadComponent component={RMUpdatePage} />
            },
            {
              path: "relationshipManagers/detail/:id/:internalid",
              element: <LoadComponent component={RMDetailPage} />
            },

            {
              path: "state",
              element: <LoadComponent component={StatePage} />
            },
            {
              path: "state/create",
              element: <LoadComponent component={CreateStatePage} />
            },
            {
              path: "state/detail/:id/:internalid",
              element: <LoadComponent component={DetailStatePage} />
            },
            {
              path: "state/edit/:id",
              element: <LoadComponent component={UpdateStatePage} />
            },
            {
              path: "city",
              element: <LoadComponent component={CityPage} />
            },
            {
              path: "city/create",
              element: <LoadComponent component={CreateCityPage} />
            },
            {
              path: "city/detail/:id/:internalid",
              element: <LoadComponent component={DetailCityPage} />
            },
            {
              path: "city/edit/:id",
              element: <LoadComponent component={UpdateCityPage} />
            },
            // LOV

            {
              path: "lov",
              element: <LoadComponent component={LOVPage} />
            },
            {
              path: "lov/create",
              element: <LoadComponent component={CreateLOVPage} />
            },
            {
              path: "lov/detail/:id/:internalid",
              element: <LoadComponent component={LOVDetailPage} />
            },
            {
              path: "lov/edit/:id",
              element: <LoadComponent component={UpdateLOVPage} />
            },

            //product-type

            {
              path: "product-type",
              element: <LoadComponent component={productTypePage} />
            },
            {
              path: "product-type/create",
              element: <LoadComponent component={CreateproductTypePage} />
            },
            {
              path: "product-type/detail/:id/:internalid",
              element: <LoadComponent component={ProductTypeDetailPage} />
            },
            {
              path: "product-type/edit/:id",
              element: <LoadComponent component={UpdateproductTypePage} />
            },
            //charge
            {
              path: "charge",
              element: <LoadComponent component={ChargePage} />
            },
            {
              path: "charge/create",
              element: <LoadComponent component={CreateChargePage} />
            },
            {
              path: "charge/detail/:id/:internalid",
              element: <LoadComponent component={ChargeDetailPage} />
            },
            {
              path: "charge/edit/:id",
              element: <LoadComponent component={UpdateChargePage} />
            },

            //Gl Account
            {
              path: "glaccount",
              element: <LoadComponent component={GlAccountListPage} />
            },
            {
              path: "glaccount/create",
              element: <LoadComponent component={CreateGlAccountPage} />
            },
            {
              path: "glaccount/detail/:id/:internalid",
              element: <LoadComponent component={GlAccountDetailPage} />
            },
            {
              path: "glaccount/edit/:id",
              element: <LoadComponent component={UpdateGlAccountPage} />
            }
          ]
        },
        {
          path: "registration",
          children: [
            {
              path: "customers",
              element: <LoadComponent component={CustomersPage} />
            },
            {
              path: "customers/create",
              element: <LoadComponent component={CreateCustomersPage} />
            },
            {
              path: "customers/detail/:id/:internalid",
              element: <LoadComponent component={customerDetailPage} />
            },
            {
              path: "customers/edit/:id",
              element: <LoadComponent component={UpdateCustomerPage} />
            },
            {
              path: "programme",
              element: <LoadComponent component={programmePage} />
            },
            {
              path: "programme/create",
              element: <LoadComponent component={CreateProgrammePage} />
            },

            {
              path: "programme/detail/:id/:internalid",
              element: <LoadComponent component={ProgrammeDetailPage} />
            },
            {
              path: "programme/edit/:id",
              element: <LoadComponent component={UpdateProgrammePage} />
            },
            {
              path: "promoter-director/detail/:id/:customerId/:internalid",
              element: <LoadComponent component={PromoterDirectorDetailPage} />
            },
            {
              path: "promoter-director/edit/:id/:customerId/",
              element: <LoadComponent component={PromoterDirectorUpdateForm} />
            },
            {
              path: "key-management/detail/:id/:customerId",
              element: <LoadComponent component={KeyManagementPersonnelDetailPage} />
            },
            {
              path: "key-management/edit/:id/:customerId",
              element: <LoadComponent component={KeyManagementPersonnelUpdateForm} />
            },
            {
              path: "partner/detail/:id/:customerId",
              element: <LoadComponent component={PartnerDetailPage} />
            },
            {
              path: "partner/edit/:id/:customerId",
              element: <LoadComponent component={PartnerUpdateForm} />
            },

            {
              path: "communications/detail/:id/:customerId/:internalid",
              element: <LoadComponent component={CustomerCummicationsDetailPage} />
            },
            {
              path: "communications/edit/:id/:customerId",
              element: <LoadComponent component={UpdateCustomerCummicationsPage} />
            },

            //address
            {
              path: "address/detail/:id/:customerId/:internalid",
              element: <LoadComponent component={AddressDetailPage} />
            },
            {
              path: "address/edit/:id/:customerId",
              element: <LoadComponent component={AddressUpdateForm} />
            },

            //limit config
            {
              path: "limit-config",
              element: <LoadComponent component={LimitConfigPage} />
            },
            {
              path: "limit-config/create",
              element: <LoadComponent component={LimitConfigCreateForm} />
            },
            {
              path: "limit-config/detail/:customerId/:id/:internalid",
              element: <LoadComponent component={LimitConfigDetailPage} />
            },
            {
              path: "limit-config/edit/:customerId/:id",
              element: <LoadComponent component={LimitConfigUpdateForm} />
            },

            //beneficiary

            {
              path: "customer-beneficiary/detail/:id/:customerId/:internalid",
              element: <LoadComponent component={beneficiaryDetailPage} />
            },
            {
              path: "customer-beneficiary/edit/:id/:customerId",
              element: <LoadComponent component={CustomerBeneficiaryUpdateForm} />
            },

            {
              path: "customer-beneficiary-programme/detail/:id/:customerId",
              element: <LoadComponent component={beneficiaryProgrammeDetailPage} />
            },
            {
              path: "customer-beneficiary-programme/edit/:id/:customerId",
              element: <LoadComponent component={CustomerBeneficiaryProgrammeUpdateForm} />
            },

            //buyer-seller
            {
              path: "buyer-seller",
              element: <LoadComponent component={BuyerSellerPage} />
            },
            {
              path: "buyer-seller/create",
              element: <LoadComponent component={CreateBuyerSellerPage} />
            },
            {
              path: "buyer-seller/detail/:id/:internalid",
              element: <LoadComponent component={BuyerSellerDetailPage} />
            },
            {
              path: "buyer-seller/edit/:id",
              element: <LoadComponent component={BuyerSellerUpdateForm} />
            }
          ]
        },
        {
          path: "transaction",
          children: [
            {
              path: "instrument-batch",
              element: <LoadComponent component={instrumentBatchListPage} />
            },
            {
              path: "instrument-batch/create",
              element: <LoadComponent component={CreateInVoicePage} />
            },
            {
              path: "instrument-batch/detail/:externalId",
              element: <LoadComponent component={instrumnetBatchDetailPage} />
            },
            {
              path: "instrument",
              element: <LoadComponent component={instrumentListPage} />
            },

            {
              path: "instrument/detail/:id",
              element: <LoadComponent component={instrumentDetailPage} />
            },

            {
              path: "staging-batch",
              element: <LoadComponent component={stagingBatchListPage} />
            },
            {
              path: "staging-batch/detail/:externalId",
              element: <LoadComponent component={stagingBatchDetailPage} />
            },
            {
              path: "staging-instrument",
              element: <LoadComponent component={stagingInstrumentListPage} />
            },

            {
              path: "staging-instrument/detail/:externalId",
              element: <LoadComponent component={StagingBatchInstrumentDetailPage} />
            },
            {
              path: "payment-batch",
              element: <LoadComponent component={paymentBatchListPage} />
            },
            {
              path: "payment-batch/detail/:externalId",
              element: <LoadComponent component={paymentBatchDetailPage} />
            },
            {
              path: "payment-instrument",
              element: <LoadComponent component={paymentInstrumentListPage} />
            },
            {
              path: "payment-instrument/detail/:externalId",
              element: <LoadComponent component={paymentInstrumentDetailPage} />
            },
            ///
            {
              path: "interest-accrual",
              element: <LoadComponent component={InterestAccrualListPage} />
            },
            {
              path: "interest-accrual/detail/:externalId",
              element: <LoadComponent component={InterestAccrualDetailPage} />
            },
            //
            {
              path: "instrument-balances-daily-history",
              element: <LoadComponent component={InstrumentBalancesDailyHistoryListPage} />
            },
            {
              path: "instrument-balances-daily-history/detail/:externalId",
              element: <LoadComponent component={InstrumentBalancesDailyHistoryDetailsPage} />
            },

            //
            {
              path: "daily-balances",
              element: <LoadComponent component={DailyBalancesListPage} />
            },
            {
              path: "daily-balances/detail/:externalId",
              element: <LoadComponent component={DailyBalancesDetailPage} />
            },

            //disbursed auth

            {
              path: "disbursement-authorization",
              element: <LoadComponent component={DisbursedmenAuthorizationtPage} />
            },
            {
              path: "disbursement-authorization/detail/:externalId",
              element: <LoadComponent component={DisbursedmentAuthorizationDetailPage} />
            }
          ]
        },

        //repaymet
        {
          path: "repayment",
          children: [
            {
              path: "repayment-request",
              element: <LoadComponent component={repaymentListPage} />
            },
            {
              path: "repayment-request/detail/:id",
              element: <LoadComponent component={repaymentDetailPage} />
            },
            {
              path: "repayment-sattlement",
              element: <LoadComponent component={repaymentSattlementListPage} />
            },
            {
              path: "repayment-sattlement/detail/:id",
              element: <LoadComponent component={repaymentSattlementDetailPage} />
            },
            {
              path: "repayment-adjuement-entries/detail/:id",
              element: <LoadComponent component={RepaymentAdjumentEntriesDetailsPage} />
            },
            {
              path: "repayment-staged-adjuement-entries/detail/:id",
              element: <LoadComponent component={RepaymentStagedAdjumentEntriesDetailsPage} />
            },
            {
              path: "excess-money",
              element: <LoadComponent component={excessMoneyListPage} />
            },
            {
              path: "excess-money/detail/:id",
              element: <LoadComponent component={excessMoneyDetailPage} />
            }
          ]
        },
        //reports
        {
          path: "report",
          children: [
            {
              path: "disbursement-advice/create",
              element: <LoadComponent component={CreateDisbursementAdvicePage} />
            },
            {
              path: "limit/create",
              element: <LoadComponent component={CreateLimitReportPage} />
            },
            {
              path: "client-summary/create",
              element: <LoadComponent component={CreateClientSummaryPage} />
            }
          ]
        },
        {
          path: "report",
          children: [
            {
              path: "interest-computation/create",
              element: <LoadComponent component={CreateInterestComputationPage} />
            },
            {
              path: "repayment-advice/create",
              element: <LoadComponent component={CreateRepaymentAdvicePage} />
            },
            {
              path: "program-summary/create",
              element: <LoadComponent component={CreateProgrammeSummaryPage} />
            }
          ]
        },
        {
          path: "system-info",
          children: [
            {
              path: "login-info",
              element: <LoadComponent component={LoginInfoPage} />
            }
          ]
        },
        {
          path: "pages",
          children: [
            {
              path: "error-404-alt",
              element: <LoadComponent component={ErrorPageNotFoundAlt} />
            }
          ]
        }
      ]
    }
  ]);
};

export { AllRoutes };
