import { Navigate, useLocation } from 'react-router-dom';
import { APICore } from 'helpers/api/apiCore';
import { useUser } from 'hooks';
import { intersect } from 'utils';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();

    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    if (api.isUserAuthenticated() === false) {
        return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    }

    // check if route is restricted by role
    // if (roles && roles.indexOf(loggedInUser.role) === -1) {
    //     // role not authorised so redirect to home page
    //     return <Navigate to={{ pathname: '/' }} />;
    // }

    // If there is no intersection between the roles which are allowed to see this route, vs the roles that the current user has
    // then we navigate to the 401 component.
    if (intersect(roles, loggedInUser.roles).length === 0) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/error-404' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
