import { FilterMetadata } from "redux/types";
import { AuthActionTypes } from "./constants";

export type AuthActionType = {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.REFRESH_TOKEN
    | AuthActionTypes.REFRESH_TOKEN_SUCCESS
    | AuthActionTypes.REFRESH_TOKEN_ERROR
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.LOGIN_HISTORY
    | AuthActionTypes.LOGIN_HISTORY_SUCCESS
    | AuthActionTypes.LOGIN_HISTORY_ERROR
    | AuthActionTypes.CHANGE_PASSWORD
    | AuthActionTypes.CHANGE_PASSWORD_SUCCESS
    | AuthActionTypes.CHANGE_PASSWORD_ERROR
    | AuthActionTypes.RESET_CHANGE_PASSWORD
    | AuthActionTypes.RESET_PASSWORD_SUCCESS
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
  payload: {} | string;
};

type User = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data }
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error }
});

export const loginUser = (username: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { username, password }
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {}
});

export const signupUser = (fullname: string, email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password }
});

export const forgotPassword = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email }
});

export const refreshToken = (): AuthActionType => ({
  type: AuthActionTypes.REFRESH_TOKEN,
  payload: {}
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {}
});

export const loginHistory = (payload: FilterMetadata): AuthActionType => ({
  type: AuthActionTypes.LOGIN_HISTORY,
  payload: payload
});

export const changePassword = (payload: any): AuthActionType => ({
  type: AuthActionTypes.CHANGE_PASSWORD,
  payload: payload
});

export const resetChangePassword = (): AuthActionType => ({
  type: AuthActionTypes.RESET_CHANGE_PASSWORD,
  payload: {}
});
