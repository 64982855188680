import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateBaseRate, updateBaseRateVersion } from "./types";
import moment from "moment";

const api = new APICore();

function fetchBaseRatesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates`;
  return api.get(`${baseUrl}`, params);
}

function fetchBaseRateApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params}`;
  return api.get(`${baseUrl}`, {});
}

function createBaseRateApi(params: CreateBaseRate) {
  // console.log("saga",params);

  let isDraft = params.draft;
  let baseRateCreationData = {
    name: params.name,
    currencyId: params.currencyId,
    rateInPercentage: params.rateInPercentage,
    tenor: params.tenor,
    startDate: params.startDate,
    endDate: params.endDate
  };
  if (!isDraft) {
    // @ts-ignore
    baseRateCreationData["makerRemarks"] = params.makerRemarks;
  }

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates?draft=${isDraft}`;
  return api.create(`${baseUrl}`, baseRateCreationData);
}

function updateBaseRateApi(params: any) {
  // console.log(params);

  var startDate = params?.startDate && params?.startDate.split("/").reverse().join("-");
  var endDate = params?.endDate && params?.endDate.split("/").reverse().join("-");

  let backendApiUrl = "";
  let apiData: any = {
    name: params.name,
    currencyId: params.currencyId.id ? params.currencyId.id : params.currencyId,
    rateInPercentage: params.rateInPercentage,
    tenor: params.tenor,
    startDate: moment(new Date(startDate)).format("yyyy-MM-DD"),
    endDate: moment(new Date(endDate)).format("yyyy-MM-DD")
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/base-rates/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/base-rates/${params.externalId}?draft=${params.draft}`;
  }

  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params.externalBaseRateId}`;
  // return api.update(`${baseUrl}`, updateBaserateData);
}

function fetchBaseRateVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}


function deleteBaseRateApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchBaseRateLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchBaseRateVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveBaseRateApi(params: updateBaseRateVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${externalBaseRateId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectBaseRateApi(params: updateBaseRateVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalBaseRateId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${externalBaseRateId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockBaseRateApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBaseRateId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${externalBaseRateId}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeBaseRateApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBaseRateId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${externalBaseRateId}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeBaseRateApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBaseRateId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/base-rates/${externalBaseRateId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  fetchBaseRatesApi,
  createBaseRateApi,
  fetchBaseRateApi,
  updateBaseRateApi,
  fetchBaseRateVersionApi,
  approveBaseRateApi,
  rejectBaseRateApi,
  blockBaseRateApi,
  freezeBaseRateApi,
  unFreezeBaseRateApi,
  fetchBaseRateVersionDetailsApi,
  fetchBaseRateLatestVersionApi,
  deleteBaseRateApi
};
