import { InstrumentReducerDispatch, InstrumentReduxState, InstrumentReduxTypes } from "./types";


const INSTRUMNET_INIT_STATE = {
    loading: false,
    data: {
        metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
                self: null,
                first: null,
                previous: null,
                next: null,
                last: null
            }
        },
        records: []
    },
    error: null
};

const InstrumentReducer = (state: InstrumentReduxState = INSTRUMNET_INIT_STATE, action: InstrumentReducerDispatch) => {
    switch (action.type) {
        case InstrumentReduxTypes.FETCH_INSTRUMENT: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case InstrumentReduxTypes.FETCH_INSTRUMENT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case InstrumentReduxTypes.FETCH_INSTRUMENT_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case InstrumentReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};

const InstrumentsReducer = (state: InstrumentReduxState = INSTRUMNET_INIT_STATE, action: InstrumentReducerDispatch) => {
    switch (action.type) {
        case InstrumentReduxTypes.FETCH_INSTRUMENTS: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case InstrumentReduxTypes.FETCH_INSTRUMENTS_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case InstrumentReduxTypes.FETCH_INSTRUMENTS_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case InstrumentReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};

export { InstrumentReducer, InstrumentsReducer }