import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { fetchAllDailyBalanceHistoryApi, fetchDailyBalanceHistoryApi } from "./api";
import { InstrumentDailyBalanceHistoryFetchRequest, InstrumentDailyBalanceHistoryReduxTypes } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchAllInstrumentsDailyBalanceHistory({ payload, type }: InstrumentDailyBalanceHistoryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAllDailyBalanceHistoryApi, payload);
    yield put({
      type: InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchInstrumentsDailyBalanceHistory({ payload, type }: InstrumentDailyBalanceHistoryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchDailyBalanceHistoryApi, payload);
    yield put({
      type: InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchAllIntrumenetDailyBalanceHistory() {
  yield takeEvery(InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY, fetchAllInstrumentsDailyBalanceHistory);
}

export function* watchFetchIntrumenetDailyBalanceHistory() {
  yield takeEvery(InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY, fetchInstrumentsDailyBalanceHistory);
}

function* instrumentDailyBalanceHistorySaga() {
  yield all([fork(watchFetchAllIntrumenetDailyBalanceHistory), fork(watchFetchIntrumenetDailyBalanceHistory)]);
}

export default instrumentDailyBalanceHistorySaga;
