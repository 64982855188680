import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateCurrencyRequest, CurrencyFetchRequest, CurrencyReduxTypes, FetchCCurrencyByIdRequest, UpdateCurrencyRequest } from "./types";
import {
  approveCurrencyApi,
  blockCurrencyApi,
  createCurrencyApi,
  deleteCurrencyApi,
  fetchCurrencyApi,
  fetchCurrencyByIdApi,
  fetchCurrencyLatestVersionApi,
  fetchCurrencyVersionApi,
  fetchCurrencyVersionDetailsApi,
  freezeCurrencyApi,
  rejectCurrencyApi,
  unFreezeCurrencyApi,
  updateCurrencyApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCurrency({ payload, type }: CurrencyFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCurrencyApi, payload);
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCurrencyBYID({ payload, type }: FetchCCurrencyByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCurrencyByIdApi, payload);
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(createCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.CREATE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.CREATE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCurrency({ payload, type }: UpdateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(updateCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.UPDATE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.UPDATE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteCurrency({ payload, type }: UpdateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(deleteCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.DELETE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.DELETE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCurrencyVersion({ payload, type }: CurrencyFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCurrencyVersionApi, payload);
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCurrencyVersionDetails({ payload, type }: CurrencyFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCurrencyVersionDetailsApi, payload);
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCurrencyLatestVersion({ payload, type }: CurrencyFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCurrencyLatestVersionApi, payload);
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(approveCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.APROVE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.APROVE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(rejectCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.REJECT_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.REJECT_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(blockCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.BLOCK_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.BLOCK_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(freezeCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.FREEZE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.FREEZE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCurrency({ payload, type }: CreateCurrencyRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCurrencyApi, payload);

    yield put({
      type: CurrencyReduxTypes.UNFREEZE_CURRENCY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CurrencyReduxTypes.UNFREEZE_CURRENCY_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCurrency() {
  yield takeEvery(CurrencyReduxTypes.FETCH_CURRENCY, fetchCurrency);
}

export function* watchCreateCurrency() {
  yield takeEvery(CurrencyReduxTypes.CREATE_CURRENCY, createCurrency);
}
export function* watchFetchCurrencyById() {
  yield takeEvery(CurrencyReduxTypes.FETCH_CURRENCY_BY_ID, fetchCurrencyBYID);
}
export function* watchUpdateCurrency() {
  yield takeEvery(CurrencyReduxTypes.UPDATE_CURRENCY, updateCurrency);
}
//

export function* watchFetchCurrencyVersion() {
  yield takeEvery(CurrencyReduxTypes.FETCH_CURRENCY_VERSION, fetchCurrencyVersion);
}

export function* watchAproveCurrency() {
  yield takeEvery(CurrencyReduxTypes.APROVE_CURRENCY, aproveCurrency);
}
export function* watchRejectCurrencyById() {
  yield takeEvery(CurrencyReduxTypes.REJECT_CURRENCY, rejectCurrency);
}
export function* watchBlockCurrency() {
  yield takeEvery(CurrencyReduxTypes.BLOCK_CURRENCY, blockCurrency);
}

export function* watchFreezeCurrency() {
  yield takeEvery(CurrencyReduxTypes.FREEZE_CURRENCY, freezeCurrency);
}

export function* watchUnFreezeCurrency() {
  yield takeEvery(CurrencyReduxTypes.UNFREEZE_CURRENCY, unFreezeCurrency);
}

export function* watchFetchCurrencyVersionDetails() {
  yield takeEvery(CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS, fetchCurrencyVersionDetails);
}

export function* watchFetchCurrencyLatestVersion() {
  yield takeEvery(CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION, fetchCurrencyLatestVersion);
}

export function* watchDeleteCurrency() {
  yield takeEvery(CurrencyReduxTypes.DELETE_CURRENCY, deleteCurrency);
}

function* currencySaga() {
  yield all([
    fork(watchFetchCurrency),
    fork(watchCreateCurrency),
    fork(watchFetchCurrencyById),
    fork(watchUpdateCurrency),

    fork(watchFetchCurrencyVersion),
    fork(watchAproveCurrency),
    fork(watchRejectCurrencyById),
    fork(watchBlockCurrency),
    fork(watchFreezeCurrency),
    fork(watchUnFreezeCurrency),
    fork(watchFetchCurrencyVersionDetails),
    fork(watchFetchCurrencyLatestVersion),
    fork(watchDeleteCurrency)
  ]);
}

export default currencySaga;
