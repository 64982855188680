import { CreateHolidayReduxState, HolidayReducerDispatch, HolidayReduxState, HolidayReduxTypes } from "./types";

const HOLIDAY_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const HolidayListReducer = (state: HolidayReduxState = HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FETCH_HOLIDAY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_HOLIDAY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const HolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const HolidayLatestVersionReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const HolidayVersionDetailsReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CreateHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.CREATE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.CREATE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.UPDATE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.UPDATE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET_UPDATE_HOLIDAY:
      return {
        ...state,
        error: null,
        loading: false
      };
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.DELETE_HOLIDAY: {
      return {
        ...state,
        loading: true
      };
    }
    case HolidayReduxTypes.DELETE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.DELETE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET_UPDATE_HOLIDAY:
      return {
        ...state,
        error: null,
        loading: false
      };
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchHolidayVesrions = (state: CreateHolidayReduxState = HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FETCH_HOLIDAY_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FETCH_HOLIDAY_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.APPROVE_HOLIDAY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case HolidayReduxTypes.APPROVE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case HolidayReduxTypes.APPROVE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.REJECT_HOLIDAY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case HolidayReduxTypes.REJECT_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.REJECT_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.BLOCK_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.BLOCK_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.FREEZE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.FREEZE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeHolidayReducer = (state: CreateHolidayReduxState = CREATE_HOLIDAY_INIT_STATE, action: HolidayReducerDispatch) => {
  switch (action.type) {
    case HolidayReduxTypes.UNFREEZE_HOLIDAY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case HolidayReduxTypes.UNFREEZE_HOLIDAY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case HolidayReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  HolidayListReducer,
  CreateHolidayReducer,
  HolidayReducer,
  UpdateHolidayReducer,
  FetchHolidayVesrions,
  ApproveHolidayReducer,
  RejectHolidayReducer,
  BlockHolidayReducer,
  FreezeHolidayReducer,
  UnFreezeHolidayReducer,
  HolidayVersionDetailsReducer,
  HolidayLatestVersionReducer,
  DeleteHolidayReducer
};
