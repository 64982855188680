import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../config";
import { CreateCity, UpdateCity } from "./types";

const api = new APICore();

function fetchCitiesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities`;
  return api.get(`${baseUrl}`, params);
}

function fetchCityByIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params}`;
  return api.get(`${baseUrl}`, {});
}

function createCityApi(params: CreateCity) {
  let backendApiUrl = "";

  let apiData: any = {
    stateId: params.stateId ? params.stateId : "",
    name: params.name ? params.name.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/cities`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/cities?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/cities`;
  // return api.create(`${baseUrl}`, params);
}

function updateCityApi(params: any) {
  // console.log("params", params);

  let backendApiUrl = "";

  let apiData: any = {
    stateId: params.stateId ? params.stateId : params.state?.id,
    name: params.name ? params.name.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/cities/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/cities/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function fetchCityVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}


function deleteCityApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchCityVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function fetchCityLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function approveCityApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectCityApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCityApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeCityApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeCityApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/cities/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchCitiesApi,
  fetchCityByIdApi,
  createCityApi,
  updateCityApi,
  fetchCityVersionApi,
  approveCityApi,
  rejectCityApi,
  blockCityApi,
  freezeCityApi,
  unFreezeCityApi,
  fetchCityVersionDetailsApi,
  fetchCityLatestVersionApi,
  deleteCityApi
};
