import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum InstrumentBatchReduxTypes {
    FETCH_INSTRUMENT_BATCHES = '@@auth/FETCH_INSTRUMENT_BATCHES',
    FETCH_INSTRUMENT_BATCHES_SUCCESS = '@@auth/FETCH_INSTRUMENT_BATCHES_SUCCESS',
    FETCH_INSTRUMENT_BATCHES_ERROR = '@@auth/FETCH_INSTRUMENT_BATCHES_ERROR',

    FETCH_INSTRUMENT_BATCH = '@@auth/FETCH_INSTRUMENT_BATCH',
    FETCH_INSTRUMENT_BATCH_SUCCESS = '@@auth/FETCH_INSTRUMENT_BATCH_SUCCESS',
    FETCH_INSTRUMENT_BATCH_ERROR = '@@auth/FETCH_INSTRUMENT_BATCH_ERROR',

    UPLOAD_INSTRUMENT_BATCHES = '@@auth/UPLOAD_INSTRUMENT_BATCHES',
    UPLOAD_INSTRUMENT_BATCHES_SUCCESS = '@@auth/UPLOAD_INSTRUMENT_BATCHES_SUCCESS',
    UPLOAD_INSTRUMENT_BATCHES_ERROR = '@@auth/UPLOAD_INSTRUMENT_BATCHES_ERROR',

    FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS = "@@auth/FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS",
    FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_SUCCESS = "@@auth/FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_SUCCESS",
    FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_ERROR = "@@auth/FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_ERROR",

    RESET = '@@auth/RESET',
}

export type InstrumentBatchActionDispatch = {
    type:
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH
    | InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS
    | InstrumentBatchReduxTypes.RESET;
    payload: any;
};

export type InstrumentBatchReducerDispatch = {
    type:
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_SUCCESS
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCHES_ERROR
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_SUCCESS
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_ERROR
    | InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES
    | InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_SUCCESS
    | InstrumentBatchReduxTypes.UPLOAD_INSTRUMENT_BATCHES_ERROR
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_SUCCESS
    | InstrumentBatchReduxTypes.FETCH_INSTRUMENT_BATCH_AUDIT_ACTIONS_ERROR
    | InstrumentBatchReduxTypes.RESET;
    payload: {
        data?: FetchApiResponse<instrumnetBatch>;
        error?: string;
    };
};

export type InstrumentBatchReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<instrumnetBatch>;
    error?: string | null;
}
export type InstrumentBatchUploadReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<instrumnetBatch>;
    error?: string | null;
}


export type instrumnetBatch = {

    "id": string,
    "externalId": string,
    "userStatus": string,
    "clientReferenceNumber": string,
    "financingProgramExternalId": string,
    "batchingStrategy": string,
    "processingStatus": string,
    "instrumentSource": string,
    "inputFile": string,
    "outputFile": string,
    "errorText": string,
    "totalInstrumentCount": number,
    "active": boolean,
    "createdOn": Date,
    "updatedOn": Date,
    "instruments": [instrument],
}

export type instrument =
    {
        "id": string,
        "externalId": string,
        "instrumentBatchId": string,
        "counterPartyExternalId": string,
        "currencyShortCode": string,
        "corporateCode": string,
        "instrumentNumber": string,
        "instrumentDate": string,
        "dueDate": string,
        "receivedDate": string,
        "instrumentAmount": number,
        "additionalDetails": string,
        "docType": string,
        "ifscCode": string,
        "accountNumber": string,
        "userRemarks": string,
        "processingStatus": string,
        "userStatus": string,
        "errorText": string,
        "active": true,
        "createdOn": Date,
        "updatedOn": Date,
    }

export type FetchInstumentBatchesRequest = {
    payload: FilterMetadata;
    type: string;
};
export type FetchInstumentBatchRequest = {
    payload: string;
    type: string;
};
export type IntrumentBatchUploadRequest = {
    payload: any;
    type: string;
}