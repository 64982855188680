import { SagaIterator } from "redux-saga";
import { ClientSummaryReduxTypes, CreateClientSummaryRequest } from "./types";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createClientSummaryApi } from "./api";

function* createClientSummary({ payload }: CreateClientSummaryRequest): SagaIterator {
    try {
        const response = yield call(createClientSummaryApi, payload);
        yield put({
            type: ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_ERROR,
            payload: { error: error }
        })
    }
}
export function* watchCreateClientSummary() {
    yield takeEvery(ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT, createClientSummary)
}

function* clientSummarySaga() {
    yield fork(watchCreateClientSummary)
}
export default clientSummarySaga;