import {
  CreateCustomerBeneficiaryProgrammeReduxState,
  CustomerBeneficiaryProgrammeReducerDispatch,
  CustomerBeneficiaryProgrammeReduxState,
  CustomerBeneficiaryProgrammeReduxTypes
} from "./types";

const CUSTOMER_BENEFICIARY_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const CustomerBeneficiaryProgrammeListReducer = (
  state: CustomerBeneficiaryProgrammeReduxState = CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CUSTOMER_BENEFICIARY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const CreateCustomerBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryProgrammeVersionListReducer = (
  state: CustomerBeneficiaryProgrammeReduxState = CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_ERROR: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeBeneficiaryProgrammeReducer = (
  state: CreateCustomerBeneficiaryProgrammeReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryProgrammeReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryProgrammeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CustomerBeneficiaryProgrammeListReducer,
  CreateCustomerBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeReducer,
  UpdateBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeVersionListReducer,
  AproveBeneficiaryProgrammeReducer,
  RejectBeneficiaryProgrammeReducer,
  BlockBeneficiaryProgrammeReducer,
  FreezeBeneficiaryProgrammeReducer,
  UnFreezeBeneficiaryProgrammeReducer
};
