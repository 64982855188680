import { CreateProgrammeSummaryReduxState, ProgrammeSummaryReducerDispatch, ProgrammeSummaryReduxTypes } from "./types"

const CREATE_PROGRAMME_SUMMARY_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateProgrammeSummaryReducer = (state: CreateProgrammeSummaryReduxState = CREATE_PROGRAMME_SUMMARY_INIT_STATE, action: ProgrammeSummaryReducerDispatch) => {
    switch (action.type) {
        case ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT:

            return {
                ...state,
                loading: true
            }
        case ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_SUCCESS:

            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        case ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_ERROR:

            return {
                ...state,
                error: action.payload.error,
                loading: false
            }
        default:
            return { ...state }
    }
}

export { CreateProgrammeSummaryReducer }