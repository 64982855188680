import { CreateStateReduxState, StateReducerDispatch, StateReduxState, StateReduxTypes } from "./types";

const STATE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const StateListReducer = (state: StateReduxState = STATE_LIST_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FETCH_STATES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StateReduxTypes.FETCH_STATES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FETCH_STATES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_STATE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.CREATE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.CREATE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.UPDATE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.UPDATE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET_UPDATE_STATE:
      return {
        ...state,
        error: null,
        loading: false
      };
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeleteStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.DELETE_STATE: {
      return {
        ...state,
        loading: true
      };
    }

    case StateReduxTypes.DELETE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.DELETE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET_UPDATE_STATE:
      return {
        ...state,
        error: null,
        loading: false
      };
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FETCH_STATE_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case StateReduxTypes.FETCH_STATE_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FETCH_STATE_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StateVersionDetailsReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FETCH_STATE_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case StateReduxTypes.FETCH_STATE_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FETCH_STATE_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StateListVersionReducer = (state: StateReduxState = STATE_LIST_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FETCH_STATE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FETCH_STATE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const StateLatestVersionReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FETCH_STATE_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case StateReduxTypes.FETCH_STATE_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FETCH_STATE_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.APPROVE_STATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StateReduxTypes.APPROVE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.APPROVE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.REJECT_STATE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case StateReduxTypes.REJECT_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.REJECT_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.BLOCK_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.BLOCK_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.FREEZE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.FREEZE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeStateReducer = (state: CreateStateReduxState = CREATE_STATE_INIT_STATE, action: StateReducerDispatch) => {
  switch (action.type) {
    case StateReduxTypes.UNFREEZE_STATE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case StateReduxTypes.UNFREEZE_STATE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case StateReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  StateListReducer,
  CreateStateReducer,
  StateReducer,
  UpdateStateReducer,
  StateListVersionReducer,
  ApproveStateReducer,
  RejectStateReducer,
  BlockStateReducer,
  FreezeStateReducer,
  UnFreezeStateReducer,
  StateVersionDetailsReducer,
  StateLatestVersionReducer,
  DeleteStateReducer
};
