import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum InstrumentInterestAccrualReduxTypes {
  FETCH_INTEREST_ACCRUALS = "@@auth/FETCH_INTEREST_ACCRUALS",
  FETCH_INTEREST_ACCRUALS_SUCCESS = "@@auth/FETCH_INTEREST_ACCRUALS_SUCCESS",
  FETCH_INTEREST_ACCRUALS_ERROR = "@@auth/FETCH_INTEREST_ACCRUALS_ERROR",

  FETCH_INTEREST_ACCRUAL = "@@auth/FETCH_INTEREST_ACCRUAL",
  FETCH_INTEREST_ACCRUAL_SUCCESS = "@@auth/FETCH_INTEREST_ACCRUAL_SUCCESS",
  FETCH_INTEREST_ACCRUAL_ERROR = "@@auth/FETCH_INTEREST_ACCRUAL_ERROR",

  RESET = "@@auth/RESET"
}

export type InstrumentInterestAccrualActionDispatch = {
  type: InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL | InstrumentInterestAccrualReduxTypes.RESET;
  payload: any;
};

export type InstrumentInterestAccrualReducerDispatch = {
  type:
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS_SUCCESS
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUALS_ERROR
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL_SUCCESS
    | InstrumentInterestAccrualReduxTypes.FETCH_INTEREST_ACCRUAL_ERROR
    | InstrumentInterestAccrualReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<InterestAccrual>;
    error?: string;
  };
};

export type InstrumentInterestAccrualReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<InterestAccrual>;
  error?: string | null;
};

export type InterestAccrualFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type InterestAccrual = {
  id: string;
  externalId: string;
  instrumentBatchId: string;
  counterPartyExternalId: string;
  currencyShortCode: string;
  corporateCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  dueDate: string;
  receivedDate: string;
  instrumentAmount: number;
  additionalDetails: string;
  docType: string;
  ifscCode: string;
  accountNumber: string;
  userRemarks: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  active: true;
  createdOn: Date;
  updatedOn: Date;
};
