import config from "config";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();
function createLimitReportApi(params: any) {
    let apiData: any = {
        programExternalId: params.programmeId.length ? params.programmeId : null,
        counterpartyExternalId: params.customerId.length ? params.customerId : null,

    }

    let backendApiUrl = `${config.BACKEND_BASE_URL}/report/limit`
    return api.create(`${backendApiUrl}`, apiData)
}

export { createLimitReportApi }