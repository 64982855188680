import config from "config";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();
function createRepaymentAdviceApi(params: any) {
    let apiData: any = {
        startDate: params.startDate,
        endDate: params.endDate,
        programExternalId: params.programmeId.length ? params.programmeId : null,
        counterpartyExternalId: params.customerId.length ? params.customerId : null,
        anchorExternalId: params.anchorId.length ? params.anchorId : null,
        productExternalId: params.productType && params.productType.value.length ? params.productType.value : null
    }
    let backendApiUrl = `${config.BACKEND_BASE_URL}/report/repayment-advice`
    return api.create(`${backendApiUrl}`, apiData)
}
export { createRepaymentAdviceApi }