import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  blockFinancingProgram,
  BlockFinancingProgramRequest,
  CreateFinancingProgramRequest,
  FinancingProgramFetchRequest,
  FinancingProgramReduxTypes,
  FinancingProgramVersionChangeRequest,
  UpdateFinancingProgramRequest
} from "./types";
import { FetchFinancierUserByExternalIdRequest } from "redux/financierUser/types";
import {
  approveFinancingProgramApi,
  blockFinancingProgramApi,
  createFinancingProgramApi,
  deleteFinancingProgramApi,
  fetchFinancingProgramApi,
  fetchFinancingProgrameConfigApi,
  fetchFinancingProgramLatestVersionApi,
  fetchFinancingProgramsApi,
  fetchFinancingProgramVersionApi,
  fetchFinancingProgramVersionDetailsApi,
  freezeFinancingProgramApi,
  generateloanAccountNumberApi,
  rejectFinancingProgramApi,
  unFreezeFinancingProgramApi,
  updateFinancingProgramApi,
  validateFinancingProgramApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchFinancingPrograms({ payload, type }: FinancingProgramFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramsApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteFinancingPrograms({ payload, type }: FinancingProgramFetchRequest): SagaIterator {
  try {
    const response = yield call(deleteFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgramsConfig({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgrameConfigApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgramsIds({ payload, type }: FinancingProgramFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramsApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_ERROR,
      payload: { error: error }
    });
  }
}

function* validateCustomer({ payload, type }: CreateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(validateFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* createFinancingProgram({ payload, type }: CreateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(createFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* generateLoanAccountNumberFinancingProgram({ payload, type }: CreateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(generateloanAccountNumberApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR,
      payload: { error: error }
    });
  }
}

function* updateFinancingProgram({ payload, type }: UpdateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(updateFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgram({ payload, type }: FetchFinancierUserByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgramVersions({ payload, type }: UpdateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramVersionApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgramLatestVersion({ payload, type }: UpdateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramLatestVersionApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancingProgramVersionsDetails({ payload, type }: UpdateFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancingProgramVersionDetailsApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveFinancingProgram({ payload, type }: FinancingProgramVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectFinanicingProgram({ payload, type }: FinancingProgramVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* financingProgramBlock({ payload, type }: BlockFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(blockFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.BLOCK_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.BLOCK_ERROR,
      payload: { error: error }
    });
  }
}

function* financingProgramFreeze({ payload, type }: BlockFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(freezeFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

function* unFinancingProgramFreeze({ payload, type }: BlockFinancingProgramRequest): SagaIterator {
  try {
    const response = yield call(unFreezeFinancingProgramApi, payload);

    yield put({
      type: FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchFinancingPrograms() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS, fetchFinancingPrograms);
}

export function* watchFetchFinancingProgram() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM, fetchFinancingProgram);
}

export function* watchFetchFinancingProgramVersions() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION, fetchFinancingProgramVersions);
}

export function* watchApproveCutomerVersion() {
  yield takeEvery(FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM, approveFinancingProgram);
}
export function* watchRejectCutomerVersion() {
  yield takeEvery(FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM, rejectFinanicingProgram);
}

export function* watchValidteCustomer() {
  yield takeEvery(FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM, validateCustomer);
}

export function* watchCreateFinancingProgram() {
  yield takeEvery(FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM, createFinancingProgram);
}

export function* watchUpdateFinancingProgram() {
  yield takeEvery(FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM, updateFinancingProgram);
}

export function* watchFinancigProgramBlock() {
  yield takeEvery(FinancingProgramReduxTypes.BLOCK, financingProgramBlock);
}

export function* watchFinancigProgramFreeze() {
  yield takeEvery(FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM, financingProgramFreeze);
}

export function* watchUnFinancigProgramFreeze() {
  yield takeEvery(FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM, unFinancingProgramFreeze);
}

export function* watchFetchFinancingProgramsIds() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS, fetchFinancingProgramsIds);
}

export function* watchfetchFinancingProgramsConfig() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG, fetchFinancingProgramsConfig);
}

export function* watchFetchFinancingProgramVersionsDetails() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS, fetchFinancingProgramVersionsDetails);
}

export function* watchFetchFinancingProgramLatestVersion() {
  yield takeEvery(FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION, fetchFinancingProgramLatestVersion);
}

export function* watchDeleteFinancingPrograms() {
  yield takeEvery(FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM, deleteFinancingPrograms);
}
export function* watchGenerateLoanAccountNumberFinancingProgram() {
  yield takeEvery(FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS, generateLoanAccountNumberFinancingProgram);
}

function* financingProgramSaga() {
  yield all([
    fork(watchFetchFinancingPrograms),
    fork(watchFetchFinancingProgram),
    fork(watchFetchFinancingProgramVersions),
    fork(watchApproveCutomerVersion),
    fork(watchRejectCutomerVersion),
    fork(watchValidteCustomer),
    fork(watchCreateFinancingProgram),
    fork(watchUpdateFinancingProgram),
    fork(watchFinancigProgramBlock),
    fork(watchFinancigProgramFreeze),
    fork(watchUnFinancigProgramFreeze),
    fork(watchFetchFinancingProgramsIds),
    fork(watchfetchFinancingProgramsConfig),
    fork(watchFetchFinancingProgramVersionsDetails),
    fork(watchFetchFinancingProgramLatestVersion),
    fork(watchDeleteFinancingPrograms),
    fork(watchGenerateLoanAccountNumberFinancingProgram)
  ]);
}

export default financingProgramSaga;
