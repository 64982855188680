import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum AddressReduxTypes {
  FETCH_ADDRESSES = "@@auth/FETCH_ADDRESSES",
  FETCH_ADDRESSES_SUCCESS = "@@auth/FETCH_ADDRESSES_SUCCESS",
  FETCH_ADDRESSES_ERROR = "@@auth/FETCH_ADDRESSES_ERROR",

  CREATE_ADDRESSES = "@@auth/CREATE_ADDRESSES",
  CREATE_ADDRESSES_SUCCESS = "@@auth/CREATE_ADDRESSES_SUCCESS",
  CREATE_ADDRESSES_ERROR = "@@auth/CREATE_ADDRESSES_ERROR",


  DELETE_ADDRESSES = "@@auth/DELETE_ADDRESSES",
  DELETE_ADDRESSES_SUCCESS = "@@auth/DELETE_ADDRESSES_SUCCESS",
  DELETE_ADDRESSES_ERROR = "@@auth/DELETE_ADDRESSES_ERROR",


  FETCH_BYID_ADDRESSES = "@@auth/FETCH_BYID_ADDRESSES",
  FETCH_BYID_ADDRESSES_SUCCESS = "@@auth/FETCH_BYID_ADDRESSES_SUCCESS",
  FETCH_BYID_ADDRESSES_ERROR = "@@auth/FETCH_BYID_ADDRESSES_ERROR",

  UPDATE_ADDRESSES = "@@auth/UPDATE_ADDRESSES",
  UPDATE_ADDRESSES_SUCCESS = "@@auth/UPDATE_ADDRESSES_SUCCESS",
  UPDATE_ADDRESSES_ERROR = "@@auth/UPDATE_ADDRESSES_ERROR",

  FETCH_ADDRESSES_VERSION = "@@auth/FETCH_ADDRESSES_VERSION",
  FETCH_ADDRESSES_VERSION_SUCCESS = "@@auth/FETCH_ADDRESSES_VERSION_SUCCESS",
  FETCH_ADDRESSES_VERSION_ERROR = "@@auth/FETCH_ADDRESSES_VERSION_ERROR",

  FETCH_ADDRESSES_LATEST_VERSION = "@@auth/FETCH_ADDRESSES_LATEST_VERSION",
  FETCH_ADDRESSES_LATEST_VERSION_SUCCESS = "@@auth/FETCH_ADDRESSES_LATEST_VERSION_SUCCESS",
  FETCH_ADDRESSES_LATEST_VERSION_ERROR = "@@auth/FETCH_ADDRESSES_LATEST_VERSION_ERROR",

  FETCH_ADDRESSES_VERSION_DETAILS = "@@auth/FETCH_ADDRESSES_VERSION_DETAILS",
  FETCH_ADDRESSES_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_ADDRESSES_VERSION_DETAILS_SUCCESS",
  FETCH_ADDRESSES_VERSION_DETAILS_ERROR = "@@auth/FETCH_ADDRESSES_VERSION_DETAILS_ERROR",

  APROVE_ADDRESSES = "@@auth/APROVE_ADDRESSES",
  APROVE_ADDRESSES_SUCCESS = "@@auth/APROVE_ADDRESSES_SUCCESS",
  APROVE_ADDRESSES_ERROR = "@@auth/APROVE_ADDRESSES_ERROR",

  REJECT_ADDRESSES = "@@auth/REJECT_ADDRESSES",
  REJECT_ADDRESSES_SUCCESS = "@@auth/REJECT_ADDRESSES_SUCCESS",
  REJECT_ADDRESSES_ERROR = "@@auth/REJECT_ADDRESSES_ERROR",

  BLOCK_ADDRESSES = "@@auth/BLOCK_ADDRESSES",
  BLOCK_ADDRESSES_SUCCESS = "@@auth/BLOCK_ADDRESSES_SUCCESS",
  BLOCK_ADDRESSES_ERROR = "@@auth/BLOCK_ADDRESSES_ERROR",

  FREEZE_ADDRESSES = "@@auth/FREEZE_ADDRESSES",
  FREEZE_ADDRESSES_SUCCESS = "@@auth/FREEZE_ADDRESSES_SUCCESS",
  FREEZE_ADDRESSES_ERROR = "@@auth/FREEZE_ADDRESSES_ERROR",

  UNFREEZE_ADDRESSES = "@@auth/UNFREEZE_ADDRESSES",
  UNFREEZE_ADDRESSES_SUCCESS = "@@auth/UNFREEZE_ADDRESSES_SUCCESS",
  UNFREEZE_ADDRESSES_ERROR = "@@auth/UNFREEZE_ADDRESSES_ERROR",

  RESET = "@@auth/RESET"
}

export type AddressActionDispatch = {
  type:
    | AddressReduxTypes.FETCH_ADDRESSES
    | AddressReduxTypes.CREATE_ADDRESSES
    | AddressReduxTypes.FETCH_BYID_ADDRESSES
    | AddressReduxTypes.UPDATE_ADDRESSES
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS
    | AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION
    | AddressReduxTypes.APROVE_ADDRESSES
    | AddressReduxTypes.REJECT_ADDRESSES
    | AddressReduxTypes.BLOCK_ADDRESSES
    | AddressReduxTypes.FREEZE_ADDRESSES
    | AddressReduxTypes.UNFREEZE_ADDRESSES
    | AddressReduxTypes.DELETE_ADDRESSES

    | AddressReduxTypes.RESET;
  payload: any;
};
export type AddressReducerDispatch = {
  type:
    | AddressReduxTypes.FETCH_ADDRESSES
    | AddressReduxTypes.FETCH_ADDRESSES_SUCCESS
    | AddressReduxTypes.FETCH_ADDRESSES_ERROR
    | AddressReduxTypes.CREATE_ADDRESSES_SUCCESS
    | AddressReduxTypes.CREATE_ADDRESSES_ERROR
    | AddressReduxTypes.FETCH_BYID_ADDRESSES_SUCCESS
    | AddressReduxTypes.FETCH_BYID_ADDRESSES_ERROR
    | AddressReduxTypes.UPDATE_ADDRESSES_SUCCESS
    | AddressReduxTypes.UPDATE_ADDRESSES_ERROR
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_SUCCESS
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_ERROR
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_SUCCESS
    | AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_ERROR
    | AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION
    | AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_SUCCESS
    | AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_ERROR
    | AddressReduxTypes.APROVE_ADDRESSES_SUCCESS
    | AddressReduxTypes.APROVE_ADDRESSES_ERROR
    | AddressReduxTypes.REJECT_ADDRESSES_SUCCESS
    | AddressReduxTypes.REJECT_ADDRESSES_ERROR
    | AddressReduxTypes.BLOCK_ADDRESSES_SUCCESS
    | AddressReduxTypes.BLOCK_ADDRESSES_ERROR
    | AddressReduxTypes.FREEZE_ADDRESSES_SUCCESS
    | AddressReduxTypes.FREEZE_ADDRESSES_ERROR
    | AddressReduxTypes.UNFREEZE_ADDRESSES_SUCCESS
    | AddressReduxTypes.UNFREEZE_ADDRESSES_ERROR
    | AddressReduxTypes.DELETE_ADDRESSES
    | AddressReduxTypes.DELETE_ADDRESSES_SUCCESS
    | AddressReduxTypes.DELETE_ADDRESSES_ERROR

    | AddressReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Address>;
    error?: string;
  };
};

export type Address = {
  id: string;
  floorNumber: string;
  doorNumber: string;
  building: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  location: string;
  addressType: string;
  customerId: string;
};

export type AddressFetchRequest = {
  payload: FilterMetadata;
  type: string;
  customerId: string;
};

export type CreateAddressReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<AddresCreationRequest>;
  error?: string | null;
};

export type AddressReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Address>;
  error?: string | null;
};

export type AddresCreationRequest = {
  floorNumber: string;
  doorNumber: string;
  building: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  location: string;
  addressType: string;
  customerId: string;
  draft: boolean;
  makerRemarks: string;
};

export type CreateAddressRequest = {
  payload: AddresCreationRequest;
  type: string;
};

export type FetchByIdAddressRequest = {
  payload: string;
  type: string;
};
