import { ClientSummaryReducerDispatch, ClientSummaryReduxTypes, CreateClientSummaryReduxState } from "./types"

const CREATE_CLIENT_SUMMARY_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateClientSummaryReducer = (state: CreateClientSummaryReduxState = CREATE_CLIENT_SUMMARY_INIT_STATE, action: ClientSummaryReducerDispatch) => {
    switch (action.type) {
        case ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT: {
            return {
                ...state,
                loading: true
            }
        }
        case ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        }
        case ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_ERROR: {
            return {
                data: {},
                error: action.payload.error,
                loading: false
            }
        }
        default:
            return { ...state };
    }
}
export { CreateClientSummaryReducer }