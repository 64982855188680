import config from "config";
export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
};
const userSession = sessionStorage.getItem("vayana_lms_user");
const isAdminMaker = userSession ? JSON.parse(userSession ? userSession : "").roles.includes("Admin Maker") : "";
const getDisabledMenuItem = (disablemenu: string) => {
  const disableMenu = disablemenu.split(",")
  return disableMenu
}
const filterMenuItem = (items: MenuItemType[], disablemenu: string) => {
  const disableMenuItem = getDisabledMenuItem(disablemenu)
  return items.filter(item => !disableMenuItem.includes(item.key)).map((item) => {
    if (item.children) {
      item.children = filterMenuItem(item.children, disablemenu)
    }
    return item;
  })
}
const MENU_ITEMS: MenuItemType[] = filterMenuItem([
  {
    key: "ds-analytics",
    label: "Dashboard",
    url: "/dashboard/my",
    icon: "uil-home-alt"
  },
  { key: "navigation", label: "Setup", isTitle: true },
  {
    key: "master",
    label: "Masters",
    isTitle: false,
    icon: "uil-box",
    // badge: { variant: 'success', text: '4' },
    children: [
      {
        key: "User",
        label: "User Management",
        url: "/master/users",
        parentKey: "master"
      },
      {
        key: "Role",
        label: "User Role",
        url: "/master/roles",
        parentKey: "master"
      },
      {
        key: "Currency",
        label: "Currencies",
        url: "/master/currencies",
        parentKey: "master"
      },
      {
        key: "BaseRate",
        label: "Base Rates",
        url: "/master/base-rates",
        parentKey: "master"
      },
      {
        key: "Holiday",
        label: "Holidays",
        url: "/master/holidays",
        parentKey: "master"
      },
      {
        key: "HolidayBatch",
        label: "Holiday Batch",
        url: "/master/holiday-batch",
        parentKey: "master"
      },
      {
        key: "Branch",
        label: "Branch",
        url: "/master/branch",
        parentKey: "master"
      },
      {
        key: "Country",
        label: "Country",
        url: "/master/country",
        parentKey: "master"
      },
      {
        key: "State",
        label: "State",
        url: "/master/state",
        parentKey: "master"
      },
      {
        key: "City",
        label: "City",
        url: "/master/city",
        parentKey: "master"
      },
      {
        key: "RelationshipManagers",
        label: "RelationshipManagers",
        url: "/master/relationshipManagers",
        parentKey: "master"
      },
      {
        key: "LOV",
        label: "LOV",
        url: "/master/lov",
        parentKey: "master"
      },
      {
        key: "ProductType",
        label: "Product Type",
        url: "/master/product-type",
        parentKey: "master"
      },
      {
        key: "Charge",
        label: "Charge",
        url: "/master/charge",
        parentKey: "master"
      },
      {
        key: "GlAccount",
        label: "Gl Account",
        url: "/master/glaccount",
        parentKey: "master"
      }
    ]
  },
  {
    key: "registration",
    label: "Registration",
    isTitle: false,
    icon: "uil-document-layout-center",
    // badge: { variant: 'success', text: '4' },
    children: [
      {
        key: "Customers",
        label: "Customers",
        url: "/registration/customers",
        parentKey: "registration"
      },
      {
        key: "LimitConfig",
        label: "Limit Configuration",
        url: "/registration/limit-config",
        parentKey: "registration"
      },
      {
        key: "Programme",
        label: "Programme",
        url: "/registration/programme",
        parentKey: "registration"
      },
      {
        key: "BuyerSeller",
        label: "Buyer Seller",
        url: "/registration/buyer-seller",
        parentKey: "registration"
      }
    ]
  },
  {
    key: "transaction",
    label: "Transaction",
    isTitle: false,
    icon: "uil-clipboard-alt",
    // badge: { variant: 'success', text: '4' },
    children: [
      {
        key: "InstrumentBatch",
        label: "Instrument Batch",
        url: "/transaction/instrument-batch",
        parentKey: "transaction"
      },
      {
        key: "Instrument",
        label: "Instrument",
        url: "/transaction/instrument",
        parentKey: "transaction"
      },
      {
        key: "StagingBatch",
        label: "Staging Batch",
        url: "/transaction/staging-batch",
        parentKey: "transaction"
      },
      {
        key: "StagingInstrument",
        label: "Staging Instrument",
        url: "/transaction/staging-instrument",
        parentKey: "transaction"
      },
      {
        key: "PaymentBatch",
        label: "Payment Batch",
        url: "/transaction/payment-batch",
        parentKey: "transaction"
      },
      {
        key: "PaymentInstrument",
        label: "Payment Instrument",
        url: "/transaction/payment-instrument",
        parentKey: "transaction"
      },
      {
        key: "InterestAccrual",
        label: "Daily Interest Accrual",
        url: "/transaction/interest-accrual",
        parentKey: "transaction"
      },
      {
        key: "InstrumentBalancesDailyHistory",
        label: "Instrument Balances Daily History",
        url: "/transaction/instrument-balances-daily-history",
        parentKey: "transaction"
      },
      {
        key: "DailyBalances",
        label: "Instrument Balances",
        url: "/transaction/daily-balances",
        parentKey: "transaction"
      },
      {
        key: "DisbursementAuthorization",
        label: "Disbursement Authorization",
        url: "/transaction/disbursement-authorization",
        parentKey: "transaction"
      }
    ]
  },
  {
    key: "repayment",
    label: "Repayment",
    isTitle: false,
    icon: "uil-chart",
    // badge: { variant: 'success', text: '4' },
    children: [
      {
        key: "repaymentRequest",
        label: "Repayment Request",
        url: "/repayment/repayment-request",
        parentKey: "repayment"
      },
      {
        key: "repaymentSattlement",
        label: "Repayment Instrument Settlement Request",
        url: "/repayment/repayment-sattlement",
        parentKey: "repayment"
      },
      {
        key: "excessMoney",
        label: "Excess Money",
        url: "/repayment/excess-money",
        parentKey: "repayment"
      }
    ]
  },
  {
    key: "reports",
    label: "Reports",
    isTitle: false,
    icon: "uil-file-chart",
    children: [
      {
        key: "DisbursementAdvice",
        label: "Disbursement Advice",
        url: "/report/disbursement-advice/create",
        parentKey: "reports"
      },
      {
        key: "LimitReport",
        label: "Limit Report",
        url: "/report/limit/create",
        parentKey: "reports"
      },
      {
        key: "ClientSummary",
        label: "Client Summary",
        url: "/report/client-summary/create",
        parentKey: "reports"
      },
      {
        key: "InterestComputation",
        label: "Interest Computation",
        url: "/report/interest-computation/create",
        parentKey: "reports"
      },
      {
        key: "RepaymentAdvice",
        label: "Repayment Advice",
        url: "/report/repayment-advice/create",
        parentKey: "reports"
      },
      {
        key: "ProgrammeSummary",
        label: "Programme Summary",
        url: '/report/program-summary/create',
        parentKey: "reports"
      }
    ]
  },
  {
    key: "system-info",
    label: "System Info",
    isTitle: false,
    icon: "uil-rss",
    // badge: { variant: 'success', text: '4' },
    children: [
      {
        key: "SystemInfo",
        label: "Login Logs",
        url: "/system-info/login-info",
        parentKey: "system-info"
      }
    ]
  }
], config.DISABLED_MENU);
export { MENU_ITEMS };