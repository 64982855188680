import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum PaymentBatchReduxTypes {
  FETCH_PAYMENT_BATCHES = "@@auth/FETCH_PAYMENT_BATCHES",
  FETCH_PAYMENT_BATCHES_SUCCESS = "@@auth/FETCH_PAYMENT_BATCHES_SUCCESS",
  FETCH_PAYMENT_BATCHES_ERROR = "@@auth/FETCH_PAYMENT_BATCHES_ERROR",

  FETCH_PAYMENT_BATCH = "@@auth/FETCH_PAYMENT_BATCH",
  FETCH_PAYMENT_BATCH_SUCCESS = "@@auth/FETCH_PAYMENT_BATCH_SUCCESS",
  FETCH_PAYMENT_BATCH_ERROR = "@@auth/FETCH_PAYMENT_BATCH_ERROR",

  ACTION_PAYMENT_BATCH = "@@auth/ACTION_PAYMENT_BATCH",
  ACTION_PAYMENT_BATCH_SUCCESS = "@@auth/ACTION_PAYMENT_BATCH_SUCCESS",
  ACTION_PAYMENT_BATCH_ERROR = "@@auth/ACTION_PAYMENT_BATCH_ERROR",

  FETCH_VERSION_PAYMENT_BATCHES = "@@auth/FETCH_VERSION_PAYMENT_BATCHES",
  FETCH_VERSION_PAYMENT_BATCHES_SUCCESS = "@@auth/FETCH_VERSION_PAYMENT_BATCHES_SUCCESS",
  FETCH_VERSION_PAYMENT_BATCHES_ERROR = "@@auth/FETCH_VERSION_PAYMENT_BATCHES_ERROR",

  APPROVE_PAYMENT_BATCH = "@@auth/APPROVE_PAYMENT_BATCH",
  APPROVE_PAYMENT_BATCH_SUCCESS = "@@auth/APPROVE_PAYMENT_BATCH_SUCCESS",
  APPROVE_PAYMENT_BATCH_ERROR = "@@auth/APPROVE_PAYMENT_BATCH_ERROR",

  REJECT_PAYMENT_BATCH = "@@auth/REJECT_PAYMENT_BATCH",
  REJECT_PAYMENT_BATCH_SUCCESS = "@@auth/REJECT_PAYMENT_BATCH_SUCCESS",
  REJECT_PAYMENT_BATCH_ERROR = "@@auth/REJECT_PAYMENT_BATCH_ERROR",

  FETCH_PAYMENT_BATCH_AUDIT_ACTIONS = "@@auth/FETCH_PAYMENT_BATCH_AUDIT_ACTIONS",
  FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_SUCCESS = "@@auth/FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_SUCCESS",
  FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_ERROR = "@@auth/FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_ERROR",

  RESET = "@@auth/RESET"
}

export type PaymentBatchActionDispatch = {
  type:
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES
    | PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH
    | PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH
    | PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH
    | PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS
    | PaymentBatchReduxTypes.RESET;
  payload: any;
};

export type PaymentBatchReducerDispatch = {
  type:
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_SUCCESS
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCHES_ERROR
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_SUCCESS
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_ERROR
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_SUCCESS
    | PaymentBatchReduxTypes.FETCH_PAYMENT_BATCH_AUDIT_ACTIONS_ERROR
    | PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH
    | PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_SUCCESS
    | PaymentBatchReduxTypes.ACTION_PAYMENT_BATCH_ERROR
    | PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH
    | PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_SUCCESS
    | PaymentBatchReduxTypes.APPROVE_PAYMENT_BATCH_ERROR
    | PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH
    | PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_SUCCESS
    | PaymentBatchReduxTypes.REJECT_PAYMENT_BATCH_ERROR
    | PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES
    | PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_SUCCESS
    | PaymentBatchReduxTypes.FETCH_VERSION_PAYMENT_BATCHES_ERROR
    | PaymentBatchReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<PaymentBatch>;
    error?: string;
  };
};

export type PaymentBatchReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<PaymentBatch>;
  error?: string | null;
};

export type PaymentBatch = {
  active: boolean;
  createdOn: string;
  errorText: string;
  externalId: string;
  id: string;
  paymentBatchingStrategy: string;
  processingStatus: string;
  retries: number;
  stagingBatchId: string;
  transactionReferenceNumber: string;
  updatedOn: string;
  userStatus: string;
  utrNumber: string;
};

export type FetchPaymentBatchesRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FetchPaymentBatchRequest = {
  payload: string;
  type: string;
};


export type reProcessingPaymentBatchRequest = {
  payload: Array<any>;
  type: string;
};

export type CreatePaymentBatchReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<PaymentBatch>;
  error?: string | null;
};