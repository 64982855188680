import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum GlAccountReduxTypes {
  FETCH_GL_ACCOUNT = "@@auth/FETCH_GL_ACCOUNT",
  FETCH_GL_ACCOUNT_SUCCESS = "@@auth/FETCH_GL_ACCOUNT_SUCCESS",
  FETCH_GL_ACCOUNT_ERROR = "@@auth/FETCH_GL_ACCOUNT_ERROR",

  CREATE_GL_ACCOUNT = "@@auth/CREATE_GL_ACCOUNT",
  CREATE_GL_ACCOUNT_SUCCESS = "@@auth/CREATE_GL_ACCOUNT_SUCCESS",
  CREATE_GL_ACCOUNT_ERROR = "@@auth/CREATE_GL_ACCOUNT_ERROR",

  FETCH_GL_ACCOUNT_BY_ID = "@@auth/FETCH_GL_ACCOUNT_BY_ID",
  FETCH_GL_ACCOUNT_BY_ID_SUCCESS = "@@auth/FETCH_GL_ACCOUNT_BY_ID_SUCCESS",
  FETCH_GL_ACCOUNT_BY_ID_ERROR = "@@auth/FETCH_GL_ACCOUNT_BY_ID_ERROR",

  UPDATE_GL_ACCOUNT = "@@auth/UPDATE_GL_ACCOUNT",
  UPDATE_GL_ACCOUNT_SUCCESS = "@@auth/UPDATE_GL_ACCOUNT_SUCCESS",
  UPDATE_GL_ACCOUNT_ERROR = "@@auth/UPDATE_GL_ACCOUNT_ERROR",
  RESET_UPDATE_GL_ACCOUNT = "@@auth/RESET_UPDATE_GL_ACCOUNT",

  SEARCH_FILTER_GlAccount = "@@auth/SEARCH_FILTER_GlAccount",
  SEARCH_FILTER_GlAccount_SUCCESS = "@@auth/SEARCH_FILTER_GlAccount_SUCCESS",
  SEARCH_FILTER_GlAccount_ERROR = "@@auth/SEARCH_FILTER_GlAccount_ERROR",

  FETCH_GL_ACCOUNT_VERSION = "@@auth/FETCH_GL_ACCOUNT_VERSION",
  FETCH_GL_ACCOUNT_VERSION_SUCCESS = "@@auth/FETCH_GL_ACCOUNT_VERSION_SUCCESS",
  FETCH_GL_ACCOUNT_VERSION_ERROR = "@@auth/FETCH_GL_ACCOUNT_VERSION_ERROR",

  FETCH_GL_ACCOUNT_LATEST_VERSION = "@@auth/FETCH_GL_ACCOUNT_LATEST_VERSION",
  FETCH_GL_ACCOUNT_LATEST_VERSION_SUCCESS = "@@auth/FETCH_GL_ACCOUNT_LATEST_VERSION_SUCCESS",
  FETCH_GL_ACCOUNT_LATEST_VERSION_ERROR = "@@auth/FETCH_GL_ACCOUNT_LATEST_VERSION_ERROR",

  FETCH_GL_ACCOUNT_VERSION_DETAILS = "@@auth/FETCH_GL_ACCOUNT_VERSION_DETAILS",
  FETCH_GL_ACCOUNT_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_GL_ACCOUNT_VERSION_DETAILS_SUCCESS",
  FETCH_GL_ACCOUNT_VERSION_DETAILS_ERROR = "@@auth/FETCH_GL_ACCOUNT_VERSION_DETAILS_ERROR",

  APROVE_GL_ACCOUNT = "@@auth/APROVE_GL_ACCOUNT",
  APROVE_GL_ACCOUNT_SUCCESS = "@@auth/APROVE_GL_ACCOUNT_SUCCESS",
  APROVE_GL_ACCOUNT_ERROR = "@@auth/APROVE_GL_ACCOUNT_ERROR",

  DELETE_GL_ACCOUNT = "@@auth/DELETE_GL_ACCOUNT",
  DELETE_GL_ACCOUNT_SUCCESS = "@@auth/DELETE_GL_ACCOUNT_SUCCESS",
  DELETE_GL_ACCOUNT_ERROR = "@@auth/DELETE_GL_ACCOUNT_ERROR",

  REJECT_GL_ACCOUNT = "@@auth/REJECT_GL_ACCOUNT",
  REJECT_GL_ACCOUNT_SUCCESS = "@@auth/REJECT_GL_ACCOUNT_SUCCESS",
  REJECT_GL_ACCOUNT_ERROR = "@@auth/REJECT_GL_ACCOUNT_ERROR",

  BLOCK_GL_ACCOUNT = "@@auth/BLOCK_GL_ACCOUNT",
  BLOCK_GL_ACCOUNT_SUCCESS = "@@auth/BLOCK_GL_ACCOUNT_SUCCESS",
  BLOCK_GL_ACCOUNT_ERROR = "@@auth/BLOCK_GL_ACCOUNT_ERROR",

  FREEZE_GL_ACCOUNT = "@@auth/FREEZE_GL_ACCOUNT",
  FREEZE_GL_ACCOUNT_SUCCESS = "@@auth/FREEZE_GL_ACCOUNT_SUCCESS",
  FREEZE_GL_ACCOUNT_ERROR = "@@auth/FREEZE_GL_ACCOUNT_ERROR",

  UNFREEZE_GL_ACCOUNT = "@@auth/UNFREEZE_GL_ACCOUNT",
  UNFREEZE_GL_ACCOUNT_SUCCESS = "@@auth/UNFREEZE_GL_ACCOUNT_SUCCESS",
  UNFREEZE_GL_ACCOUNT_ERROR = "@@auth/UNFREEZE_GL_ACCOUNT_ERROR",

  RESET = "@@auth/RESET"
}
export type GlAccountActionDispatch = {
  type:
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT
    | GlAccountReduxTypes.CREATE_GL_ACCOUNT
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID
    | GlAccountReduxTypes.UPDATE_GL_ACCOUNT
    | GlAccountReduxTypes.RESET_UPDATE_GL_ACCOUNT
    | GlAccountReduxTypes.SEARCH_FILTER_GlAccount
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION
    | GlAccountReduxTypes.APROVE_GL_ACCOUNT
    | GlAccountReduxTypes.REJECT_GL_ACCOUNT
    | GlAccountReduxTypes.BLOCK_GL_ACCOUNT
    | GlAccountReduxTypes.FREEZE_GL_ACCOUNT
    | GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT
    | GlAccountReduxTypes.DELETE_GL_ACCOUNT
    | GlAccountReduxTypes.RESET;
  payload: any;
};
export type GlAccountReducerDispatch = {
  type:
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.CREATE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.CREATE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_SUCCESS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_ERROR
    | GlAccountReduxTypes.UPDATE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.UPDATE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.RESET_UPDATE_GL_ACCOUNT
    | GlAccountReduxTypes.SEARCH_FILTER_GlAccount_SUCCESS
    | GlAccountReduxTypes.SEARCH_FILTER_GlAccount_ERROR
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_SUCCESS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_ERROR
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_SUCCESS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_ERROR
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_SUCCESS
    | GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_ERROR
    | GlAccountReduxTypes.APROVE_GL_ACCOUNT
    | GlAccountReduxTypes.APROVE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.APROVE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.REJECT_GL_ACCOUNT
    | GlAccountReduxTypes.REJECT_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.REJECT_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.BLOCK_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.BLOCK_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.FREEZE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.FREEZE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.DELETE_GL_ACCOUNT
    | GlAccountReduxTypes.DELETE_GL_ACCOUNT_SUCCESS
    | GlAccountReduxTypes.DELETE_GL_ACCOUNT_ERROR
    | GlAccountReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<GlAccount>;
    error?: string;
  };
};

export type GlAccountReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<GlAccount>;
  error?: string | null;
};

export type GlAccountFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateGlAccountRequestRequest = {
  payload: any;
  type: string;
};
export type GlAccount = {
  id: string;
  type: string;
  value: string;
  displayValue: string;
  isDefault: boolean;
  sequenceNumber: number;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};

export type CreateGlAccountReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<GlAccount>;
  error?: string | null;
};

export type FetchGlAccountByIdRequest = {
  payload: string;
  type: string;
};

export type UpdateGlAccount = {
  externalUserId: string;
  type: string;
  value: string;
  displayValue: string;
  isDefault: boolean;
  sequenceNumber: number;
};

export type UpdateGlAccountRequest = {
  payload: UpdateGlAccount;
  type: string;
};
