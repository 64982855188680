import config from "config";
import { FilterMetadata } from "redux/types";
import { APICore } from "helpers/api/apiCore";
import { CreateLov, UpdateLov } from "./types";

const api = new APICore();

function fetchLovApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values`;
  return api.get(`${baseUrl}`, params);
}

function createLovApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    type: params.type.value,
    value: params.value.trim(),
    displayValue: params.displayValue.trim(),
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/list-of-values`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/list-of-values?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values`;
  // return api.create(`${baseUrl}`, params);
}

function fetchLovApiById(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params}`;
  return api.get(`${baseUrl}`, {});
}

function updateLovApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    type: params.type.value,
    value: params.value.trim(),
    displayValue: params.displayValue.trim(),
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function searchfilterApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values`;

  if (params.type !== "") {
    return api.get(`${baseUrl}`, { pgsz: params.pgno || 10, pgno: params.pgsize || 1, "filters[type][$eqStr]": params.type });
  } else {
    return api.get(`${baseUrl}`, { "pgsz=10&pgno": 1 });
  }
  // return api.get(`${baseUrl}`,{"filters[name][$startsWithStr]":params.name});
}

function fetchLovTenorApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values`;
  return api.get(`${baseUrl}`, params);
}

function fetchLovVersionApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function fetchLovVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function fetchLovLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}


function deleteLovApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params}`;
  return api.delete(`${baseUrl}`);
}


function approveLOVApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectLOVApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function blockLOVApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeLOVApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeLOVApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/list-of-values/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchLovApi,
  createLovApi,
  fetchLovApiById,
  updateLovApi,
  searchfilterApi,
  fetchLovTenorApi,
  fetchLovVersionApi,
  approveLOVApi,
  rejectLOVApi,
  blockLOVApi,
  freezeLOVApi,
  unFreezeLOVApi,
  fetchLovVersionDetailsApi,
  fetchLovLatestVersionApi,
  deleteLovApi
};

