import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CountryReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_COUNTRIES = "@@auth/FETCH_COUNTRIES",
  FETCH_COUNTRIES_SUCCESS = "@@auth/FETCH_COUNTRIES_SUCCESS",
  FETCH_COUNTRIES_ERROR = "@@auth/FETCH_COUNTRIES_ERROR",

  FETCH_COUNTRY_BY_ID = "@@auth/FETCH_COUNTRY_BY_ID",
  FETCH_COUNTRY_BY_ID_SUCCESS = "@@auth/FETCH_COUNTRY_BY_ID_SUCCESS",
  FETCH_COUNTRY_BY_ID_ERROR = "@@auth/FETCH_COUNTRY_BY_ID_ERROR",

  CREATE_COUNTRY = "@@auth/CREATE_COUNTRY",
  CREATE_COUNTRY_SUCCESS = "@@auth/CREATE_COUNTRY_SUCCESS",
  CREATE_COUNTRY_ERROR = "@@auth/CREATE_COUNTRY_ERROR",

  UPDATE_COUNTRY = "@@auth/UPDATE_COUNTRY",
  UPDATE_COUNTRY_SUCCESS = "@@auth/UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_ERROR = "@@auth/UPDATE_COUNTRY_ERROR",
  RESET_UPDATE_COUNTRY = "@@auth/RESET_UPDATE_COUNTRY",


  DELETE_COUNTRY = "@@auth/DELETE_COUNTRY",
  DELETE_COUNTRY_SUCCESS = "@@auth/DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_ERROR = "@@auth/DELETE_COUNTRY_ERROR",

  FETCH_COUNTRIES_VERSIONS = "@@auth/FETCH_COUNTRIES_VERSIONS",
  FETCH_COUNTRIES_VERSIONS_SUCCESS = "@@auth/FETCH_COUNTRIES_VERSIONS_SUCCESS",
  FETCH_COUNTRIES_VERSIONS_ERROR = "@@auth/FETCH_COUNTRIES_VERSIONS_ERROR",

  FETCH_COUNTRIES_VERSIONS_DETAILS = "@@auth/FETCH_COUNTRIES_VERSIONS_DETAILS",
  FETCH_COUNTRIES_VERSIONS_DETAILS_SUCCESS = "@@auth/FETCH_COUNTRIES_VERSIONS_DETAILS_SUCCESS",
  FETCH_COUNTRIES_VERSIONS_DETAILS_ERROR = "@@auth/FETCH_COUNTRIES_VERSIONS_DETAILS_ERROR",

  FETCH_COUNTRIES_LATEST_VERSIONS = "@@auth/FETCH_COUNTRIES_LATEST_VERSIONS",
  FETCH_COUNTRIES_LATEST_VERSIONS_SUCCESS = "@@auth/FETCH_COUNTRIES_LATEST_VERSIONS_SUCCESS",
  FETCH_COUNTRIES_LATEST_VERSIONS_ERROR = "@@auth/FETCH_COUNTRIES_LATEST_VERSIONS_ERROR",

  APPROVE_COUNTRIES = "@@auth/APPROVE_COUNTRIES",
  APPROVE_COUNTRIES_SUCCESS = "@@auth/APPROVE_COUNTRIES_SUCCESS",
  APPROVE_COUNTRIES_ERROR = "@@auth/APPROVE_COUNTRIES_ERROR",

  REJECT_COUNTRIES = "@@auth/REJECT_COUNTRIES",
  REJECT_COUNTRIES_SUCCESS = "@@auth/REJECT_COUNTRIES_SUCCESS",
  REJECT_COUNTRIES_ERROR = "@@auth/REJECT_COUNTRIES_ERROR",

  BLOCK_COUNTRIES = "@@auth/BLOCK_COUNTRIES",
  BLOCK_COUNTRIES_SUCCESS = "@@auth/BLOCK_COUNTRIES_SUCCESS",
  BLOCK_COUNTRIES_ERROR = "@@auth/BLOCK_COUNTRIES_ERROR",

  FREEZE_COUNTRIES = "@@auth/FREEZE_COUNTRIES",
  FREEZE_COUNTRIES_SUCCESS = "@@auth/FREEZE_COUNTRIES_SUCCESS",
  FREEZE_COUNTRIES_ERROR = "@@auth/FREEZE_COUNTRIES_ERROR",

  UNFREEZE_COUNTRIES = "@@auth/UNFREEZE_COUNTRIES",
  UNFREEZE_COUNTRIES_SUCCESS = "@@auth/UNFREEZE_COUNTRIES_SUCCESS",
  UNFREEZE_COUNTRIES_ERROR = "@@auth/UNFREEZE_COUNTRIES_ERROR",

  RESET = "@@auth/RESET"
}

export type CountryActionDispatch = {
  type:
    | CountryReduxTypes.FETCH_COUNTRIES
    | CountryReduxTypes.FETCH_COUNTRY_BY_ID
    | CountryReduxTypes.CREATE_COUNTRY
    | CountryReduxTypes.UPDATE_COUNTRY
    | CountryReduxTypes.DELETE_COUNTRY

    | CountryReduxTypes.RESET_UPDATE_COUNTRY
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS
    | CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS
    | CountryReduxTypes.APPROVE_COUNTRIES
    | CountryReduxTypes.REJECT_COUNTRIES
    | CountryReduxTypes.BLOCK_COUNTRIES
    | CountryReduxTypes.FREEZE_COUNTRIES
    | CountryReduxTypes.UNFREEZE_COUNTRIES
    | CountryReduxTypes.RESET;
  payload: any;
};

export type CountryReducerDispatch = {
  type:
    | CountryReduxTypes.FETCH_COUNTRIES
    | CountryReduxTypes.FETCH_COUNTRIES_SUCCESS
    | CountryReduxTypes.FETCH_COUNTRIES_ERROR
    | CountryReduxTypes.FETCH_COUNTRY_BY_ID
    | CountryReduxTypes.FETCH_COUNTRY_BY_ID_SUCCESS
    | CountryReduxTypes.FETCH_COUNTRY_BY_ID_ERROR
    | CountryReduxTypes.CREATE_COUNTRY_SUCCESS
    | CountryReduxTypes.CREATE_COUNTRY_ERROR
    | CountryReduxTypes.UPDATE_COUNTRY_SUCCESS
    | CountryReduxTypes.UPDATE_COUNTRY_ERROR
    | CountryReduxTypes.RESET_UPDATE_COUNTRY
    | CountryReduxTypes.DELETE_COUNTRY
    | CountryReduxTypes.DELETE_COUNTRY_SUCCESS
    | CountryReduxTypes.DELETE_COUNTRY_ERROR

    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_SUCCESS
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_ERROR
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_SUCCESS
    | CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_ERROR
    | CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS
    | CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_SUCCESS
    | CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_ERROR
    | CountryReduxTypes.APPROVE_COUNTRIES
    | CountryReduxTypes.APPROVE_COUNTRIES_SUCCESS
    | CountryReduxTypes.APPROVE_COUNTRIES_ERROR
    | CountryReduxTypes.REJECT_COUNTRIES
    | CountryReduxTypes.REJECT_COUNTRIES_SUCCESS
    | CountryReduxTypes.REJECT_COUNTRIES_ERROR
    | CountryReduxTypes.BLOCK_COUNTRIES_SUCCESS
    | CountryReduxTypes.BLOCK_COUNTRIES_ERROR
    | CountryReduxTypes.FREEZE_COUNTRIES_SUCCESS
    | CountryReduxTypes.FREEZE_COUNTRIES_ERROR
    | CountryReduxTypes.UNFREEZE_COUNTRIES_SUCCESS
    | CountryReduxTypes.UNFREEZE_COUNTRIES_ERROR
    | CountryReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Country>;
    error?: string;
  };
};

export type CountryReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Country>;
  error?: string | null;
};

export type CountriesFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type Country = {
  externalId: string;
  id: string;
  name: string;
  countryCode: string;
  active: boolean;
  createdOn: Date;
  updatedOn: Date;
};

export type CreateCountryRequest = {
  payload: CreateCountry;
  type: string;
};

export type UpdateCountryRequest = {
  payload: UpdateCountry;
  type: string;
};
export type CreateCountryReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Country>;
  error?: string | null;
};

export type CreateCountry = {
  name: string;
  countryCode: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateCountry = {
  id: string;
  name: string;
  countryCode: string;
  externalId: string;
  makerRemarks: string;
  draft: boolean;
};

export type FetchCountryByIdRequest = {
  payload: string;
  type: string;
};
