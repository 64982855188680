import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateDisbursementAuth, DisbursementAuth, updateDisbursementAuthVersion } from "./types";

const api = new APICore();

function fetchDisbursementAuthApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch`;
  return api.get(`${baseUrl}`, params);
}

function fetchDisbursementAuthByExternalIdApi(params: any) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params}`;
  return api.get(`${baseUrl}`, params);
}

function fetchDisbursementAuthAuditActionsApi(params:FilterMetadata){
  const baseUrl=`${config.BACKEND_TXN_BASE_URL}/action-audit`;
  return api.get(`${baseUrl}`,params)
}

function createDisbursementAuthApi(params: any) {
  // console.log("paramsC", params);

  let backendApiUrl = "";

  let formData = new FormData();
  formData.append("file", params);
  // return api.create(baseUrl, formData)

  backendApiUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch?draft=${true}`;

  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, formData);

  // const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch`;
  // return api.create(`${baseUrl}`, params);
}

function updateDisbursementAuthApi(params: any) {
  // console.log("params", params);

  let backendApiUrl = "";

  let formData = new FormData();
  formData.append("file", params?.holidayBatch[0]);

  backendApiUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}?draft=${params?.draft}`;

  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, formData);
  // const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}`;
  // return api.update(`${baseUrl}`, data);
}

function publishDisbursementAuthApi(params: any) {
  let backendApiUrl = "";

  const apiData = { makerRemarks: params.makerRemarks ? params.makerRemarks.trim() : "" };
  backendApiUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}/publish`;

  const baseUrl = backendApiUrl;

  return api.updatePatch(`${baseUrl}`, apiData);
}

function fetchDisbursementAuthVersionApi(params: any) {

  console.log(params);
  
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function approveDisbursementAuthApi(params: updateDisbursementAuthVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${externalHolidayId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectDisbursementAuthApi(params: updateDisbursementAuthVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${externalHolidayId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockDisbursementAuthApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeDisbursementAuthApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeDisbursementAuthApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/loan-disbursement-response-batch/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchDisbursementAuthApi,
  createDisbursementAuthApi,
  fetchDisbursementAuthByExternalIdApi,
  updateDisbursementAuthApi,
  fetchDisbursementAuthVersionApi,
  approveDisbursementAuthApi,
  rejectDisbursementAuthApi,
  blockDisbursementAuthApi,
  freezeDisbursementAuthApi,
  unFreezeDisbursementAuthApi,
  publishDisbursementAuthApi,
  fetchDisbursementAuthAuditActionsApi
};
