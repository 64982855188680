import { CreateRepaymentAdviceReduxState, RepaymentAdviceReducerDispatch, RepaymentAdviceReduxTypes } from "./types"

const CREATE_REPAYMENT_ADVICE_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateRepaymentAdviceReducer = (state: CreateRepaymentAdviceReduxState = CREATE_REPAYMENT_ADVICE_INIT_STATE, action: RepaymentAdviceReducerDispatch) => {
    switch (action.type) {
        case RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT:
            return {
                ...state,
                loading: true
            }
        case RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        case RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }
        default:
            return { ...state }
    }
}

export { CreateRepaymentAdviceReducer }