import { SagaIterator } from "redux-saga";
import { CreateRepaymentAdviceRequest, RepaymentAdviceReduxTypes } from "./types";
import { createRepaymentAdviceApi } from "./api";
import { call, fork, put, takeEvery } from "redux-saga/effects";


function* createRepaymentAdvice({ payload }: CreateRepaymentAdviceRequest): SagaIterator {
    try {
        const response = yield call(createRepaymentAdviceApi, payload);
        yield put({
            type: RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_ERROR,
            payload: { error: error }
        })
    }
}

export function* watchCreateRepaymentAdvice() {
    yield takeEvery(RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT, createRepaymentAdvice)
}

function* repaymentAdviceSaga() {
    yield fork(watchCreateRepaymentAdvice)
}

export default repaymentAdviceSaga