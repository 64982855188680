import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import { updateFinancingProgramVersion } from "./types";

const api = new APICore();

function fetchFinancingProgramsApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs`;
  return api.get(`${baseUrl}`, params);
}
function fetchFinancingProgramApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}


function deleteFinancingProgramApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchFinancingProgrameConfigApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params?.id}/config`;
  return api.create(`${baseUrl}`, params.value);
}

function fetchFinancingProgramVersionApi(params: any) {
  let filterdata = {
    pgno: params.pgno,
    pgsz: params.pgsz,
    sort: params.sort
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.id}/versions`;
  return api.get(`${baseUrl}`, filterdata);
}

function fetchFinancingProgramLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function fetchFinancingProgramVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveFinancingProgramApi(params: updateFinancingProgramVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks
  };
  let externalBaseRateId = params.externalCustomerId;

  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${externalBaseRateId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectFinancingProgramApi(params: updateFinancingProgramVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks
  };
  let externalBaseRateId = params.externalCustomerId;

  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${externalBaseRateId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function validateFinancingProgramApi(params: any) {
  // console.log(params?.adhocBorrowerLimitId);

  let backendApiUrl = "";

  let apiData: any = {
    branchId: params.branchId.length ? params.branchId[0]["id"] : null,
    customerId: params.customerId.length ? params.customerId[0]["id"] : null,
    productTypeId: params.productTypeId.length ? params.productTypeId[0]["id"] : null,
    currencyId: params.currencyId.length ? params.currencyId[0]["id"] : null,
    // normalBorrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    normalBaseRateId: params?.normalBaseRateId?.length && params?.normalBaseRateId[0] ? params?.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params.normalSpreadRateOfInterest ? params.normalSpreadRateOfInterest : 0,
    // adhocBorrowerLimitId: params.adhocBorrowerLimitId ? params.adhocBorrowerLimitId : null,
    adhocBaseRateId: params.adhocBaseRateId?.length && params.adhocBaseRateId[0] ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params.adhocSpreadRateOfInterest ? params.adhocSpreadRateOfInterest : 0,
    name: params.name ? params.name : "",
    // discountPercentage: params.discountPercentage ? params.discountPercentage : 0,
    programLimit: params.programLimit ? params.programLimit : 0,
    overdueSpreadRateOfInterest: params.overdueSpreadRateOfInterest ? params.overdueSpreadRateOfInterest : 0,
    graceRateOfInterest: params.graceRateOfInterest ? params.graceRateOfInterest : 0,
    interestType: params.interestType ? params.interestType.value : "None",
    tenorDays: params.tenorDays ? params.tenorDays : 0,
    graceDays: params.graceDays ? params.graceDays : 0,
    staleDays: params.staleDays ? params.staleDays : 0,
    startDate: params.startDate ? params.startDate : "0001-01-01",
    endDate: params.endDate ? params.endDate : "0001-01-01",
    autoDebit: params.autoDebit ? true : false,
    autoDebitBankAccountNumber: params.autoDebitBankAccountNumber ? params.autoDebitBankAccountNumber.trim() : "",
    interestDebitParty: params.interestDebitParty ? params.interestDebitParty.value : "None",
    overdueDebitParty: params.overdueDebitParty ? params.overdueDebitParty.value : "None",
    principalRepaymentBy: params.principalRepaymentBy ? params.principalRepaymentBy.value : "None",
    instrumentDueDateCalculation: params.instrumentDueDateCalculation ? params.instrumentDueDateCalculation.value : "None",
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : "None"
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/validate`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/validate?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function createFinancingProgramApi(params: any) {
  let backendApiUrl = "";
  let apiData: any = {
    branchId: params.branchId.length ? params.branchId[0]["id"] : null,
    customerId: params.customerId.length ? params.customerId[0]["id"] : null,
    productTypeId: params.productTypeId.length ? params.productTypeId[0]["id"] : null,
    currencyId: params.currencyId.length ? params.currencyId[0]["id"] : null,
    // normalBorrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    normalBaseRateId: params.normalBaseRateId?.length ? params.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params.normalSpreadRateOfInterest ? params.normalSpreadRateOfInterest : 0,
    // adhocBorrowerLimitId: params.adhocBorrowerLimitId ? params.adhocBorrowerLimitId : null,
    adhocBaseRateId: params.adhocBaseRateId?.length && params.adhocBaseRateId[0] ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params.adhocSpreadRateOfInterest ? params.adhocSpreadRateOfInterest : 0,
    name: params.name ? params.name : "",
    // discountPercentage: params.discountPercentage ? params.discountPercentage : 0,
    programLimit: params.programLimit ? params.programLimit : 0,
    overdueSpreadRateOfInterest: params.overdueSpreadRateOfInterest ? params.overdueSpreadRateOfInterest : 0,
    graceRateOfInterest: params.graceRateOfInterest ? params.graceRateOfInterest : 0,
    interestType: params.interestType ? params.interestType.value : "None",
    graceDays: params.graceDays ? params.graceDays : 0,
    tenorDays: params.tenorDays ? params.tenorDays : 0,
    staleDays: params.staleDays ? params.staleDays : 0,
    startDate: params.startDate ? params.startDate : "0001-01-01",
    endDate: params.endDate ? params.endDate : "0001-01-01",
    autoDebit: params.autoDebit ? true : false,
    autoDebitBankAccountNumber: params.autoDebitBankAccountNumber ? params.autoDebitBankAccountNumber : "",
    umrnNumber: params?.umrnNumber ? params?.umrnNumber : "",
    ifscCode: params?.ifscCode ? params?.ifscCode : "",
    interestDebitParty: params.interestDebitParty ? params.interestDebitParty.value : "None",
    overdueDebitParty: params.overdueDebitParty ? params.overdueDebitParty.value : "None",
    principalRepaymentBy: params.principalRepaymentBy ? params.principalRepaymentBy.value : "None",
    instrumentDueDateCalculation: params.instrumentDueDateCalculation ? params.instrumentDueDateCalculation.value : "None",
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : "None",

    invoiceConcentrationAmount: params.invoiceConcentrationAmount ? params.invoiceConcentrationAmount : 0,

    dailyTransactionLimit: params.dailyTransactionLimit ? params.dailyTransactionLimit : 0,
    maxFinancingPercentage: params.maxFinancingPercentage ? params.maxFinancingPercentage : "0",
    paymentBatchingStrategy: params.paymentBatchingStrategy.value ? params.paymentBatchingStrategy?.value : "NoBatching",
    isInheritedAppropriationGroup: params.isInheritedAppropriationGroup ? params.isInheritedAppropriationGroup : false,
    appropriationGroup: params?.appropriationGroup?.value ? params?.appropriationGroup?.value : null,
    //partialDisbursementAllowed

    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "FIFO",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    isInheritedRepaymentSettlementStrategy: params.isInheritedRepaymentSettlementStrategy ? params.isInheritedRepaymentSettlementStrategy : false,
    isInheritedUniqueLoanAndVirtualAccountNumber: params.isInheritedUniqueLoanAndVirtualAccountNumber ? params.isInheritedUniqueLoanAndVirtualAccountNumber : false,

    partialDisbursementAllowed: params.partialDisbursementAllowed && params.partialDisbursementAllowed?.value == "Yes" ? true : false,
    marginAmountAccountNumber: params?.marginAmountAccountNumber,
    marginAmountIfscCode: params?.marginAmountIfscCode,
    loanAccountNumber: params?.loanAccountNumber ? params?.loanAccountNumber : "",
    borrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    isAutoGeneratedLoanAccountNumber:params?.isAutoGeneratedLoanAccountNumber,

    //   NoBatching(100),
    // DueDate(200),
    // LumpSum(300);
  };
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateFinancingProgramApi(params: any) {

  
  console.log(params);
  
  let backendApiUrl = "";
  let apiData: any = {
    // DROP COLUMN IF EXISTS`is_inherited_payment_batching_strategy`,
    branchId: params?.branchId?.length ? params.branchId[0]["id"] : null,
    customerId: params.customerId.length ? params.customerId[0]["id"] : null,
    productTypeId: params.productTypeId.length ? params.productTypeId[0]["id"] : null,
    currencyId: params.currencyId.length ? params.currencyId[0]["id"] : null,
    // normalBorrowerLimitId: params.normalBorrowerLimitId ? params.normalBorrowerLimitId : null,
    normalBaseRateId: params.normalBaseRateId?.length && params?.normalBaseRateId[0] ? params.normalBaseRateId[0]["id"] : null,
    normalSpreadRateOfInterest: params.normalSpreadRateOfInterest ? params.normalSpreadRateOfInterest : 0,
    
    // adhocBorrowerLimitId: params.adhocBorrowerLimitId ? params.adhocBorrowerLimitId : null,
    adhocBaseRateId: params.adhocBaseRateId?.length && params?.adhocBaseRateId[0] ? params.adhocBaseRateId[0]["id"] : null,
    adhocSpreadRateOfInterest: params.adhocSpreadRateOfInterest ? params.adhocSpreadRateOfInterest : 0,
    name: params.name ? params.name : "",
    // discountPercentage: params.discountPercentage ? params.discountPercentage : 0,
    programLimit: params.programLimit ? params.programLimit : 0,
    overdueSpreadRateOfInterest: params.overdueSpreadRateOfInterest ? params.overdueSpreadRateOfInterest : 0,
    graceRateOfInterest: params.graceRateOfInterest ? params.graceRateOfInterest : 0,
    interestType: params.interestType ? params.interestType.value : "None",
    tenorDays: params.tenorDays ? params.tenorDays : 0,
    graceDays: params.graceDays ? params.graceDays : 0,
    staleDays: params.staleDays ? params.staleDays : 0,
    startDate: params.startDate ? params.startDate : "0001-01-01",
    endDate: params.endDate ? params.endDate : "0001-01-01",
    autoDebit: params.autoDebit ? true : false,
    autoDebitBankAccountNumber: params.autoDebitBankAccountNumber ? params?.autoDebitBankAccountNumber.trim() : "",
    umrnNumber: params?.umrnNumber ? params?.umrnNumber : "",
    ifscCode: params?.ifscCode ? params?.ifscCode : "",
    interestDebitParty: params.interestDebitParty ? params.interestDebitParty.value : "None",
    overdueDebitParty: params.overdueDebitParty ? params.overdueDebitParty.value : "None",
    principalRepaymentBy: params.principalRepaymentBy ? params.principalRepaymentBy.value : "None",
    instrumentDueDateCalculation: params.instrumentDueDateCalculation ? params.instrumentDueDateCalculation.value : "None",
    dueDateStrategy: params.dueDateStrategy ? params.dueDateStrategy.value : "None",

    invoiceConcentrationAmount: params.invoiceConcentrationAmount ? params.invoiceConcentrationAmount : 0,
    maxFinancingPercentage: params.maxFinancingPercentage ? params.maxFinancingPercentage : "0",
    paymentBatchingStrategy: params.paymentBatchingStrategy.value ? params.paymentBatchingStrategy?.value : "NoBatching",
    isInheritedAppropriationGroup: params.isInheritedAppropriationGroup ? params.isInheritedAppropriationGroup : false,
    appropriationGroup: params?.appropriationGroup?.value ? params?.appropriationGroup?.value : null,

    dailyTransactionLimit: params.dailyTransactionLimit ? params.dailyTransactionLimit : 0,

    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "FIFO",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    isInheritedRepaymentSettlementStrategy: params.isInheritedRepaymentSettlementStrategy ? params.isInheritedRepaymentSettlementStrategy : false,
    isInheritedUniqueLoanAndVirtualAccountNumber: params.isInheritedUniqueLoanAndVirtualAccountNumber ? params.isInheritedUniqueLoanAndVirtualAccountNumber : false,

    //partialDisbursementAllowed

    partialDisbursementAllowed: params.partialDisbursementAllowed && params.partialDisbursementAllowed?.value == "Yes" ? true : false,


    loanAccountNumber: params?.loanAccountNumber ? params?.loanAccountNumber : "",
    borrowerLimitId: params.borrowerLimitId ? params?.borrowerLimitId : null,

    marginAmountAccountNumber: params?.marginAmountAccountNumber,
    marginAmountIfscCode: params?.marginAmountIfscCode,
    isAutoGeneratedLoanAccountNumber:params?.isAutoGeneratedLoanAccountNumber,

  };

  
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.externalId}?draft=${params.draft}&group=${params.group}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}
function blockFinancingProgramApi(params: any) {
  let blockData = {
    inactiveReason: params.inactiveReason.trim()
  };
  let backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.externalId}/block`;
  return api.updatePatch(`${backendApiUrl}`, blockData);
}

function freezeFinancingProgramApi(params: any) {
  let blockData = {
    inactiveReason: params.inactiveReason.trim()
  };
  let backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.id}/freeze`;
  return api.updatePatch(`${backendApiUrl}`, blockData);
}

function unFreezeFinancingProgramApi(params: any) {
  let blockData = {
    inactiveReason: params.inactiveReason.trim()
  };
  let backendApiUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params.id}/unfreeze`;
  return api.updatePatch(`${backendApiUrl}`, blockData);
}


function generateloanAccountNumberApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/financing-programs/${params}/autogenerate-loan-account-number`;
  return api.get(`${baseUrl}`, {});
}

export {
  fetchFinancingProgramsApi,
  fetchFinancingProgramApi,
  fetchFinancingProgramVersionApi,
  approveFinancingProgramApi,
  rejectFinancingProgramApi,
  validateFinancingProgramApi,
  createFinancingProgramApi,
  updateFinancingProgramApi,
  blockFinancingProgramApi,
  freezeFinancingProgramApi,
  unFreezeFinancingProgramApi,
  fetchFinancingProgrameConfigApi,
  fetchFinancingProgramVersionDetailsApi,
  fetchFinancingProgramLatestVersionApi,
  deleteFinancingProgramApi,
  generateloanAccountNumberApi
};
