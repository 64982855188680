import { SagaIterator } from "redux-saga";
import { CreateProgrammeSummaryRequest, ProgrammeSummaryReduxTypes } from "./types";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createProgrammeSummaryApi } from "./api";

function* createProgrammeSummary({ payload }: CreateProgrammeSummaryRequest): SagaIterator {
    try {
        const response = yield call(createProgrammeSummaryApi, payload)

        yield put({
            type: ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT_ERROR,
            payload: { error: error }
        })
    }
}

export function* watchCreateProgrammeSummary() {
    yield takeEvery(ProgrammeSummaryReduxTypes.PROGRAMME_SUMMARY_REPORT, createProgrammeSummary)
}

function* programmeSummarySaga() {
    yield fork(watchCreateProgrammeSummary)
}

export default programmeSummarySaga