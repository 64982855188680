import config from "config";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();
function createProgrammeSummaryApi(params: any) {
    let apiData: any = {
        programExternalId: params.programmeId.length ? params.programmeId : null
    }
    let backendApiUrl = `${config.BACKEND_BASE_URL}/report/program-summary`
   
    return api.create(`${backendApiUrl}`, apiData)
}
export { createProgrammeSummaryApi }