import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CustomerBeneficiaryProgrammeReduxTypes {
    // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
    // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
    FETCH_CUSTOMER_BENEFICARY_PROGRAMME = '@@auth/FETCH_CUSTOMER_BENEFICARY_PROGRAMME',
    FETCH_CUSTOMER_BENEFICARY_PROGRAMME_SUCCESS = '@@auth/FETCH_CUSTOMER_BENEFICARY_PROGRAMME_SUCCESS',
    FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR = '@@auth/FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR',

    CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME = '@@auth/CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME',
    CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_SUCCESS = '@@auth/CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_SUCCESS',
    CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_ERROR = '@@auth/CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_ERROR',

    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME',
    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',

    UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME',
    UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',

    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION',
    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_SUCCESS = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_SUCCESS',
    FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_ERROR = '@@auth/FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_ERROR',


    APROVE_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/APROVE_CUSTOMER_BENEFICIAL_PROGRAMME',
    APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',


    REJECT_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/REJECT_CUSTOMER_BENEFICIAL_PROGRAMME',
    REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',


    BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME',
    BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',



    FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME',
    FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',


    UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME = '@@auth/UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME',
    UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS = '@@auth/UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS',
    UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR = '@@auth/UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR',

    RESET = '@@auth/RESET',
}

export type CustomerBeneficiaryProgrammeActionDispatch = {
    type:
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME

    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION
    | CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME



    | CustomerBeneficiaryProgrammeReduxTypes.RESET;
    payload: any;
};

export type CustomerBeneficiaryProgrammeReducerDispatch = {
    type:
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR
    | CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_ERROR
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR
    | CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR

    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_ERROR

    | CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR


    | CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR


    | CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR


    | CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR

    | CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS
    | CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR
    

    | CustomerBeneficiaryProgrammeReduxTypes.RESET;
    payload: {
        data?: FetchApiResponse<CustomerBeneficiaryProgramme>;
        error?: string;
    };
};

export type CustomerBeneficiaryProgrammeReduxState = {
    loading?: boolean;
    data?: FetchApiResponse<CustomerBeneficiaryProgramme>;
    error?: string | null;
}

export type CustomerBeneficiaryProgrammeFetchRequest = {
    payload: FilterMetadata;
    type: string;
};

export type CustomerBeneficiaryProgramme = {
    id: string;
    customerId: string;
    ifsccode: string;
    accountnum: string;
    corporateCode: string;
    name: string;
    default: boolean;
    active: boolean;
};



export type CreateCustomerBeneficiaryProgrammeRequest = {
    payload: CreateCustomerBeneficiaryProgrammeType;
    type: string;
}

export type CreateCustomerBeneficiaryProgrammeType = {
    customerId: string;
    accountnum: string;
    corporateCode: string;
    default: boolean;
    ifsccode: string;
    name: string;
    draft:boolean;
    makerRemarks:string;
   
}
export type CreateCustomerBeneficiaryProgrammeReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<CreateCustomerBeneficiaryProgrammeType>;
    error?: string | null;
}