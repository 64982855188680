import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CurrencyReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_CURRENCY = "@@auth/FETCH_CURRENCY",
  FETCH_CURRENCY_SUCCESS = "@@auth/FETCH_CURRENCY_SUCCESS",
  FETCH_CURRENCY_ERROR = "@@auth/FETCH_CURRENCY_ERROR",

  FETCH_CURRENCY_BY_ID = "@@auth/FETCH_CURRENCY_BY_ID",
  FETCH_CURRENCY_BY_ID_SUCCESS = "@@auth/FETCH_CURRENCY_BY_ID_SUCCESS",
  FETCH_CURRENCY_BY_ID_ERROR = "@@auth/FETCH_CURRENCY_BY_ID_ERROR",

  CREATE_CURRENCY = "@@auth/CREATE_CURRENCY",
  CREATE_CURRENCY_SUCCESS = "@@auth/CREATE_CURRENCY_SUCCESS",
  CREATE_CURRENCY_ERROR = "@@auth/CREATE_CURRENCY_ERROR",

  UPDATE_CURRENCY = "@@auth/UPDATE_CURRENCY",
  UPDATE_CURRENCY_SUCCESS = "@@auth/UPDATE_CURRENCY_SUCCESS",
  UPDATE_CURRENCY_ERROR = "@@auth/UPDATE_CURRENCY_ERROR",
  RESET_UPDATE_CURRENCY = "@@auth/RESET_UPDATE_CURRENCY",


  DELETE_CURRENCY = "@@auth/DELETE_CURRENCY",
  DELETE_CURRENCY_SUCCESS = "@@auth/DELETE_CURRENCY_SUCCESS",
  DELETE_CURRENCY_ERROR = "@@auth/DELETE_CURRENCY_ERROR",
  RESET_DELETE_CURRENCY = "@@auth/RESET_DELETE_CURRENCY",

  FETCH_CURRENCY_VERSION_DETAILS = "@@auth/FETCH_CURRENCY_VERSION_DETAILS",
  FETCH_CURRENCY_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_CURRENCY_VERSION_DETAILS_SUCCESS",
  FETCH_CURRENCY_VERSION_DETAILS_ERROR = "@@auth/FETCH_CURRENCY_VERSION_DETAILS_ERROR",

  FETCH_CURRENCY_VERSION = "@@auth/FETCH_CURRENCY_VERSION",
  FETCH_CURRENCY_VERSION_SUCCESS = "@@auth/FETCH_CURRENCY_VERSION_SUCCESS",
  FETCH_CURRENCY_VERSION_ERROR = "@@auth/FETCH_CURRENCY_VERSION_ERROR",

  FETCH_CURRENCY_LATEST_VERSION = "@@auth/FETCH_CURRENCY_LATEST_VERSION",
  FETCH_CURRENCY_LATEST_VERSION_SUCCESS = "@@auth/FETCH_CURRENCY_LATEST_VERSION_SUCCESS",
  FETCH_CURRENCY_LATEST_VERSION_ERROR = "@@auth/FETCH_CURRENCY_LATEST_VERSION_ERROR",

  APROVE_CURRENCY = "@@auth/APROVE_CURRENCY",
  APROVE_CURRENCY_SUCCESS = "@@auth/APROVE_CURRENCY_SUCCESS",
  APROVE_CURRENCY_ERROR = "@@auth/APROVE_CURRENCY_ERROR",

  REJECT_CURRENCY = "@@auth/REJECT_CURRENCY",
  REJECT_CURRENCY_SUCCESS = "@@auth/REJECT_CURRENCY_SUCCESS",
  REJECT_CURRENCY_ERROR = "@@auth/REJECT_CURRENCY_ERROR",

  BLOCK_CURRENCY = "@@auth/BLOCK_CURRENCY",
  BLOCK_CURRENCY_SUCCESS = "@@auth/BLOCK_CURRENCY_SUCCESS",
  BLOCK_CURRENCY_ERROR = "@@auth/BLOCK_CURRENCY_ERROR",

  FREEZE_CURRENCY = "@@auth/FREEZE_CURRENCY",
  FREEZE_CURRENCY_SUCCESS = "@@auth/FREEZE_CURRENCY_SUCCESS",
  FREEZE_CURRENCY_ERROR = "@@auth/FREEZE_CURRENCY_ERROR",

  UNFREEZE_CURRENCY = "@@auth/UNFREEZE_CURRENCY",
  UNFREEZE_CURRENCY_SUCCESS = "@@auth/UNFREEZE_CURRENCY_SUCCESS",
  UNFREEZE_CURRENCY_ERROR = "@@auth/UNFREEZE_CURRENCY_ERROR",

  RESET = "@@auth/RESET"
}

export type CurrencyActionDispatch = {
  type:
    | CurrencyReduxTypes.FETCH_CURRENCY
    | CurrencyReduxTypes.FETCH_CURRENCY_BY_ID
    | CurrencyReduxTypes.CREATE_CURRENCY
    | CurrencyReduxTypes.UPDATE_CURRENCY
    | CurrencyReduxTypes.DELETE_CURRENCY

    | CurrencyReduxTypes.RESET_UPDATE_CURRENCY
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION
    | CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS
    | CurrencyReduxTypes.APROVE_CURRENCY
    | CurrencyReduxTypes.REJECT_CURRENCY
    | CurrencyReduxTypes.BLOCK_CURRENCY
    | CurrencyReduxTypes.FREEZE_CURRENCY
    | CurrencyReduxTypes.UNFREEZE_CURRENCY
    | CurrencyReduxTypes.RESET;
  payload: any;
};

export type CurrencyReducerDispatch = {
  type:
    | CurrencyReduxTypes.FETCH_CURRENCY
    | CurrencyReduxTypes.FETCH_CURRENCY_SUCCESS
    | CurrencyReduxTypes.FETCH_CURRENCY_ERROR
    | CurrencyReduxTypes.FETCH_CURRENCY_BY_ID
    | CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_SUCCESS
    | CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_ERROR
    | CurrencyReduxTypes.CREATE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.CREATE_CURRENCY_ERROR
    | CurrencyReduxTypes.UPDATE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.UPDATE_CURRENCY_ERROR
    | CurrencyReduxTypes.RESET_UPDATE_CURRENCY

    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_SUCCESS
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_ERROR
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_SUCCESS
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_ERROR
    | CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS
    | CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION
    | CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_SUCCESS
    | CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_ERROR
    | CurrencyReduxTypes.APROVE_CURRENCY
    | CurrencyReduxTypes.APROVE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.APROVE_CURRENCY_ERROR
    | CurrencyReduxTypes.REJECT_CURRENCY_SUCCESS
    | CurrencyReduxTypes.REJECT_CURRENCY
    | CurrencyReduxTypes.REJECT_CURRENCY_ERROR
    | CurrencyReduxTypes.BLOCK_CURRENCY_SUCCESS
    | CurrencyReduxTypes.BLOCK_CURRENCY_ERROR
    | CurrencyReduxTypes.FREEZE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.FREEZE_CURRENCY_ERROR
    | CurrencyReduxTypes.UNFREEZE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.UNFREEZE_CURRENCY_ERROR
    | CurrencyReduxTypes.DELETE_CURRENCY
    | CurrencyReduxTypes.DELETE_CURRENCY_SUCCESS
    | CurrencyReduxTypes.DELETE_CURRENCY_ERROR

    | CurrencyReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Currency>;
    error?: string;
  };
};

export type CurrencyReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Currency>;
  error?: string | null;
};

export type CurrencyFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type Currency = {
  id: string;
  longName: string;
  numOfDecimalPlaces: number;
  shortCode: string;
  draft: string;
  makerRemarks: string;
};
export type CreateCurrencyRequest = {
  payload: CreateCurrencyPayLoad;
  type: string;
};

export type UpdateCurrencyRequest = {
  payload: Currency;
  type: string;
};
export type CreateCurrencyReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Currency>;
  error?: string | null;
};

export type CreateCurrencyPayLoad = {
  longName: string;
  numOfDecimalPlaces: number;
  shortCode: string;
  draft: string;
  makerRemarks: string;
};

export type FetchCCurrencyByIdRequest = {
  payload: string;
  type: string;
};
