import { CreateCurrencyReduxState } from "redux/currency/types";
import {
  CreateFCustomerReduxState,
  CustomerReducerDispatch,
  CustomerReduxState,
  CustomerReduxTypes,
  panGstDetailreduxType,
  PanGstDetailReducerDispatch,
  TaskIdByPanNumberReduxState,
  taskResultByTaskIdReduxState,
  TaskStatusByTaskIdReduxState
} from "./types";

const TASK_ID_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const TaskIdByPanNumberReducer = (state: TaskIdByPanNumberReduxState = TASK_ID_INIT_STATE, action: PanGstDetailReducerDispatch) => {
  switch (action.type) {
    case panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case panGstDetailreduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const TaskStatusByTaskIdReducer = (state: TaskStatusByTaskIdReduxState = TASK_ID_INIT_STATE, action: PanGstDetailReducerDispatch) => {
  switch (action.type) {
    case panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case panGstDetailreduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const TaskResultByTaskIdReducer = (state: taskResultByTaskIdReduxState = TASK_ID_INIT_STATE, action: PanGstDetailReducerDispatch) => {
  switch (action.type) {
    case panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case panGstDetailreduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const GstDetailReducer = (state: taskResultByTaskIdReduxState = TASK_ID_INIT_STATE, action: PanGstDetailReducerDispatch) => {
  switch (action.type) {
    case panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case panGstDetailreduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CUSTOMER_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CustomerListReducer = (state: CustomerReduxState = CUSTOMER_LIST_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FETCH_CUSTOMERS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const CustomerReducer = (state: CustomerReduxState = CUSTOMER_LIST_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FETCH_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CUSTOMER_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CustomerVersionsReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerVersionsDetailsReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerLatestVersionsReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }

    case CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    // case CustomerReduxTypes.RESET:
    //   return {
    //     ...state,
    //     data: {},
    //     loading: false,
    //     error: false
    //   };
    default:
      return { ...state };
  }
};

const CustomerValidateReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.VALIDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.VALIDATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET_CREATED_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const CreateCustomerReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.CREATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: {}
      };
    }
    case CustomerReduxTypes.CREATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET_CREATED_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const UpdateCustomerReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: {}
      };
    }
    case CustomerReduxTypes.UPDATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET_UPDATED_REDUCER: {
      return {
        ...state,
        data: {},
        loading: false,
        error: {}
      };
    }
    default:
      return { ...state };
  }
};


const DeleteCustomerReducer = (state: CreateCurrencyReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.DELETE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.DELETE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveCustomerReducer = (state: CreateCurrencyReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.APPROVE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.APPROVE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeDuplicateCustomerReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.DEDUPLICATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.DEDUPLICATE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectCustomerReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.REJECT_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.REJECT_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBlockReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.BLOCK_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.BLOCK_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerFreezeReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.FREEZE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.FREEZE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerUnFreezeReducer = (state: CreateFCustomerReduxState = CREATE_CUSTOMER_INIT_STATE, action: CustomerReducerDispatch) => {
  switch (action.type) {
    case CustomerReduxTypes.UNFREEZE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerReduxTypes.UNFREEZE_CUSTOMER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  TaskIdByPanNumberReducer,
  TaskStatusByTaskIdReducer,
  TaskResultByTaskIdReducer,
  CustomerListReducer,
  CustomerReducer,
  CustomerVersionsReducer,
  ApproveCustomerReducer,
  RejectCustomerReducer,
  GstDetailReducer,
  CustomerValidateReducer,
  CreateCustomerReducer,
  UpdateCustomerReducer,
  DeDuplicateCustomerReducer,
  CustomerBlockReducer,
  CustomerFreezeReducer,
  CustomerUnFreezeReducer,
  CustomerVersionsDetailsReducer,
  CustomerLatestVersionsReducer,
  DeleteCustomerReducer
};
