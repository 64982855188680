import { dailyBalanceReducerDispatch, DailyBalanceReduxState, DailyBalanceReduxTypes } from "./types";

const INSTRUMNET_INIT_STATE = {
  loading: false,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const InstrumentDailyBalancesReducer = (state: DailyBalanceReduxState = INSTRUMNET_INIT_STATE, action: dailyBalanceReducerDispatch) => {
  switch (action.type) {
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DailyBalanceReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const InstrumentDailyBalanceReducer = (state: DailyBalanceReduxState = INSTRUMNET_INIT_STATE, action: dailyBalanceReducerDispatch) => {
  switch (action.type) {
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DailyBalanceReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export { InstrumentDailyBalancesReducer,InstrumentDailyBalanceReducer };
