import { FilterMetadata } from "redux/types";
import { CustomerActionDispatch, CustomerReduxTypes, panGstDetailreduxType, PanGstDetailActionDispatch, updateCustomerVersion } from "./types";

export const fetchTaskIdByPanNumber = (payload: any): PanGstDetailActionDispatch => ({
  type: panGstDetailreduxType.FETCH_TASK_ID_BY_PAN_NUMBER,
  payload: payload
});

export const fetchTaskStatusByTaskId = (payload: any): PanGstDetailActionDispatch => ({
  type: panGstDetailreduxType.FETCH_TASK_STATUS_BY_TASK_ID,
  payload: payload
});

export const fetchTaskResultByTaskId = (payload: any): PanGstDetailActionDispatch => ({
  type: panGstDetailreduxType.FETCH_GSP_TASK_RESULT_BY_TASK_ID,
  payload: payload
});

export const fetchGstDetailByGstNumber = (payload: any): PanGstDetailActionDispatch => ({
  type: panGstDetailreduxType.FETCH_GST_DETAIL_BY_GST_NUMBER,
  payload: payload
});

export const fetchCustomers = (payload: FilterMetadata): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FETCH_CUSTOMERS,
  payload: payload
});

export const fetchCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FETCH_CUSTOMER,
  payload: payload
});

export const fetcCustomerVersions = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION,
  payload: payload
});


export const fetcCustomerLatestVersions = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FETCH_CUSTOMER_LATEST_VERSION,
  payload: payload
});


export const fetcCustomerVersionsDetails = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FETCH_CUSTOMER_VERSION_DETAILS,
  payload: payload
});


export const resetValidation = (): CustomerActionDispatch => ({
  type: CustomerReduxTypes.RESET,
  payload: {}
});

export const approveCustomerVersions = (payload: updateCustomerVersion): CustomerActionDispatch => ({
  type: CustomerReduxTypes.APPROVE_CUSTOMER,
  payload: payload
});
export const rejectCustomerVersions = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.REJECT_CUSTOMER,
  payload: payload
});

export const resetCustomer = (): CustomerActionDispatch => ({
  type: CustomerReduxTypes.RESET,
  payload: {}
});
export const resetCustomerCreationData = (): CustomerActionDispatch => ({
  type: CustomerReduxTypes.RESET_CREATED_REDUCER,
  payload: {}
});

export const resetCustomerUpdateData = (): CustomerActionDispatch => ({
  type: CustomerReduxTypes.RESET_UPDATED_REDUCER,
  payload: {}
});


export const validateCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.VALIDATE_CUSTOMER,
  payload: payload
});

export const createCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.CREATE_CUSTOMER,
  payload: payload
});

export const deleteCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.DELETE_CUSTOMER,
  payload: payload
});

export const updateCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.UPDATE_CUSTOMER,
  payload: payload
});
export const deDuplicateCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.DEDUPLICATE_CUSTOMER,
  payload: payload
});

export const blockCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.BLOCK_CUSTOMER,
  payload: payload
});

export const freezeCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.FREEZE_CUSTOMER,
  payload: payload
});

export const unFreezeCustomer = (payload: any): CustomerActionDispatch => ({
  type: CustomerReduxTypes.UNFREEZE_CUSTOMER,
  payload: payload
});
