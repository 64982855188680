import { Branch } from "redux/branch/types";
import { Currency } from "redux/currency/types";
import { Customer } from "redux/customer/types";
import { ProductType } from "redux/productType/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum FinancingProgramReduxTypes {
  FETCH_FINANCING_PROGRAMS = "@@auth/FETCH_FINANCING_PROGRAMS",
  FETCH_FINANCING_PROGRAMS_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAMS_SUCCESS",
  FETCH_FINANCING_PROGRAMS_ERROR = "@@auth/FETCH_FINANCING_PROGRAMS_ERROR",

  FETCH_FINANCING_PROGRAMS_CONFIG = "@@auth/FETCH_FINANCING_PROGRAMS_CONFIG",
  FETCH_FINANCING_PROGRAMS_CONFIG_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAMS_CONFIG_SUCCESS",
  FETCH_FINANCING_PROGRAMS_CONFIG_ERROR = "@@auth/FETCH_FINANCING_PROGRAMS_CONFIG_ERROR",


  GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS = "@@auth/GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS",
  GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS = "@@auth/GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS",
  GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR = "@@auth/GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR",


  FETCH_FINANCING_PROGRAMS_IDS = "@@auth/FETCH_FINANCING_PROGRAMS_IDS",
  FETCH_FINANCING_PROGRAMS_IDS_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAMS_IDS_SUCCESS",
  FETCH_FINANCING_PROGRAMS_IDS_ERROR = "@@auth/FETCH_FINANCING_PROGRAMS_IDS_ERROR",

  FETCH_FINANCING_PROGRAM = "@@auth/FETCH_FINANCING_PROGRAM",
  FETCH_FINANCING_PROGRAM_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAM_SUCCESS",
  FETCH_FINANCING_PROGRAM_ERROR = "@@auth/FETCH_FINANCING_PROGRAM_ERROR",

  FETCH_FINANCING_PROGRAM_VERSION = "@@auth/FETCH_FINANCING_PROGRAM_VERSION",
  FETCH_FINANCING_PROGRAM_VERSION_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAM_VERSION_SUCCESS",
  FETCH_FINANCING_PROGRAM_VERSION_ERROR = "@@auth/FETCH_FINANCING_PROGRAM_VERSION_ERROR",

  FETCH_FINANCING_PROGRAM_VERSION_DETAILS = "@@auth/FETCH_FINANCING_PROGRAM_VERSION_DETAILS",
  FETCH_FINANCING_PROGRAM_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAM_VERSION_DETAILS_SUCCESS",
  FETCH_FINANCING_PROGRAM_VERSION_DETAILS_ERROR = "@@auth/FETCH_FINANCING_PROGRAM_VERSION_DETAILS_ERROR",

  FETCH_FINANCING_PROGRAM_LATEST_VERSION = "@@auth/FETCH_FINANCING_PROGRAM_LATEST_VERSION",
  FETCH_FINANCING_PROGRAM_LATEST_VERSION_SUCCESS = "@@auth/FETCH_FINANCING_PROGRAM_LATEST_VERSION_SUCCESS",
  FETCH_FINANCING_PROGRAM_LATEST_VERSION_ERROR = "@@auth/FETCH_FINANCING_PROGRAM_LATEST_VERSION_ERROR",

  APPROVE_FINANACING_PROGRAM = "@@auth/APPROVE_FINANACING_PROGRAM",
  APPROVE_FINANACING_PROGRAM_SUCCESS = "@@auth/APPROVE_FINANACING_PROGRAM_SUCCESS",
  APPROVE_FINANACING_PROGRAM_ERROR = "@@auth/APPROVE_FINANACING_PROGRAM_ERROR",


  DELETE_FINANACING_PROGRAM = "@@auth/DELETE_FINANACING_PROGRAM",
  DELETE_FINANACING_PROGRAM_SUCCESS = "@@auth/DELETE_FINANACING_PROGRAM_SUCCESS",
  DELETE_FINANACING_PROGRAM_ERROR = "@@auth/DELETE_FINANACING_PROGRAM_ERROR",

  REJECT_FINANCING_PROGRAM = "@@authREJECT_FINANCING_PROGRAM",
  REJECT_FINANCING_PROGRAM_SUCCESS = "@@authREJECT_FINANCING_PROGRAM_SUCCESS",
  REJECT_FINANCING_PROGRAM_ERROR = "@@authREJECT_FINANCING_PROGRAM_ERROR",

  VALIDATE_FINANCING_PROGRAM = "@@authVALIDATE_FINANCING_PROGRAM",
  VALIDATE_FINANCING_PROGRAM_SUCCESS = "@@authVALIDATE_FINANCING_PROGRAM_SUCCESS",
  VALIDATE_FINANCING_PROGRAM_ERROR = "@@authVALIDATE_FINANCING_PROGRAM_ERROR",

  CREATE_FINANACING_PROGRAM = "@@auth/CREATE_FINANACING_PROGRAM",
  CREATE_FINANACING_PROGRAM_SUCCESS = "@@auth/CREATE_FINANACING_PROGRAM_SUCCESS",
  CREATE_FINANACING_PROGRAM_ERROR = "@@auth/CREATE_TASK_ID_Y_PAN_NUMBER_ERROR",

  UPDATE_FINANCING_PROGRAM = "@@auth/UPDATE_FINANCING_PROGRAM",
  UPDATE_FINANCING_PROGRAM_SUCCESS = "@@auth/UPDATE_FINANCING_PROGRAM_SUCCESS",
  UPDATE_FINANCING_PROGRAM_ERROR = "@@auth/UPDATE_FINANCING_PROGRAM_ERROR",

  BLOCK = "@@auth/BLOCK",
  BLOCK_SUCCESS = "@@auth/BLOCK_SUCCESS",
  BLOCK_ERROR = "@@auth/BLOCK_ERROR",

  FREEZE_FINANCING_PROGRAM = "@@auth/FREEZE_FINANCING_PROGRAM",
  FREEZE_FINANCING_PROGRAM_SUCCESS = "@@auth/FREEZE_FINANCING_PROGRAM_SUCCESS",
  FREEZE_FINANCING_PROGRAM_ERROR = "@@auth/FREEZE_FINANCING_PROGRAM_ERROR",

  UNFREEZE_FINANCING_PROGRAM = "@@auth/UNFREEZE_FINANCING_PROGRAM",
  UNFREEZE_FINANCING_PROGRAM_SUCCESS = "@@auth/UNFREEZE_FINANCING_PROGRAM_SUCCESS",
  UNFREEZE_FINANCING_PROGRAM_ERROR = "@@auth/UNFREEZE_FINANCING_PROGRAM_ERROR",

  RESET = "@@auth/RESET",
  RESET_CREATED_FINANCING_PROGRAM_REDUCER = "@@auth/RESET_CREATED_FINANCING_PROGRAM_REDUCER",
  RESET_UPDATED_FINANCING_PROGRAM_REDUCER = "@@auth/RESET_UPDATED_FINANCING_PROGRAM_REDUCER"
}

export type FinancingProgramActionDispatch = {
  type:
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION
    | FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM
    | FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM
    | FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.RESET_CREATED_FINANCING_PROGRAM_REDUCER
    | FinancingProgramReduxTypes.RESET_UPDATED_FINANCING_PROGRAM_REDUCER
    | FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS

    | FinancingProgramReduxTypes.BLOCK
    | FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM
    | FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM

    | FinancingProgramReduxTypes.RESET;
  payload: any;
};
export type FinancingProgramReducerDispatch = {
  type:
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_ERROR
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_SUCCESS
    | FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_ERROR
    | FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.BLOCK_SUCCESS
    | FinancingProgramReduxTypes.BLOCK_ERROR
    | FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.RESET_CREATED_FINANCING_PROGRAM_REDUCER
    | FinancingProgramReduxTypes.RESET_UPDATED_FINANCING_PROGRAM_REDUCER
    | FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM
    | FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_SUCCESS
    | FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_ERROR
    | FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS
    | FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS
    | FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR

    | FinancingProgramReduxTypes.RESET;

  payload: {
    data?: FetchApiResponse<FinancingProgram>;
    error?: string;
  };
};

export type FinancingProgram = {
  id: string;
  externalId: string;
  name: string;
  branch: Branch;
  customer: Customer;
  productType: ProductType;
  currency: Currency;
  normalBorrowerLimit: string;
  normalBaseRate: string;
  adhocBorrowerLimit: string;
  adhocBaseRate: string;
  userId: string;
  discountPercentage: number;
  programLimit: number;
  normalSpreadRateOfInterest: number;
  adhocSpreadRateOfInterest: number;
  penalRateOfInterest: number;
  graceRateOfInterest: number;
  interestType: string;
  tenorDays: number;
  graceDays: number;
  staleDays: number;
  startDate: string;
  endDate: string;
  autoDebit: boolean;
  autoDebitBankAccountNumber: string;
  interestDebitParty: string;
  penalDebitParty: string;
  principalRepaymentBy: string;
  instrumentDueDateCalculation: string;
  dueDateStrategy: string;
  draft: boolean;
  status: string;
  isDisabled: boolean;
  active: boolean;
  blocked: boolean;
  freeze: boolean;
  updatedOn:string;
  createdOn:string
};

export type CreateFinancingProgram = {
  contactNumber: string;
  relationshipManagerId: string;
  cbsId: string;
  tradeName: string;
  fullName: string;
  registeredAddress: registerAddressDetails;
  industryClassification: string;
  riskCategory: string;
  riskRemarks: string;
  emailId: string;
  dateOfIncorporation: string;
  dateOfCommencement: string;
  tdsApplicable: boolean;
  makerRemarks: string;
  pan: string;
  gstIn: string;
  cin: string;
  tan: string;
  aadhar: string;
  registrationNumber: string;
  constitutionOfBusiness: string;
  timezone: string;
  locale: string;
};
export type registerAddressDetails = {
  floorNumber: string;
  doorNumber: string;
  building: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  location: string;
  addressType: string;
};
export type PanTaskID = {
  taskId: string;
};

export type taskStatus = {
  status: string;
};
export type fetchApiRequst = {
  payload: string;
  type: string;
};

export type TaskIdByPanNumberReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<PanTaskID>;
  error?: string | null;
};

export type TaskStatusByTaskIdReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<taskStatus>;
  error?: string | null;
};

export type taskResultByTaskIdReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<taskStatus>;
  error?: string | null;
};

export type FinancingProgramFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FinancingProgramReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<FinancingProgram>;
  error?: string | null;
};

export type FetchFinancingProgramByExternalIdRequest = {
  payload: string;
  type: string;
};

export type UpdateFinancingProgramRequest = {
  payload: FinancingProgram;
  type: string;
};

export type CreateFinancingProgramRequest = {
  payload: CreateFinancingProgram;
  type: string;
};
export type CreateFFinancingProgramReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<FinancingProgram>;
  error?: string | null;
};

export type FinancingProgramVersionChangeRequest = {
  payload: updateFinancingProgramVersion;
  type: string;
};
export type updateFinancingProgramVersion = {
  checkerRemarks: string;
  externalCustomerId: string;
};

export type errors = {
  feild: string;
  message: string;
};
export type ValidationReduxState = {
  loading?: boolean;
  data?: errors;
  error?: string | null;
};

export type blockFinancingProgram = {
  inactiveReason: string;
  programExternalId: string;
};

export type BlockFinancingProgramRequest = {
  payload: blockFinancingProgram;
  type: string;
};
