import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchStagingBatchApi(params: any) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

function fetchStagingBatchesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches`;
  return api.get(`${baseUrl}`, params);
}


function fetchStagingBatchesVersionApi(params: any) {

  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batch-actions/${params.externalId}/action/versions`;
  return api.get(`${baseUrl}`, params.filter);
}

function reProcessingStagingBatchApi(params: Array<any>) {

  
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches/reprocess`;
  return api.updatePatch(`${baseUrl}`, params);
}

function markeRemarkReProcessingStagingBatchApi(params: any) {
  let data = {
    stagingBatchId: params?.stagingBatchId,
    stagingBatchExternalId: params?.externalId,
    makerRemarks: params?.values?.makerRemarks,
    stagingBatchActionType:""
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batch-actions`;
  return api.create(`${baseUrl}`, data);
}

function markeRemarkSkipValidationsForStagingIBatchApi(params: any) {
  let data = {
    stagingBatchId: params?.stagingBatchId,
    stagingBatchExternalId: params?.externalId,
    typePayload: params.validations,
    stagingBatchActionType: params.SkipValidationsAndDisburse,
    makerRemarks: params?.values?.makerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batch-actions`;
  return api.create(`${baseUrl}`, data);
}


function aproveStagingIBatchApi(params: any) {
  let data = {

    checkerRemarks: params?.values?.checkerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batch-actions/${params.externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, data);
}


function rejectStagingIBatchMakerApi(params: any) {
  
  let data = {
    stagingBatchIds:params?.stagingBatchIds,
    rejectRemark:params?.makerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches/reject`;
  return api.updatePatch(`${baseUrl}`, data);
}

function rejectStagingIBatchCheckerApi(params: any) {
  let data = {
    checkerRemarks:params?.values?.checkerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batch-actions/${params?.externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, data);
}

function skipValidationsForStagingIBatchApi(params: any) {
  let data = {
    stagingBatchIds: params["ids"],
    skipValidations: params["validations"]
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches/skippable-validations`;
  return api.update(`${baseUrl}`, data);
}

function disburseStagingBatchApi(params: Array<any>) {
  let data = {
    stagingBatchIds: params
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/staging-batches/disburse`;
  return api.updatePatch(`${baseUrl}`, data);
}
function updateStagingBatchLimitTypeApi(params: any){
  let data={
    stagingBatchIds:params?.stagingBatchIds,
    limitType:params?.limitType
  }
  const baseUrl=`${config.BACKEND_TXN_BASE_URL}/staging-batches/limit-change`;
  return api.updatePatch(`${baseUrl}`,data)
}

function fetchStagingBatchAuditActionsApi(params:FilterMetadata){
  const baseUrl=`${config.BACKEND_TXN_BASE_URL}/action-audit`;
  return api.get(`${baseUrl}`,params)
}
export {
  fetchStagingBatchesApi,
  fetchStagingBatchApi,
  reProcessingStagingBatchApi,
  skipValidationsForStagingIBatchApi,
  disburseStagingBatchApi,
  markeRemarkReProcessingStagingBatchApi,
  markeRemarkSkipValidationsForStagingIBatchApi,
  fetchStagingBatchesVersionApi,
  aproveStagingIBatchApi,
  rejectStagingIBatchMakerApi,
  rejectStagingIBatchCheckerApi,
  updateStagingBatchLimitTypeApi,
  fetchStagingBatchAuditActionsApi
};
