import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { aproveStagingIBatchApi, disburseStagingBatchApi, fetchStagingBatchApi, fetchStagingBatchAuditActionsApi, fetchStagingBatchesApi, fetchStagingBatchesVersionApi, markeRemarkReProcessingStagingBatchApi, markeRemarkSkipValidationsForStagingIBatchApi, rejectStagingIBatchCheckerApi, rejectStagingIBatchMakerApi, reProcessingStagingBatchApi, skipValidationsForStagingIBatchApi, updateStagingBatchLimitTypeApi } from "./api";
import { FetchStagingBatchRequest, FetchStagingBatchesRequest, StagingBatchReduxTypes, reProcessingStagingBatchRequest } from "./types";
import { skipValidationRequest } from "../stagingInstrument/types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchStagingBatches({ payload, type }: FetchStagingBatchesRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingBatchesApi, payload);
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStagingBatchesChild({ payload, type }: FetchStagingBatchesRequest): SagaIterator {
  try {
    const response = yield call(fetchStagingBatchesApi, payload);
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStagingBatch({ payload, type }: FetchStagingBatchesRequest): SagaIterator {

  try {
    const response = yield call(fetchStagingBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStagingBatchVersion({ payload, type }: FetchStagingBatchesRequest): SagaIterator {

  try {
    const response = yield call(fetchStagingBatchesVersionApi, payload);
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_ERROR,
      payload: { error: error }
    });
  }
}
function* fetchStagingBatchAuditActions({payload,type}:FetchStagingBatchesRequest):SagaIterator{
  try {
    const response = yield call(fetchStagingBatchAuditActionsApi, payload);
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* reProcessingStagingBatch({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(reProcessingStagingBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}




function* makerRemarkSkipValidationForStagingBatch({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(markeRemarkSkipValidationsForStagingIBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.MARKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* makerRemarkDisburseStagingBatch({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(markeRemarkSkipValidationsForStagingIBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* disburseStagingBatch({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(disburseStagingBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* skipValidationForStagingBatch({ payload, type }: skipValidationRequest): SagaIterator {
  try {
    const response = yield call(skipValidationsForStagingIBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveStagingBatch({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(aproveStagingIBatchApi, payload);
    yield put({
      type: StagingBatchReduxTypes.APROVE_STAGING_BATCHES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.APROVE_STAGING_BATCHES_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectStagingBatchMaker({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(rejectStagingIBatchMakerApi, payload);
    yield put({
      type: StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectStagingBatchChecker({ payload, type }: reProcessingStagingBatchRequest): SagaIterator {
  try {
    
    const response = yield call(rejectStagingIBatchCheckerApi, payload);
    yield put({
      type: StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_ERROR,
      payload: { error: error }
    });
  }
}

function* updateStagingBatchLimitType({ payload, type }: FetchStagingBatchRequest): SagaIterator {
  try{
    const response=yield call(updateStagingBatchLimitTypeApi,payload);
    yield put({
      type:StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_SUCCESS,
      payload:{data:response.data.data}
    });
  }catch(error:any){
    yield put({
      type:StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_ERROR,
      payload:{error:error}
    });
  }
}
export function* watchFetchStagingBatches() {
  yield takeEvery(StagingBatchReduxTypes.FETCH_STAGING_BATCHES, fetchStagingBatches);
}

export function* watchFetchStagingBatcheVersion() {
  yield takeEvery(StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION, fetchStagingBatchVersion);
}


export function* watchFetchStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.FETCH_STAGING_BATCH, fetchStagingBatch);
}

export function* watchFetchStagingBatchChild() {
  yield takeEvery(StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD, fetchStagingBatchesChild);
}

export function* watchfetchStagingBatchAuditActions() {
  yield takeEvery(StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS, fetchStagingBatchAuditActions);
}

export function* watchReprocessingStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES, reProcessingStagingBatch);
}

export function* watchDisburseStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES, disburseStagingBatch);
}

export function* watchSkipValidationForStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCHES, skipValidationForStagingBatch);
}



export function* watchMakerRemarkDisburseStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES, makerRemarkDisburseStagingBatch);
}

export function* watchMakerRemarkSkipValidationForStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES, makerRemarkSkipValidationForStagingBatch);
}

export function* watchAproveStagingBatch() {
  yield takeEvery(StagingBatchReduxTypes.APROVE_STAGING_BATCHES, aproveStagingBatch);
}

export function* watchRejectStagingBatchMaker() {
  yield takeEvery(StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER, rejectStagingBatchMaker);
}

export function* watchRejectStagingBatchChecker() {
  yield takeEvery(StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER, rejectStagingBatchChecker);
}

export function* watchUpdateStagingBatchLimitType() {
  yield takeEvery(StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE, updateStagingBatchLimitType);
}





function* stagingBatchSaga() {
  yield all([fork(watchFetchStagingBatches), 
    fork(watchFetchStagingBatch), 
    fork(watchReprocessingStagingBatch), 
    fork(watchSkipValidationForStagingBatch),
    fork(watchDisburseStagingBatch),
    fork(watchMakerRemarkSkipValidationForStagingBatch),
    fork(watchMakerRemarkDisburseStagingBatch),
    fork(watchFetchStagingBatcheVersion),
    fork(watchAproveStagingBatch),
    fork(watchRejectStagingBatchMaker),
    fork(watchRejectStagingBatchChecker),
    fork(watchFetchStagingBatchChild),
    fork(watchUpdateStagingBatchLimitType),
    fork(watchfetchStagingBatchAuditActions)


  ]);
}

export default stagingBatchSaga;
