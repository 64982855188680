import { ChargeReducerDispatch, ChargeReduxState, ChargeReduxTypes, CreateChargeReduxState } from "./types";

const CHARGE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const ChargeListReducer = (state: ChargeReduxState = CHARGE_LIST_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FETCH_CHARGES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case ChargeReduxTypes.FETCH_CHARGES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FETCH_CHARGES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CITY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.CREATE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.CREATE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.UPDATE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.UPDATE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FETCH_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ChargeVersionDetailsReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS: {
      return {
        loading: true
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ChargeLatestVersionReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION: {
      return {
        loading: true
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DeleteChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.DELETE_CHARGE: {
      return {
        loading: true
      };
    }
    case ChargeReduxTypes.DELETE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.DELETE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ChargeListVersionReducer = (state: ChargeReduxState = CHARGE_LIST_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FETCH_CHARGE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FETCH_CHARGE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.APPROVE_CHARGE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case ChargeReduxTypes.APPROVE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.APPROVE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.REJECT_CHARGE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case ChargeReduxTypes.REJECT_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.REJECT_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.BLOCK_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.BLOCK_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.FREEZE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.FREEZE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeChargeReducer = (state: CreateChargeReduxState = CREATE_CITY_INIT_STATE, action: ChargeReducerDispatch) => {
  switch (action.type) {
    case ChargeReduxTypes.UNFREEZE_CHARGE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ChargeReduxTypes.UNFREEZE_CHARGE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ChargeReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  ChargeListReducer,
  ChargeReducer,
  CreateChargeReducer,
  UpdateChargeReducer,
  ChargeListVersionReducer,
  ApproveChargeReducer,
  RejectChargeReducer,
  BlockChargeReducer,
  FreezeChargeReducer,
  UnFreezeChargeReducer,
  ChargeVersionDetailsReducer,
  ChargeLatestVersionReducer,
  DeleteChargeReducer
};
