import { CreateApiResponse, FetchApiResponse } from "redux/types"

export enum RepaymentAdviceReduxTypes {
    REPAYMENT_ADVICE_REPORT = "@@auth/REPAYMENT_ADVICE_REPORT",
    REPAYMENT_ADVICE_REPORT_SUCCESS = "@@auth/REPAYMENT_ADVICE_REPORT_SUCCESS",
    REPAYMENT_ADVICE_REPORT_ERROR = "@@auth/REPAYMENT_ADVICE_REPORT_ERROR"
}

export type RepaymentAdviceActionDispatch = {
    type: | RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT
    payload: any
}

export type RepaymentAdviceReducerDispatch = {
    type: RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT
    | RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_SUCCESS
    | RepaymentAdviceReduxTypes.REPAYMENT_ADVICE_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<RepaymentAdvice>
        error?: string
    }
}

export type RepaymentAdvice = {
    id: string,
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}

export type CreateRepaymentAdvice = {
    startDate: string,
    endDate: string,
    programId: string,
    customerId: string,
    anchorId: string,
    productType: string
}

export type CreateRepaymentAdviceRequest = {
    payload: CreateRepaymentAdvice,
    type: string
}

export type CreateRepaymentAdviceReduxState = {
    loading?: boolean,
    data?: CreateApiResponse<RepaymentAdvice>
    error?: string | null
}
