import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateCurrencyPayLoad, Currency } from "./types";

const api = new APICore();

function fetchCurrencyApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies`;
  return api.get(`${baseUrl}`, params);
}

function fetchCurrencyByIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params}`;
  return api.get(`${baseUrl}`, {});
}

function createCurrencyApi(params: CreateCurrencyPayLoad) {
  let backendApiUrl = "";

  let apiData: any = {
    longName: params.longName ? params.longName.trim() : "",
    numOfDecimalPlaces: params.numOfDecimalPlaces ? params.numOfDecimalPlaces : "",
    shortCode: params?.shortCode ? params?.shortCode.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/currencies`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/currencies?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/currencies`;
  // return api.create(`${baseUrl}`, params);
}

function updateCurrencyApi(params: any) {
  // console.log("params", params);

  let backendApiUrl = "";

  let apiData: any = {
    longName: params.longName ? params.longName.trim() : "",
    numOfDecimalPlaces: params.numOfDecimalPlaces ? params.numOfDecimalPlaces : "",
    shortCode: params?.shortCode ? params?.shortCode.trim() : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/currencies/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/currencies/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
  //   const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params.id}`;
  //   return api.update(`${baseUrl}`, data);
}

function fetchCurrencyVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params.id}/versions`;
  return api.get(`${baseUrl}`, params.searchFilters);
}

function deleteCurrencyApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchCurrencyVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}


function fetchCurrencyLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function approveCurrencyApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectCurrencyApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCurrencyApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeCurrencyApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeCurrencyApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/currencies/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchCurrencyVersionApi,
  approveCurrencyApi,
  rejectCurrencyApi,
  blockCurrencyApi,
  fetchCurrencyApi,
  createCurrencyApi,
  fetchCurrencyByIdApi,
  updateCurrencyApi,
  freezeCurrencyApi,
  unFreezeCurrencyApi,
  fetchCurrencyVersionDetailsApi,
  fetchCurrencyLatestVersionApi,
  deleteCurrencyApi
};
