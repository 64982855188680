import { DisbursementReduxTypes } from "redux/transaction/disbursement/type"
import { CreateApiResponse, FetchApiResponse } from "redux/types";

export enum DisbursementAdviceReduxTypes {
    DISBURSEMENT_ADVICE_REPORT = "@@auth/DISBURSEMENT_ADVICE_REPORT",
    DISBURSEMENT_ADVICE_REPORT_SUCCESS = "@@auth/DISBURSEMENT_ADVICE_REPORT_SUCESS",
    DISBURSEMENT_ADVICE_REPORT_ERROR = "@@auth/DISBURSEMENT_ADVICE_REPORT_ERROR"
}
export type DisbursementAdviceActionDispatch = {
    type: | DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT;
    payload: any;
}
export type DisbursementAdviceReducerDispatch = {
    type: | DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT
    | DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_SUCCESS
    | DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<DisbursementAdvice>
        error?: string;
    };
};

export type DisbursementAdvice = {
    id: string,
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}
export type CreateDisbursementAdvice = {
    startDate: string,
    endDate: string,
    programId: string,
    counterPartyId: string,
    anchorId: string
}
export type CreateDisbursementAdviceRequest = {
    payload: CreateDisbursementAdvice,
    type: string
}

export type CreateDisbursementAdviceReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<DisbursementAdvice>;
    error?: string | null;
};
