import { SagaIterator } from "redux-saga";
import { CreateLimitReportRequest, LimitReportReduxTypes } from "./types";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createLimitReportApi } from "./api";

function* createLimitReport({ payload }: CreateLimitReportRequest): SagaIterator {
    try {
        const response = yield call(createLimitReportApi, payload);
        yield put({
            type: LimitReportReduxTypes.LIMIT_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: LimitReportReduxTypes.LIMIT_REPORT_ERROR,
            payload: { error: error }
        })
    }
}

export function* watchCreateLimitReport() {
    yield takeEvery(LimitReportReduxTypes.LIMIT_REPORT, createLimitReport)
}

function* limitReportSaga() {
    yield fork(watchCreateLimitReport)
}

export default limitReportSaga;