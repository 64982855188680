import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../../config";
import { UserSession } from "redux/types";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      // window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      window.location.href = "/access-denied";
    } else {
      switch (error.response.status) {
        case 401:
          if (sessionStorage.getItem("vayana_lms_user")) {
            window.location.href = "/account/logout";
          } else {
            //window.location.href = "/account/login";
          }

          message = error.response.data.error ? error.response?.data?.error : error.message ||"Invalid credentials";
          break;
        case 400:
          message = error.response.data.error ? error.response?.data?.error : error.message || error;           
           break;
          
        case 403:
          window.location.href = "/access-denied";
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message = error.response.data.error && error ? error.response?.data?.error : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "vayana_lms_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (session: UserSession | null) => {
  if (session) {
    // axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    axios.defaults.headers.common["Authorization"] = "Bearer " + session.token;
    axios.defaults.headers.common["financierId"] = session.financierId;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["financierId"];
  }
};

const getTokenExpiryDiff = () => {
  let sessionData: any = sessionStorage.getItem("vayana_lms_user");
  if (!sessionData) {
    return 1;
  }
  let currentTime = Number(new Date());
  let tokenExpiry = Number(new Date(JSON.parse(sessionData).expiry));

  const msInMinute = 60 * 1000;

  return Math.round(Math.abs(tokenExpiry - currentTime) / msInMinute);
};

const getUserFromSession = (): UserSession => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user === "object" ? user : JSON.parse(user)) : null;
};


/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
  setAuthorization(user);
}

class APICore {
  /**
   * Fetches data from given url
   */
  refreshTokenIfExpired = async () => {
    let isTokenRefreshed = false;
    if (sessionStorage.getItem("vayana_lms_user") !== "" && sessionStorage.getItem("vayana_lms_user") !== null) {
      let getExpiryDiff = getTokenExpiryDiff();

      if (getExpiryDiff <= 5 && getExpiryDiff >= 1) {
        let response = await axios.put(`${config.BACKEND_BASE_URL}/login`, {});
        this.setLoggedInUser(response.data.data);
        setAuthorization(response.data.data);

        isTokenRefreshed = true;
      }
    }

    return isTokenRefreshed;
  };

  get = async (url: string, params: any) => {
    let response;
    let filterData: any;
    let moreFilters: any = [];

    if (params) {
      await this.refreshTokenIfExpired();

      var queryString = Object.keys(params).map(key => {
        if (key === "sortField") {
        }
        if (key !== "filters") {
          filterData = key + "=" + params[key];
        } else {
          Object.keys(params["filters"]).map(filter => {
            params[key][filter].forEach((element: any) => {
              filterData = key + "[" + filter + "]" + "[$" + element["matchMode"] + "]" + "=" + encodeURIComponent(element["value"]);
              moreFilters.push(filterData);
            });
          });
          filterData = moreFilters.join("&");
        }
        return filterData;
      });
      response = axios.get(`${url}?${queryString.join("&")}`, params);

      // var queryString = params ? Object.keys(params).map((key) => key + '=' + params[key]).join('&') : '';
    
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = async (url: string, params: any) => {
    let response;
    if (params) {
      await this.refreshTokenIfExpired();
      var queryString = params
        ? Object.keys(params)
            .map(key => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = async (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      await this.refreshTokenIfExpired();
      queryString = params
        ? Object.keys(params)
            .map(key => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = async (url: string, data: any) => {
    await this.refreshTokenIfExpired();

    return axios.post(url, data);
  };

  /**
   * Updates patch data
   */
  updatePatch = async (url: string, data: any) => {
    await this.refreshTokenIfExpired();
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = async (url: string, data: any) => {
    await this.refreshTokenIfExpired();
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = async (url: string) => {
    await this.refreshTokenIfExpired();
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = async (url: string, data: any) => {
    await this.refreshTokenIfExpired();
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data"
      }
    };
    return axios.post(url, formData, config);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = async (url: string, data: any) => {
    await this.refreshTokenIfExpired();
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data"
      }
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();
    if (!user || (user && !user.token)) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      this.refreshTokenIfExpired();
      console.warn("access token expired");
      sessionStorage.removeItem("vayana_lms_user");
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: UserSession | null) => {
    if (session) sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = (): UserSession => {
    return getUserFromSession();
  };
}

export { APICore, setAuthorization, getTokenExpiryDiff};
