import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum EodBodReduxType {
  START_EOD_JOB = "@auth/START_EOD_JOB",
  START_EOD_JOB_SUCCESS = "@auth/START_EOD_JOB_SUCCESS",
  START_EOD_JOB_ERROR = "@auth/START_EOD_JOB_ERROR",

  START_EOD_JOB_LOGGED_IN_USERS = "@@auth/START_EOD_JOB_LOGGED_IN_USERS",
  START_EOD_JOB_LOGGED_IN_USERS_SUCCESS = "@@auth/START_EOD_JOB_LOGGED_IN_USERS_SUCCESS",
  START_EOD_JOB_LOGGED_IN_USERS_ERROR = "@@auth/START_EOD_JOB_LOGGED_IN_USERS_ERROR",

  GET_EOD_JOB_STATUS = "@auth/GET_EOD_JOB_STATUS",
  GET_EOD_JOB_STATUS_SUCCESS = "@auth/GET_EOD_JOB_STATUS_SUCCESS",
  GET_EOD_JOB_STATUS_ERROR = "@auth/GET_EOD_JOB_STATUS_ERROR",

  GET_BUSINESS_DATE = "@auth/GET_BUSINESS_DATE",
  GET_BUSINESS_DATE_SUCCESS = "@auth/GET_BUSINESS_DATE_SUCCESS",
  GET_BUSINESS_DATE_ERROR = "@auth/GET_BUSINESS_DATE_ERROR",

  INITIAL_UPDATE_BUSINESS_DATE = "@auth/INITIAL_UPDATE_BUSINESS_DATE",
  INITIAL_UPDATE_BUSINESS_DATE_SUCCESS = "@auth/INITIAL_UPDATE_BUSINESS_DATE_SUCCESS",
  INITIAL_UPDATE_BUSINESS_DATE_ERROR = "@auth/INITIAL_UPDATE_BUSINESS_DATE_ERROR",
  RESET_INITIAL_UPDATE_BUSINESS_DATE = "@auth/RESET_INITIAL_UPDATE_BUSINESS_DATE",

  UPDATE_BUSINESS_DATE = "@auth/UPDATE_BUSINESS_DATE",
  UPDATE_BUSINESS_DATE_SUCCESS = "@auth/UPDATE_BUSINESS_DATE_SUCCESS",
  UPDATE_BUSINESS_DATE_ERROR = "@auth/UPDATE_BUSINESS_DATE_ERROR",

  RESET_EOD_JOB = "@@auth/RESET_EOD_JOB",
  RESET_EOD_JOB_STATUS = "@@auth/RESET_EOD_JOB_STATUS",
  RESET_BUSINESS_DATE = "@@auth/RESET_BUSINESS_DATE",

  FORCE_LOGOUT = "@auth/FORCE_LOGOUT",
  FORCE_LOGOUT_SUCCESS = "@auth/FORCE_LOGOUT_SUCCESS",
  FORCE_LOGOUT_ERROR = "@auth/FORCE_LOGOUT_ERROR",
  RESET_FORCE_LOGOUT = "@@auth/RESET_FORCE_LOGOUT"
}

export type EodBodActionDispatch = {
  type:
  | EodBodReduxType.START_EOD_JOB
  | EodBodReduxType.GET_EOD_JOB_STATUS
  | EodBodReduxType.GET_BUSINESS_DATE
  | EodBodReduxType.UPDATE_BUSINESS_DATE
  | EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE
  | EodBodReduxType.RESET_INITIAL_UPDATE_BUSINESS_DATE
  | EodBodReduxType.RESET_EOD_JOB_STATUS
  | EodBodReduxType.RESET_BUSINESS_DATE
  | EodBodReduxType.FORCE_LOGOUT
  | EodBodReduxType.RESET_FORCE_LOGOUT
  | EodBodReduxType.RESET_EOD_JOB
  | EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS;
  payload: any;
};
export type EodLoggedInJobsActionDispatch = {
  type: EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS;
  payload: any;
}
export type EodLoggedInJobsReducerDispatch = {
  type: | EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS
  | EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_ERROR
  | EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_SUCCESS;

  payload: {
    data?: FetchApiResponse<EodJobLoggedInUsers>;
    error?: string;
  };
}

export type EodBodReducerDispatch = {
  type:
  | EodBodReduxType.START_EOD_JOB
  | EodBodReduxType.START_EOD_JOB_SUCCESS
  | EodBodReduxType.START_EOD_JOB_ERROR
  | EodBodReduxType.GET_EOD_JOB_STATUS
  | EodBodReduxType.GET_EOD_JOB_STATUS_SUCCESS
  | EodBodReduxType.GET_EOD_JOB_STATUS_ERROR
  | EodBodReduxType.GET_EOD_JOB_STATUS
  | EodBodReduxType.GET_EOD_JOB_STATUS_SUCCESS
  | EodBodReduxType.GET_EOD_JOB_STATUS_ERROR
  | EodBodReduxType.GET_BUSINESS_DATE
  | EodBodReduxType.GET_BUSINESS_DATE_SUCCESS
  | EodBodReduxType.GET_BUSINESS_DATE_ERROR
  | EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE
  | EodBodReduxType.RESET_INITIAL_UPDATE_BUSINESS_DATE
  | EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_SUCCESS
  | EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_ERROR
  | EodBodReduxType.UPDATE_BUSINESS_DATE
  | EodBodReduxType.UPDATE_BUSINESS_DATE_SUCCESS
  | EodBodReduxType.UPDATE_BUSINESS_DATE_ERROR
  | EodBodReduxType.RESET_EOD_JOB_STATUS
  | EodBodReduxType.RESET_BUSINESS_DATE
  | EodBodReduxType.FORCE_LOGOUT
  | EodBodReduxType.FORCE_LOGOUT_SUCCESS
  | EodBodReduxType.FORCE_LOGOUT_ERROR
  | EodBodReduxType.RESET_FORCE_LOGOUT
  | EodBodReduxType.RESET_EOD_JOB_STATUS
  | EodBodReduxType.RESET_EOD_JOB

  payload: {
    data?: FetchApiResponse<EodJob>;
    error?: string;
  };
};
export type EodBodJobReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<EodJob>;
  error?: string | null;
};

export type EodJob = {
  id: string;
  externalId: string;
  financierId: string;
  userId: string;
  startTime: string;
  endTime: string;
  status: string;
  version: number;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};
export type EodJobLoggedInUsers = {
  id: string;
  email:string
  name : string
  mobile : string
  lastLoggedInAt:string
  lastLoggedOutAt:string
  checkerRemarks:string
}
export type fetchEodJobStatusRequest = {
  payload: FilterMetadata;
  type: string;
};

export type fetchBusinessDateRequest = {
  payload: FilterMetadata;
  type: string;
};
