import { ProductLimitConfigActionDispatch, ProductLimitConfigReduxTypes } from "./types";

export const fetchProductLimitConfig = (payload: any): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG,
  payload: payload
});

export const createProductLimitConfig = (payload: any): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG,
  payload: payload
});

export const fetchSingleProductLimitConfig = (payload: any): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG,
  payload: payload
});

export const updateProductLimitConfig = (payload: any): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG,
  payload: payload
});

export const resetProductLimitConfig = (): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.RESET,
  payload: {}
});


export const resetSingleProductLimitConfig = (): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.RESET_SINGLE_LIMIT_CONFIG,
  payload: {}
});

export const resetCreateProductLimitConfig = (): ProductLimitConfigActionDispatch => ({
  type: ProductLimitConfigReduxTypes.RESET_CREATE_PRODUCT_LIMIT_CONFIG,
  payload: {}
});


