import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  approveUserRoleApi,
  blockUserRoleApi,
  createUserRoleApi,
  deleteRoleersionApi,
  fetchModulePersmissionsApi,
  fetchRoleVersionDetailsApi,
  fetchUserRoleApi,
  fetchUserRoleLatestVersionApi,
  fetchUserRolesApi,
  fetchUseVRoleersionApi,
  freezeUserRoleApi,
  rejectUserRoleApi,
  unFreezeUserRoleApi,
  updateUserRolesApi
} from "./api";
import {
  UserRoleReduxTypes,
  UserRoleFetchRequest,
  CreateUserRoleRequest,
  FetchUserRoleByExternalIdRequest,
  UpdateUserRoleRequest,
  UserRoleVersionChangeRequest
} from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchUserRoles({ payload, type }: UserRoleFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchUserRolesApi, payload);
    // yield put(UserRoleApiResponseSuccess(UserRoleReduxTypes.FETCH_USER_ROLES, response.data));
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(UserRoleApiResponseError(UserRoleReduxTypes.FETCH_USER_ROLES, error));
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchModulePermission({ payload, type }: UserRoleFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchModulePersmissionsApi, payload);
    // yield put(UserRoleApiResponseSuccess(UserRoleReduxTypes.FETCH_USER_ROLES, response.data));
    yield put({
      type: UserRoleReduxTypes.FETCH_MODULE_PERMISSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(UserRoleApiResponseError(UserRoleReduxTypes.FETCH_USER_ROLES, error));
    yield put({
      type: UserRoleReduxTypes.FETCH_MODULE_PERMISSION_ERROR,
      payload: { error: error }
    });
  }
}

function* createUserRole({ payload, type }: CreateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(createUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.CREATE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.CREATE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchUserRoleByExternalId({ payload, type }: FetchUserRoleByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchUserRoleVersion({ payload, type }: UpdateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(fetchUseVRoleersionApi, payload);

    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteUserRole({ payload, type }: UpdateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(deleteRoleersionApi, payload);

    yield put({
      type: UserRoleReduxTypes.DELETE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.DELETE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchUserRoleVersionDetails({ payload, type }: UpdateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(fetchRoleVersionDetailsApi, payload);

    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchUserRoleLatestVersion({ payload, type }: UpdateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(fetchUserRoleLatestVersionApi, payload);

    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}


function* updtaeUserRole({ payload, type }: UpdateUserRoleRequest): SagaIterator {
  try {
    const response = yield call(updateUserRolesApi, payload);

    yield put({
      type: UserRoleReduxTypes.UPDATE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.UPDATE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* approveUserRole({ payload, type }: UserRoleVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.APPROVE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.APPROVE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectUserRole({ payload, type }: UserRoleVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.REJECT_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.REJECT_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* blockUserRole({ payload, type }: UserRoleVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.BLOCK_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.BLOCK_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeUserRole({ payload, type }: UserRoleVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.FREEZE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.FREEZE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeUserRole({ payload, type }: UserRoleVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeUserRoleApi, payload);

    yield put({
      type: UserRoleReduxTypes.UNFREEZE_USER_ROLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: UserRoleReduxTypes.UNFREEZE_USER_ROLE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchUserRoles() {
  yield takeEvery(UserRoleReduxTypes.FETCH_USER_ROLES, fetchUserRoles);
}

export function* watchCreateUserRoles() {
  yield takeEvery(UserRoleReduxTypes.CREATE_USER_ROLE, createUserRole);
}

export function* watchFetchUserRole() {
  yield takeEvery(UserRoleReduxTypes.FETCH_USER_ROLE, FetchUserRoleByExternalId);
}

export function* watchUpdateUserRoles() {
  yield takeEvery(UserRoleReduxTypes.UPDATE_USER_ROLE, updtaeUserRole);
}

export function* watchUserRolesVersion() {
  yield takeEvery(UserRoleReduxTypes.FETCH_USER_ROLE_VERSION, fetchUserRoleVersion);
}

export function* watchApproveUserRole() {
  yield takeEvery(UserRoleReduxTypes.APPROVE_USER_ROLE, approveUserRole);
}

export function* watchRejectUserRole() {
  yield takeEvery(UserRoleReduxTypes.REJECT_USER_ROLE, rejectUserRole);
}

export function* watchBlockUserRole() {
  yield takeEvery(UserRoleReduxTypes.BLOCK_USER_ROLE, blockUserRole);
}

export function* watchFreezeUserRole() {
  yield takeEvery(UserRoleReduxTypes.FREEZE_USER_ROLE, freezeUserRole);
}

export function* watchUnFreezeUserRole() {
  yield takeEvery(UserRoleReduxTypes.UNFREEZE_USER_ROLE, unFreezeUserRole);
}

export function* watchModulePermission() {
  yield takeEvery(UserRoleReduxTypes.FETCH_MODULE_PERMISSION, fetchModulePermission);
}

export function* watchFetchUserRoleVersionDetails() {
  yield takeEvery(UserRoleReduxTypes.FETCH_USER_ROLE_VERSION_DETAILS, fetchUserRoleVersionDetails);
}

export function* watchFetchUserRoleLatestVersion() {
  yield takeEvery(UserRoleReduxTypes.FETCH_USER_ROLE_LATEST_VERSION, fetchUserRoleLatestVersion);
}


export function* watchDeleteUserRole() {
  yield takeEvery(UserRoleReduxTypes.DELETE_USER_ROLE, deleteUserRole);
}








function* UserRoleSaga() {
  yield all([
    fork(watchFetchUserRoles),
    fork(watchCreateUserRoles),
    fork(watchFetchUserRole),
    fork(watchUpdateUserRoles),
    fork(watchUserRolesVersion),
    fork(watchApproveUserRole),
    fork(watchRejectUserRole),
    fork(watchBlockUserRole),
    fork(watchFreezeUserRole),
    fork(watchUnFreezeUserRole),
    fork(watchModulePermission),
    fork(watchFetchUserRoleVersionDetails),
    fork(watchFetchUserRoleLatestVersion),
    fork(watchDeleteUserRole)
  ]);
}

export default UserRoleSaga;
