import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CitiesFetchRequest, CityReduxTypes, CreateCityRequest, FetchCityByIdRequest, UpdateCityRequest } from "./types";
import {
  approveCityApi,
  blockCityApi,
  createCityApi,
  deleteCityApi,
  fetchCitiesApi,
  fetchCityByIdApi,
  fetchCityLatestVersionApi,
  fetchCityVersionApi,
  fetchCityVersionDetailsApi,
  freezeCityApi,
  rejectCityApi,
  unFreezeCityApi,
  updateCityApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCitiesApi, payload);
    yield put({
      type: CityReduxTypes.FETCH_CITIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.FETCH_CITIES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCityByID({ payload, type }: FetchCityByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCityByIdApi, payload);
    yield put({
      type: CityReduxTypes.FETCH_CITY_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CityReduxTypes.FETCH_CITY_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createCity({ payload, type }: CreateCityRequest): SagaIterator {
  try {
    const response = yield call(createCityApi, payload);

    yield put({
      type: CityReduxTypes.CREATE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CityReduxTypes.CREATE_CITY_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCity({ payload, type }: UpdateCityRequest): SagaIterator {
  try {
    const response = yield call(updateCityApi, payload);

    yield put({
      type: CityReduxTypes.UPDATE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CityReduxTypes.UPDATE_CITY_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteCity({ payload, type }: UpdateCityRequest): SagaIterator {
  try {
    const response = yield call(deleteCityApi, payload);

    yield put({
      type: CityReduxTypes.DELETE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CityReduxTypes.DELETE_CITY_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchCitiesVersion({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCityVersionApi, payload);
    yield put({
      type: CityReduxTypes.FETCH_CITY_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.FETCH_CITY_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCitiesVersionDetails({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCityVersionDetailsApi, payload);
    yield put({
      type: CityReduxTypes.FETCH_CITY_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.FETCH_CITY_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCitiesLatestVersion({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCityLatestVersionApi, payload);
    yield put({
      type: CityReduxTypes.FETCH_CITY_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.FETCH_CITY_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* approveCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(approveCityApi, payload);
    yield put({
      type: CityReduxTypes.APPROVE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.APPROVE_CITY_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectCityApi, payload);
    yield put({
      type: CityReduxTypes.REJECT_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.REJECT_CITY_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(blockCityApi, payload);
    yield put({
      type: CityReduxTypes.BLOCK_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.BLOCK_CITY_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeCityApi, payload);
    yield put({
      type: CityReduxTypes.FREEZE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.FREEZE_CITY_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCities({ payload, type }: CitiesFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCityApi, payload);
    yield put({
      type: CityReduxTypes.UNFREEZE_CITY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CityReduxTypes.UNFREEZE_CITY_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCities() {
  yield takeEvery(CityReduxTypes.FETCH_CITIES, fetchCities);
}
export function* watchCreateCity() {
  yield takeEvery(CityReduxTypes.CREATE_CITY, createCity);
}
export function* watchFetchCityById() {
  yield takeEvery(CityReduxTypes.FETCH_CITY_BY_ID, fetchCityByID);
}
export function* watchUpdateCity() {
  yield takeEvery(CityReduxTypes.UPDATE_CITY, updateCity);
}
export function* watchVerisonCity() {
  yield takeEvery(CityReduxTypes.FETCH_CITY_VERSION, fetchCitiesVersion);
}
export function* watchApprovetCity() {
  yield takeEvery(CityReduxTypes.APPROVE_CITY, approveCities);
}
export function* watchRejectCity() {
  yield takeEvery(CityReduxTypes.REJECT_CITY, rejectCities);
}

export function* watchBlockCity() {
  yield takeEvery(CityReduxTypes.BLOCK_CITY, blockCities);
}

export function* watchFreezeCity() {
  yield takeEvery(CityReduxTypes.FREEZE_CITY, freezeCities);
}

export function* watchUnFreezeCity() {
  yield takeEvery(CityReduxTypes.UNFREEZE_CITY, unFreezeCities);
}

export function* watchFetchCitiesVersionDetails() {
  yield takeEvery(CityReduxTypes.FETCH_CITY_VERSION_DETAILS, fetchCitiesVersionDetails);
}

export function* watchFetchCitiesLatestVersion() {
  yield takeEvery(CityReduxTypes.FETCH_CITY_LATEST_VERSION, fetchCitiesLatestVersion);
}

export function* watchDeleteCity() {
  yield takeEvery(CityReduxTypes.DELETE_CITY, deleteCity);
}




function* citySaga() {
  yield all([
    fork(watchFetchCities),
    fork(watchCreateCity),
    fork(watchFetchCityById),
    fork(watchUpdateCity),
    fork(watchVerisonCity),
    fork(watchApprovetCity),
    fork(watchRejectCity),
    fork(watchBlockCity),
    fork(watchFreezeCity),
    fork(watchUnFreezeCity),
    fork(watchFetchCitiesVersionDetails),
    fork(watchFetchCitiesLatestVersion),
    fork(watchDeleteCity)
  ]);
}

export default citySaga;
