import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchPaymentBatchApi(params: any) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batches/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

function fetchPaymentBatchesApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batches`;
  return api.get(`${baseUrl}`, params);
}

function fetchVersionPaymentBatchesApi(params: any) {
  
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batch-actions/${params?.externalId}/action/versions`;

  return api.get(`${baseUrl}`, params.filter);
}

function fetchPaymentBatchAuditActionsApi(params:FilterMetadata){
  const baseUrl=`${config.BACKEND_TXN_BASE_URL}/action-audit`;
  return api.get(`${baseUrl}`,params)
}

function approvePaymentBatchesApi(params: any) {
  let data = {
    checkerRemarks: params?.checkerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batch-actions/${params.externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, data);
}

function rejectPaymentBatchesApi(params: any) {
  
  let data = {
    checkerRemarks: params?.checkerRemarks
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batch-actions/${params.externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, data);
}

function markeRemarkReProcessingPaymentBatchesApi(params: any) {
  
  let data = {
    paymentBatchId: params?.paymentBatchId,
    paymentBatchExternalId: params?.externalId,
    makerRemarks: params?.values?.makerRemarks,
    typePayload:params?.typePayload,
    paymentBatchActionType:params?.paymentBatchActionType
  };
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-batch-actions`;
  return api.create(`${baseUrl}`, data);
}
export {
  fetchPaymentBatchesApi,
  fetchPaymentBatchApi,
  fetchVersionPaymentBatchesApi,
  fetchPaymentBatchAuditActionsApi,
  approvePaymentBatchesApi,
  rejectPaymentBatchesApi,
  markeRemarkReProcessingPaymentBatchesApi
};
