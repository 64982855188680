import { BaseRate } from "redux/baseRate/types";
import { Customer } from "redux/customer/types";
import { ProductType } from "redux/productType/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum BuyerSellerReduxTypes {
  FETCH_BUYER_SELLERS = "@@auth/FETCH_BUYER_SELLERS",
  FETCH_BUYER_SELLERS_SUCCESS = "@@auth/FETCH_BUYER_SELLERS_SUCCESS",
  FETCH_BUYER_SELLERS_ERROR = "@@auth/FETCH_BUYER_SELLERS_ERROR",

  CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS = "@@auth/CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS",
  CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS = "@@auth/CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS",
  CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR = "@@auth/CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR",

  FETCH_BUYER_SELLER = "@@auth/FETCH_BUYER_SELLER",
  FETCH_BUYER_SELLER_SUCCESS = "@@auth/FETCH_BUYER_SELLER_SUCCESS",
  FETCH_BUYER_SELLER_ERROR = "@@auth/FETCH_BUYER_SELLER_ERROR",
  RESET_BUYER_SELLER = "@@auth/RESET_BUYER_SELLER",

  FETCH__BUYER_SELLER_VERSION = "@@auth/FETCH__BUYER_SELLER_VERSION",
  FETCH__BUYER_SELLER_VERSION_SUCCESS = "@@auth/FETCH__BUYER_SELLER_VERSION_SUCCESS",
  FETCH__BUYER_SELLER_VERSION_ERROR = "@@auth/FETCH__BUYER_SELLER_VERSION_ERROR",

  FETCH__BUYER_SELLER_LATEST_VERSION = "@@auth/FETCH__BUYER_SELLER_LATEST_VERSION",
  FETCH__BUYER_SELLER_LATEST_VERSION_SUCCESS = "@@auth/FETCH__BUYER_SELLER_LATEST_VERSION_SUCCESS",
  FETCH__BUYER_SELLER_LATEST_VERSION_ERROR = "@@auth/FETCH__BUYER_SELLER_LATEST_VERSION_ERROR",

  FETCH__BUYER_SELLER_VERSION_DETAILS = "@@auth/FETCH__BUYER_SELLER_VERSION_DETAILS",
  FETCH__BUYER_SELLER_VERSION_DETAILS_SUCCESS = "@@auth/FETCH__BUYER_SELLER_VERSION_DETAILS_SUCCESS",
  FETCH__BUYER_SELLER_VERSION_DETAILS_ERROR = "@@auth/FETCH__BUYER_SELLER_VERSION_DETAILS_ERROR",

  VALIDATE_BUYER_SELLER = "@@auth/VALIDATE_BUYER_SELLER",
  VALIDATE_BUYER_SELLER_SUCCESS = "@@auth/VALIDATE_BUYER_SELLER_SUCCESS",
  VALIDATE_BUYER_SELLER_ERROR = "@@auth/VALIDATE_BUYER_SELLER_ERROR",

  CREATE_BUYER_SELLER = "@@auth/CREATE_BUYER_SELLER",
  CREATE_BUYER_SELLER_SUCCESS = "@@auth/CREATE_BUYER_SELLER_SUCCESS",
  CREATE_BUYER_SELLER_ERROR = "@@auth/CREATE_BUYER_SELLER_ERROR",

  DELETE_BUYER_SELLER = "@@auth/DELETE_BUYER_SELLER",
  DELETE_BUYER_SELLER_SUCCESS = "@@auth/DELETE_BUYER_SELLER_SUCCESS",
  DELETE_BUYER_SELLER_ERROR = "@@auth/DELETE_BUYER_SELLER_ERROR",

  APROVE_BUYER_SELLER = "@@auth/APROVE_BUYER_SELLER",
  APROVE_BUYER_SELLER_SUCCESS = "@@auth/APROVE_BUYER_SELLER_SUCCESS",
  APROVE_BUYER_SELLER_ERROR = "@@auth/APROVE_BUYER_SELLER_ERROR",

  REJECT_BUYER_SELLER = "@@auth/REJECT_BUYER_SELLER",
  REJECT_BUYER_SELLER_SUCCESS = "@@auth/REJECT_BUYER_SELLER_SUCCESS",
  REJECT_BUYER_SELLER_ERROR = "@@auth/REJECT_BUYER_SELLER_ERROR",

  BLOCK_BUYER_SELLER = "@@auth/BLOCK_BUYER_SELLER",
  BLOCK_BUYER_SELLER_SUCCESS = "@@auth/BLOCK_BUYER_SELLER_SUCCESS",
  BLOCK_BUYER_SELLER_ERROR = "@@auth/BLOCK_BUYER_SELLER_ERROR",

  UPDATE_BUYER_SELLER = "@@auth/UPDATE_BUYER_SELLER",
  UPDATE_BUYER_SELLER_SUCCESS = "@@auth/UPDATE_BUYER_SELLER_SUCCESS",
  UPDATE_BUYER_SELLER_ERROR = "@@auth/UPDATE_BUYER_SELLER_ERROR",

  FREEZE_BUYER_SELLER = "@@auth/FREEZE_BUYER_SELLER",
  FREEZE_BUYER_SELLER_SUCCESS = "@@auth/FREEZE_BUYER_SELLER_SUCCESS",
  FREEZE_BUYER_SELLER_ERROR = "@@auth/FREEZE_BUYER_SELLER_ERROR",

  UNFREEZE_BUYER_SELLER = "@@auth/UNFREEZE_BUYER_SELLER",
  UNFREEZE_BUYER_SELLER_SUCCESS = "@@auth/UNFREEZE_BUYER_SELLER_SUCCESS",
  UNFREEZE_BUYER_SELLER_ERROR = "@@auth/UNFREEZE_BUYER_SELLER_ERROR",

  FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER = "@@auth/FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER",
  FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_SUCCESS = "@@auth/FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_SUCCESS",
  FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_ERROR = "@@auth/FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_ERROR",
  RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER = "@@auth/RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER",

  FETCH_CONFIG_VALUE_BUYER_SELLER = "@@auth/FETCH_CONFIG_VALUE_BUYER_SELLER",
  FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_SUCCESS = "@@auth/FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_SUCCESS",
  FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_ERROR = "@@auth/FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_ERROR",
  RESET_CONFIG_VALUE_BUYER_SELLER = "@@auth/RESET_CONFIG_VALUE_BUYER_SELLER",

  RESET = "@@auth/RESET",
  RESET_CREATED_BUYER_SELLER_REDUCER = "@@auth/RESET_CREATED_BUYER_SELLER_REDUCER",
  RESET_UPDATED_BUYER_SELLER_REDUCER = "@@auth/RESET_UPDATED_BUYER_SELLER_REDUCER"
}

export type BuyerSellerActionDispatch = {
  type:
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLERS
    | BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLER
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS
    | BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER
    | BuyerSellerReduxTypes.CREATE_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET
    | BuyerSellerReduxTypes.RESET_CREATED_BUYER_SELLER_REDUCER
    | BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER
    | BuyerSellerReduxTypes.APROVE_BUYER_SELLER
    | BuyerSellerReduxTypes.REJECT_BUYER_SELLER
    | BuyerSellerReduxTypes.BLOCK_BUYER_SELLER
    | BuyerSellerReduxTypes.FREEZE_BUYER_SELLER
    | BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER
    | BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET_CONFIG_VALUE_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET_BUYER_SELLER
    | BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER
    | BuyerSellerReduxTypes.UPDATE_BUYER_SELLER
    | BuyerSellerReduxTypes.DELETE_BUYER_SELLER;

  payload: any;
};
export type BuyerSellerReducerDispatch = {
  type:
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLERS
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_SUCCESS
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_ERROR
    | BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS
    | BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS
    | BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.FETCH_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_SUCCESS
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_ERROR
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_SUCCESS
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_ERROR
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_SUCCESS
    | BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_ERROR
    | BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.CREATE_BUYER_SELLER
    | BuyerSellerReduxTypes.CREATE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.CREATE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.APROVE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.APROVE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.REJECT_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.REJECT_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.UPDATE_BUYER_SELLER
    | BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER
    | BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.RESET_CONFIG_VALUE_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET_BUYER_SELLER
    | BuyerSellerReduxTypes.RESET
    | BuyerSellerReduxTypes.RESET_CREATED_BUYER_SELLER_REDUCER
    | BuyerSellerReduxTypes.DELETE_BUYER_SELLER
    | BuyerSellerReduxTypes.DELETE_BUYER_SELLER_SUCCESS
    | BuyerSellerReduxTypes.DELETE_BUYER_SELLER_ERROR
    | BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER;

  payload: {
    data?: FetchApiResponse<BuyerSeller>;
    error?: string;
  };
};

export type BuyerSeller = {
  freeze: boolean;
  id: string;
  externalId: string;
  programmeId: ProductType;
  customer: Customer;
  invoiceConcentration: string;
  instrumentDueDateCalculation: string;
  dueDateStrategy: string;
  normalBaseRate: BaseRate;
  normalSpreadRateOfInterest: string;
  normalTotalRateOfInterest: string;
  adhocSpreadRateOfInterest: string;
  adhocTotalRateOfInterest: string;
  penalRateOfInterest: string;
  interestType: string;
  consolidationType: string;
  graceDays: string;
  staleDays: string;
  autoFinance: boolean;
  graceRateOfInterest: string;
  validateCreditAccount: boolean;
  autoDebit: boolean;
  autoDebitBankAccountNumber: string;
  interestDebitParty: string;
  penalDebitParty: string;
  principalRepaymentBy: string;
  startDate: string;
  endDate: string;
  relationshipAccountNumber: string;
  repaymentBankAccountNumber: string;
  additionalDetails: string;
  remarks: string;
  active: boolean;
  status: string;
  draft: boolean;
};

export type BuyerSellersFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type BuyerSellersReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<BuyerSeller>;
  error?: string | null;
};

export type CreateBuyerSeller = {
  programmeId: ProductType;
  customer: Customer;
  invoiceConcentration: string;
  instrumentDueDateCalculation: string;
  dueDateStrategy: string;

  normalBaseRateId: BaseRate;
  normalSpreadRateOfInterest: string;
  adhocBaseRateId: BaseRate;
  adhocSpreadRateOfInterest: string;
  penalRateOfInterest: string;
  interestType: string;
  consolidationType: string;
  graceDays: string;
  staleDays: string;

  autoFinance: boolean;
  graceRateOfInterest: string;
  validateCreditAccount: boolean;
  autoDebit: boolean;
  autoDebitBankAccountNumber: string;

  relationshipAccountNumber: string;
  repaymentBankAccountNumber: string;

  interestDebitParty: string;
  principalRepaymentBy: string;
  penalDebitParty: string;

  normalBorrowerLimitId: string;
  adhocBorrowerLimitId: string;
  additionalDetails: string;
  remarks: string;
};
export type CreateBuyerSellerRequest = {
  payload: CreateBuyerSeller;
  type: string;
};
export type CreateBuyerSellerReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<BuyerSeller>;
  error?: string | null;
};

export type UpdateBuyerSellerRequest = {
  payload: BuyerSeller;
  type: string;
};

export type errors = {
  field: string;
  message: string;
};
export type ValidationReduxState = {
  loading?: boolean;
  data?: errors;
  error?: string | null;
};

export type updateBuyerSellerVersion = {
  checkerRemarks: string;
  externalCustomerId: string;
};

export type BuyerSellerVersionChangeRequest = {
  payload: updateBuyerSellerVersion;
  type: string;
};

export type blockBuyerSeller = {
  pan: string;
  emailId: string;
  contactNumber: string;
};

export type blockBuyerSellerRequest = {
  payload: blockBuyerSeller;
  type: string;
};
