import {
  CreateRelationshipManagerReduxState,
  CreaterelationshipManagerReduxState,
  RelationshipManagerReducerDispatch,
  RelationShipManagerReduxState,
  RelationshipManagerReduxTypes
} from "./types";

const RELATIONSHIP_MANAGER_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CREATE_RELATIONSHIP_MANAGER_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const RelationshipManagerListReducer = (state: RelationShipManagerReduxState = RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_SUCCESS:
    case RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ERROR:
    case RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

// const usersReducer = (state: RelationshipManagerReduxTypes = initialState, action) => {
//     switch (action.type) {
//         case Types.GET_USERS_SUCCESS:
//             return {
//                 ...state,
//                 items: action.payload.items
//             };
//         default:
//             return state;
//     }
// };

const CreaterelationshipManagerReducer = (state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RelationshipMaanagerReducer = (state: CreateRelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const  DeleteRelationshipMaanagerReducer = (state: CreateRelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER: {
      return {
        ...state,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.DELETE_FRELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};



const RelationshipMaanagerLatestVersionReducer = (state: CreateRelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_SUCCESS
    : {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const RelationshipMaanagerVersionDetailsReducer = (state: CreateRelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateRelationshipMaanagerReducer = (
  state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE,
  action: RelationshipManagerReducerDispatch
) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case RelationshipManagerReduxTypes.RESET_UPDATE_RELATIONSHIP_MANAGER:
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RelationshipManagerListVersionReducer = (state: RelationShipManagerReduxState = RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveRelationshipManagerReducer = (state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.APROVE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.APROVE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.APROVE_FRELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectRelationshipManagerReducer = (state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockRelationshipManagerReducer = (state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeRelationshipManagerReducer = (state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE, action: RelationshipManagerReducerDispatch) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeRelationshipManagerReducer = (
  state: CreaterelationshipManagerReduxState = CREATE_RELATIONSHIP_MANAGER_INIT_STATE,
  action: RelationshipManagerReducerDispatch
) => {
  switch (action.type) {
    case RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RelationshipManagerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  RelationshipManagerListReducer,
  CreaterelationshipManagerReducer,
  RelationshipMaanagerReducer,
  UpdateRelationshipMaanagerReducer,
  RelationshipManagerListVersionReducer,
  AproveRelationshipManagerReducer,
  RejectRelationshipManagerReducer,
  BlockRelationshipManagerReducer,
  FreezeRelationshipManagerReducer,
  UnFreezeRelationshipManagerReducer,
  RelationshipMaanagerVersionDetailsReducer,
  RelationshipMaanagerLatestVersionReducer,
  DeleteRelationshipMaanagerReducer
};
