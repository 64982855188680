import { CreateLimitReportReduxState, LimitReportActionDispatch, LimitReportReducerDispatch, LimitReportReduxTypes } from "./types"

const CREATE_LIMIT_REPORT_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateLimitReportReducer = (state: CreateLimitReportReduxState = CREATE_LIMIT_REPORT_INIT_STATE, action: LimitReportReducerDispatch) => {
    switch (action.type) {
        case LimitReportReduxTypes.LIMIT_REPORT: {
            return {
                ...state,
                loading: true
            }
        }
        case LimitReportReduxTypes.LIMIT_REPORT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        }

        case LimitReportReduxTypes.LIMIT_REPORT_ERROR: {
            return {
                data: {},
                error: action.payload.error,
                loading: false
            }
        }


        default:
            return { ...state };
    }
}
export { CreateLimitReportReducer }