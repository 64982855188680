import { AddressReducerDispatch, AddressReduxState, AddressReduxTypes, CreateAddressReduxState } from "./types";

const ADDRESS_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};
const CustomerAddressListReducer = (state: AddressReduxState = ADDRESS_LIST_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FETCH_ADDRESSES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const CREATE_ADDRESS_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.CREATE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.CREATE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.DELETE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.DELETE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const FetchAddressVersionDetailsReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }

    case AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerAddressReducer = (state: CreateAddressReduxState = ADDRESS_LIST_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FETCH_BYID_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.FETCH_BYID_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.UPDATE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.UPDATE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AddressListVersionReducer = (state: AddressReduxState = ADDRESS_LIST_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FETCH_ADDRESSES_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const FetchAddressLatestVersionReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case AddressReduxTypes.RESET: {
      return {
        ...state,
        error: {},
        data: {},
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const AproveCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.APROVE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.APROVE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.REJECT_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.REJECT_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.BLOCK_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.BLOCK_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.FREEZE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.FREEZE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCustomerAddressReducer = (state: CreateAddressReduxState = CREATE_ADDRESS_INIT_STATE, action: AddressReducerDispatch) => {
  switch (action.type) {
    case AddressReduxTypes.UNFREEZE_ADDRESSES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case AddressReduxTypes.UNFREEZE_ADDRESSES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case AddressReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  AddressListVersionReducer,
  CustomerAddressListReducer,
  CreateAddressReducer,
  CustomerAddressReducer,
  UpdateCustomerAddressReducer,
  AproveCustomerAddressReducer,
  RejectCustomerAddressReducer,
  BlockCustomerAddressReducer,
  FreezeCustomerAddressReducer,
  UnFreezeCustomerAddressReducer,
  FetchAddressVersionDetailsReducer,
  FetchAddressLatestVersionReducer,
  DeleteAddressReducer
};
