import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { createProductLimitConfigApi, fetchProductLimitConfigApi, fetchSingleProductLimitConfigApi, updateProductLimitConfigApi } from "./api";
import { CreateProductLimitConfigRequest, ProductLimitConfigFetchRequest, ProductLimitConfigReduxTypes, UpdateProductLimitConfigRequest } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchProductLimitConfig({ payload, type }: ProductLimitConfigFetchRequest): SagaIterator {
    try {
        const response = yield call(fetchProductLimitConfigApi, payload);
        yield put({
            type: ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
        yield put({
            type: ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG_ERROR,
            payload: { error: error },
        });
    }
}


function* createProductLimitConfig({ payload, type }: CreateProductLimitConfigRequest): SagaIterator {
    try {
        const response = yield call(createProductLimitConfigApi, payload);

        yield put({
            type: ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {

        yield put({
            type: ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG_ERROR,
            payload: { error: error },
        });
    }
}

function* fetchSingleProductLimitConfig({ payload, type }: ProductLimitConfigFetchRequest): SagaIterator {
    try {
        const response = yield call(fetchSingleProductLimitConfigApi, payload);
        yield put({
            type: ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
        yield put({
            type: ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG_ERROR,
            payload: { error: error },
        });
    }
}

function* updateProductLimitConfig({ payload, type }: UpdateProductLimitConfigRequest): SagaIterator {
    try {
        const response = yield call(updateProductLimitConfigApi, payload);

        yield put({
            type: ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {

        yield put({
            type: ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG_ERROR,
            payload: { error: error },
        });
    }
}

export function* watchFetchProductLimitConfig() {
    yield takeEvery(ProductLimitConfigReduxTypes.FETCH_PRODUCT_LIMIT_CONFIG, fetchProductLimitConfig);
}
export function* watchCreateProductLimitConfig() {
    yield takeEvery(ProductLimitConfigReduxTypes.CREATE_PRODUCT_LIMIT_CONFIG,createProductLimitConfig);
}
export function* watchFetchSingleProductLimitConfig() {
    yield takeEvery(ProductLimitConfigReduxTypes.FETCH_SINGLE_PRODUCT_LIMIT_CONFIG,fetchSingleProductLimitConfig);
}
export function* watchUpdateProductLimitConfig() {
    yield takeEvery(ProductLimitConfigReduxTypes.UPDATE_PRODUCT_LIMIT_CONFIG,updateProductLimitConfig);
}

function* productLimitConfigSaga() {
    yield all([
        fork(watchFetchProductLimitConfig),
        fork(watchCreateProductLimitConfig),
        fork(watchFetchSingleProductLimitConfig),
        fork(watchUpdateProductLimitConfig),
    ]);
}

export default productLimitConfigSaga;
