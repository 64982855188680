import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  approveLimitConfigApi,
  blockLimitConfigApi,
  createLimitConfigApi,
  deleteLimitConfigApi,
  fetchLimitConfigApi,
  fetchLimitConfigByCustomerExternalIdApi,
  fetchLimitConfigLatestVersionApi,
  fetchLimitConfigVersionApi,
  fetchLimitConfigVersionDetailsApi,
  fetchSingleAggregateLimitConfigHistroyApi,
  fetchSingleLimitConfigApi,
  fetchSingleLimitConfigHistroyApi,
  freezeLimitConfigApi,
  rejectLimitConfigApi,
  unFreezeLimitConfigApi,
  updateLimitConfigApi
} from "./api";
import { CreateLimitConfigRequest, LimitConfigFetchRequest, LimitConfigReduxTypes, limitConfigVersionChangeRequest, UpdateLimitConfigRequest } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchLimitConfig({ payload, type }: LimitConfigFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLimitConfigApi, payload);
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLimitConfigByCustomerExternalId({ payload, type }: LimitConfigFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLimitConfigByCustomerExternalIdApi, payload);
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLimitConfigHistroy({ payload, type }: LimitConfigFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchSingleLimitConfigHistroyApi, payload);
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchAggregateLimitConfigHistroy({ payload, type }: LimitConfigFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchSingleAggregateLimitConfigHistroyApi, payload);
    yield put({
      type: LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY_ERROR,
      payload: { error: error }
    });
  }
}

function* createLimitConfig({ payload, type }: CreateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(createLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.CREATE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteLimitConfig({ payload, type }: CreateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(deleteLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.DELETE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchSingleLimitConfig({ payload, type }: LimitConfigFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchSingleLimitConfigApi, payload);
    yield put({
      type: LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* updateLimitConfig({ payload, type }: UpdateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(updateLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLimitConfigVersion({ payload, type }: UpdateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(fetchLimitConfigVersionApi, payload);

    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLimitConfigLatestVersion({ payload, type }: UpdateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(fetchLimitConfigLatestVersionApi, payload);

    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLimitConfigVersionDetails({ payload, type }: UpdateLimitConfigRequest): SagaIterator {
  try {
    const response = yield call(fetchLimitConfigVersionDetailsApi, payload);

    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveLimitConfig({ payload, type }: limitConfigVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectLimitConfig({ payload, type }: limitConfigVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.REJECT_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* blockLimitConfig({ payload, type }: limitConfigVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* freezekLimitConfig({ payload, type }: limitConfigVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezekLimitConfig({ payload, type }: limitConfigVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeLimitConfigApi, payload);

    yield put({
      type: LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG, fetchLimitConfig);
}
export function* watchFetchLimitConfigByCustomerExternalId() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_BY_CUSTOMER_EXTERNAL_ID, fetchLimitConfigByCustomerExternalId);
}
export function* watchCreateLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.CREATE_LIMIT_CONFIG, createLimitConfig);
}
export function* watchSingleLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_SINGLE_LIMIT_CONFIG, fetchSingleLimitConfig);
}
export function* watchUpdateLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.UPDATE_LIMIT_CONFIG, updateLimitConfig);
}
export function* watchLimitConfigVersion() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION, fetchLimitConfigVersion);
}

export function* watchApproveLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.APPROVE_LIMIT_CONFIG, approveLimitConfig);
}

export function* watchRejectLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.REJECT_LIMIT_CONFIG, rejectLimitConfig);
}

export function* watchBlockLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.BLOCK_LIMIT_CONFIG, blockLimitConfig);
}

export function* watchFreezeLimitConfign() {
  yield takeEvery(LimitConfigReduxTypes.FREEZE_LIMIT_CONFIG, freezekLimitConfig);
}

export function* watchUnFreezeLimitConfign() {
  yield takeEvery(LimitConfigReduxTypes.UNFREEZE_LIMIT_CONFIG, unFreezekLimitConfig);
}

export function* watchLimitConfigHistroy() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_HISTROY, fetchLimitConfigHistroy);
}
export function* watchAggregateLimitConfigHistroy() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_AGGREGATE_LIMIT_CONFIG_HISTROY, fetchAggregateLimitConfigHistroy);
}

export function* wacthFetchLimitConfigVersionDetails() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_VERSION_DETAILS, fetchLimitConfigVersionDetails);
}

export function* watchFetchLimitConfigLatestVersion() {
  yield takeEvery(LimitConfigReduxTypes.FETCH_LIMIT_CONFIG_LATEST_VERSION, fetchLimitConfigLatestVersion);
}

export function* watchDeleteLimitConfig() {
  yield takeEvery(LimitConfigReduxTypes.DELETE_LIMIT_CONFIG, deleteLimitConfig);
}

function* limitConfigSaga() {
  yield all([
    fork(watchFetchLimitConfig),
    fork(watchFetchLimitConfigByCustomerExternalId),
    fork(watchCreateLimitConfig),
    fork(watchSingleLimitConfig),
    fork(watchUpdateLimitConfig),
    fork(watchLimitConfigVersion),
    fork(watchApproveLimitConfig),
    fork(watchRejectLimitConfig),
    fork(watchBlockLimitConfig),
    fork(watchFreezeLimitConfign),
    fork(watchUnFreezeLimitConfign),
    fork(watchLimitConfigHistroy),
    fork(watchAggregateLimitConfigHistroy),
    fork(wacthFetchLimitConfigVersionDetails),
    fork(watchFetchLimitConfigLatestVersion),
    fork(watchDeleteLimitConfig)
  ]);
}

export default limitConfigSaga;
