import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum StagingBatchReduxTypes {
  FETCH_STAGING_BATCHES = "@@auth/FETCH_STAGING_BATCHES",
  FETCH_STAGING_BATCHES_SUCCESS = "@@auth/FETCH_STAGING_BATCHES_SUCCESS",
  FETCH_STAGING_BATCHES_ERROR = "@@auth/FETCH_STAGING_BATCHES_ERROR",
  RESET_FETCH_STAGING_BATCHES = "@@auth/RESET_FETCH_STAGING_BATCHES",

  FETCH_STAGING_BATCHES_CHILD = "@@auth/FETCH_STAGING_BATCHES_CHILD",
  FETCH_STAGING_BATCHES_CHILD_SUCCESS = "@@auth/FETCH_STAGING_BATCHES_CHILD_SUCCESS",
  FETCH_STAGING_BATCHES_CHILD_ERROR = "@@auth/FETCH_STAGING_BATCHES_CHILD_ERROR",
  RESET_FETCH_STAGING_BATCHES_CHILD = "@@auth/RESET_FETCH_STAGING_BATCHES_CHILD",

  FETCH_STAGING_BATCH = "@@auth/FETCH_STAGING_BATCH",
  FETCH_STAGING_BATCH_SUCCESS = "@@auth/FETCH_STAGING_BATCH_SUCCESS",
  FETCH_STAGING_BATCH_ERROR = "@@auth/FETCH_STAGING_BATCH_ERROR",

  FETCH_STAGING_BATCH_VERSION = "@@auth/FETCH_STAGING_BATCH_VERSION",
  FETCH_STAGING_BATCH_VERSION_SUCCESS = "@@auth/FETCH_STAGING_BATCH_VERSION_SUCCESS",
  FETCH_STAGING_BATCH_VERSION_ERROR = "@@auth/FETCH_STAGING_BATCH_VERSION_ERROR",

  REPROCESS_STAGING_BATCHES = "@@auth/REPROCESS_STAGING_BATCHES",
  REPROCESS_STAGING_BATCHES_SUCCESS = "@@auth/REPROCESS_STAGING_BATCHES_SUCCESS",
  REPROCESS_STAGING_BATCHES_ERROR = "@@auth/REPROCESS_STAGING_BATCHES_ERROR",

  SKIP_VALIDATION_STAGING_BATCHES = "@@auth/SKIP_VALIDATION_STAGING_BATCHES",
  SKIP_VALIDATION_STAGING_BATCH_SUCCESS = "@@auth/SKIP_VALIDATION_STAGING_BATCH_SUCCESS",
  SKIP_VALIDATION_STAGING_BATCH_ERROR = "@@auth/SKIP_VALIDATION_STAGING_BATCH_BATCHES_ERROR",

  MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES = "@@auth/MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES",
  MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_SUCCESS = "@@auth/MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_SUCCESS",
  MARKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_ERROR = "@@auth/MARKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_ERROR",

  DISBURSE_STAGING_BATCHES = "@@auth/DISBURSE_STAGING_BATCHES",
  DISBURSE_STAGING_BATCHES_SUCCESS = "@@auth/DISBURSE_STAGING_BATCHES_SUCCESS",
  DISBURSE_STAGING_BATCHES_ERROR = "@@auth/DISBURSE_STAGING_BATCHES_ERROR",

  MAKER_REMARK_DISBURSE_STAGING_BATCHES = "@@auth/MAKER_REMARK_DISBURSE_STAGING_BATCHES",
  MAKER_REMARK_DISBURSE_STAGING_BATCHES_SUCCESS = "@@auth/MAKER_REMARK_DISBURSE_STAGING_BATCHES_SUCCESS",
  MAKER_REMARK_DISBURSE_STAGING_BATCHES_ERROR = "@@auth/MAKER_REMARK_DISBURSE_STAGING_BATCHES_ERROR",

  APROVE_STAGING_BATCHES = "@@auth/APROVE_STAGING_BATCHES",
  APROVE_STAGING_BATCHES_SUCCESS = "@@auth/APROVE_STAGING_BATCHES_SUCCESS",
  APROVE_STAGING_BATCHES_ERROR = "@@auth/APROVE_STAGING_BATCHES_ERROR",
  RESET_APROVE_STAGING_BATCHES = "@@auth/RESET_APROVE_STAGING_BATCHES",

  REJECT_STAGING_BATCHES_MAKER = "@@auth/REJECT_STAGING_BATCHES_MAKER",
  REJECT_STAGING_BATCHES_MAKER_SUCCESS = "@@auth/REJECT_STAGING_BATCHES_MAKER_SUCCESS",
  REJECT_STAGING_BATCHES_MAKER_ERROR = "@@auth/REJECT_STAGING_BATCHES_MAKER_ERROR",
  RESET_REJECT_STAGING_BATCHES = "@@auth/RESET_REJECT_STAGING_BATCHES",

  REJECT_STAGING_BATCHES_CHECKER = "@@auth/REJECT_STAGING_BATCHES_CHECKER",
  REJECT_STAGING_BATCHES_CHECKER_SUCCESS = "@@auth/REJECT_STAGING_BATCHES_CHECKER_SUCCESS",
  REJECT_STAGING_BATCHES_CHECKER_ERROR = "@@auth/REJECT_STAGING_BATCHES_CHECKER_ERROR",

  RESET_STAGING_BATCH = "@@auth/RESET_STAGING_BATCH",
  RESET_STAGING_BATCH_LIST = "@@auth/RESET_STAGING_BATCH_LIST",
  RESET_DISBURSEMENT = "@@auth/RESET_DISBURSEMENT",
  RESET_REPROCESSING = "@@auth/RESET_REPROCESSING",
  RESET_SKIP_VALIDATION = "@@auth/RESET_SKIP_VALIDATION",

  UPDATE_STAGING_BATCH_LIMIT_TYPE = "@@auth/UPDATE_STAGING_BATCH_LIMIT_TYPE",
  UPDATE_STAGING_BATCH_LIMIT_TYPE_SUCCESS = "@@auth/UPDATE_STAGING_BATCH_LIMIT_TYPE_SUCCESS",
  UPDATE_STAGING_BATCH_LIMIT_TYPE_ERROR = "@@auth/UPDATE_STAGING_BATCH_LIMIT_TYPE_ERROR",
  RESET_UPDATE_STAGING_BATCH_LIMIT_TYPE = "@@auth/RESET_UPDATE_STAGING_BATCH_LIMIT_TYPE",

  FETCH_STAGING_BATCH_AUDIT_ACTIONS = "@@auth/FETCH_STAGING_BATCH_AUDIT_ACTIONS",
  FETCH_STAGING_BATCH_AUDIT_ACTIONS_SUCCESS = "@@auth/FETCH_STAGING_BATCH_AUDIT_ACTIONS_SUCCESS",
  FETCH_STAGING_BATCH_AUDIT_ACTIONS_ERROR = "@@auth/FETCH_STAGING_BATCH_AUDIT_ACTIONS_ERROR",
  RESET_STAGING_BATCH_AUDIT_ACTIONS = "RESET_STAGING_BATCH_AUDIT_ACTIONS"
}

export type StagingBatchActionDispatch = {
  type:
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION
    | StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCHES
    | StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES
    | StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES
    | StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES
    | StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES
    | StagingBatchReduxTypes.APROVE_STAGING_BATCHES
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER
    | StagingBatchReduxTypes.RESET_APROVE_STAGING_BATCHES
    | StagingBatchReduxTypes.RESET_REJECT_STAGING_BATCHES
    | StagingBatchReduxTypes.RESET_STAGING_BATCH
    | StagingBatchReduxTypes.RESET_STAGING_BATCH_LIST
    | StagingBatchReduxTypes.RESET_DISBURSEMENT
    | StagingBatchReduxTypes.RESET_REPROCESSING
    | StagingBatchReduxTypes.RESET_SKIP_VALIDATION
    | StagingBatchReduxTypes.RESET_FETCH_STAGING_BATCHES_CHILD
    | StagingBatchReduxTypes.RESET_FETCH_STAGING_BATCHES
    | StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE
    | StagingBatchReduxTypes.RESET_UPDATE_STAGING_BATCH_LIMIT_TYPE
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS
    | StagingBatchReduxTypes.RESET_STAGING_BATCH_AUDIT_ACTIONS;

  payload: any;
};

export type StagingBatchReducerDispatch = {
  type:
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_SUCCESS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_ERROR
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_SUCCESS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCHES_CHILD_ERROR
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_SUCCESS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_ERROR
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_SUCCESS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_VERSION_ERROR
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_SUCCESS
    | StagingBatchReduxTypes.FETCH_STAGING_BATCH_AUDIT_ACTIONS_ERROR
    | StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES
    | StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_SUCCESS
    | StagingBatchReduxTypes.REPROCESS_STAGING_BATCHES_ERROR
    | StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES
    | StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_SUCCESS
    | StagingBatchReduxTypes.MAKER_REMARK_DISBURSE_STAGING_BATCHES_ERROR
    | StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCHES
    | StagingBatchReduxTypes.MAKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_SUCCESS
    | StagingBatchReduxTypes.MARKER_REMARK_SKIP_VALIDATION_STAGING_BATCH_ERROR
    | StagingBatchReduxTypes.APROVE_STAGING_BATCHES
    | StagingBatchReduxTypes.APROVE_STAGING_BATCHES_SUCCESS
    | StagingBatchReduxTypes.APROVE_STAGING_BATCHES_ERROR
    | StagingBatchReduxTypes.RESET_APROVE_STAGING_BATCHES
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_SUCCESS
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_MAKER_ERROR
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_SUCCESS
    | StagingBatchReduxTypes.REJECT_STAGING_BATCHES_CHECKER_ERROR
    | StagingBatchReduxTypes.RESET_APROVE_STAGING_BATCHES
    | StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCHES
    | StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_SUCCESS
    | StagingBatchReduxTypes.SKIP_VALIDATION_STAGING_BATCH_ERROR
    | StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES
    | StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_SUCCESS
    | StagingBatchReduxTypes.DISBURSE_STAGING_BATCHES_ERROR
    | StagingBatchReduxTypes.RESET_STAGING_BATCH
    | StagingBatchReduxTypes.RESET_STAGING_BATCH_LIST
    | StagingBatchReduxTypes.RESET_DISBURSEMENT
    | StagingBatchReduxTypes.RESET_REPROCESSING
    | StagingBatchReduxTypes.RESET_SKIP_VALIDATION
    | StagingBatchReduxTypes.RESET_FETCH_STAGING_BATCHES
    | StagingBatchReduxTypes.RESET_FETCH_STAGING_BATCHES_CHILD
    | StagingBatchReduxTypes.RESET_STAGING_BATCH_AUDIT_ACTIONS
    | StagingBatchReduxTypes.RESET_REJECT_STAGING_BATCHES
    | StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE
    | StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_SUCCESS
    | StagingBatchReduxTypes.UPDATE_STAGING_BATCH_LIMIT_TYPE_ERROR
    |  StagingBatchReduxTypes.RESET_UPDATE_STAGING_BATCH_LIMIT_TYPE;

  payload: {
    data?: FetchApiResponse<stagingBatch>;
    error?: string;
  };
};

export type StagingBatchReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<stagingBatch>;
  error?: string | null;
};

export type CreateStagingBatchReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<stagingBatch>;
  error?: string | null;
};

export type StagingBatchReprocessReduxState = {
  loading?: boolean;
  data?: string;
  error?: string | null;
};

export type stagingBatch = {
  id: string;
  externalId: string;
  instrumentBatchId: string;
  counterPartyId: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  totalInstrumentCount: number;
  active: boolean;
  createdOn: number;
  updatedOn: number;
};

export type FetchStagingBatchesRequest = {
  payload: FilterMetadata;
  type: string;
};

export type FetchStagingBatchRequest = {
  payload: string;
  type: string;
};
export type reProcessingStagingBatchRequest = {
  payload: Array<any>;
  type: string;
};
