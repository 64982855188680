import { CreateRepaymentRedux, RepaymentReducerDispatch, RepaymentReduxState, RepaymentReduxTypes } from "./types";

const STATE_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const RepaymentListReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentFiltersReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_REQUEST: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentAdjustmentEntriesReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentStagedAdjustmentEntriesReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentAdjustmentEntrieReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentStagedAdjustmentEntrieReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CreateRepaymentRequestSettleReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const CreateRepaymentRequestSettleManuallyReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY: {
      return {
        ...state,
        loading: true
      };
    }
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const CREATE_STATE_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const UploadRepaymentReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET_CREATE_REPAYMENT_REQUEST: {
      return {
        data: {},
        loading: false,
        error: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UploadRepaymentSettlementReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET_UPLOAD_REPAYMENT_REQUEST_SETTLEMENT: {
      return {
        data: {},
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};

const RepaymentReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RepaymentListVersionReducer = (state: RepaymentReduxState = STATE_LIST_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveRepaymentReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectRepaymentReducer = (state: CreateRepaymentRedux = CREATE_STATE_INIT_STATE, action: RepaymentReducerDispatch) => {
  switch (action.type) {
    case RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case RepaymentReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  RepaymentListReducer,
  UploadRepaymentReducer,
  RepaymentReducer,
  RepaymentListVersionReducer,
  ApproveRepaymentReducer,
  RejectRepaymentReducer,
  UploadRepaymentSettlementReducer,
  RepaymentFiltersReducer,
  RepaymentAdjustmentEntriesReducer,
  RepaymentStagedAdjustmentEntriesReducer,
  RepaymentAdjustmentEntrieReducer,
  RepaymentStagedAdjustmentEntrieReducer,
  CreateRepaymentRequestSettleReducer,
  CreateRepaymentRequestSettleManuallyReducer
};
