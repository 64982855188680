import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  aproveCustomerAddressApi,
  blockCustomerAddressApi,
  createAddressApi,
  deleteCustomerAddressApi,
  fetchAddressesApi,
  fetchAddressesLatestVersionApi,
  fetchByIdAddressesApi,
  fetchByIdAddressesVersionApi,
  fetchByIdAddressesVersionDetailsApi,
  freezeCustomerAddressApi,
  rejectCustomerAddressApi,
  unFreezeCustomerAddressApi,
  UpdatedAddressesApi
} from "./api";
import { AddressFetchRequest, AddressReduxTypes, CreateAddressRequest, FetchByIdAddressRequest } from "./types";

function* fetchAddresses({ payload, type, customerId }: AddressFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAddressesApi, payload);

    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* createAddress({ payload, type }: CreateAddressRequest): SagaIterator {
  try {
    const response = yield call(createAddressApi, payload);

    yield put({
      type: AddressReduxTypes.CREATE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.CREATE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteAddress({ payload, type }: CreateAddressRequest): SagaIterator {
  try {
    const response = yield call(deleteCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.DELETE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.DELETE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchByIdAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(fetchByIdAddressesApi, payload);

    yield put({
      type: AddressReduxTypes.FETCH_BYID_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FETCH_BYID_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateByIdAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(UpdatedAddressesApi, payload);

    yield put({
      type: AddressReduxTypes.UPDATE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.UPDATE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchAddressesVersion({ payload, type, customerId }: AddressFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchByIdAddressesVersionApi, payload);

    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchAddressesVersionDetails({ payload, type }: AddressFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchByIdAddressesVersionDetailsApi, payload);

    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchAddressesLatestVersion({ payload, type }: AddressFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAddressesLatestVersionApi, payload);

    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCustomerAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(aproveCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.APROVE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.APROVE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomerAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.REJECT_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.REJECT_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCustomerAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(blockCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.BLOCK_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.BLOCK_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCustomerAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.FREEZE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.FREEZE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCustomerAddress({ payload, type }: FetchByIdAddressRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerAddressApi, payload);

    yield put({
      type: AddressReduxTypes.UNFREEZE_ADDRESSES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: AddressReduxTypes.UNFREEZE_ADDRESSES_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchAddresses() {
  yield takeEvery(AddressReduxTypes.FETCH_ADDRESSES, fetchAddresses);
}

export function* watchCreateAddresses() {
  yield takeEvery(AddressReduxTypes.CREATE_ADDRESSES, createAddress);
}

export function* watchFetchByIdAddress() {
  yield takeEvery(AddressReduxTypes.FETCH_BYID_ADDRESSES, fetchByIdAddress);
}
export function* watchUpdateByIdAddress() {
  yield takeEvery(AddressReduxTypes.UPDATE_ADDRESSES, UpdateByIdAddress);
}

export function* watchFetchAddressVersion() {
  yield takeEvery(AddressReduxTypes.FETCH_ADDRESSES_VERSION, fetchAddressesVersion);
}

export function* watchAproveCustomerAddress() {
  yield takeEvery(AddressReduxTypes.APROVE_ADDRESSES, aproveCustomerAddress);
}
export function* watchRejectCustomerAddress() {
  yield takeEvery(AddressReduxTypes.REJECT_ADDRESSES, rejectCustomerAddress);
}

export function* watchBlockCustomerAddress() {
  yield takeEvery(AddressReduxTypes.BLOCK_ADDRESSES, blockCustomerAddress);
}

export function* watchFreezeCustomerAddress() {
  yield takeEvery(AddressReduxTypes.FREEZE_ADDRESSES, freezeCustomerAddress);
}

export function* watchUnFreezeCustomerAddress() {
  yield takeEvery(AddressReduxTypes.UNFREEZE_ADDRESSES, unFreezeCustomerAddress);
}

export function* watchFetchAddressesVersionDetails() {
  yield takeEvery(AddressReduxTypes.FETCH_ADDRESSES_VERSION_DETAILS, fetchAddressesVersionDetails);
}

export function* watchFetchAddressesLatestVersion() {
  yield takeEvery(AddressReduxTypes.FETCH_ADDRESSES_LATEST_VERSION, fetchAddressesLatestVersion);
}


export function* watchDeleteAddress() {
  yield takeEvery(AddressReduxTypes.DELETE_ADDRESSES, deleteAddress);
}

function* AddressSaga() {
  yield all([
    fork(watchFetchAddresses),
    fork(watchCreateAddresses),
    fork(watchFetchByIdAddress),
    fork(watchUpdateByIdAddress),
    fork(watchFetchAddressVersion),
    fork(watchAproveCustomerAddress),
    fork(watchRejectCustomerAddress),
    fork(watchBlockCustomerAddress),
    fork(watchFreezeCustomerAddress),
    fork(watchUnFreezeCustomerAddress),
    fork(watchFetchAddressesVersionDetails),
    fork(watchFetchAddressesLatestVersion),
    fork(watchDeleteAddress)
  ]);
}
export default AddressSaga;
