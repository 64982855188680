import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  approveCountry,
  blockCountryApi,
  createCountryApi,
  deleteCountryApi,
  fetchCountriesApi,
  fetchCountriesLatestVersionApi,
  fetchCountriesVersionApi,
  fetchCountriesVersionDetailsApi,
  fetchCountryByIdApi,
  freezeCountryApi,
  rejectCountry,
  unFreezeCountryApi,
  updateCountryApi
} from "./api";
import { CountriesFetchRequest, CountryReduxTypes, CreateCountryRequest, FetchCountryByIdRequest, UpdateCountryRequest } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCountries({ payload, type }: CountriesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCountriesApi, payload);
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCountryByID({ payload, type }: FetchCountryByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchCountryByIdApi, payload);
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRY_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRY_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createCountry({ payload, type }: CreateCountryRequest): SagaIterator {
  try {
    const response = yield call(createCountryApi, payload);

    yield put({
      type: CountryReduxTypes.CREATE_COUNTRY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.CREATE_COUNTRY_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCountry({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(updateCountryApi, payload);

    yield put({
      type: CountryReduxTypes.UPDATE_COUNTRY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.UPDATE_COUNTRY_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteCountry({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(deleteCountryApi, payload);

    yield put({
      type: CountryReduxTypes.DELETE_COUNTRY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.DELETE_COUNTRY_ERROR,
      payload: { error: error }
    });
  }
}




function* fetchCountryVersion({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(fetchCountriesVersionApi, payload);

    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCountryVersionDetails({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(fetchCountriesVersionDetailsApi, payload);

    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCountryLatestVersion({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(fetchCountriesLatestVersionApi, payload);

    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCountryApprove({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(approveCountry, payload);

    yield put({
      type: CountryReduxTypes.APPROVE_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.APPROVE_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}
function* fetchCountryReject({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(rejectCountry, payload);

    yield put({
      type: CountryReduxTypes.REJECT_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.REJECT_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCountry({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(blockCountryApi, payload);

    yield put({
      type: CountryReduxTypes.BLOCK_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.BLOCK_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCountry({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(freezeCountryApi, payload);

    yield put({
      type: CountryReduxTypes.FREEZE_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.FREEZE_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCountry({ payload, type }: UpdateCountryRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCountryApi, payload);

    yield put({
      type: CountryReduxTypes.UNFREEZE_COUNTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CountryReduxTypes.UNFREEZE_COUNTRIES_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCountries() {
  yield takeEvery(CountryReduxTypes.FETCH_COUNTRIES, fetchCountries);
}
export function* watchCreateCountry() {
  yield takeEvery(CountryReduxTypes.CREATE_COUNTRY, createCountry);
}
export function* watchFetchCountryById() {
  yield takeEvery(CountryReduxTypes.FETCH_COUNTRY_BY_ID, fetchCountryByID);
}
export function* watchUpdateCountry() {
  yield takeEvery(CountryReduxTypes.UPDATE_COUNTRY, updateCountry);
}
export function* watchfetchCountryVersion() {
  yield takeEvery(CountryReduxTypes.FETCH_COUNTRIES_VERSIONS, fetchCountryVersion);
}

export function* watchApproveCountry() {
  yield takeEvery(CountryReduxTypes.APPROVE_COUNTRIES, fetchCountryApprove);
}
export function* watchRjectCountry() {
  yield takeEvery(CountryReduxTypes.REJECT_COUNTRIES, fetchCountryReject);
}

export function* watchBlockCountry() {
  yield takeEvery(CountryReduxTypes.BLOCK_COUNTRIES, blockCountry);
}

export function* watchFreezeCountry() {
  yield takeEvery(CountryReduxTypes.FREEZE_COUNTRIES, freezeCountry);
}

export function* watchUnFreezeCountry() {
  yield takeEvery(CountryReduxTypes.UNFREEZE_COUNTRIES, unFreezeCountry);
}

export function* watchfetchCountryVersionDetails() {
  yield takeEvery(CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS, fetchCountryVersionDetails);
}

export function* watchFetchCountryLatestVersion() {
  yield takeEvery(CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS, fetchCountryLatestVersion);
}


export function* watchDeleteCountry() {
  yield takeEvery(CountryReduxTypes.DELETE_COUNTRY, deleteCountry);
}




function* countrySaga() {
  yield all([
    fork(watchFetchCountries),
    fork(watchCreateCountry),
    fork(watchFetchCountryById),
    fork(watchUpdateCountry),
    fork(watchfetchCountryVersion),
    fork(watchApproveCountry),
    fork(watchRjectCountry),
    fork(watchBlockCountry),
    fork(watchFreezeCountry),
    fork(watchUnFreezeCountry),
    fork(watchfetchCountryVersionDetails),
    fork(watchFetchCountryLatestVersion),
    fork(watchDeleteCountry)
  ]);
}

export default countrySaga;
