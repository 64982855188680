import { CreateDisbursementAuthReduxState, DisbursementAuthReducerDispatch, DisbursementAuthReduxState, DisbursementAuthReduxTypes } from "./types";

const HOLIDAY_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const DisbursementAuthListReducer = (state: DisbursementAuthReduxState = HOLIDAY_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const DisbursementAuthAuditActionsReducer=(state: DisbursementAuthReduxState = HOLIDAY_INIT_STATE, action: DisbursementAuthReducerDispatch)=>{
  switch (action.type) {
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS: {
      return {
        ...state,
        loading: true
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_AUDIT_ACTIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_DISBURSEDMENT_AUTH_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const DisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_BY_EXTERNAL_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CreateDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error:false,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.CREATE_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET_CREATE_DISBURSEDMENT_AUTH: {
      return {
        error: false,
        loading: false
      };
    }

    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.UPDATE_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const PublishDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.PUBLISH_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchDisbursementAuthVesrions = (state: CreateDisbursementAuthReduxState = HOLIDAY_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.FETCH_DISBURSEDMENT_AUTH_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ApproveDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.APPROVE_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.REJECT_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.BLOCK_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.FREEZE_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeDisbursementAuthReducer = (state: CreateDisbursementAuthReduxState = CREATE_DISBURSEDMENT_AUTH_INIT_STATE, action: DisbursementAuthReducerDispatch) => {
  switch (action.type) {
    case DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.UNFREEZE_DISBURSEDMENT_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case DisbursementAuthReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  DisbursementAuthListReducer,
  CreateDisbursementAuthReducer,
  DisbursementAuthReducer,
  UpdateDisbursementAuthReducer,
  PublishDisbursementAuthReducer,
  FetchDisbursementAuthVesrions,
  ApproveDisbursementAuthReducer,
  RejectDisbursementAuthReducer,
  BlockDisbursementAuthReducer,
  FreezeDisbursementAuthReducer,
  UnFreezeDisbursementAuthReducer,
  DisbursementAuthAuditActionsReducer
};
