import { SagaIterator } from "redux-saga";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { FetchPaymentInstrumentsRequest, PaymentInstrumentReduxTypes } from "./types";
import { fetchPaymentInstrumentApi, fetchPaymentInstrumentsApi } from "./api";



/**
 * Login the user
 * @param {*} payload - username and password
 */
 function* fetchPaymentInstruments({ payload, type }: FetchPaymentInstrumentsRequest): SagaIterator {
    try {
        const response = yield call(fetchPaymentInstrumentsApi, payload);
        yield put({
            type: PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        
        yield put({
            type: PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_ERROR,
            payload: { error: error },
        });
    }
}


function* fetchPaymentInstrument({ payload, type }: FetchPaymentInstrumentsRequest): SagaIterator {

    
    try {
        const response = yield call(fetchPaymentInstrumentApi, payload);
        yield put({
            type: PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_SUCCESS,
            payload: { data: response.data.data },
        });
    } catch (error: any) {
        
        yield put({
            type: PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_ERROR,
            payload: { error: error },
        });
    }
}



export function* watchFetchPaymentInstruments() {
    yield takeEvery(PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS, fetchPaymentInstruments);
}


export function* watchFetchPaymentInstrument() {
    yield takeEvery(PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT, fetchPaymentInstrument);
}



function* paymentInstrumentSaga() {
    yield all([
        fork(watchFetchPaymentInstruments),
        fork(watchFetchPaymentInstrument),
       
    ]);
}

export default paymentInstrumentSaga;
