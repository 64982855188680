import { CustomerCummunicationsReducerDispatch, CustomerCummunicationsReduxState, CustomerCummunicationsReduxType } from "./types";

const CUSTOMER_CUMMUNICATIONS_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const CustomerCummunicationsListReducer = (
  state: CustomerCummunicationsReduxState = CUSTOMER_CUMMUNICATIONS_LIST_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const CustomerCummunicationsCreatereducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.CREATE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerCummunicationsBYIdReducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerCummunicationsVersionDetailsReducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerCummunicationsLatestVersionReducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCustomerCummunicationsReducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.UPDATE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};




const DeleteCustomerCummunicationsReducer = (
  state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.DELETE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const CustomerCummunicationsListVersionReducer = (
  state: CustomerCummunicationsReduxState = CUSTOMER_CUMMUNICATIONS_LIST_INIT_STATE,
  action: CustomerCummunicationsReducerDispatch
) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FETCH_CUSTOMER_CUMMUNICATIONS_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveCummunicationsReducer = (state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE, action: CustomerCummunicationsReducerDispatch) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.APROVE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectCummunicationsReducer = (state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE, action: CustomerCummunicationsReducerDispatch) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.REJECT_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockCummunicationsReducer = (state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE, action: CustomerCummunicationsReducerDispatch) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.BLOCK_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const FreezeCummunicationsReducer = (state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE, action: CustomerCummunicationsReducerDispatch) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.FREEZE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCummunicationsReducer = (state: CustomerCummunicationsReduxState = CREATE_CUSTOMER_CUMMUNICATIONS_INIT_STATE, action: CustomerCummunicationsReducerDispatch) => {
  switch (action.type) {
    case CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.UNFREEZE_CUSTOMER_CUMMUNICATIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerCummunicationsReduxType.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
export {
  AproveCummunicationsReducer,
  RejectCummunicationsReducer,
  BlockCummunicationsReducer,
  CustomerCummunicationsListReducer,
  CustomerCummunicationsListVersionReducer,
  CustomerCummunicationsCreatereducer,
  CustomerCummunicationsBYIdReducer,
  UpdateCustomerCummunicationsReducer,
  FreezeCummunicationsReducer,
  UnFreezeCummunicationsReducer,
  CustomerCummunicationsVersionDetailsReducer,
  CustomerCummunicationsLatestVersionReducer,
  DeleteCustomerCummunicationsReducer
};
