import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import financierUserSaga from "./financierUser/saga";
import currencySaga from "./currency/saga";
import baseRateSaga from "./baseRate/saga";
import holidaySaga from "./holiday/saga";
import branchSaga from "./branch/saga";
import helperSaga from "./helperData/saga";
import countrySaga from "./country/saga";
import stateSaga from "./state/saga";
import citySaga from "./city/saga";
import relationshipManagerSaga from "./relationshipManager/saga";
import lovSaga from "./lov/saga";
import customerSaga from "./customer/saga";
import PromoterDirectorsSaga from "./customerContact/saga";
import AddressSaga from "./customerAddress/saga";
import fetchCustomerCummunicationSaga from "./customerCummications/saga";
import productTypeSaga from "./productType/saga";
import limitConfigSaga from "./limitConfig/saga";
import CustomerBeneficiarySaga from "./customerBeneficary/saga";
import financingProgramSaga from "./financingPrograms/saga";
import productLimitConfigSaga from "./productLimitConfig/saga";
import buyerSellerSaga from "./buyerSeller/saga";
import CustomerBeneficiaryProgrammeSaga from "./customerBeneficaryProgrammme/saga";
import chargeSaga from "./charge/saga";
import instrumentBatchSaga from "./transaction/instrumentBatch/saga";
import instrumentSaga from "./transaction/instrument/saga";

import stagingBatchSaga from "./transaction/stagingBatch/saga";
import stagingInstrumentSaga from "./transaction/stagingInstrument/saga";

import paymentInstrumentSaga from "./transaction/paymentInstrument/saga";
import paymentBatchSaga from "./transaction/payment/saga";
import holidayBatchSaga from "./holidayBatch/saga";
import disbursementSaga from "./transaction/disbursement/saga";
import UserRoleSaga from "./userRoles/saga";
import eodBodSaga from "./eodBod/saga";
import instrumentInterestAccrualSaga from "./transaction/interestAccrual/saga";
import instrumentDailyBalanceSaga from "./transaction/dailyBalances/saga";
import RepaymentSaga from "./Repayment/repaymentRequest/saga";
import RepaymentSettlementSaga from "./Repayment/repaymentSettlement/saga";

import instrumentDailyBalanceHistorySaga from "./transaction/instrumentDailyBalancesHistory/saga";
import glAccountSaga from "./glAccount/saga";
import DisbursementSagaAuth from "./DisbursementAuth/saga";
import disbursementAdviceSaga from "./report/disbursementAdvice/saga";
import limitReportSaga from "./report/limitReport/saga";
import clientSummarySaga from "./report/clientSummary/saga";
import programmeSummarySaga from "./report/programmeSummary/saga";
import interestComputationSaga from "./report/interestComputation/saga";
import repaymentAdviceSaga from "./report/repaymentAdvice/saga";
import ExcessMoneySaga from "./Repayment/excessMoney/saga";

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    financierUserSaga(),
    currencySaga(),
    baseRateSaga(),
    holidaySaga(),
    branchSaga(),
    helperSaga(),
    countrySaga(),
    stateSaga(),
    citySaga(),
    relationshipManagerSaga(),
    lovSaga(),
    customerSaga(),
    PromoterDirectorsSaga(),
    AddressSaga(),
    fetchCustomerCummunicationSaga(),
    productTypeSaga(),
    limitConfigSaga(),
    productLimitConfigSaga(),
    CustomerBeneficiarySaga(),
    financingProgramSaga(),
    buyerSellerSaga(),
    CustomerBeneficiaryProgrammeSaga(),
    chargeSaga(),
    instrumentBatchSaga(),
    instrumentSaga(),
    stagingBatchSaga(),
    stagingInstrumentSaga(),
    paymentBatchSaga(),
    paymentInstrumentSaga(),
    holidayBatchSaga(),
    disbursementSaga(),
    UserRoleSaga(),
    eodBodSaga(),
    instrumentInterestAccrualSaga(),
    instrumentDailyBalanceSaga(),
    RepaymentSaga(),
    RepaymentSettlementSaga(),
    ExcessMoneySaga(),
    instrumentDailyBalanceHistorySaga(),
    glAccountSaga(),
    DisbursementSagaAuth(),
    disbursementAdviceSaga(),
    limitReportSaga(),
    clientSummarySaga(),
    interestComputationSaga(),
    repaymentAdviceSaga(),
    programmeSummarySaga(),

  ]);
}
