import { CreateFFinancingProgramReduxState, FinancingProgramReducerDispatch, FinancingProgramReduxState, FinancingProgramReduxTypes } from "./types";

const FINANCING_PROGRAM_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const FinancingProgramListReducer = (state: FinancingProgramReduxState = FINANCING_PROGRAM_LIST_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FinancingProgramConfigListReducer = (state: any = [], action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_CONFIG_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const FinancingProgramIdsReducer = (state: FinancingProgramReduxState = FINANCING_PROGRAM_LIST_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAMS_IDS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_FINANCING_PROGRAM_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const FinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const FinancinProgramVersionDetailsReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }

    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const FinancingProgramVersionsReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const FinancingProgramLatestVersionsReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION: {
      return {
        ...state,
        data: {},
        error: {},
        loading: true
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FETCH_FINANCING_PROGRAM_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET: {
      return {
        ...state,
        data: {},
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const FinancingProgramValidateReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.VALIDATE_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET_CREATED_FINANCING_PROGRAM_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }

    default:
      return { ...state };
  }
};

const CreateFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null
      };
    }
    case FinancingProgramReduxTypes.CREATE_FINANACING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET_CREATED_FINANCING_PROGRAM_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const DeleteFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null
      };
    }
    case FinancingProgramReduxTypes.DELETE_FINANACING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

const UpdateFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.UPDATE_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET_UPDATED_FINANCING_PROGRAM_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};


const GenerateLoanAccountNumberFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS: {
      return {
        ...state,
        loading: true
      };
    }
    case FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.GENERATE_LOAN_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET_UPDATED_FINANCING_PROGRAM_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const ApproveFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.APPROVE_FINANACING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectFinancinProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.REJECT_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockFinancingProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.BLOCK_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.BLOCK_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

const FreezeFinancingProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.FREEZE_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

const UnFreezeFinancingProgramReducer = (state: CreateFFinancingProgramReduxState = CREATE_FINANCING_PROGRAM_INIT_STATE, action: FinancingProgramReducerDispatch) => {
  switch (action.type) {
    case FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.UNFREEZE_FINANCING_PROGRAM_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case FinancingProgramReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

export {
  FinancingProgramListReducer,
  FinancinProgramReducer,
  FinancingProgramVersionsReducer,
  ApproveFinancinProgramReducer,
  RejectFinancinProgramReducer,
  FinancingProgramValidateReducer,
  CreateFinancinProgramReducer,
  UpdateFinancinProgramReducer,
  BlockFinancingProgramReducer,
  FreezeFinancingProgramReducer,
  UnFreezeFinancingProgramReducer,
  FinancingProgramIdsReducer,
  FinancingProgramConfigListReducer,
  FinancinProgramVersionDetailsReducer,
  FinancingProgramLatestVersionsReducer,
  DeleteFinancinProgramReducer,
  GenerateLoanAccountNumberFinancinProgramReducer
};
