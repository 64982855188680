import { FilterMetadata } from 'redux/types';
import { CreateHoliday, Holiday, HolidayActionDispatch, HolidayReduxTypes, updateHolidayVersion } from './types';



export const fetchHoliday = (payload: FilterMetadata): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FETCH_HOLIDAY,
    payload: payload,
});

export const fetchHolidayByExternalId = (payload: any): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FETCH_HOLIDAY_BY_EXTERNAL_ID,
    payload: payload,
});

export const createHoliday = (payload:CreateHoliday): HolidayActionDispatch => ({
    type: HolidayReduxTypes.CREATE_HOLIDAY,
    payload: payload,
});

export const updateHoliday = (payload:Holiday): HolidayActionDispatch => ({
    type: HolidayReduxTypes.UPDATE_HOLIDAY,
    payload: payload,
});


export const deleteHoliday = (payload:any): HolidayActionDispatch => ({
    type: HolidayReduxTypes.DELETE_HOLIDAY,
    payload: payload,
});

export const resetUpdateHoliday = (): HolidayActionDispatch => ({
    type: HolidayReduxTypes.RESET_UPDATE_HOLIDAY,
    payload: {},
});

export const fetchHolidayVersions = (payload: any): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION,
    payload: payload,
});

export const fetchHolidayVersionsDetails = (payload: any): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FETCH_HOLIDAY_VERSION_DETAILS,
    payload: payload,
});

export const fetchHolidayLatestVersions = (payload: any): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FETCH_HOLIDAY_LATEST_VERSION,
    payload: payload,
});

export const approveHoliday = (payload: updateHolidayVersion): HolidayActionDispatch => ({
    type: HolidayReduxTypes.APPROVE_HOLIDAY,
    payload: payload,
});
export const rejectHoliday = (payload: updateHolidayVersion): HolidayActionDispatch => ({
    type: HolidayReduxTypes.REJECT_HOLIDAY,
    payload: payload,
});


export const blockHoliday = (payload: updateHolidayVersion): HolidayActionDispatch => ({
    type: HolidayReduxTypes.BLOCK_HOLIDAY,
    payload: payload,
});

export const freezeHoliday = (payload: updateHolidayVersion): HolidayActionDispatch => ({
    type: HolidayReduxTypes.FREEZE_HOLIDAY,
    payload: payload,
});

export const unFreezeHoliday = (payload: updateHolidayVersion): HolidayActionDispatch => ({
    type: HolidayReduxTypes.UNFREEZE_HOLIDAY,
    payload: payload,
});

export const resetHoliday = (): HolidayActionDispatch => ({
    type: HolidayReduxTypes.RESET,
    payload: {},
});
