
import { CreateInterestComputationReduxState, InterestComputationReducerDispatch, InterestComputationReduxTypes } from "./types"

const CREATE_INTEREST_COMPUTATION_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateInterestComputationReducer = (state: CreateInterestComputationReduxState = CREATE_INTEREST_COMPUTATION_INIT_STATE, action: InterestComputationReducerDispatch) => {
    switch (action.type) {
        case InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT:
            return {
                ...state,
                loading: true
            }

        case InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        }

        case InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            }
        }
        default:
            return { ...state }
    }
}

export { CreateInterestComputationReducer }