import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum LovReduxTypes {
  FETCH_LOV = "@@auth/FETCH_LOV",
  FETCH_LOV_SUCCESS = "@@auth/FETCH_LOV_SUCCESS",
  FETCH_LOV_ERROR = "@@auth/FETCH_LOV_ERROR",

  CREATE_FETCH_LOV = "@@auth/CREATE_FETCH_LOV",
  CREATE_FETCH_LOV_SUCCESS = "@@auth/CREATE_FETCH_LOV_SUCCESS",
  CREATE_FETCH_LOV_ERROR = "@@auth/CREATE_FETCH_LOV_ERROR",

  FETCH_LOV_BY_ID = "@@auth/CREATE_FETCH_LOV_ERROR",
  FETCH_LOV_BY_ID_SUCCESS = "@@auth/FETCH_LOV_BY_ID_SUCCESS",
  FETCH_LOV_BY_ID_ERROR = "@@auth/FETCH_LOV_BY_ID_ERROR",

  UPDATE_LOV = "@@auth/UPDATE_LOV",
  UPDATE_LOV_SUCCESS = "@@auth/UPDATE_LOV_SUCCESS",
  UPDATE_LOV_ERROR = "@@auth/UPDATE_LOV_ERROR",
  RESET_UPDATE_LOV = "@@auth/RESET_UPDATE_LOV",

  DELETE_LOV = "@@auth/DELETE_LOV",
  DELETE_LOV_SUCCESS = "@@auth/DELETE_LOV_SUCCESS",
  DELETE_LOV_ERROR = "@@auth/DELETE_LOV_ERROR",

  SEARCH_FILTER_LOV = "@@auth/SEARCH_FILTER_LOV",
  SEARCH_FILTER_LOV_SUCCESS = "@@auth/SEARCH_FILTER_LOV_SUCCESS",
  SEARCH_FILTER_LOV_ERROR = "@@auth/SEARCH_FILTER_LOV_ERROR",

  FETCH_LOV_TENOR = "@@auth/FETCH_LOV_TENOR",
  FETCH_LOV_TENOR_SUCCESS = "@@auth/FETCH_LOV_TENOR_SUCCESS",
  FETCH_LOV_TENOR_ERROR = "@@auth/FETCH_LOV_TENOR_ERROR",

  FETCH_LOV_VERSION = "@@auth/FETCH_LOV_VERSION",
  FETCH_LOV_VERSION_SUCCESS = "@@auth/FETCH_LOV_VERSION_SUCCESS",
  FETCH_LOV_VERSION_ERROR = "@@auth/FETCH_LOV_VERSION_ERROR",

  FETCH_LOV_VERSION_DETAILS = "@@auth/FETCH_LOV_VERSION_DETAILS",
  FETCH_LOV_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_LOV_VERSION_DETAILS_SUCCESS",
  FETCH_LOV_VERSION_DETAILS_ERROR = "@@auth/FETCH_LOV_VERSION_DETAILS_ERROR",

  FETCH_LOV_LATEST_VERSION = "@@auth/FETCH_LOV_LATEST_VERSION",
  FETCH_LOV_LATEST_VERSION_SUCCESS = "@@auth/FETCH_LOV_LATEST_VERSION_SUCCESS",
  FETCH_LOV_LATEST_VERSION_ERROR = "@@auth/FETCH_LOV_LATEST_VERSION_ERROR",

  APROVE_LOV = "@@auth/APROVE_LOV",
  APROVE_LOV_SUCCESS = "@@auth/APROVE_LOV_SUCCESS",
  APROVE_LOV_ERROR = "@@auth/APROVE_LOV_ERROR",

  REJECT_LOV = "@@auth/REJECT_LOV",
  REJECT_LOV_SUCCESS = "@@auth/REJECT_LOV_SUCCESS",
  REJECT_LOV_ERROR = "@@auth/REJECT_LOV_ERROR",

  BLOCK_LOV = "@@auth/BLOCK_LOV",
  BLOCK_LOV_SUCCESS = "@@auth/BLOCK_LOV_SUCCESS",
  BLOCK_LOV_ERROR = "@@auth/BLOCK_LOV_ERROR",

  FREEZE_LOV = "@@auth/FREEZE_LOV",
  FREEZE_LOV_SUCCESS = "@@auth/FREEZE_LOV_SUCCESS",
  FREEZE_LOV_ERROR = "@@auth/FREEZE_LOV_ERROR",

  UNFREEZE_LOV = "@@auth/UNFREEZE_LOV",
  UNFREEZE_LOV_SUCCESS = "@@auth/UNFREEZE_LOV_SUCCESS",
  UNFREEZE_LOV_ERROR = "@@auth/UNFREEZE_LOV_ERROR",

  RESET = "@@auth/RESET"
}
export type LovActionDispatch = {
  type:
    | LovReduxTypes.FETCH_LOV
    | LovReduxTypes.CREATE_FETCH_LOV
    | LovReduxTypes.FETCH_LOV_BY_ID
    | LovReduxTypes.UPDATE_LOV
    | LovReduxTypes.RESET_UPDATE_LOV
    | LovReduxTypes.SEARCH_FILTER_LOV
    | LovReduxTypes.FETCH_LOV_TENOR
    | LovReduxTypes.FETCH_LOV_VERSION
    | LovReduxTypes.FETCH_LOV_VERSION_DETAILS
    | LovReduxTypes.FETCH_LOV_LATEST_VERSION
    | LovReduxTypes.APROVE_LOV
    | LovReduxTypes.REJECT_LOV
    | LovReduxTypes.BLOCK_LOV
    | LovReduxTypes.FREEZE_LOV
    | LovReduxTypes.UNFREEZE_LOV
    | LovReduxTypes.DELETE_LOV

    | LovReduxTypes.RESET;
  payload: any;
};
export type LovReducerDispatch = {
  type:
    | LovReduxTypes.FETCH_LOV
    | LovReduxTypes.FETCH_LOV_SUCCESS
    | LovReduxTypes.FETCH_LOV_ERROR
    | LovReduxTypes.CREATE_FETCH_LOV_SUCCESS
    | LovReduxTypes.CREATE_FETCH_LOV_ERROR
    | LovReduxTypes.FETCH_LOV_BY_ID
    | LovReduxTypes.FETCH_LOV_BY_ID_SUCCESS
    | LovReduxTypes.FETCH_LOV_BY_ID_ERROR
    | LovReduxTypes.UPDATE_LOV_SUCCESS
    | LovReduxTypes.UPDATE_LOV_ERROR
    | LovReduxTypes.RESET_UPDATE_LOV
    | LovReduxTypes.SEARCH_FILTER_LOV_SUCCESS
    | LovReduxTypes.SEARCH_FILTER_LOV_ERROR
    | LovReduxTypes.FETCH_LOV_TENOR_SUCCESS
    | LovReduxTypes.FETCH_LOV_TENOR_ERROR
    | LovReduxTypes.FETCH_LOV_VERSION_SUCCESS
    | LovReduxTypes.FETCH_LOV_VERSION_ERROR
    | LovReduxTypes.FETCH_LOV_VERSION_DETAILS
    | LovReduxTypes.FETCH_LOV_VERSION_DETAILS_SUCCESS
    | LovReduxTypes.FETCH_LOV_VERSION_DETAILS_ERROR
    | LovReduxTypes.FETCH_LOV_LATEST_VERSION
    | LovReduxTypes.FETCH_LOV_LATEST_VERSION_SUCCESS
    | LovReduxTypes.FETCH_LOV_LATEST_VERSION_ERROR
    | LovReduxTypes.APROVE_LOV
    | LovReduxTypes.APROVE_LOV_SUCCESS
    | LovReduxTypes.APROVE_LOV_ERROR
    | LovReduxTypes.REJECT_LOV_SUCCESS
    | LovReduxTypes.REJECT_LOV
    | LovReduxTypes.REJECT_LOV_ERROR
    | LovReduxTypes.BLOCK_LOV_SUCCESS
    | LovReduxTypes.BLOCK_LOV_ERROR
    | LovReduxTypes.FREEZE_LOV_SUCCESS
    | LovReduxTypes.FREEZE_LOV_ERROR
    | LovReduxTypes.UNFREEZE_LOV_SUCCESS
    | LovReduxTypes.UNFREEZE_LOV_ERROR
    | LovReduxTypes.DELETE_LOV
    | LovReduxTypes.DELETE_LOV_SUCCESS
    | LovReduxTypes.DELETE_LOV_ERROR

    | LovReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Lov>;
    error?: string;
  };
};

export type LovReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Lov>;
  error?: string | null;
};

export type LovFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateLovRequestRequest = {
  payload: CreateLov;
  type: string;
};
export type Lov = {
  id: string;
  type: string;
  value: string;
  displayValue: string;
  isDefault: boolean;
  sequenceNumber: number;
  active: boolean;
  createdOn: string;
  updatedOn: string;
};

export type CreateLov = {
  id: string;
  type: string;
  value: string;
  displayValue: string;
  isDefault: boolean;
  sequenceNumber: number;
};

export type CreateLovReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Lov>;
  error?: string | null;
};

export type FetchLovByIdRequest = {
  payload: string;
  type: string;
};

export type UpdateLov = {
  externalUserId: string;
  type: string;
  value: string;
  displayValue: string;
  isDefault: boolean;
  sequenceNumber: number;
};

export type UpdateLovRequest = {
  payload: UpdateLov;
  type: string;
};
