import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateStateRequest, FetchStateByIdRequest, StateReduxTypes, StatesFetchRequest, UpdateStateRequest } from "./types";
import {
  approveStateApi,
  blockStateApi,
  createStateApi,
  deleteStateApi,
  fetchStateByIdApi,
  fetchStateLatestVersionApi,
  fetchStatesApi,
  fetchStateVersionApi,
  fetchStateVersionDetailsApi,
  freezeStateApi,
  rejectStateApi,
  unFreezeStateApi,
  updateStateApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchStates({ payload, type }: StatesFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchStatesApi, payload);
    yield put({
      type: StateReduxTypes.FETCH_STATES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: StateReduxTypes.FETCH_STATES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStateByID({ payload, type }: FetchStateByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchStateByIdApi, payload);
    yield put({
      type: StateReduxTypes.FETCH_STATE_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.FETCH_STATE_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createState({ payload, type }: CreateStateRequest): SagaIterator {
  try {
    const response = yield call(createStateApi, payload);

    yield put({
      type: StateReduxTypes.CREATE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.CREATE_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* updateState({ payload, type }: UpdateStateRequest): SagaIterator {
  try {
    const response = yield call(updateStateApi, payload);

    yield put({
      type: StateReduxTypes.UPDATE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.UPDATE_STATE_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteState({ payload, type }: UpdateStateRequest): SagaIterator {
  try {
    const response = yield call(deleteStateApi, payload);

    yield put({
      type: StateReduxTypes.DELETE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.DELETE_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStateVersion({ payload, type }: FetchStateByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchStateVersionApi, payload);
    yield put({
      type: StateReduxTypes.FETCH_STATE_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.FETCH_STATE_VERSION_ERROR,
      payload: { error: error }
    });
  }
}
function* fetchStateLatestVersion({ payload, type }: FetchStateByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchStateLatestVersionApi, payload);
    yield put({
      type: StateReduxTypes.FETCH_STATE_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.FETCH_STATE_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchStateVersionDetails({ payload, type }: FetchStateByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchStateVersionDetailsApi, payload);
    yield put({
      type: StateReduxTypes.FETCH_STATE_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.FETCH_STATE_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveState({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(approveStateApi, payload);

    yield put({
      type: StateReduxTypes.APPROVE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.APPROVE_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectState({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(rejectStateApi, payload);

    yield put({
      type: StateReduxTypes.REJECT_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.REJECT_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* blockState({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(blockStateApi, payload);

    yield put({
      type: StateReduxTypes.BLOCK_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.BLOCK_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeState({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(freezeStateApi, payload);

    yield put({
      type: StateReduxTypes.FREEZE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.FREEZE_STATE_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeState({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(unFreezeStateApi, payload);

    yield put({
      type: StateReduxTypes.UNFREEZE_STATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: StateReduxTypes.UNFREEZE_STATE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchStates() {
  yield takeEvery(StateReduxTypes.FETCH_STATES, fetchStates);
}
export function* watchCreateState() {
  yield takeEvery(StateReduxTypes.CREATE_STATE, createState);
}
export function* watchFetchStateById() {
  yield takeEvery(StateReduxTypes.FETCH_STATE_BY_ID, fetchStateByID);
}
export function* watchUpdateState() {
  yield takeEvery(StateReduxTypes.UPDATE_STATE, updateState);
}

export function* watchfetchStateVersion() {
  yield takeEvery(StateReduxTypes.FETCH_STATE_VERSION, fetchStateVersion);
}
export function* watchAppoveState() {
  yield takeEvery(StateReduxTypes.APPROVE_STATE, approveState);
}
export function* watchRejectState() {
  yield takeEvery(StateReduxTypes.REJECT_STATE, rejectState);
}

export function* watchBlockState() {
  yield takeEvery(StateReduxTypes.BLOCK_STATE, blockState);
}

export function* watchFreezeState() {
  yield takeEvery(StateReduxTypes.FREEZE_STATE, freezeState);
}

export function* watchUnFreezeState() {
  yield takeEvery(StateReduxTypes.UNFREEZE_STATE, unFreezeState);
}

export function* watchFetchStateVersionDetails() {
  yield takeEvery(StateReduxTypes.FETCH_STATE_VERSION_DETAILS, fetchStateVersionDetails);
}

export function* watchFetchStateLatestVersion() {
  yield takeEvery(StateReduxTypes.FETCH_STATE_LATEST_VERSION, fetchStateLatestVersion);
}


export function* wathcDeleteState() {
  yield takeEvery(StateReduxTypes.DELETE_STATE, deleteState);
}



function* stateSaga() {
  yield all([
    fork(watchFetchStates),
    fork(watchCreateState),
    fork(watchFetchStateById),
    fork(watchUpdateState),
    fork(watchfetchStateVersion),
    fork(watchAppoveState),
    fork(watchRejectState),
    fork(watchBlockState),
    fork(watchFreezeState),
    fork(watchUnFreezeState),
    fork(watchFetchStateVersionDetails),
    fork(watchFetchStateLatestVersion),
    fork(wathcDeleteState)
  ]);
}

export default stateSaga;
