import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchPaymentInstrumentApi(params: any) {


  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-instruments/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

function fetchPaymentInstrumentsApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/payment-instruments`;
  return api.get(`${baseUrl}`, params);
}

export { fetchPaymentInstrumentsApi, fetchPaymentInstrumentApi };
