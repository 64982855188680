import { CreateApiResponse, FetchApiResponse } from "redux/types";

export enum ClientSummaryReduxTypes {
    CLIENT_SUMMARY_REPORT = "@@auth/CLIENT_SUMMARY_REPORT",
    CLIENT_SUMMARY_REPORT_SUCCESS = "@@auth/CLIENT_SUMMARY_REPORT_SUCCESS",
    CLIENT_SUMMARY_REPORT_ERROR = "@@auth/CLIENT_SUMMARY_REPORT_ERROR"
}

export type ClientSummaryActionDispatch = {
    type: | ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT;
    payload: any;
}

export type ClientSummaryReducerDispatch = {
    type: | ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT
    | ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_SUCCESS
    | ClientSummaryReduxTypes.CLIENT_SUMMARY_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<ClientSummary>
        error?: string
    }
}

export type ClientSummary = {
    id: string,
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}

export type CreateClientSummary = {
    customerExternalId: string
}

export type CreateClientSummaryRequest = {
    payload: CreateClientSummary,
    type: string
}

export type CreateClientSummaryReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<ClientSummary>;
    error?: string | null;
}