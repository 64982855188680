import { FilterMetadata } from "redux/types";
import { CityActionDispatch, CityReduxTypes, CreateCity, UpdateCity } from "./types";

export const fetchCities = (payload: FilterMetadata): CityActionDispatch => ({
    type: CityReduxTypes.FETCH_CITIES,
    payload: payload
});

export const createCity = (payload:CreateCity): CityActionDispatch => ({
    type: CityReduxTypes.CREATE_CITY,
    payload: payload,
});

export const fetchCityById= (payload: any): CityActionDispatch => ({
    type: CityReduxTypes.FETCH_CITY_BY_ID,
    payload: payload,
});

export const updateCity = (payload:UpdateCity): CityActionDispatch => ({
    type: CityReduxTypes.UPDATE_CITY,
    payload: payload,
});

export const resetUpdateCity = (): CityActionDispatch => ({
    type: CityReduxTypes.RESET_UPDATE_CITY,
    payload: {},
});

export const resetCity = (): CityActionDispatch => ({
    type: CityReduxTypes.RESET,
    payload: {},
});


export const fetchCityVersion= (payload: any): CityActionDispatch => ({
    type: CityReduxTypes.FETCH_CITY_VERSION,
    payload: payload,
});

export const deleteCity= (payload: any): CityActionDispatch => ({
    type: CityReduxTypes.DELETE_CITY,
    payload: payload,
});

export const fetchCityVersionDetails= (payload: any): CityActionDispatch => ({
    type: CityReduxTypes.FETCH_CITY_VERSION_DETAILS,
    payload: payload,
});



export const fetchCityLatestVersion= (payload: any): CityActionDispatch => ({
    type: CityReduxTypes.FETCH_CITY_LATEST_VERSION,
    payload: payload,
});


export const approveCity = (payload:any): CityActionDispatch => ({
    type: CityReduxTypes.APPROVE_CITY,
    payload: payload,
});

export const rejectCity = (payload:any): CityActionDispatch => ({
    type: CityReduxTypes.REJECT_CITY,
    payload: payload,
});

export const blockCity = (payload:any): CityActionDispatch => ({
    type: CityReduxTypes.BLOCK_CITY,
    payload: payload,
});


export const freezeCity = (payload:any): CityActionDispatch => ({
    type: CityReduxTypes.FREEZE_CITY,
    payload: payload,
});

export const unFreezeCity = (payload:any): CityActionDispatch => ({
    type: CityReduxTypes.UNFREEZE_CITY,
    payload: payload,
});


