
import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();


function fetchInstrumentApi(params: any) {
    // console.log('params');
    // console.log('decodeURI(params)',decodeURI(params));
    
    
    const baseUrl = `${config.BACKEND_TXN_BASE_URL}/instruments/${decodeURI(params)}`;
    return api.get(`${baseUrl}`, params);
}

function fetchAllInstrumentsApi(params: any) {
    const baseUrl = `${config.BACKEND_TXN_BASE_URL}/instruments`;
    return api.get(`${baseUrl}`, params);
}

export { fetchInstrumentApi, fetchAllInstrumentsApi }