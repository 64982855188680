import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { CreaterelationshipManagerRequest, RelationShipManagerFetchRequest, RelationshipManagerReduxTypes, UpdateRelationshipManagerRequest } from "./types";
import {
  createRelationshipManagerApi,
  fetchRelationshipManagerApi,
  fetchRelationshipManagerBYId,
  updateRelationshipManagerApi,
  fetchRelationshipManagerSearchFilter,
  fetchRMVersionApi,
  approveRMApi,
  rejectRMApi,
  blockRMApi,
  freezeRMApi,
  unFreezeRMApi,
  fetchRMVersionDetailsApi,
  fetchRMLatestVersionApi,
  deleteRMVersionApi
} from "./api";

function* fetchRelationshipManager({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRelationshipManagerApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* createRelationshipManager({ payload, type }: CreaterelationshipManagerRequest): SagaIterator {
  try {
    const response = yield call(createRelationshipManagerApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchRelationshipManagerId({ payload, type }: CreaterelationshipManagerRequest): SagaIterator {
  try {
    const response = yield call(fetchRelationshipManagerBYId, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* updtaRelationshipManager({ payload, type }: UpdateRelationshipManagerRequest): SagaIterator {
  try {
    const response = yield call(updateRelationshipManagerApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteRelationshipManager({ payload, type }: UpdateRelationshipManagerRequest): SagaIterator {
  try {
    const response = yield call(deleteRMVersionApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.DELETE_FRELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRelationshipManagerSearchFilterSaga({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRelationshipManagerSearchFilter, payload);

    yield put({
      type: RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRelationshipManagerVersion({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRMVersionApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRelationshipManagerVersionDetails({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRMVersionDetailsApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRelationshipManagerLatestVersion({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRMLatestVersionApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveRelationshipManager({ payload, type }: CreaterelationshipManagerRequest): SagaIterator {
  try {
    const response = yield call(approveRMApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.APROVE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.APROVE_FRELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectRelationshipManager({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectRMApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* blockRelationshipManager({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(blockRMApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeRelationshipManager({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeRMApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeRelationshipManager({ payload, type }: RelationShipManagerFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeRMApi, payload);

    yield put({
      type: RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER, fetchRelationshipManager);
}

export function* watchCreateRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.CREATE_RELATIONSHIP_MANAGER, createRelationshipManager);
}

export function* watchFetchRelationshipManagerById() {
  yield takeEvery(RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_ID, FetchRelationshipManagerId);
}

export function* watchupdateRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.UPDATE_RELATIONSHIP_MANAGER, updtaRelationshipManager);
}

export function* watchseachFilterRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.SEARCH_FILTER_RELATIONSHIP_MANAGER, fetchRelationshipManagerSearchFilterSaga);
}

export function* watchFetchRelationshipManagerVersion() {
  yield takeEvery(RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION, fetchRelationshipManagerVersion);
}

export function* watchAproveRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.APPROVE_RELATIONSHIP_MANAGER, aproveRelationshipManager);
}

export function* watchRejectRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.REJECT_RELATIONSHIP_MANAGER, rejectRelationshipManager);
}

export function* watchBlockRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.BLOCK_RELATIONSHIP_MANAGER, blockRelationshipManager);
}

export function* watchFreezeRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.FREEZE_RELATIONSHIP_MANAGER, freezeRelationshipManager);
}

export function* watchUnFreezeRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.UNFREEZE_RELATIONSHIP_MANAGER, unFreezeRelationshipManager);
}

export function* watchFetchRelationshipManagerVersionDetails() {
  yield takeEvery(RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_VERSION_DETAILS, fetchRelationshipManagerVersionDetails);
}

export function* watchFetchRelationshipManagerLatestVersion() {
  yield takeEvery(RelationshipManagerReduxTypes.FETCH_RELATIONSHIP_MANAGER_LATEST_VERSION, fetchRelationshipManagerLatestVersion);
}

export function* watchDeleteRelationshipManager() {
  yield takeEvery(RelationshipManagerReduxTypes.DELETE_RELATIONSHIP_MANAGER, deleteRelationshipManager);
}

function* relationshipManagerSaga() {
  yield all([
    fork(watchFetchRelationshipManager),
    fork(watchCreateRelationshipManager),
    fork(watchFetchRelationshipManagerById),
    fork(watchupdateRelationshipManager),
    fork(watchseachFilterRelationshipManager),

    fork(watchFetchRelationshipManagerVersion),
    fork(watchAproveRelationshipManager),
    fork(watchRejectRelationshipManager),
    fork(watchBlockRelationshipManager),
    fork(watchFreezeRelationshipManager),
    fork(watchUnFreezeRelationshipManager),
    fork(watchFetchRelationshipManagerVersionDetails),
    fork(watchFetchRelationshipManagerLatestVersion),
    fork(watchDeleteRelationshipManager)
  ]);
}

export default relationshipManagerSaga;
