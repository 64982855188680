import config from "config";
import { APICore } from "helpers/api/apiCore";
import { CreateCustomerContactType } from "./types";

const api = new APICore();

function createCustomerContactsApi(params: CreateCustomerContactType) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    name: params.name.trim(),
    designation: params.designation.trim(),
    pan: params.pan.trim(),
    aadhar: params.aadhar.trim(),
    dateOfBirth: params.dateOfBirth,
    din: params.din.trim(),
    contactType: params.contactType
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts`;
  // return api.create(`${baseUrl}`, data);
}
function fetchCustomerContactsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts`;
  return api.get(`${baseUrl}`, params);
}

function fetchCustomerContactsIDApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function updateCustomerContact(params: any) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    name: params.name.trim(),
    designation: params.designation.trim(),
    pan: params.pan.trim(),
    aadhar: params.aadhar.trim(),
    dateOfBirth: params.dateOfBirth,
    din: params.din.trim(),
    contactType: params.contactType.trim()
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalUserId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalUserId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalUserId}`;
  // return api.update(`${baseUrl}`, updateCustomerContactData);
}

function fetchCustomerContactsVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}/versions`;
  return api.get(`${baseUrl}`, {});
}


function deleteCustomerContactsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}`;
  return api.delete(`${baseUrl}`);
}

function fetchCustomerContactsVersionDatailApi(params: any) {
  
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalID}/versions/${params.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function aproveCustomerContactsApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalCustomerId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectCustomerContactsApi(params: any) {
  // console.log("params", params);

  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.externalCustomerId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCustomerContactApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeCustomerContactApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeCustomerContactApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/contacts/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  createCustomerContactsApi,
  fetchCustomerContactsApi,
  fetchCustomerContactsIDApi,
  updateCustomerContact,
  fetchCustomerContactsVersionApi,
  aproveCustomerContactsApi,
  rejectCustomerContactsApi,
  blockCustomerContactApi,
  freezeCustomerContactApi,
  unFreezeCustomerContactApi,
  fetchCustomerContactsVersionDatailApi,
  deleteCustomerContactsApi
};
