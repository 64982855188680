import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  createCustomerBeneficiaryApi,
  fetchCustomerBeneficialApi,
  fetchCustomerBeneficiaryApi,
  updateCustomerBeneficialApi,
  fetchCustomerBeneficialVersionApi,
  approveCustomerBeneficialApi,
  rejectCustomerBeneficialApi,
  blockCustomerBeneficialApi,
  freezeCustomerBeneficialApi,
  unFreezeCustomerBeneficialApi,
  fetchCustomerBeneficialVersionDetailsApi,
  fetchCustomerBeneficialLatestVersionApi,
  deleteCustomerBeneficialApi
} from "./api";
import { CreateCustomerBeneficiaryRequest, CustomerBeneficiaryFetchRequest, CustomerBeneficiaryReduxTypes } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCustomerBeneficiary({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficiaryApi, payload);
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteCustomerBeneficiary({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(deleteCustomerBeneficialApi, payload);
    yield put({
      type: CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}


function* createCustomerBeneficiary({ payload, type }: CreateCustomerBeneficiaryRequest): SagaIterator {
  try {
    const response = yield call(createCustomerBeneficiaryApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialApi, payload);
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerVersionDetailsBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialVersionDetailsApi, payload);
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(updateCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerBeneficiaryVersion({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialVersionApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerLatestVersionBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialLatestVersionApi, payload);
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(approveCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(blockCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCustomerBeneficial({ payload, type }: CustomerBeneficiaryFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerBeneficialApi, payload);

    yield put({
      type: CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCustomerBeneficary() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY, fetchCustomerBeneficiary);
}
export function* watchCreateCustomerBeneficary() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY, createCustomerBeneficiary);
}
export function* watchCreateCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL, fetchCustomerBeneficial);
}

export function* watchUpdateCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL, updateCustomerBeneficial);
}

export function* watchFetchCustomerBeneficialVersion() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION, fetchCustomerBeneficiaryVersion);
}

export function* watchAproveCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL, aproveCustomerBeneficial);
}

export function* watchRejectCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL, rejectCustomerBeneficial);
}

export function* watchBlockCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL, blockCustomerBeneficial);
}

export function* watchFreezeCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL, freezeCustomerBeneficial);
}

export function* watchUnFreezeCustomerBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL, unFreezeCustomerBeneficial);
}

export function* watchFetchCustomerVersionDetailsBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS, fetchCustomerVersionDetailsBeneficial);
}

export function* watchFetchCustomerLatestVersionBeneficial() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION, fetchCustomerLatestVersionBeneficial);
}

export function* watchDeleteCustomerBeneficiary() {
  yield takeEvery(CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL, deleteCustomerBeneficiary);
}


function* CustomerBeneficiarySaga() {
  yield all([
    fork(watchFetchCustomerBeneficary),
    fork(watchCreateCustomerBeneficary),
    fork(watchCreateCustomerBeneficial),
    fork(watchUpdateCustomerBeneficial),
    fork(watchFetchCustomerBeneficialVersion),
    fork(watchAproveCustomerBeneficial),
    fork(watchRejectCustomerBeneficial),
    fork(watchBlockCustomerBeneficial),
    fork(watchFreezeCustomerBeneficial),
    fork(watchUnFreezeCustomerBeneficial),
    fork(watchFetchCustomerVersionDetailsBeneficial),
    fork(watchFetchCustomerLatestVersionBeneficial),
    fork(watchDeleteCustomerBeneficiary)
  ]);
}

export default CustomerBeneficiarySaga;
