import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  approveFinancierUserApi,
  blockFinancierUserApi,
  createFinancierUsersApi,
  createPasswordFinancierUsersApi,
  deleteFinancierUseVersionApi,
  fetchFinancierUseLatestVersionApi,
  fetchFinancierUserApi,
  fetchFinancierUsersApi,
  fetchFinancierUseVersionApi,
  fetchFinancierUseVersionDetailsApi,
  freezeFinancierUserApi,
  passwordPasswordFinancierUsersApi,
  rejectFinancierUserApi,
  unFreezeFinancierUserApi,
  updateFinancierUsersApi
} from "./api";
import {
  FinancierUserReduxTypes,
  FinancierUserFetchRequest,
  CreateFinancierUserRequest,
  FetchFinancierUserByExternalIdRequest,
  UpdateFinancierUserRequest,
  financierUserVersionChangeRequest
} from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchFinancierUsers({ payload, type }: FinancierUserFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancierUsersApi, payload);
    // yield put(financierUserApiResponseSuccess(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, response.data));
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USERS_ERROR,
      payload: { error: error }
    });
  }
}

function* createFinancierUser({ payload, type }: CreateFinancierUserRequest): SagaIterator {
  try {
    const response = yield call(createFinancierUsersApi, payload);

    yield put({
      type: FinancierUserReduxTypes.CREATE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.CREATE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchFinancierUserByExternalId({ payload, type }: FetchFinancierUserByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancierUserVersion({ payload, type }: UpdateFinancierUserRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancierUseVersionApi, payload);

    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancierUserVersionDetails({ payload, type }: UpdateFinancierUserRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancierUseVersionDetailsApi, payload);

    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchFinancierUserLatestVersion({ payload, type }: UpdateFinancierUserRequest): SagaIterator {
  try {
    const response = yield call(fetchFinancierUseLatestVersionApi, payload);

    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* updtaeFinancierUser({ payload, type }: UpdateFinancierUserRequest): SagaIterator {
  try {
    const response = yield call(updateFinancierUsersApi, payload);

    yield put({
      type: FinancierUserReduxTypes.UPDATE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.UPDATE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* approveFinancierUser({ payload, type }: financierUserVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.APPROVE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.APPROVE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectFinancierUser({ payload, type }: financierUserVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.REJECT_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.REJECT_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* blockFinancierUser({ payload, type }: financierUserVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.BLOCK_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.BLOCK_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeFinancierUser({ payload, type }: financierUserVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.FREEZE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.FREEZE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeFinancierUser({ payload, type }: financierUserVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeFinancierUserApi, payload);

    yield put({
      type: FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

function* createPasswordFinancierUser({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(createPasswordFinancierUsersApi, payload);

    yield put({
      type: FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.PASSWORD_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}
function* updatePasswordFinancierUser({ payload, type }: any): SagaIterator {

  try {
    const response = yield call(passwordPasswordFinancierUsersApi, payload);

    yield put({
      type: FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteFinancierUser({ payload, type }: any): SagaIterator {

  try {
    const response = yield call(deleteFinancierUseVersionApi, payload);

    yield put({
      type: FinancierUserReduxTypes.DELETE_FINANCIER_USER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: FinancierUserReduxTypes.DELETE_FINANCIER_USER_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchFinancierUsers() {
  yield takeEvery(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, fetchFinancierUsers);
}

export function* watchCreateFinancierUsers() {
  yield takeEvery(FinancierUserReduxTypes.CREATE_FINANCIER_USER, createFinancierUser);
}

export function* watchFetchFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.FETCH_FINANCIER_USER, FetchFinancierUserByExternalId);
}

export function* watchUpdateFinancierUsers() {
  yield takeEvery(FinancierUserReduxTypes.UPDATE_FINANCIER_USER, updtaeFinancierUser);
}

export function* watchFinancierUsersVersion() {
  yield takeEvery(FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION, fetchFinancierUserVersion);
}

export function* watchApproveFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.APPROVE_FINANCIER_USER, approveFinancierUser);
}

export function* watchRejectFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.REJECT_FINANCIER_USER, rejectFinancierUser);
}

export function* watchBlockFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.BLOCK_FINANCIER_USER, blockFinancierUser);
}

export function* watchFreezeFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.FREEZE_FINANCIER_USER, freezeFinancierUser);
}

export function* watchUnFreezeFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.UNFREEZE_FINANCIER_USER, unFreezeFinancierUser);
}

export function* watchCreatePasswordFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.PASSWORD_FINANCIER_USER, createPasswordFinancierUser);
}

export function* watchUpdatePasswordFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.UPDATE_PASSWORD_FINANCIER_USER, updatePasswordFinancierUser);
}


export function* watchFetchFinancierUserVersionDetails() {
  yield takeEvery(FinancierUserReduxTypes.FETCH_FINANCIER_USER_VERSION_DETAILS, fetchFinancierUserVersionDetails);
}


export function* watchFetchFinancierUserLatestVersion() {
  yield takeEvery(FinancierUserReduxTypes.FETCH_FINANCIER_USER_LATEST_VERSION, fetchFinancierUserLatestVersion);
}


export function* watchDeleteFinancierUser() {
  yield takeEvery(FinancierUserReduxTypes.DELETE_FINANCIER_USER, deleteFinancierUser);
}








function* financierUserSaga() {
  yield all([
    fork(watchFetchFinancierUsers),
    fork(watchCreateFinancierUsers),
    fork(watchFetchFinancierUser),
    fork(watchUpdateFinancierUsers),
    fork(watchFinancierUsersVersion),
    fork(watchApproveFinancierUser),
    fork(watchRejectFinancierUser),
    fork(watchBlockFinancierUser),
    fork(watchFreezeFinancierUser),
    fork(watchUnFreezeFinancierUser),
    fork(watchCreatePasswordFinancierUser),
    fork(watchUpdatePasswordFinancierUser),
    fork(watchFetchFinancierUserVersionDetails),
    fork(watchFetchFinancierUserLatestVersion),
    fork(watchDeleteFinancierUser)
  ]);
}

export default financierUserSaga;
