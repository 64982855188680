import { CreateCurrencyReduxState, CurrencyReducerDispatch, CurrencyReduxState, CurrencyReduxTypes } from "./types";

const CURRENCY_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CurrencyListReducer = (state: CurrencyReduxState = CURRENCY_LIST_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FETCH_CURRENCY: {
      return {
        ...state,

        loading: true
      };
    }

    case CurrencyReduxTypes.FETCH_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CURRENCY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.CREATE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.CREATE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.UPDATE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.UPDATE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CurrencyReduxTypes.RESET_UPDATE_CURRENCY:
      return {
        ...state,
        error: null,
        loading: false
      };

    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.DELETE_CURRENCY: {
      return {
        ...state,
        loading: false
      };
    }
    case CurrencyReduxTypes.DELETE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.DELETE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
  

    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const CurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FETCH_CURRENCY_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CurrencyVersionDetailsReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS: {
      return {
        loading: true
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS_ERROR: {
      return {
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};
const CurrencyVersionListReducer = (state: CurrencyReduxState = CURRENCY_LIST_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FETCH_CURRENCY_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FetchCurrencyLatestVerionReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const aproveCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.APROVE_CURRENCY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CurrencyReduxTypes.APROVE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.APROVE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const rejectCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.REJECT_CURRENCY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CurrencyReduxTypes.REJECT_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.REJECT_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const blockCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.BLOCK_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.BLOCK_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.FREEZE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.FREEZE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCurrencyReducer = (state: CreateCurrencyReduxState = CREATE_CURRENCY_INIT_STATE, action: CurrencyReducerDispatch) => {
  switch (action.type) {
    case CurrencyReduxTypes.UNFREEZE_CURRENCY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CurrencyReduxTypes.UNFREEZE_CURRENCY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CurrencyReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CurrencyVersionListReducer,
  aproveCurrencyReducer,
  rejectCurrencyReducer,
  blockCurrencyReducer,
  CurrencyListReducer,
  CreateCurrencyReducer,
  CurrencyReducer,
  UpdateCurrencyReducer,
  FreezeCurrencyReducer,
  UnFreezeCurrencyReducer,
  CurrencyVersionDetailsReducer,
  FetchCurrencyLatestVerionReducer,
  DeleteCurrencyReducer
};
