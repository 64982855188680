import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { DisbursementReduxTypes, DisbursementsFetchRequest } from "./type";
import { fetchAllDisbursementsApi } from "./api";

function* fetchAllDisbursement({ payload, type }: DisbursementsFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAllDisbursementsApi, payload);
    yield put({
      type: DisbursementReduxTypes.FETCH_DISBURSEMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: DisbursementReduxTypes.FETCH_DISBURSEMENTS_ERROR,
      payload: { error: error }
    });
  }
}
export function* watchFetchDisbursements() {
  yield takeEvery(DisbursementReduxTypes.FETCH_DISBURSEMENTS, fetchAllDisbursement);
}

function* disbursementSaga() {
  yield all([fork(watchFetchDisbursements)]);
}


export default disbursementSaga;