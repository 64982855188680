import { ExcessMoneyByIdRedux, ExcessMoneyReducerDispatch, ExcessMoneyReduxState, ExcessMoneyTypes } from "./types";

const EXCESS_MONEY_LIST_INIT_STATE = {
    data: {
        metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
                self: null,
                first: null,
                previous: null,
                next: null,
                last: null
            }
        },
        records: []
    },
    loading: false,
    error: null
};

const ExcessMoneyListReducer = (state: ExcessMoneyReduxState = EXCESS_MONEY_LIST_INIT_STATE, action: ExcessMoneyReducerDispatch) => {
    switch (action.type) {
        case ExcessMoneyTypes.FETCH_EXCESS_MONEYS: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case ExcessMoneyTypes.FETCH_EXCESS_MONEYS_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case ExcessMoneyTypes.FETCH_EXCESS_MONEYS_FAIL: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case ExcessMoneyTypes.RESET: {
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        }
        default:
            return { ...state };
    }
}

const EXCESS_MONEY_INIT_STATE = {
    data: {},
    loading: false,
    error: null
  };
const ExcessMoneyReducer = (state: ExcessMoneyByIdRedux = EXCESS_MONEY_INIT_STATE, action: ExcessMoneyReducerDispatch) => {
    switch (action.type) {
        case ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_FAIL: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case ExcessMoneyTypes.RESET: {
            return {
                ...state,
                data: {},
                loading: false,
                error: false
            };
        }
        default:
            return { ...state };
    }
}

export {
    ExcessMoneyListReducer,
    ExcessMoneyReducer
}