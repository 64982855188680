import { APICore } from 'helpers/api/apiCore';

import config from '../../config';
import { CreateProductLimitConfig } from './types';


const api = new APICore();

function fetchProductLimitConfigApi(params: any) {
    const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits`;
    return api.get(`${baseUrl}`, params.filters);
}

function fetchSingleProductLimitConfigApi(params: any) {
    const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/limits/${params.limitExternalId}`;
    return api.get(`${baseUrl}`, {});
}

function createProductLimitConfigApi(params: CreateProductLimitConfig) {
    let backendApiUrl = "";
    let apiData: any = {
        levelType: params.levelType ? params.levelType : "",
        // productNature: params.productNature ? params.productNature : "",
        productTypeId: params.productTypeId ? params.productTypeId : null,
        parentAggregateId: params.parentAggregateId ? params.parentAggregateId : null,
        currencyId: params.currencyId ? params?.currencyId?.id : null,
        normalSanctionedAmount: params.normalSanctionedAmount ? params.normalSanctionedAmount : "",
        normalOperativeAmount: params.normalOperativeAmount ? params.normalOperativeAmount : "",
        normalEffectiveUpto: params.normalEffectiveUpto ? params.normalEffectiveUpto : "",
        normalEffective: params.normalEffective ? params.normalEffective : "",
        adhocSanctionedAmount: params.adhocSanctionedAmount ? params.adhocSanctionedAmount : "",
        // adhocOperativeAmount: params.adhocOperativeAmount ? params.adhocOperativeAmount : "",
        adhocEffectiveUpto: params.adhocEffectiveUpto ? params.adhocEffectiveUpto : "",
        adhocEffective: params.adhocEffective ? params.adhocEffective : ""
    }

    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits?draft=true`;
    const baseUrl = backendApiUrl;
    return api.create(`${baseUrl}`, apiData);
}

function updateProductLimitConfigApi(params: any) {



    let backendApiUrl = "";
    let apiData: any = {
        levelType: params.levelType ? params.levelType : "",
        // productNature: params.productNature ? params.productNature : "",
        productTypeId: params.productTypeId ? params.productTypeId : null,
        parentAggregateId: params.parentAggregateId ? params.parentAggregateId : null,
        currencyId: params.currencyId ? params?.currencyId?.id : null,
        normalSanctionedAmount: params.normalSanctionedAmount ? params.normalSanctionedAmount : "",
        normalOperativeAmount: params.normalOperativeAmount ? params.normalOperativeAmount : "",
        normalEffectiveUpto: params.normalEffectiveUpto ? params.normalEffectiveUpto : "",
        normalEffective: params.normalEffective ? params.normalEffective : "",
        adhocSanctionedAmount: params.adhocSanctionedAmount ? params.adhocSanctionedAmount : "",
        // adhocOperativeAmount: params.adhocOperativeAmount ? params.adhocOperativeAmount : "",
        adhocEffectiveUpto: params.adhocEffectiveUpto ? params.adhocEffectiveUpto : "",
        adhocEffective: params.adhocEffective ? params.adhocEffective : ""
    }


    if (!params.draft) {
        apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
        backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits/${params.limitExternalId}`;
    }
    else {
        backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits/${params.limitExternalId}?draft=true`;
    }
    const baseUrl = backendApiUrl;
    return api.update(`${baseUrl}`, apiData);
}

export { fetchProductLimitConfigApi, createProductLimitConfigApi, updateProductLimitConfigApi, fetchSingleProductLimitConfigApi };
