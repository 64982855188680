import { BuyerSellerReducerDispatch, BuyerSellersReduxState, BuyerSellerReduxTypes, CreateBuyerSellerReduxState } from "./types";

const BUYER_SELLERS_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const BuyerSellersListReducer = (state: BuyerSellersReduxState = BUYER_SELLERS_LIST_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH_BUYER_SELLERS: {
      return {
        ...state,
        loading: true
      };
    }
    case BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case BuyerSellerReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_BUYER_SELLER_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const BuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_BUYER_SELLER:
      return {
        data: {},
        loading: false,
        error: {}
      };
    default:
      return { ...state };
  }
};

const BuyerSellerVersionDetailsReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_BUYER_SELLER:
      return {
        data: {},
        loading: false,
        error: {}
      };
    default:
      return { ...state };
  }
};

const BuyerSellerPartialDisbursementAllowedReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER: {
      return {
        data: {},
        error: {},
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const BuyerSellerValidateReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_CREATED_BUYER_SELLER_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const BuyerSellerVersionsReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

const BuyerSellerLatestVersionReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    // case BuyerSellerReduxTypes.RESET:
    //   return {
    //     data: {},
    //     loading: false,
    //     error: {}
    //   };
    default:
      return { ...state };
  }
};

const CreateBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.CREATE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.CREATE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_CREATED_BUYER_SELLER_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const ApproveBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.APROVE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.APROVE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.REJECT_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.REJECT_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BuyerSellerBlockReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    // case BuyerSellerReduxTypes.UPDATE_BUYER_SELLER: {
    //   return {
    //     loading: true
    //   };
    // }
    case BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const DeleteBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.DELETE_BUYER_SELLER: {
      return {
        loading: true
      };
    }
    case BuyerSellerReduxTypes.DELETE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.DELETE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const FreezeBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const UnFreezeBuyerSellerReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_UPDATED_BUYER_SELLER_REDUCER: {
      return {
        data: {},
        loading: false,
        error: null
      };
    }
    default:
      return { ...state };
  }
};

const BuyerSellerConfigValues = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.RESET_CONFIG_VALUE_BUYER_SELLER: {
      return {
        data: {},
        error: {},
        loading: false
      };
    }

    default:
      return { ...state };
  }
};

const BuyerSellerCreateRelationAccountNumberReducer = (state: CreateBuyerSellerReduxState = CREATE_BUYER_SELLER_INIT_STATE, action: BuyerSellerReducerDispatch) => {
  switch (action.type) {
    case BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS: {
      return {
        ...state,
        loading: true
      };
    }
    case BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        error: null,
        loading: false
      };
    }
    case BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default:
      return { ...state };
  }
};

export {
  BuyerSellersListReducer,
  BuyerSellerReducer,
  BuyerSellerVersionsReducer,
  BuyerSellerValidateReducer,
  CreateBuyerSellerReducer,
  ApproveBuyerSellerReducer,
  RejectBuyerSellerReducer,
  BuyerSellerBlockReducer,
  UpdateBuyerSellerReducer,
  FreezeBuyerSellerReducer,
  UnFreezeBuyerSellerReducer,
  BuyerSellerPartialDisbursementAllowedReducer,
  BuyerSellerConfigValues,
  BuyerSellerCreateRelationAccountNumberReducer,
  BuyerSellerVersionDetailsReducer,
  BuyerSellerLatestVersionReducer,
  DeleteBuyerSellerReducer
};
