import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { CreateLovRequestRequest, FetchLovByIdRequest, LovFetchRequest, LovReduxTypes, UpdateLovRequest } from "./types";
import {
  approveLOVApi,
  blockLOVApi,
  createLovApi,
  deleteLovApi,
  fetchLovApi,
  fetchLovApiById,
  fetchLovLatestVersionApi,
  fetchLovTenorApi,
  fetchLovVersionApi,
  fetchLovVersionDetailsApi,
  freezeLOVApi,
  rejectLOVApi,
  searchfilterApi,
  unFreezeLOVApi,
  updateLovApi
} from "./api";

function* fetchLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLovApi, payload);
    yield put({
      type: LovReduxTypes.FETCH_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* createLov({ payload, type }: CreateLovRequestRequest): SagaIterator {
  try {
    const response = yield call(createLovApi, payload);

    yield put({
      type: LovReduxTypes.CREATE_FETCH_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.CREATE_FETCH_LOV_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteLov({ payload, type }: CreateLovRequestRequest): SagaIterator {
  try {
    const response = yield call(deleteLovApi, payload);

    yield put({
      type: LovReduxTypes.DELETE_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.DELETE_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLovById({ payload, type }: FetchLovByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchLovApiById, payload);

    yield put({
      type: LovReduxTypes.FETCH_LOV_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateLov({ payload, type }: UpdateLovRequest): SagaIterator {
  try {
    const response = yield call(updateLovApi, payload);

    yield put({
      type: LovReduxTypes.UPDATE_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.UPDATE_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchSeachFilterLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(searchfilterApi, payload);
    yield put({
      type: LovReduxTypes.SEARCH_FILTER_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.SEARCH_FILTER_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLovTenor({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLovTenorApi, payload);
    yield put({
      type: LovReduxTypes.FETCH_LOV_TENOR_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_TENOR_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLovVersion({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLovVersionApi, payload);
    yield put({
      type: LovReduxTypes.FETCH_LOV_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchLovVersionDetails({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLovVersionDetailsApi, payload);
    yield put({
      type: LovReduxTypes.FETCH_LOV_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchLovLatestVersion({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchLovLatestVersionApi, payload);
    yield put({
      type: LovReduxTypes.FETCH_LOV_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FETCH_LOV_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(approveLOVApi, payload);
    yield put({
      type: LovReduxTypes.APROVE_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.APROVE_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectLOVApi, payload);
    yield put({
      type: LovReduxTypes.REJECT_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.REJECT_LOV_ERROR,
      payload: { error: error }
    });
  }
}

function* blockLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(blockLOVApi, payload);
    yield put({
      type: LovReduxTypes.BLOCK_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.BLOCK_LOV_ERROR,
      payload: { error: error }
    });
  }
}


function* freezeLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeLOVApi, payload);
    yield put({
      type: LovReduxTypes.FREEZE_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.FREEZE_LOV_ERROR,
      payload: { error: error }
    });
  }
}



function* unFreezeLov({ payload, type }: LovFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeLOVApi, payload);
    yield put({
      type: LovReduxTypes.UNFREEZE_LOV_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: LovReduxTypes.UNFREEZE_LOV_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchLov() {
  yield takeEvery(LovReduxTypes.FETCH_LOV, fetchLov);
}
export function* watchCreatehLov() {
  yield takeEvery(LovReduxTypes.CREATE_FETCH_LOV, createLov);
}

export function* watchfetchLovById() {
  yield takeEvery(LovReduxTypes.FETCH_LOV_BY_ID, fetchLovById);
}

export function* watchUpdatehLov() {
  yield takeEvery(LovReduxTypes.UPDATE_LOV, UpdateLov);
}

export function* watchSeachFilterLov() {
  yield takeEvery(LovReduxTypes.SEARCH_FILTER_LOV, fetchSeachFilterLov);
}

export function* watchLovTenor() {
  yield takeEvery(LovReduxTypes.FETCH_LOV_TENOR, fetchLovTenor);
}

export function* watchFetchLovVersion() {
  yield takeEvery(LovReduxTypes.FETCH_LOV_VERSION, fetchLovVersion);
}

export function* watchAproveLov() {
  yield takeEvery(LovReduxTypes.APROVE_LOV, aproveLov);
}
export function* watchRejectLov() {
  yield takeEvery(LovReduxTypes.REJECT_LOV, rejectLov);
}

export function* watchBlockLov() {
    yield takeEvery(LovReduxTypes.BLOCK_LOV, blockLov);
  }


export function* watchFreezeLov() {
  yield takeEvery(LovReduxTypes.FREEZE_LOV, freezeLov);
}


export function* watchUnFreezeLov() {
  yield takeEvery(LovReduxTypes.UNFREEZE_LOV, unFreezeLov);
}

export function* watchfetchLovVersionDetails() {
  yield takeEvery(LovReduxTypes.FETCH_LOV_VERSION_DETAILS, fetchLovVersionDetails);
}


export function* watchFetchLovLatestVersion() {
  yield takeEvery(LovReduxTypes.FETCH_LOV_LATEST_VERSION, fetchLovLatestVersion);
}

export function* watchDeleteLov() {
  yield takeEvery(LovReduxTypes.DELETE_LOV, deleteLov);
}










  

function* lovSaga() {
  yield all([
    fork(watchFetchLov),
    fork(watchCreatehLov),
    fork(watchfetchLovById),
    fork(watchUpdatehLov),
    fork(watchSeachFilterLov),
    fork(watchLovTenor),
    fork(watchFetchLovVersion),
    fork(watchAproveLov),
    fork(watchRejectLov),
    fork(watchBlockLov),
    fork(watchFreezeLov),
    fork(watchUnFreezeLov),
    fork(watchfetchLovVersionDetails),
    fork(watchFetchLovLatestVersion),
    fork(watchDeleteLov)
  ]);
}
export default lovSaga;
