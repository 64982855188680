import { CreateApiResponse, FetchApiResponse } from "redux/types";

export enum LimitReportReduxTypes {
    LIMIT_REPORT = "@@auth/LIMIT_REPORT",
    LIMIT_REPORT_SUCCESS = "@@auth/LIMIT_REPORT_SUCCESS",
    LIMIT_REPORT_ERROR = "@@auth/LIMIT_REPORT_ERROR"
}

export type LimitReportActionDispatch = {
    type: | LimitReportReduxTypes.LIMIT_REPORT;
    payload: any;
}

export type LimitReportReducerDispatch = {
    type: | LimitReportReduxTypes.LIMIT_REPORT
    | LimitReportReduxTypes.LIMIT_REPORT_SUCCESS
    | LimitReportReduxTypes.LIMIT_REPORT_ERROR
    payload: {
        data?: FetchApiResponse<LimitReport>
        error?: string
    }
}

export type LimitReport = {
    id: string,
    financierId: string,
    userId: string,
    type: string,
    status: number,
    updatedOn: string;
    createdOn: string;
}

export type CreateLimitReport = {
    programId: string,
    counterPartyId: string,
}

export type CreateLimitReportRequest = {
    payload: CreateLimitReport,
    type: string
}

export type CreateLimitReportReduxState = {
    loading?: boolean;
    data?: CreateApiResponse<LimitReport>
    error?: string | null;
}