import { Currency } from "redux/currency/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum BaseRateReduxTypes {
  FETCH_BASE_RATES = "@@auth/FETCH_BASE_RATES",
  FETCH_BASE_RATES_SUCCESS = "@@auth/FETCH_BASE_RATES_SUCCESS",
  FETCH_BASE_RATES_ERROR = "@@auth/FETCH_BASE_RATES_ERROR",

  FETCH_BASE_RATE = "@@auth/FETCH_BASE_RATE",
  FETCH_BASE_RATE_SUCCESS = "@@auth/FETCH_BASE_RATE_SUCCESS",
  FETCH_BASE_RATE_ERROR = "@@auth/FETCH_BASE_RATE_ERROR",

  CREATE_BASE_RATE = "@@auth/CREATE_BASE_RATE",
  CREATE_BASE_RATE_SUCCESS = "@@auth/CREATE_BASE_RATE_SUCCESS",
  CREATE_BASE_RATE_ERROR = "@@auth/CREATE_BASE_RATE_ERROR",

  UPDATE_BASE_RATE = "@@auth/UPDATE_BASE_RATE",
  UPDATE_BASE_RATE_SUCCESS = "@@auth/UPDATE_BASE_RATE_SUCCESS",
  UPDATE_BASE_RATE_ERROR = "@@auth/UPDATE_BASE_RATE_ERROR",
  RESET_UPDATE_BASE_RATE = "@@auth/RESET_UPDATE_BASE_RATE",

  DELETE_BASE_RATE = "@@auth/DELETE_BASE_RATE",
  DELETE_BASE_RATE_SUCCESS = "@@auth/DELETE_BASE_RATE_SUCCESS",
  DELETE_BASE_RATE_ERROR = "@@auth/DELETE_BASE_RATE_ERROR",

  FETCH_BASE_RATE_VERSION = "@@auth/FETCH_BASE_RATE_VERSION",
  FETCH_BASE_RATE_VERSION_SUCCESS = "@@auth/FETCH_BASE_RATE_VERSION_SUCCESS",
  FETCH_BASE_RATE_VERSION_ERROR = "@@auth/FETCH_BASE_RATE_VERSION_ERROR",

  FETCH_BASE_RATE_VERSION_DETAILS = "@@auth/FETCH_BASE_RATE_VERSION_DETAILS",
  FETCH_BASE_RATE_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_BASE_RATE_VERSION_DETAILS_SUCCESS",
  FETCH_BASE_RATE_VERSION_DETAILS_ERROR = "@@auth/FETCH_BASE_RATE_VERSION_DETAILS_ERROR",

  FETCH_BASE_RATE_LATEST_VERSION = "@@auth/FETCH_BASE_RATE_LATEST_VERSION",
  FETCH_BASE_RATE_LATEST_VERSION_SUCCESS = "@@auth/FETCH_BASE_RATE_LATEST_VERSION_SUCCESS",
  FETCH_BASE_RATE_LATEST_VERSION_ERROR = "@@auth/FETCH_BASE_RATE_LATEST_VERSION_ERROR",

  APPROVE_BASE_RATE = "@@auth/APPROVE_BASE_RATE",
  APPROVE_BASE_RATE_SUCCESS = "@@auth/APPROVE_BASE_RATE_SUCCESS",
  APPROVE_BASE_RATE_ERROR = "@@auth/APPROVE_BASE_RATE_ERROR",

  REJECT_BASE_RATE = "@@auth/REJECT_BASE_RATE",
  REJECT_BASE_RATE_SUCCESS = "@@auth/REJECT_BASE_RATE_SUCCES",
  REJECT_BASE_RATE_ERROR = "@@auth/REJECT_BASE_RATE_ERROR",

  BLOCK_BASE_RATE = "@@auth/BLOCK_BASE_RATE",
  BLOCK_BASE_RATE_SUCCESS = "@@auth/BLOCK_BASE_RATE_SUCCES",
  BLOCK_BASE_RATE_ERROR = "@@auth/BLOCK_BASE_RATE_ERROR",

  FREEZE_BASE_RATE = "@@auth/FREEZE_BASE_RATE",
  FREEZE_BASE_RATE_SUCCESS = "@@auth/FREEZE_BASE_RATE_SUCCES",
  FREEZE_BASE_RATE_ERROR = "@@auth/FREEZE_BASE_RATE_ERROR",

  UNFREEZE_BASE_RATE = "@@auth/UNFREEZE_BASE_RATE",
  UNFREEZE_BASE_RATE_SUCCESS = "@@auth/UNFREEZE_BASE_RATE_SUCCES",
  UNFREEZE_BASE_RATE_ERROR = "@@auth/UNFREEZE_BASE_RATE_ERROR",

  RESET = "@@auth/RESET"
}

export type BaseRateActionDispatch = {
  type:
    | BaseRateReduxTypes.FETCH_BASE_RATES
    | BaseRateReduxTypes.FETCH_BASE_RATE
    | BaseRateReduxTypes.CREATE_BASE_RATE
    | BaseRateReduxTypes.UPDATE_BASE_RATE
    | BaseRateReduxTypes.DELETE_BASE_RATE

    | BaseRateReduxTypes.RESET_UPDATE_BASE_RATE
    | BaseRateReduxTypes.APPROVE_BASE_RATE
    | BaseRateReduxTypes.REJECT_BASE_RATE
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS
    | BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION
    | BaseRateReduxTypes.BLOCK_BASE_RATE
    | BaseRateReduxTypes.FREEZE_BASE_RATE
    | BaseRateReduxTypes.UNFREEZE_BASE_RATE
    | BaseRateReduxTypes.RESET;
  payload: any;
};

export type BaseRateReducerDispatch = {
  type:
    | BaseRateReduxTypes.FETCH_BASE_RATES
    | BaseRateReduxTypes.FETCH_BASE_RATES_SUCCESS
    | BaseRateReduxTypes.FETCH_BASE_RATES_ERROR
    | BaseRateReduxTypes.FETCH_BASE_RATE
    | BaseRateReduxTypes.FETCH_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.FETCH_BASE_RATE_ERROR
    | BaseRateReduxTypes.CREATE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.CREATE_BASE_RATE_ERROR
    | BaseRateReduxTypes.UPDATE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.UPDATE_BASE_RATE_ERROR
    | BaseRateReduxTypes.RESET_UPDATE_BASE_RATE
    | BaseRateReduxTypes.APPROVE_BASE_RATE
    | BaseRateReduxTypes.APPROVE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.APPROVE_BASE_RATE_ERROR
    | BaseRateReduxTypes.REJECT_BASE_RATE
    | BaseRateReduxTypes.REJECT_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.REJECT_BASE_RATE_ERROR
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_SUCCESS
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS_ERROR
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_SUCCESS
    | BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_ERROR
    | BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION
    | BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_SUCCESS
    | BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION_ERROR
    | BaseRateReduxTypes.BLOCK_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.BLOCK_BASE_RATE_ERROR
    | BaseRateReduxTypes.FREEZE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.FREEZE_BASE_RATE_ERROR
    | BaseRateReduxTypes.UNFREEZE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.UNFREEZE_BASE_RATE_ERROR
    | BaseRateReduxTypes.DELETE_BASE_RATE
    | BaseRateReduxTypes.DELETE_BASE_RATE_SUCCESS
    | BaseRateReduxTypes.DELETE_BASE_RATE_ERROR

    | BaseRateReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<BaseRate>;
    error?: string;
  };
};

export type BaseRateReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<BaseRate>;
  error?: string | null;
};

export type BaseRateFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type BaseRate = {
  id: string;
  externalId: string;
  status: number;
  name: string;
  currency: Currency;
  rateInPercentage: string;
  userId: string;
  active: boolean;
  inactiveReason: string;
  isDisabled: boolean;
  checkerRemarks: string;
};

export type CreateBaseRate = {
  name: string;
  currencyId: string;
  draft: boolean;
  rateInPercentage: number;
  makerRemarks: string;
  tenor: string;
  startDate: string;
  endDate: string;
};

export type CreateBaseRateRequest = {
  payload: CreateBaseRate;
  type: string;
};
export type UpdateBaseRateRequest = {
  payload: UpdateBaseRate;
  type: string;
};

export type CreateBaseRateReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Currency>;
  error?: string | null;
};
export type UpdateBaseRate = {
  id: string;
  name: string;
  currency: string;
  draft: boolean;
  rateInPercentage: number;
  externalBaseRateId: string;
  makerRemarks: string;
};
export type FetchBaseRateByExternalIdRequest = {
  payload: string;
  type: string;
};
export type baseRateVersionChangeRequest = {
  payload: updateBaseRateVersion;
  type: string;
};
export type updateBaseRateVersion = {
  checkerRemarks: string;
  externalId: string;
};
