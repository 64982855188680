import config from "config";
import { APICore } from "helpers/api/apiCore";

const api = new APICore();

function fetchAllDisbursementsApi(params: any) {
  const baseUrl = `${config.BACKEND_TXN_BASE_URL}/disbursements`;
  return api.get(`${baseUrl}`, params);
}
export { fetchAllDisbursementsApi };
