import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateBranchPayLoad, UpdateBranch, updateBranchVersion } from "./types";

const api = new APICore();

function fetchbranchApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches`;
  return api.get(`${baseUrl}`, params);
}

function fetchBranchByExternalIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params}`;
  return api.get(`${baseUrl}`, {});
}

function createBranchApi(params: any) {
  let backendApiUrl = "";

  let country: any = params.country;
  let state: any = params.state;
  let city: any = params.city;
  let apiData: any = {
    cbsCode: params.cbsCode,
    name: params.name.trim(),
    address1: params.address1.trim(),
    address2: params.address2.trim(),
    city: city.name,
    state: state.name,
    country: country.name,
    zip: params.zip
  };

  // const baseUrl = `${config.BACKEND_BASE_URL}/branches`;
  // return api.create(`${baseUrl}`, createForm);
  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/branches`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/branches?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateBranchApi(params: any) {
  let backendApiUrl = "";

  let country: any = params.country;
  let state: any = params.state;
  let city: any = params.city;
  let apiData: any = {
    cbsCode: params.cbsCode,
    name: params.name.trim(),
    address1: params.address1.trim(),
    address2: params.address2.trim(),
    city: city.name,
    state: state.name,
    country: country.name,
    zip: params.zip
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/branches/${params.externalBranchId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/branches/${params.externalBranchId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params.externalBranchId}`;
  // return api.update(`${baseUrl}`, updateBaserateData);
}

function fetchBranchVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function fetchBranchLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function deleteBranchApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params}`;
  return api.delete(`${baseUrl}`);
}
function fetchBranchVersionApiDetails(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveBranchApi(params: updateBranchVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectBranchApi(params: updateBranchVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockBranchApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBranchId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${externalBranchId}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeBranchApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBranchId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${externalBranchId}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeBranchApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalBranchId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/branches/${externalBranchId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  fetchbranchApi,
  createBranchApi,
  fetchBranchByExternalIdApi,
  fetchBranchVersionApi,
  approveBranchApi,
  rejectBranchApi,
  updateBranchApi,
  blockBranchApi,
  freezeBranchApi,
  unFreezeBranchApi,
  fetchBranchVersionApiDetails,
  fetchBranchLatestVersionApi,
  deleteBranchApi
};
