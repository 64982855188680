import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  blockBuyerSellerRequest,
  BuyerSellerReduxTypes,
  BuyerSellersFetchRequest,
  BuyerSellerVersionChangeRequest,
  CreateBuyerSellerRequest,
  UpdateBuyerSellerRequest
} from "./types";
import {
  createBuyerSellerApi,
  fetchBuyerSellerApi,
  fetchBuyerSellersApi,
  validateBuyerSellerApi,
  fetchBuyerSellerVersionApi,
  approveBuyerSellerpi,
  rejectBuyerSellerpi,
  buyerSellerBlockApi,
  updateBuyerSellerApi,
  buyerSellerFreezeApi,
  buyerSellerUnFreezeApi,
  fetchBuyerSellerpartialDisbursementAllowedApi,
  fetchBuyerSellerConfigValueApi,
  createRelationAccountNumberApi,
  fetchBuyerSellerVerionDetailsApi,
  fetchBuyerSellerLatestVersionApi,
  deleteBuyerSellerApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* fetchBuyerSellers({ payload, type }: BuyerSellersFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellersApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH_BUYER_SELLERS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBuyerSeller({ payload, type }: BuyerSellersFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* createRelationAccountNumber({ payload, type }: BuyerSellersFetchRequest): SagaIterator {
  try {
    const response = yield call(createRelationAccountNumberApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS_ERROR,
      payload: { error: error }
    });
  }
}

function* validateBuyerSeller({ payload, type }: CreateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(validateBuyerSellerApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchBuyerSellerVersions({ payload, type }: UpdateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerVersionApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_ERROR
    });
  }
}

function* fetchBuyerSellerLatestVersion({ payload, type }: UpdateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerLatestVersionApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION_ERROR
    });
  }
}

function* fetchBuyerSellerVersionsDetails({ payload, type }: UpdateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerVerionDetailsApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS_ERROR
    });
  }
}

function* createBuyerSeller({ payload, type }: CreateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(createBuyerSellerApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.CREATE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.CREATE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}


function* deleteBuyerSeller({ payload, type }: CreateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(deleteBuyerSellerApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.DELETE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.DELETE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* approveBuyerSeller({ payload, type }: BuyerSellerVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveBuyerSellerpi, payload);

    yield put({
      type: BuyerSellerReduxTypes.APROVE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.APROVE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectBuyerSeller({ payload, type }: BuyerSellerVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectBuyerSellerpi, payload);

    yield put({
      type: BuyerSellerReduxTypes.REJECT_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.REJECT_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* blockBuyerSeller({ payload, type }: blockBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(buyerSellerBlockApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.BLOCK_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeBuyerSeller({ payload, type }: blockBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(buyerSellerFreezeApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FREEZE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* updateBuyerSeller({ payload, type }: UpdateBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(updateBuyerSellerApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.UPDATE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeBuyerSeller({ payload, type }: blockBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(buyerSellerUnFreezeApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* partialDisbursementAllowed({ payload, type }: blockBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerpartialDisbursementAllowedApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchConfigValue({ payload, type }: blockBuyerSellerRequest): SagaIterator {
  try {
    const response = yield call(fetchBuyerSellerConfigValueApi, payload);

    yield put({
      type: BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER_BUYER_SELLER_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchBuyerSellers() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH_BUYER_SELLERS, fetchBuyerSellers);
}

export function* watchFetchBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH_BUYER_SELLER, fetchBuyerSeller);
}

export function* watchValidateBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.VALIDATE_BUYER_SELLER, validateBuyerSeller);
}

export function* watchFetchBuyerSellerVersions() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION, fetchBuyerSellerVersions);
}

export function* watchCreateBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.CREATE_BUYER_SELLER, createBuyerSeller);
}

export function* watchAproveBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.APROVE_BUYER_SELLER, approveBuyerSeller);
}

export function* watchRejectBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.REJECT_BUYER_SELLER, rejectBuyerSeller);
}

export function* watchBlockBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.BLOCK_BUYER_SELLER, blockBuyerSeller);
}

export function* watchUpdateBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.UPDATE_BUYER_SELLER, updateBuyerSeller);
}

export function* watchFreezeBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.FREEZE_BUYER_SELLER, freezeBuyerSeller);
}

export function* watchUnFreezeBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.UNFREEZE_BUYER_SELLER, unFreezeBuyerSeller);
}

export function* watchPartialDisbursementAllowedBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH_PARTIALDISBURSEMENTALLOWED_BUYER_SELLER, partialDisbursementAllowed);
}

export function* watchFetchConfigValue() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH_CONFIG_VALUE_BUYER_SELLER, fetchConfigValue);
}

export function* watchcreateRelationAccountNumber() {
  yield takeEvery(BuyerSellerReduxTypes.CREATE_RELATIONSHIP_ACCOUNT_NUMBER_BUYER_SELLERS, createRelationAccountNumber);
}

export function* watchFetchBuyerSellerVersionsDetails() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH__BUYER_SELLER_VERSION_DETAILS, fetchBuyerSellerVersionsDetails);
}
export function* watchFetchBuyerSellerLatestVersion() {
  yield takeEvery(BuyerSellerReduxTypes.FETCH__BUYER_SELLER_LATEST_VERSION, fetchBuyerSellerLatestVersion);
}


export function* watchDeleteBuyerSeller() {
  yield takeEvery(BuyerSellerReduxTypes.DELETE_BUYER_SELLER, deleteBuyerSeller);
}



function* buyerSellerSaga() {
  yield all([
    fork(watchFetchBuyerSellers),
    fork(watchFetchBuyerSeller),
    fork(watchFetchBuyerSellerVersions),
    fork(watchValidateBuyerSeller),
    fork(watchCreateBuyerSeller),
    fork(watchAproveBuyerSeller),
    fork(watchRejectBuyerSeller),
    fork(watchBlockBuyerSeller),
    fork(watchUpdateBuyerSeller),
    fork(watchFreezeBuyerSeller),
    fork(watchUnFreezeBuyerSeller),
    fork(watchPartialDisbursementAllowedBuyerSeller),
    fork(watchFetchConfigValue),
    fork(watchcreateRelationAccountNumber),
    fork(watchFetchBuyerSellerVersionsDetails),
    fork(watchFetchBuyerSellerLatestVersion),
    fork(watchDeleteBuyerSeller)
  ]);
}

export default buyerSellerSaga;
