import { State } from "redux/state/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum ChargeReduxTypes {
  FETCH_CHARGES = "@@auth/FETCH_CHARGES",
  FETCH_CHARGES_SUCCESS = "@@auth/FETCH_CHARGES_SUCCESS",
  FETCH_CHARGES_ERROR = "@@auth/FETCH_CHARGES_ERROR",

  FETCH_CHARGE = "@@auth/FETCH_CHARGE",
  FETCH_CHARGE_SUCCESS = "@@auth/FETCH_CHARGE_SUCCESS",
  FETCH_CHARGE_ERROR = "@@auth/FETCH_CHARGE_ERROR",

  CREATE_CHARGE = "@@auth/CREATE_CHARGE",
  CREATE_CHARGE_SUCCESS = "@@auth/CREATE_CHARGE_SUCCESS",
  CREATE_CHARGE_ERROR = "@@auth/CREATE_CHARGE_ERROR",

  UPDATE_CHARGE = "@@auth/UPDATE_CHARGE",
  UPDATE_CHARGE_SUCCESS = "@@auth/UPDATE_CHARGE_SUCCESS",
  UPDATE_CHARGE_ERROR = "@@auth/UPDATE_CHARGE_ERROR",


  DELETE_CHARGE = "@@auth/DELETE_CHARGE",
  DELETE_CHARGE_SUCCESS = "@@auth/DELETE_CHARGE_SUCCESS",
  DELETE_CHARGE_ERROR = "@@auth/DELETE_CHARGE_ERROR",

  FETCH_CHARGE_VERSION = "@@auth/FETCH_CHARGE_VERSION",
  FETCH_CHARGE_VERSION_SUCCESS = "@@auth/FETCH_CHARGE_VERSION_SUCCESS",
  FETCH_CHARGE_VERSION_ERROR = "@@auth/FETCH_CHARGE_VERSION_ERROR",

  FETCH_CHARGE_VERSION_DETAILS = "@@auth/FETCH_CHARGE_VERSION_DETAILS",
  FETCH_CHARGE_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_CHARGE_VERSION_DETAILS_SUCCESS",
  FETCH_CHARGE_VERSION_DETAILS_ERROR = "@@auth/FETCH_CHARGE_VERSION_DETAILS_ERROR",

  FETCH_CHARGE_LATEST_VERSION = "@@auth/FETCH_CHARGE_LATEST_VERSION",
  FETCH_CHARGE_LATEST_VERSION_SUCCESS = "@@auth/FETCH_CHARGE_LATEST_VERSION_SUCCESS",
  FETCH_CHARGE_LATEST_VERSION_ERROR = "@@auth/FETCH_CHARGE_LATEST_VERSION_ERROR",

  APPROVE_CHARGE = "@@auth/APPROVE_CHARGE",
  APPROVE_CHARGE_SUCCESS = "@@auth/APPROVE_CHARGE_SUCCESS",
  APPROVE_CHARGE_ERROR = "@@auth/APPROVE_CHARGE_ERROR",

  REJECT_CHARGE = "@@auth/REJECT_CHARGE",
  REJECT_CHARGE_SUCCESS = "@@auth/REJECT_CHARGE_SUCCESS",
  REJECT_CHARGE_ERROR = "@@auth/REJECT_CHARGE_ERROR",

  BLOCK_CHARGE = "@@auth/BLOCK_CHARGE",
  BLOCK_CHARGE_SUCCESS = "@@auth/BLOCK_CHARGE_SUCCESS",
  BLOCK_CHARGE_ERROR = "@@auth/BLOCK_CHARGE_ERROR",

  FREEZE_CHARGE = "@@auth/FREEZE_CHARGE",
  FREEZE_CHARGE_SUCCESS = "@@auth/FREEZE_CHARGE_SUCCESS",
  FREEZE_CHARGE_ERROR = "@@auth/FREEZE_CHARGE_ERROR",

  UNFREEZE_CHARGE = "@@auth/UNFREEZE_CHARGE",
  UNFREEZE_CHARGE_SUCCESS = "@@auth/UNFREEZE_CHARGE_SUCCESS",
  UNFREEZE_CHARGE_ERROR = "@@auth/UNFREEZE_CHARGE_ERROR",

  RESET = "@@auth/RESET"
}

export type ChargeActionDispatch = {
  type:
    | ChargeReduxTypes.FETCH_CHARGES
    | ChargeReduxTypes.FETCH_CHARGE
    | ChargeReduxTypes.CREATE_CHARGE
    | ChargeReduxTypes.UPDATE_CHARGE
    | ChargeReduxTypes.DELETE_CHARGE

    | ChargeReduxTypes.FETCH_CHARGE_VERSION
    | ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS
    | ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION
    | ChargeReduxTypes.APPROVE_CHARGE
    | ChargeReduxTypes.REJECT_CHARGE
    | ChargeReduxTypes.BLOCK_CHARGE
    | ChargeReduxTypes.FREEZE_CHARGE
    | ChargeReduxTypes.UNFREEZE_CHARGE
    | ChargeReduxTypes.RESET;
  payload: any;
};

export type ChargeReducerDispatch = {
  type:
    | ChargeReduxTypes.FETCH_CHARGES
    | ChargeReduxTypes.FETCH_CHARGES_SUCCESS
    | ChargeReduxTypes.FETCH_CHARGES_ERROR
    | ChargeReduxTypes.FETCH_CHARGE_SUCCESS
    | ChargeReduxTypes.FETCH_CHARGE_ERROR
    | ChargeReduxTypes.CREATE_CHARGE_SUCCESS
    | ChargeReduxTypes.CREATE_CHARGE_ERROR
    | ChargeReduxTypes.UPDATE_CHARGE_SUCCESS
    | ChargeReduxTypes.UPDATE_CHARGE_ERROR
    | ChargeReduxTypes.DELETE_CHARGE
    | ChargeReduxTypes.DELETE_CHARGE_SUCCESS
    | ChargeReduxTypes.DELETE_CHARGE_ERROR

    | ChargeReduxTypes.FETCH_CHARGE_VERSION_SUCCESS
    | ChargeReduxTypes.FETCH_CHARGE_VERSION_ERROR
    | ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS
    | ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_SUCCESS
    | ChargeReduxTypes.FETCH_CHARGE_VERSION_DETAILS_ERROR
    | ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION
    | ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_SUCCESS
    | ChargeReduxTypes.FETCH_CHARGE_LATEST_VERSION_ERROR
    | ChargeReduxTypes.APPROVE_CHARGE
    | ChargeReduxTypes.APPROVE_CHARGE_SUCCESS
    | ChargeReduxTypes.APPROVE_CHARGE_ERROR
    | ChargeReduxTypes.REJECT_CHARGE
    | ChargeReduxTypes.REJECT_CHARGE_SUCCESS
    | ChargeReduxTypes.REJECT_CHARGE_ERROR
    | ChargeReduxTypes.BLOCK_CHARGE_SUCCESS
    | ChargeReduxTypes.BLOCK_CHARGE_ERROR
    | ChargeReduxTypes.FREEZE_CHARGE_SUCCESS
    | ChargeReduxTypes.FREEZE_CHARGE_ERROR
    | ChargeReduxTypes.UNFREEZE_CHARGE_SUCCESS
    | ChargeReduxTypes.UNFREEZE_CHARGE_ERROR
    | ChargeReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<Charge>;
    error?: string;
  };
};

export type ChargeReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<Charge>;
  error?: string | null;
};

export type Charge = {
  id: string;
  programId: string;
  chargeType: string;
  gstPercentage: string;
  amountWithoutGst: string;
  gstAmount: string;
  amount: string;
  active: boolean;
  createdOn: Date;
  updatedOn: Date;
  externalId: string;
};

export type CitiesFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateChargeReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<Charge>;
  error?: string | null;
};

export type CreateCharge = {
  buyerSellerId: string;
  chargeType: string;
  gstPercentage: string;
  amountWithoutGst: string;
  gstAmount: string;
  amount: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateCharge = {
  name: string;
  stateId: string;
  id: string;
  makerRemarks: string;
  draft: string;
};

export type CreateChargeRequest = {
  payload: CreateCharge;
  type: string;
};

export type UpdateChargeRequest = {
  payload: UpdateCharge;
  type: string;
};

export type FetchChargeByIdRequest = {
  payload: string;
  type: string;
};
