import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { CreateRepaymentRequest, RepaymentReduxTypes, RepaymentFetchRequest } from "./types";
import {
  approveRepaymentApi,
  createRepaymentApi,
  createRepaymentRequestSettleApi,
  createRepaymentRequestSettleManuallyApi,
  fetchRepaymentApi,
  fetchRepaymentsAdjusmentEntrieApi,
  fetchRepaymentsAdjusmentEntriesApi,
  fetchRepaymentsApi,
  fetchRepaymentVersionApi,
  rejectRepaymentApi,
  uploadRepaymentSettlemtnApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchRepayments({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentsFilters({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepayment({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_ERROR,
      payload: { error: error }
    });
  }
}

function* createRepayment({ payload, type }: CreateRepaymentRequest): SagaIterator {
  try {
    const response = yield call(createRepaymentApi, payload);

    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ERROR,
      payload: { error: error }
    });
  }
}

function* createRepaymentSettleManually({ payload, type }: CreateRepaymentRequest): SagaIterator {
  try {
    const response = yield call(createRepaymentRequestSettleManuallyApi, payload);

    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY_ERROR,
      payload: { error: error }
    });
  }
}

function* uploadRepaymentSettlement({ payload, type }: CreateRepaymentRequest): SagaIterator {
  try {
    const response = yield call(uploadRepaymentSettlemtnApi, payload);

    yield put({
      type: RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentVersion({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentVersionApi, payload);

    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* approveRepayment({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(approveRepaymentApi, payload);

    yield put({
      type: RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectRepayment({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(rejectRepaymentApi, payload);

    yield put({
      type: RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentAdjustmentEntries({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsAdjusmentEntriesApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentStagedAdjustmentEntries({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsAdjusmentEntriesApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentAdjustmentEntrie({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsAdjusmentEntrieApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchRepaymentStagedAdjustmentEntrie({ payload, type }: RepaymentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchRepaymentsAdjusmentEntrieApi, payload);
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE_ERROR,
      payload: { error: error }
    });
  }
}

function* createRepaymentRequestSettle({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(createRepaymentRequestSettleApi, payload);
    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(CurrencyApiResponseError(CurrencyReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchRepayments() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST, fetchRepayments);
}
export function* watchCreateRepayment() {
  yield takeEvery(RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST, createRepayment);
}
export function* watchFetchRepayment() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST, fetchRepayment);
}

export function* watchfetchRepaymentVersion() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENT_REQUEST_VERSION, fetchRepaymentVersion);
}
export function* watchAppoveRepayment() {
  yield takeEvery(RepaymentReduxTypes.APPROVE_REPAYMENT_REQUEST, approveRepayment);
}
export function* watchRejectRepayment() {
  yield takeEvery(RepaymentReduxTypes.REJECT_REPAYMENT_REQUEST, rejectRepayment);
}

export function* watchUploadRepaymentSettlement() {
  yield takeEvery(RepaymentReduxTypes.UPLOAD_REPAYMENT_REQUEST_SETTLEMENT, uploadRepaymentSettlement);
}

export function* watchFetchRepaymentRequestFilters() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_REQUEST_FILTERS_REQUEST, fetchRepaymentsFilters);
}

export function* watchFetchRepaymentAdjustmentEntries() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIES, fetchRepaymentAdjustmentEntries);
}

export function* watchFetchRepaymentStagedAdjustmentEntries() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIES, fetchRepaymentStagedAdjustmentEntries);
}
export function* watchFetchRepaymentAdjustmentEntrie() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_ADJUSTMENT_ENTRIE, fetchRepaymentAdjustmentEntrie);
}

export function* watchFetchRepaymentStagedAdjustmentEntrie() {
  yield takeEvery(RepaymentReduxTypes.FETCH_REPAYMENTS_STAGED_ADJUSTMENT_ENTRIE, fetchRepaymentStagedAdjustmentEntrie);
}

export function* wachCreateRepaymentRequestSettle() {
  yield takeEvery(RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE, createRepaymentRequestSettle);
}

export function* watchCreateRepaymentSettleManually() {
  yield takeEvery(RepaymentReduxTypes.CREATE_REPAYMENT_REQUEST_ACTION_SATTLE_MANUALLY, createRepaymentSettleManually);
}

function* RepaymentSaga() {
  yield all([
    fork(watchFetchRepayments),
    fork(watchCreateRepayment),
    fork(watchFetchRepayment),
    fork(watchfetchRepaymentVersion),
    fork(watchAppoveRepayment),
    fork(watchRejectRepayment),
    fork(watchUploadRepaymentSettlement),
    fork(watchFetchRepaymentRequestFilters),
    fork(watchFetchRepaymentAdjustmentEntries),
    fork(watchFetchRepaymentStagedAdjustmentEntries),
    fork(watchFetchRepaymentAdjustmentEntrie),
    fork(watchFetchRepaymentStagedAdjustmentEntrie),
    fork(wachCreateRepaymentRequestSettle),
    fork(watchCreateRepaymentSettleManually)
  ]);
}

export default RepaymentSaga;
