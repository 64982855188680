import { CreateCustomerBeneficiaryReduxState, CustomerBeneficiaryReducerDispatch, CustomerBeneficiaryReduxState, CustomerBeneficiaryReduxTypes } from "./types";

const CUSTOMER_BENEFICIARY_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const CustomerBeneficiaryListReducer = (state: CustomerBeneficiaryReduxState = CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_CUSTOMER_BENEFICIARY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};
const CreateCustomerBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};



const DeleteCustomerBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryVersionDetailsReducer = (
  state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryVersionListReducer = (state: CreateCustomerBeneficiaryReduxState = CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_ERROR: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CustomerBeneficiaryLatestVersionReducer = (
  state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE,
  action: CustomerBeneficiaryReducerDispatch
) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION: {
      return {
        ...state,
        data: {},
        loading: true
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeBeneficiaryReducer = (state: CreateCustomerBeneficiaryReduxState = CREATE_CUSTOMER_BENEFICIARY_INIT_STATE, action: CustomerBeneficiaryReducerDispatch) => {
  switch (action.type) {
    case CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CustomerBeneficiaryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CustomerBeneficiaryListReducer,
  CreateCustomerBeneficiaryReducer,
  CustomerBeneficiaryReducer,
  UpdateBeneficiaryReducer,
  CustomerBeneficiaryVersionListReducer,
  AproveBeneficiaryReducer,
  RejectBeneficiaryReducer,
  BlockBeneficiaryReducer,
  FreezeBeneficiaryReducer,
  UnFreezeBeneficiaryReducer,
  CustomerBeneficiaryVersionDetailsReducer,
  CustomerBeneficiaryLatestVersionReducer,
  DeleteCustomerBeneficiaryReducer
};
