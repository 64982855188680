import { CreateProductTypeeduxState, ProductReduxTypes, ProductTypeReducerDispatch, ProducttypeReduxState } from "./types";

const PRODUCT_TYPE_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: true,
  error: null
};

const ProdcutTypeListReducer = (state: ProducttypeReduxState = PRODUCT_TYPE_INIT_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FETCH_PRODUCTS_TYPE: {
      return {
        ...state,
        loading: true
      };
    }
    case ProductReduxTypes.FETCH_PRODUCTS_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FETCH_PRODUCTS_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_PRODUCT_TYPE_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateProdcutTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.CREATE_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.CREATE_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ProdcutTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FETCH_PRODUCT_TYPE: {
      return {
        ...state,
        loading: true
      };
    }

    case ProductReduxTypes.FETCH_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const ProdcutTypeDetailsReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }

    case ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const ProdcutTypeLatestVersionReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }

    case ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.UPDATE_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.UPDATE_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ProductReduxTypes.RESET_UPDATE_PRODUCT_TYPE:
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };

    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};



const DeleteProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.DELETE_PRODUCT_TYPE: {
      return {
        ...state,
        loading: true
      };
    }
    case ProductReduxTypes.DELETE_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.DELETE_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

   

    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

//
const ProdcutTypeListVersionReducer = (state: ProducttypeReduxState = PRODUCT_TYPE_INIT_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FETCH_PRODUCT_TYPE_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.REJECT_PRODUCT_TYPE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case ProductReduxTypes.REJECT_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.REJECT_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.APROVE_PRODUCTS_TYPE: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case ProductReduxTypes.APROVE_PRODUCTS_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.APROVE_PRODUCTS_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.BLOCK_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.BLOCK_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.FREEZE_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.FREEZE_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeProductTypeReducer = (state: CreateProductTypeeduxState = CREATE_PRODUCT_TYPE_STATE, action: ProductTypeReducerDispatch) => {
  switch (action.type) {
    case ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case ProductReduxTypes.UNFREEZE_PRODUCT_TYPE_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case ProductReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  ProdcutTypeListReducer,
  ProdcutTypeReducer,
  UpdateProductTypeReducer,
  CreateProdcutTypeReducer,
  ProdcutTypeListVersionReducer,
  RejectProductTypeReducer,
  AproveProductTypeReducer,
  BlockProductTypeReducer,
  FreezeProductTypeReducer,
  UnFreezeProductTypeReducer,
  ProdcutTypeDetailsReducer,
  ProdcutTypeLatestVersionReducer,
  DeleteProductTypeReducer
};
