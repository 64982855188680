import { FilterMetadata } from 'redux/types';
import {   CreateCurrencyPayLoad, Currency, CurrencyActionDispatch, CurrencyReduxTypes } from './types';

export const fetchCurrency = (payload: FilterMetadata): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FETCH_CURRENCY,
    payload: payload,
});

export const fetchCurrencyById= (payload: any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FETCH_CURRENCY_BY_ID,
    payload: payload,
});

export const createCurrency = (payload:CreateCurrencyPayLoad): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.CREATE_CURRENCY,
    payload: payload,
});

export const updateCurrency = (payload:Currency): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.UPDATE_CURRENCY,
    payload: payload,
});

export const resetUpdateCurrency = (): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.RESET_UPDATE_CURRENCY,
    payload: {},
});


export const fetchCurrencyVersion = (payload: any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION,
    payload: payload,
});


export const fetchCurrencyVersionDetails = (payload: any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FETCH_CURRENCY_VERSION_DETAILS,
    payload: payload,
});


export const fetchCurrencyLatestVersion = (payload: any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FETCH_CURRENCY_LATEST_VERSION,
    payload: payload,
});


export const deleteCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.DELETE_CURRENCY,
    payload: payload,
});

export const aproveCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.APROVE_CURRENCY,
    payload: payload,
});

export const rejectCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.REJECT_CURRENCY,
    payload: payload,
});

export const blockCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.BLOCK_CURRENCY,
    payload: payload,
});


export const freezeCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.FREEZE_CURRENCY,
    payload: payload,
});


export const unFreezeCurrency = (payload:any): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.UNFREEZE_CURRENCY,
    payload: payload,
});

export const resetCurrency = (): CurrencyActionDispatch => ({
    type: CurrencyReduxTypes.RESET,
    payload: {},
});
