import { PaymentInstrumentActionDispatch, PaymentInstrumentReducerDispatch, PaymentInstrumentReduxState, PaymentInstrumentReduxTypes } from "./types";


const PAYMENT_INSTRUMENT_LIST_INIT_STATE = {
    loading: false,
    data: {
        metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
                self: null,
                first: null,
                previous: null,
                next: null,
                last: null
            }
        },
        records: []
    },
    error: null
};


const PaymentInstrumnetListReducer = (state: PaymentInstrumentReduxState = PAYMENT_INSTRUMENT_LIST_INIT_STATE, action: PaymentInstrumentReducerDispatch) => {
    switch (action.type) {
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENTS_ERROR : {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case PaymentInstrumentReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};


const PaymentInstrumentReducer = (state: PaymentInstrumentReduxState = PAYMENT_INSTRUMENT_LIST_INIT_STATE, action: PaymentInstrumentReducerDispatch) => {
    switch (action.type) {
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT: {
            return {
                ...state,
                data: action.payload.data,
                loading: true
            };
        }
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            };
        }
        case PaymentInstrumentReduxTypes.FETCH_PAYMENT_INSTRUMENT_ERROR: {
            return {
                ...state,
                error: action.payload.error,
                loading: false
            };
        }
        case PaymentInstrumentReduxTypes.RESET:
            return {
                ...state,
                data: {
                    metadata: {
                        totalCount: 0,
                        currentPageRecords: 0,
                        totalPages: 0,
                        links: {
                            self: null,
                            first: null,
                            previous: null,
                            next: null,
                            last: null
                        }
                    },
                    records: []
                },
                loading: false,
                error: false
            };
        default:
            return { ...state };
    }
};

export { PaymentInstrumnetListReducer, PaymentInstrumentReducer }
