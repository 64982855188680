import { CountryReducerDispatch, CountryReduxState, CountryReduxTypes, CreateCountryReduxState } from "./types";

const COUNTRY_LIST_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const CountryListReducer = (state: CountryReduxState = COUNTRY_LIST_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FETCH_COUNTRIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CountryReduxTypes.FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FETCH_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CREATE_COUNTRY_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.CREATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.CREATE_COUNTRY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.UPDATE_COUNTRY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.UPDATE_COUNTRY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CountryReduxTypes.RESET_UPDATE_COUNTRY: {
      return {
        ...state,
        error: null,
        loading: false
      };
    }

    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FETCH_COUNTRY_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }

    case CountryReduxTypes.FETCH_COUNTRY_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FETCH_COUNTRY_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};



const DeleteCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.DELETE_COUNTRY: {
      return {
        ...state,
        loading: true
      };
    }

    case CountryReduxTypes.DELETE_COUNTRY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.DELETE_COUNTRY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};
const CountryReducerLatestVersionReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS: {
      return {
        ...state,
        loading: true
      };
    }

    case CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CountryVersionDetailsReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }

    case CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const CountryVersionListReducer = (state: CountryReduxState = COUNTRY_LIST_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const approveCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.APPROVE_COUNTRIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case CountryReduxTypes.APPROVE_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.APPROVE_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const rejectCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.REJECT_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.REJECT_COUNTRIES: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case CountryReduxTypes.REJECT_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const blockCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.BLOCK_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.BLOCK_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.FREEZE_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.FREEZE_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeCountryReducer = (state: CreateCountryReduxState = CREATE_COUNTRY_INIT_STATE, action: CountryReducerDispatch) => {
  switch (action.type) {
    case CountryReduxTypes.UNFREEZE_COUNTRIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case CountryReduxTypes.UNFREEZE_COUNTRIES_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case CountryReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  CountryListReducer,
  CountryReducer,
  CreateCountryReducer,
  UpdateCountryReducer,
  CountryVersionListReducer,
  approveCountryReducer,
  rejectCountryReducer,
  blockCountryReducer,
  FreezeCountryReducer,
  UnFreezeCountryReducer,
  CountryVersionDetailsReducer,
  CountryReducerLatestVersionReducer,
  DeleteCountryReducer
};
