import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  CreateHolidayBatchRequest,
  FetchHolidayBatchByExternalIdRequest,
  HolidayBatchFetchRequest,
  HolidayBatchReduxTypes,
  holidayBatchVersionChangeRequest,
  UpdateHolidayBatchRequest
} from "./types";
import {
  approveHolidayBatchApi,
  blockHolidayBatchApi,
  createHolidayBatchApi,
  fetchHolidayBatchApi,
  fetchHolidayBatchByExternalIdApi,
  fetchHolidayBatchVersionApi,
  fetchHolidayBatchVersionDetailsApi,
  freezeHolidayBatchApi,
  publishHolidayBatchApi,
  rejectHolidayBatchApi,
  unFreezeHolidayBatchApi,
  updateHolidayBatchApi
} from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchHolidayBatch({ payload, type }: HolidayBatchFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayBatchApi, payload);
    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* filterHolidayBatch({ payload, type }: HolidayBatchFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayBatchApi, payload);
    yield put({
      type: HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* FetchHolidayBatchByExternalId({ payload, type }: FetchHolidayBatchByExternalIdRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayBatchByExternalIdApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* createHolidayBatch({ payload, type }: CreateHolidayBatchRequest): SagaIterator {
  try {
    const response = yield call(createHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* updateHolidayBatch({ payload, type }: UpdateHolidayBatchRequest): SagaIterator {
  try {
    const response = yield call(updateHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* publishHolidayBatch({ payload, type }: UpdateHolidayBatchRequest): SagaIterator {
  try {
    const response = yield call(publishHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchHolidayBatchVersion({ payload, type }: UpdateHolidayBatchRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayBatchVersionApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_ERROR,
      payload: { error: error }
    });
  }
}


function* fetchHolidayBatchDetailsVersion({ payload, type }: UpdateHolidayBatchRequest): SagaIterator {
  try {
    const response = yield call(fetchHolidayBatchVersionDetailsApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* approveHolidayBatch({ payload, type }: holidayBatchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(approveHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectHoliodayBatch({ payload, type }: holidayBatchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(rejectHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* blockHoliodayBatch({ payload, type }: holidayBatchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(blockHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeHoliodayBatch({ payload, type }: holidayBatchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(freezeHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeHoliodayBatch({ payload, type }: holidayBatchVersionChangeRequest): SagaIterator {
  try {
    const response = yield call(unFreezeHolidayBatchApi, payload);

    yield put({
      type: HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH_ERROR,
      payload: { error: error }
    });
  }
}
export function* watchFetchHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH, fetchHolidayBatch);
}

export function* watchFetchHolidayBatchByExternalId() {
  yield takeEvery(HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_BY_EXTERNAL_ID, FetchHolidayBatchByExternalId);
}

export function* watchCreateHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.CREATE_HOLIDAY_BATCH, createHolidayBatch);
}

export function* watchUpdateHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.UPDATE_HOLIDAY_BATCH, updateHolidayBatch);
}

export function* watchBlockHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.BLOCK_HOLIDAY_BATCH, blockHoliodayBatch);
}

export function* watchFetchHolidayBatchVersion() {
  yield takeEvery(HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION, fetchHolidayBatchVersion);
}

export function* watchApproveHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.APPROVE_HOLIDAY_BATCH, approveHolidayBatch);
}

export function* watchRejectHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.REJECT_HOLIDAY_BATCH, rejectHoliodayBatch);
}

export function* watchFreezeHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.FREEZE_HOLIDAY_BATCH, freezeHoliodayBatch);
}

export function* watchUnFreezeHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.UNFREEZE_HOLIDAY_BATCH, unFreezeHoliodayBatch);
}
export function* watchPublishHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.PUBLISH_HOLIDAY_BATCH, publishHolidayBatch);
}

export function* watchFilterHolidayBatch() {
  yield takeEvery(HolidayBatchReduxTypes.FILTER_HOLIDAY_BATCH, filterHolidayBatch);
}

export function* watchFetchHolidayDetailsBatch() {
  yield takeEvery(HolidayBatchReduxTypes.FETCH_HOLIDAY_BATCH_VERSION_DETAILS, fetchHolidayBatchDetailsVersion);
}






function* holidayBatchSaga() {
  yield all([
    fork(watchFetchHolidayBatch),
    fork(watchCreateHolidayBatch),
    fork(watchFetchHolidayBatchByExternalId),
    fork(watchUpdateHolidayBatch),
    fork(watchFetchHolidayBatchVersion),
    fork(watchApproveHolidayBatch),
    fork(watchRejectHolidayBatch),
    fork(watchBlockHolidayBatch),
    fork(watchFreezeHolidayBatch),
    fork(watchUnFreezeHolidayBatch),
    fork(watchPublishHolidayBatch),
    fork(watchFilterHolidayBatch),
    fork(watchFetchHolidayDetailsBatch)

    
  ]);
}

export default holidayBatchSaga;
