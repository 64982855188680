import { Country } from "redux/country/types";
import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum StateReduxTypes {
  FETCH_STATES = "@@auth/FETCH_STATES",
  FETCH_STATES_SUCCESS = "@@auth/FETCH_STATES_SUCCESS",
  FETCH_STATES_ERROR = "@@auth/FETCH_STATES_ERROR",

  FETCH_STATE_BY_ID = "@@auth/FETCH_STATE_BY_ID",
  FETCH_STATE_BY_ID_SUCCESS = "@@auth/FETCH_STATE_BY_ID_SUCCESS",
  FETCH_STATE_BY_ID_ERROR = "@@auth/FETCH_STATE_BY_ID_ERROR",

  CREATE_STATE = "@@auth/CREATE_STATE",
  CREATE_STATE_SUCCESS = "@@auth/CREATE_STATE_SUCCESS",
  CREATE_STATE_ERROR = "@@auth/CREATE_STATE_ERROR",

  UPDATE_STATE = "@@auth/UPDATE_STATE",
  UPDATE_STATE_SUCCESS = "@@auth/UPDATE_STATE_SUCCESS",
  UPDATE_STATE_ERROR = "@@auth/UPDATE_STATE_ERROR",
  RESET_UPDATE_STATE = "@@auth/RESET_UPDATE_STATE",


  DELETE_STATE = "@@auth/DELETE_STATE",
  DELETE_STATE_SUCCESS = "@@auth/DELETE_STATE_SUCCESS",
  DELETE_STATE_ERROR = "@@auth/DELETE_STATE_ERROR",

  FETCH_STATE_VERSION = "@@auth/FETCH_STATE_VERSION",
  FETCH_STATE_VERSION_SUCCESS = "@@auth/FETCH_STATE_VERSION_SUCCESS",
  FETCH_STATE_VERSION_ERROR = "@@auth/FETCH_STATE_VERSION_ERROR",

  FETCH_STATE_VERSION_DETAILS = "@@auth/FETCH_STATE_VERSION_DETAILS",
  FETCH_STATE_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_STATE_VERSION_DETAILS_SUCCESS",
  FETCH_STATE_VERSION_DETAILS_ERROR = "@@auth/FETCH_STATE_VERSION_DETAILS_ERROR",

  FETCH_STATE_LATEST_VERSION = "@@auth/FETCH_STATE_LATEST_VERSION",
  FETCH_STATE_LATEST_VERSION_SUCCESS = "@@auth/FETCH_STATE_LATEST_VERSION_SUCCESS",
  FETCH_STATE_LATEST_VERSION_ERROR = "@@auth/FETCH_STATE_LATEST_VERSION_ERROR",

  APPROVE_STATE = "@@auth/APPROVE_STATE",
  APPROVE_STATE_SUCCESS = "@@auth/APPROVE_STATE_SUCCESS",
  APPROVE_STATE_ERROR = "@@auth/APPROVE_STATE_ERROR",

  REJECT_STATE = "@@auth/REJECT_STATE",
  REJECT_STATE_SUCCESS = "@@auth/REJECT_STATE_SUCCESS",
  REJECT_STATE_ERROR = "@@auth/REJECT_STATE_ERROR",

  BLOCK_STATE = "@@auth/BLOCK_STATE",
  BLOCK_STATE_SUCCESS = "@@auth/BLOCK_STATE_SUCCESS",
  BLOCK_STATE_ERROR = "@@auth/BLOCK_STATE_ERROR",

  FREEZE_STATE = "@@auth/FREEZE_STATE",
  FREEZE_STATE_SUCCESS = "@@auth/FREEZE_STATE_SUCCESS",
  FREEZE_STATE_ERROR = "@@auth/FREEZE_STATE_ERROR",

  UNFREEZE_STATE = "@@auth/UNFREEZE_STATE",
  UNFREEZE_STATE_SUCCESS = "@@auth/UNFREEZE_STATE_SUCCESS",
  UNFREEZE_STATE_ERROR = "@@auth/UNFREEZE_STATE_ERROR",

  RESET = "@@auth/RESET"
}

export type StateActionDispatch = {
  type:
    | StateReduxTypes.FETCH_STATES
    | StateReduxTypes.FETCH_STATE_BY_ID
    | StateReduxTypes.CREATE_STATE
    | StateReduxTypes.UPDATE_STATE
    | StateReduxTypes.DELETE_STATE

    | StateReduxTypes.RESET_UPDATE_STATE
    | StateReduxTypes.FETCH_STATE_VERSION
    | StateReduxTypes.FETCH_STATE_VERSION_DETAILS
    | StateReduxTypes.FETCH_STATE_LATEST_VERSION
    | StateReduxTypes.APPROVE_STATE
    | StateReduxTypes.BLOCK_STATE
    | StateReduxTypes.REJECT_STATE
    | StateReduxTypes.FREEZE_STATE
    | StateReduxTypes.UNFREEZE_STATE
    | StateReduxTypes.RESET;
  payload: any;
};

export type StateReducerDispatch = {
  type:
    | StateReduxTypes.FETCH_STATES
    | StateReduxTypes.FETCH_STATES_SUCCESS
    | StateReduxTypes.FETCH_STATES_ERROR
    | StateReduxTypes.FETCH_STATE_BY_ID
    | StateReduxTypes.FETCH_STATE_BY_ID_SUCCESS
    | StateReduxTypes.FETCH_STATE_BY_ID_ERROR
    | StateReduxTypes.CREATE_STATE_SUCCESS
    | StateReduxTypes.CREATE_STATE_ERROR
    | StateReduxTypes.UPDATE_STATE_SUCCESS
    | StateReduxTypes.UPDATE_STATE_ERROR
    | StateReduxTypes.RESET_UPDATE_STATE
    | StateReduxTypes.DELETE_STATE
    | StateReduxTypes.DELETE_STATE_SUCCESS
    | StateReduxTypes.DELETE_STATE_ERROR

    | StateReduxTypes.FETCH_STATE_VERSION_SUCCESS
    | StateReduxTypes.FETCH_STATE_VERSION_ERROR
    | StateReduxTypes.FETCH_STATE_VERSION_DETAILS
    | StateReduxTypes.FETCH_STATE_VERSION_DETAILS_SUCCESS
    | StateReduxTypes.FETCH_STATE_VERSION_DETAILS_ERROR
    | StateReduxTypes.FETCH_STATE_LATEST_VERSION
    | StateReduxTypes.FETCH_STATE_LATEST_VERSION_SUCCESS
    | StateReduxTypes.FETCH_STATE_LATEST_VERSION_ERROR
    | StateReduxTypes.APPROVE_STATE_SUCCESS
    | StateReduxTypes.APPROVE_STATE
    | StateReduxTypes.APPROVE_STATE_ERROR
    | StateReduxTypes.REJECT_STATE
    | StateReduxTypes.REJECT_STATE_SUCCESS
    | StateReduxTypes.REJECT_STATE_ERROR
    | StateReduxTypes.BLOCK_STATE_SUCCESS
    | StateReduxTypes.BLOCK_STATE_ERROR
    | StateReduxTypes.FREEZE_STATE_SUCCESS
    | StateReduxTypes.FREEZE_STATE_ERROR
    | StateReduxTypes.UNFREEZE_STATE_SUCCESS
    | StateReduxTypes.UNFREEZE_STATE_ERROR
    | StateReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<State>;
    error?: string;
  };
};

export type StateReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<State>;
  error?: string | null;
};

export type State = {
  id: string;
  name: string;
  active: boolean;
  country: Country;
  createdOn: Date;
  updatedOn: Date;
  externalId: string;
  status: string;
};

export type CreateStateReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<State>;
  error?: string | null;
};

export type StatesFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CreateStateRequest = {
  payload: CreateState;
  type: string;
};

export type UpdateStateRequest = {
  payload: UpdateState;
  type: string;
};

export type CreateState = {
  countryId: string;
  name: string;
  draft: boolean;
  makerRemarks: string;
};

export type UpdateState = {
  externalId: string;
  countryId: Country;
  name: string;
  makerRemarks: string;
  draft: boolean;
};

export type FetchStateByIdRequest = {
  payload: string;
  type: string;
};
