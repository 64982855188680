import { CreateApiResponse, FetchApiResponse, FilterMetadata } from "redux/types";

export enum CustomerBeneficiaryReduxTypes {
  // API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  // API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',
  FETCH_CUSTOMER_BENEFICARY = "@@auth/FETCH_CUSTOMER_BENEFICARY",
  FETCH_CUSTOMER_BENEFICARY_SUCCESS = "@@auth/FETCH_CUSTOMER_BENEFICARY_SUCCESS",
  FETCH_CUSTOMER_BENEFICARY_ERROR = "@@auth/FETCH_CUSTOMER_BENEFICARY_ERROR",
  CREATE_CUSTOEMR_BENEFICIARY = "@@auth/CREATE_CUSTOEMR_BENEFICIARY",
  CREATE_CUSTOEMR_BENEFICIARY_SUCCESS = "@@auth/CREATE_CUSTOEMR_BENEFICIARY_SUCCESS",
  CREATE_CUSTOEMR_BENEFICIARY_ERROR = "@@auth/CREATE_CUSTOEMR_BENEFICIARY_ERROR",

  FETCH_CUSTOMER_BENEFICIAL = "@@auth/FETCH_CUSTOMER_BENEFICIAL",
  FETCH_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/FETCH_CUSTOMER_BENEFICIAL_SUCCESS",
  FETCH_CUSTOMER_BENEFICIAL_ERROR = "@@auth/FETCH_CUSTOMER_BENEFICIAL_ERROR",

  UPDATE_CUSTOMER_BENEFICIAL = "@@auth/UPDATE_CUSTOMER_BENEFICIAL",
  UPDATE_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/UPDATE_CUSTOMER_BENEFICIAL_SUCCESS",
  UPDATE_CUSTOMER_BENEFICIAL_ERROR = "@@auth/UPDATE_CUSTOMER_BENEFICIAL_ERROR",



  DELETE_CUSTOMER_BENEFICIAL = "@@auth/DELETE_CUSTOMER_BENEFICIAL",
  DELETE_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/DELETE_CUSTOMER_BENEFICIAL_SUCCESS",
  DELETE_CUSTOMER_BENEFICIAL_ERROR = "@@auth/DELETE_CUSTOMER_BENEFICIAL_ERROR",


  FETCH_CUSTOMER_BENEFICIAL_VERSION = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION",
  FETCH_CUSTOMER_BENEFICIAL_VERSION_SUCCESS = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION_SUCCESS",
  FETCH_CUSTOMER_BENEFICIAL_VERSION_ERROR = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION_ERROR",

  FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS",
  FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_SUCCESS = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_SUCCESS",
  FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_ERROR = "@@auth/FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_ERROR",

  FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION = "@@auth/FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION",
  FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_SUCCESS = "@@auth/FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_SUCCESS",
  FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_ERROR = "@@auth/FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_ERROR",

  APROVE_CUSTOMER_BENEFICIAL = "@@auth/APROVE_CUSTOMER_BENEFICIAL",
  APROVE_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/APROVE_CUSTOMER_BENEFICIAL_SUCCESS",
  APROVE_CUSTOMER_BENEFICIAL_ERROR = "@@auth/APROVE_CUSTOMER_BENEFICIAL_ERROR",

  REJECT_CUSTOMER_BENEFICIAL = "@@auth/REJECT_CUSTOMER_BENEFICIAL",
  REJECT_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/REJECT_CUSTOMER_BENEFICIAL_SUCCESS",
  REJECT_CUSTOMER_BENEFICIAL_ERROR = "@@auth/REJECT_CUSTOMER_BENEFICIAL_ERROR",

  BLOCK_CUSTOMER_BENEFICIAL = "@@auth/BLOCK_CUSTOMER_BENEFICIAL",
  BLOCK_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/BLOCK_CUSTOMER_BENEFICIAL_SUCCESS",
  BLOCK_CUSTOMER_BENEFICIAL_ERROR = "@@auth/BLOCK_CUSTOMER_BENEFICIAL_ERROR",

  FREEZE_CUSTOMER_BENEFICIAL = "@@auth/FREEZE_CUSTOMER_BENEFICIAL",
  FREEZE_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/FREEZE_CUSTOMER_BENEFICIAL_SUCCESS",
  FREEZE_CUSTOMER_BENEFICIAL_ERROR = "@@auth/FREEZE_CUSTOMER_BENEFICIAL_ERROR",

  UNFREEZE_CUSTOMER_BENEFICIAL = "@@auth/UNFREEZE_CUSTOMER_BENEFICIAL",
  UNFREEZE_CUSTOMER_BENEFICIAL_SUCCESS = "@@auth/UNFREEZE_CUSTOMER_BENEFICIAL_SUCCESS",
  UNFREEZE_CUSTOMER_BENEFICIAL_ERROR = "@@auth/UNFREEZE_CUSTOMER_BENEFICIAL_ERROR",

  RESET = "@@auth/RESET"
}

export type CustomerBeneficiaryActionDispatch = {
  type:
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY
    | CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS
    | CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL

    | CustomerBeneficiaryReduxTypes.RESET;
  payload: any;
};

export type CustomerBeneficiaryReducerDispatch = {
  type:
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_SUCCESS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICARY_ERROR
    | CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_SUCCESS
    | CustomerBeneficiaryReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_ERROR
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_SUCCESS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_ERROR
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_SUCCESS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_VERSION_DETAILS_ERROR
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_SUCCESS
    | CustomerBeneficiaryReduxTypes.FETCH_CUSTOMER_BENEFICIAL_LATEST_VERSION_ERROR
    | CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.APROVE_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.REJECT_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_ERROR
    | CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL
    | CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_SUCCESS
    | CustomerBeneficiaryReduxTypes.DELETE_CUSTOMER_BENEFICIAL_ERROR

    | CustomerBeneficiaryReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<CustomerBeneficiary>;
    error?: string;
  };
};

export type CustomerBeneficiaryReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<CustomerBeneficiary>;
  error?: string | null;
};

export type CustomerBeneficiaryFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type CustomerBeneficiary = {
  id: string;
  customerId: string;
  ifsccode: string;
  accountnum: string;
  corporateCode: string;
  name: string;
  default: boolean;
  active: boolean;
};

export type CreateCustomerBeneficiaryRequest = {
  payload: CreateCustomerBeneficiaryType;
  type: string;
};

export type CreateCustomerBeneficiaryType = {
  customerId: string;
  accountnum: string;
  corporateCode: string;
  default: boolean;
  ifsccode: string;
  name: string;
};
export type CreateCustomerBeneficiaryReduxState = {
  loading?: boolean;
  data?: CreateApiResponse<CreateCustomerBeneficiaryType>;
  error?: string | null;
};
