import { CreateLovReduxState, LovReducerDispatch, LovReduxState, LovReduxTypes } from "./types";

const LOV_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const LovListReducer = (state: LovReduxState = LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case LovReduxTypes.FETCH_LOV_SUCCESS:
    case LovReduxTypes.SEARCH_FILTER_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_ERROR:
    case LovReduxTypes.SEARCH_FILTER_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET: {
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};

const LovListTenorReducer = (state: LovReduxState = LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV_TENOR_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_TENOR_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET: {
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};

const deleteLOVReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.DELETE_LOV: {
      return {
        ...state,
        loading: true
      };
    }

    case LovReduxTypes.DELETE_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.DELETE_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };

    default:
      return { ...state };
  }
};

const CREATE_LOV_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateLovListReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.CREATE_FETCH_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.CREATE_FETCH_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const LovByIdReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case LovReduxTypes.FETCH_LOV_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.UPDATE_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.UPDATE_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case LovReduxTypes.RESET_UPDATE_LOV:
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const LovListVersionReducer = (state: LovReduxState = LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET: {
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};

const LovListVersionDetailsReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case LovReduxTypes.FETCH_LOV_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const LovLatestVersionReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FETCH_LOV_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case LovReduxTypes.FETCH_LOV_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FETCH_LOV_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.APROVE_LOV: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case LovReduxTypes.APROVE_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.APROVE_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const RejectLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.REJECT_LOV: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case LovReduxTypes.REJECT_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.REJECT_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.BLOCK_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.BLOCK_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.FREEZE_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.FREEZE_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeLovReducer = (state: CreateLovReduxState = CREATE_LOV_INIT_STATE, action: LovReducerDispatch) => {
  switch (action.type) {
    case LovReduxTypes.UNFREEZE_LOV_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case LovReduxTypes.UNFREEZE_LOV_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case LovReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  LovListReducer,
  CreateLovListReducer,
  LovByIdReducer,
  UpdateLovReducer,
  LovListTenorReducer,
  LovListVersionReducer,
  AproveLovReducer,
  RejectLovReducer,
  BlockLovReducer,
  FreezeLovReducer,
  UnFreezeLovReducer,
  LovListVersionDetailsReducer,
  LovLatestVersionReducer,
  deleteLOVReducer
};
