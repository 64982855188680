import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { InstrumentFetchRequest, InstrumentReduxTypes } from "./types";
import { fetchAllInstrumentsApi, fetchInstrumentApi } from "./api";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchInstrument({ payload, type }: InstrumentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchInstrumentApi, payload);
    yield put({
      type: InstrumentReduxTypes.FETCH_INSTRUMENT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentReduxTypes.FETCH_INSTRUMENT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchAllInstruments({ payload, type }: InstrumentFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchAllInstrumentsApi, payload);
    yield put({
      type: InstrumentReduxTypes.FETCH_INSTRUMENTS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: InstrumentReduxTypes.FETCH_INSTRUMENTS_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchInstrument() {
  yield takeEvery(InstrumentReduxTypes.FETCH_INSTRUMENT, fetchInstrument);
}

export function* watchFetchAllInstruments() {
  yield takeEvery(InstrumentReduxTypes.FETCH_INSTRUMENTS, fetchAllInstruments);
}

function* instrumentSaga() {
  yield all([fork(watchFetchInstrument), fork(watchFetchAllInstruments)]);
}

export default instrumentSaga;
