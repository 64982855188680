import { SagaIterator } from "redux-saga";
import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { EodBodReduxType } from "./types";
import { forceLogoutApi, getBusinessDateApi, getEodJobStatusApi, initalupdateBusinessDateApi, startEodJobApi, eodJobLoggedInUsersApi, updateBusinessDateApi } from "./apis";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* startEodJob({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(startEodJobApi, payload);

    yield put({
      type: EodBodReduxType.START_EOD_JOB_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.START_EOD_JOB_ERROR,
      payload: { error: error }
    });
  }
}

function* eodJobLoggedInUsers({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(eodJobLoggedInUsersApi, payload);
    yield put({
      type: EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_SUCCESS,
      payload: { data: response.data.data }
    })
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS_ERROR,
      payload: { error: error }
    })
  }
}
function* getEodJobStatus({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(getEodJobStatusApi, payload);

    yield put({
      type: EodBodReduxType.GET_EOD_JOB_STATUS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.GET_EOD_JOB_STATUS_ERROR,
      payload: { error: error }
    });
  }
}

function* getBusinessDate({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(getBusinessDateApi, payload);

    yield put({
      type: EodBodReduxType.GET_BUSINESS_DATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.GET_BUSINESS_DATE_ERROR,
      payload: { error: error }
    });
  }
}

function* updateBusinessDate({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(updateBusinessDateApi, payload);

    yield put({
      type: EodBodReduxType.UPDATE_BUSINESS_DATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.UPDATE_BUSINESS_DATE_ERROR,
      payload: { error: error }
    });
  }
}

function* initialUpdateBusinessDate({ }: any): SagaIterator {
  try {
    const response = yield call(initalupdateBusinessDateApi);

    yield put({
      type: EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE_ERROR,
      payload: { error: error }
    });
  }
}

function* forceLogout({ payload, type }: any): SagaIterator {
  try {
    const response = yield call(forceLogoutApi);
    yield put({
      type: EodBodReduxType.FORCE_LOGOUT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: EodBodReduxType.FORCE_LOGOUT_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchStartEodJob() {
  yield takeEvery(EodBodReduxType.START_EOD_JOB, startEodJob);
}
export function* watchStartEodJobLoggedInUsers() {
  yield takeEvery(EodBodReduxType.START_EOD_JOB_LOGGED_IN_USERS, eodJobLoggedInUsers)
}
export function* watchGetEodJobStatus() {
  yield takeEvery(EodBodReduxType.GET_EOD_JOB_STATUS, getEodJobStatus);
}
export function* watchGetBusinessDate() {
  yield takeEvery(EodBodReduxType.GET_BUSINESS_DATE, getBusinessDate);
}

export function* watchForceLogout() {
  yield takeEvery(EodBodReduxType.FORCE_LOGOUT, forceLogout);
}

export function* watchUpdateBusinessDate() {
  yield takeEvery(EodBodReduxType.UPDATE_BUSINESS_DATE, updateBusinessDate);
}

export function* watchInitialUpdateBusinessDate() {
  yield takeEvery(EodBodReduxType.INITIAL_UPDATE_BUSINESS_DATE, initialUpdateBusinessDate);
}

function* eodBodSaga() {
  yield all([
    fork(watchStartEodJob),
    fork(watchGetEodJobStatus),
    fork(watchGetBusinessDate),
    fork(watchForceLogout),
    fork(watchUpdateBusinessDate),
    fork(watchInitialUpdateBusinessDate),
    fork(watchStartEodJobLoggedInUsers)
  ]);
}
export default eodBodSaga;
