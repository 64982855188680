import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum DisbursementReduxTypes {
  FETCH_DISBURSEMENTS = "@@auth/FETCH_DISBURSEMENT",
  FETCH_DISBURSEMENTS_SUCCESS = "@@auth/FETCH_DISBURSEMENTS_SUCCESS",
  FETCH_DISBURSEMENTS_ERROR = "@@auth/FETCH_DISBURSEMENTS_ERROR",

  RESET_DISBURSEMENT = "@@auth/RESET"
}

export type DisbursementActionDispatch = {
  type: DisbursementReduxTypes.FETCH_DISBURSEMENTS | DisbursementReduxTypes.RESET_DISBURSEMENT;
  payload: any;
};

export type DisbursementReducerDispatch = {
  type:
    | DisbursementReduxTypes.FETCH_DISBURSEMENTS
    | DisbursementReduxTypes.FETCH_DISBURSEMENTS_SUCCESS
    | DisbursementReduxTypes.FETCH_DISBURSEMENTS_ERROR
    | DisbursementReduxTypes.RESET_DISBURSEMENT;
  payload: {
    data?: FetchApiResponse<disbursement>;
    error?: string;
  };
};
export type disbursement = {
  id: String;
  paymentBatchId: string;
  borrowerLimitUtilizationId: string;
  amount: number;
  disbursementDate: string;
  interestType: string;
  utr: string;
  normalSpreadRateOfInterest: number;
  normalGraceRateOfInterest: number;
  normalBaseRateOfInterest: number;
  overdueSpreadRateOfInterest: number;
  adhocSpreadRateOfInterest: number;
  adhocBaseRateOfInterest: number;
  processingStatus: string;
  active: Boolean;
  createdOn: string;
  updatedOn: string;
};

export type DisbursementsFetchRequest = {
  payload: FilterMetadata;
  type: string;
};

export type DisbursementReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<disbursement>;
  error?: string | null;
};
