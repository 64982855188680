import config from "config";
import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";

const api = new APICore();

function fetchAllInterestAccrualApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/instrument-daily-interest-accrual`;
  return api.get(`${baseUrl}`, params);
}

function fetchInterestAccrualApi(params: any) {
  const baseUrl = `${config.EOD_BASE_URL}/instrument-daily-interest-accrual/${decodeURI(params)}`;
  return api.get(`${baseUrl}`, params);
}

export { fetchAllInterestAccrualApi, fetchInterestAccrualApi };
