import { APICore } from "helpers/api/apiCore";
import { FilterMetadata } from "redux/types";
import config from "../../../config";
import { CreateRepayment, UpdateRepayment } from "./types";

const api = new APICore();

function fetchRepaymentsApi(params: FilterMetadata) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request`;
  return api.get(`${baseUrl}`, params);
}

function fetchRepaymentsAdjusmentEntriesApi(params: FilterMetadata) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-settlement-entries`;
  return api.get(`${baseUrl}`, params);
}

function createRepaymentRequestSettleApi(params: any) {
  console.log(params);

  const apiData = {
    repaymentRequestExternalId: params?.repaymentRequest?.externalId ? params?.repaymentRequest?.externalId : params?.externalId,
    typePayload: null,
    repaymentRequestActionType: "SettleInstruments",
    makerRemarks: "Request Send"
  };

  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-request-actions/settle`;
  return api.create(`${baseUrl}`, apiData);
}

function createRepaymentRequestSettleManuallyApi(params: any) {
  const apiData = {
    programExternalId: params?.programId?.externalId ? params?.programId?.externalId : "",
    customerRelationExternalId: params?.buyerSellerId?.externalId ? params?.buyerSellerId?.externalId : "",
    requestId: params?.requestId ? params?.requestId : ""
  };

  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request/repayment/settle-manually`;
  return api.create(`${baseUrl}`, apiData);
}

function fetchRepaymentsAdjusmentEntrieApi(params: any) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-settlement-entries/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchRepaymentApi(params: any) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createRepaymentApi(params: any) {
  let formData = new FormData();
  formData.append("file", params?.batchInstrumnets[0]);
  // formData.append('loanAccountNumber', "6a0ac741-81b1-4806-8d4b-90bace477f05-54c4d015-fbaa-4cb8-8f09-139d953c48e8");
  // formData.append('virtualAccountNumber', "100020033532");
  // formData.append('transactionReferenceNumber', "TR040");
  // formData.append('transactionReferenceType', "Utr");

  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request/repayment/file`;
  return api.create(baseUrl, formData);
}

function uploadRepaymentSettlemtnApi(params: any) {
  let formData = new FormData();
  formData.append("file", params?.batchInstrumnets[0]);

  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayments-request-instrument-settlement/settleInstruments/file`;
  return api.create(baseUrl, formData);
}

function fetchRepaymentVersionApi(params: any) {
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-request-actions/${params}/action/versions`;
  return api.get(`${baseUrl}`, {});
}

function approveRepaymentApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  console.log(params);

  let externalId = params.externalId;
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-request-actions/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
function rejectRepaymentApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };

  let externalId = params.externalId;
  const baseUrl = `${config.REPAYMENT_BASE_URL}/repayment-request-actions/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  fetchRepaymentsApi,
  fetchRepaymentApi,
  createRepaymentApi,
  fetchRepaymentVersionApi,
  approveRepaymentApi,
  rejectRepaymentApi,
  uploadRepaymentSettlemtnApi,
  fetchRepaymentsAdjusmentEntriesApi,
  fetchRepaymentsAdjusmentEntrieApi,
  createRepaymentRequestSettleApi,
  createRepaymentRequestSettleManuallyApi
};
