import { CreateDisbursementAdviceReduxState, DisbursementAdviceReducerDispatch, DisbursementAdviceReduxTypes } from "./types";

const CREATE_DISBURSEMENT_ADVICE_INIT_STATE = {
    data: {},
    loading: false,
    error: null
}

const CreateDisbursementAdviceReducer = (state: CreateDisbursementAdviceReduxState = CREATE_DISBURSEMENT_ADVICE_INIT_STATE, action: DisbursementAdviceReducerDispatch) => {
    switch (action.type) {
        case DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT: {
            return {
                ...state,
                loading: true
            }
        }
        case DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false
            }
        }
        case DisbursementAdviceReduxTypes.DISBURSEMENT_ADVICE_REPORT_ERROR: {
            return {
                data: {},
                error: action.payload.error,
                loading: false,
            }
        }


        default:
            return { ...state };
    }
}

export { CreateDisbursementAdviceReducer }