import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { CreateGlAccountRequestRequest, FetchGlAccountByIdRequest, GlAccountFetchRequest, GlAccountReduxTypes, UpdateGlAccountRequest } from "./types";
import {
  approveGlAccountApi,
  blockGlAccountApi,
  createGlAccountApi,
  deleteGlAccountApi,
  fetchGlAccountApi,
  fetchGlAccountApiById,
  fetchGlAccountLatestVersionApi,
  fetchGlAccountVersionApi,
  fetchGlAccountVersionDetailsApi,
  freezeGlAccountApi,
  rejectGlAccountApi,
  unFreezeGlAccountApi,
  updateGlAccountApi
} from "./api";

function* fetchGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* createGlAccount({ payload, type }: CreateGlAccountRequestRequest): SagaIterator {
  try {
    const response = yield call(createGlAccountApi, payload);

    yield put({
      type: GlAccountReduxTypes.CREATE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.CREATE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchGlAccountById({ payload, type }: FetchGlAccountByIdRequest): SagaIterator {
  try {
    const response = yield call(fetchGlAccountApiById, payload);

    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_ERROR,
      payload: { error: error }
    });
  }
}

function* UpdateGlAccount({ payload, type }: UpdateGlAccountRequest): SagaIterator {
  try {
    const response = yield call(updateGlAccountApi, payload);

    yield put({
      type: GlAccountReduxTypes.UPDATE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.UPDATE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* deleteGlAccount({ payload, type }: UpdateGlAccountRequest): SagaIterator {
  try {
    const response = yield call(deleteGlAccountApi, payload);

    yield put({
      type: GlAccountReduxTypes.DELETE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.DELETE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchGlAccountVersion({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchGlAccountVersionApi, payload);
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchGlAccountLatestVersion({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchGlAccountLatestVersionApi, payload);
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchGlAccountVersionDetails({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchGlAccountVersionDetailsApi, payload);
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(approveGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.APROVE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.APROVE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.REJECT_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.REJECT_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* blockGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(blockGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.BLOCK_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.BLOCK_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.FREEZE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.FREEZE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeGlAccount({ payload, type }: GlAccountFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeGlAccountApi, payload);
    yield put({
      type: GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchGlAccount() {
  yield takeEvery(GlAccountReduxTypes.FETCH_GL_ACCOUNT, fetchGlAccount);
}
export function* watchCreatehGlAccount() {
  yield takeEvery(GlAccountReduxTypes.CREATE_GL_ACCOUNT, createGlAccount);
}

export function* watchfetchGlAccountById() {
  yield takeEvery(GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID, fetchGlAccountById);
}

export function* watchUpdatehGlAccount() {
  yield takeEvery(GlAccountReduxTypes.UPDATE_GL_ACCOUNT, UpdateGlAccount);
}

export function* watchFetchGlAccountVersion() {
  yield takeEvery(GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION, fetchGlAccountVersion);
}

export function* watchAproveGlAccount() {
  yield takeEvery(GlAccountReduxTypes.APROVE_GL_ACCOUNT, aproveGlAccount);
}
export function* watchRejectGlAccount() {
  yield takeEvery(GlAccountReduxTypes.REJECT_GL_ACCOUNT, rejectGlAccount);
}

export function* watchBlockGlAccount() {
  yield takeEvery(GlAccountReduxTypes.BLOCK_GL_ACCOUNT, blockGlAccount);
}

export function* watchFreezeGlAccount() {
  yield takeEvery(GlAccountReduxTypes.FREEZE_GL_ACCOUNT, freezeGlAccount);
}

export function* watchUnFreezeGlAccount() {
  yield takeEvery(GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT, unFreezeGlAccount);
}

export function* watchFetchGlAccountVersionDetails() {
  yield takeEvery(GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS, fetchGlAccountVersionDetails);
}

export function* watchFetchGlAccountLatestVersion() {
  yield takeEvery(GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION, fetchGlAccountLatestVersion);
}

export function* watchDeleteGlAccount() {
  yield takeEvery(GlAccountReduxTypes.DELETE_GL_ACCOUNT, deleteGlAccount);
}

function* glAccountSaga() {
  yield all([
    fork(watchFetchGlAccount),
    fork(watchCreatehGlAccount),
    fork(watchfetchGlAccountById),
    fork(watchUpdatehGlAccount),

    fork(watchFetchGlAccountVersion),
    fork(watchAproveGlAccount),
    fork(watchRejectGlAccount),
    fork(watchBlockGlAccount),
    fork(watchFreezeGlAccount),
    fork(watchUnFreezeGlAccount),
    fork(watchFetchGlAccountVersionDetails),
    fork(watchFetchGlAccountLatestVersion),
    fork(watchDeleteGlAccount)
  ]);
}
export default glAccountSaga;
