import { APICore } from "helpers/api/apiCore";
import config from "config";
import { AddresCreationRequest } from "./types";

const api = new APICore();

function fetchAddressesApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses`;

  return api.get(`${baseUrl}`, params);
}

function createAddressApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    floorNumber: params.floorNumber.trim(),
    doorNumber: params.doorNumber.trim(),
    building: params.building.trim(),
    street: params.street.trim(),
    city: params.city.name,
    state: params.state.name,
    zip: params.zip.trim(),
    location: params.location.trim(),
    addressType: params.addressType
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // let data: any = {
  //     floorNumber: params.floorNumber,
  //     doorNumber: params.doorNumber,
  //     building: params.building,
  //     street: params.street,
  //     city: params.city,
  //     state: params.state,
  //     zip: params.zip,
  //     location: params.location,
  //     addressType: params.addressType
  // };
  // const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses`;
  // return api.create(`${baseUrl}`, data);
}

function fetchByIdAddressesApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}`;

  return api.get(`${baseUrl}`, {});
}

function UpdatedAddressesApi(params: any) {
  let backendApiUrl = "";

  // console.log("paramsc", params);

  let apiData: any = {
    floorNumber: params.floorNumber.trim(),
    doorNumber: params.doorNumber.trim(),
    building: params.building.trim(),
    street: params.street.trim(),
    city: params.city.name ? params.city.name : params.city,
    state: params.state.name ? params.state.name : params.state,
    zip: params.zip.trim(),
    location: params.location.trim(),
    addressType: params.addressType
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalUserId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalUserId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
  // const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalUserId}`;

  // return api.update(`${baseUrl}`, data);
}

function fetchByIdAddressesVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}/versions`;

  return api.get(`${baseUrl}`, {});
}

function fetchAddressesLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}/latestVersion`;

  return api.get(`${baseUrl}`, {});
}

function fetchByIdAddressesVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalID}/versions/${params.internalID}`;

  return api.get(`${baseUrl}`, {});
}



function deleteCustomerAddressApi(params: any) {

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalCustomerId}`;
  return api.delete(`${baseUrl}`);
}


function aproveCustomerAddressApi(params: any) {
  // console.log(params);

  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalCustomerId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectCustomerAddressApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.externalCustomerId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockCustomerAddressApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeCustomerAddressApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeCustomerAddressApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/addresses/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  fetchAddressesApi,
  createAddressApi,
  fetchByIdAddressesApi,
  UpdatedAddressesApi,
  fetchByIdAddressesVersionApi,
  aproveCustomerAddressApi,
  rejectCustomerAddressApi,
  blockCustomerAddressApi,
  freezeCustomerAddressApi,
  unFreezeCustomerAddressApi,
  fetchByIdAddressesVersionDetailsApi,
  fetchAddressesLatestVersionApi,
  deleteCustomerAddressApi
};
