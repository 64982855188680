import { SagaIterator } from "redux-saga";
import { CreateInterestComputationRequest, InterestComputationReduxTypes } from "./types";
import { call, fork, put, takeEvery } from "redux-saga/effects";
import { createInterestComputationApi } from "./api";

function* createInterestComputation({ payload }: CreateInterestComputationRequest): SagaIterator {
    try {
        const response = yield call(createInterestComputationApi, payload);
        yield put({
            type: InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_SUCCESS,
            payload: { data: response.data.data }
        })
    } catch (error: any) {
        yield put({
            type: InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT_ERROR,
            payload: { error: error }
        })
    }
}

export function* watchCreateInterestComputation() {
    yield takeEvery(InterestComputationReduxTypes.INTEREST_COMPUTATION_REPORT, createInterestComputation)
}

function* interestComputationSaga() {
    yield fork(watchCreateInterestComputation)
}

export default interestComputationSaga