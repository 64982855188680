import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateLimitConfig, updateLimitConfigVersion } from "./types";

const api = new APICore();

function fetchLimitConfigApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/limits`;
  return api.get(`${baseUrl}`, params);
}

function fetchSingleLimitConfigApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/limits/${params.id}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function deleteLimitConfigApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/limits/${params.id}`;
  return api.delete(`${baseUrl}`);
}

function fetchLimitConfigByCustomerExternalIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits`;
  return api.get(`${baseUrl}`, params.filters);
}

function fetchSingleLimitConfigHistroyApi(params: any) {

  const baseUrl = `${config.BACKEND_BASE_URL}/borrower-limit-history/${params}/latest`;
  return api.get(`${baseUrl}`, {});
}

function fetchSingleAggregateLimitConfigHistroyApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/borrower-limit-history/${params}/aggregate/latest`;
  return api.get(`${baseUrl}`, {});
}

function createLimitConfigApi(params: CreateLimitConfig) {
  let customer: any = params.customerExternalId;
  let backendApiUrl = "";
  let apiData: any = {
    levelType: params.levelType ? params.levelType : "",
    // productNature: params.productNature ? params.productNature : "Revolving",
    productTypeId: params.productTypeId ? params.productTypeId : null,
    parentAggregateId: params.parentAggregateId ? params.parentAggregateId : null,
    currencyId: params.currencyId ? params.currencyId.id : null,
    normalSanctionedAmount: params.normalSanctionedAmount ? params.normalSanctionedAmount : "",
    normalOperativeAmount: params.normalOperativeAmount ? params.normalOperativeAmount : "",
    normalEffectiveUpto: params.normalEffectiveUpto ? params.normalEffectiveUpto : "",
    normalEffective: params.normalEffective ? params.normalEffective : "",
    adhocSanctionedAmount: params.adhocSanctionedAmount ? params.adhocSanctionedAmount : "",
    adhocEffectiveUpto: params.adhocEffectiveUpto ? params.adhocEffectiveUpto : "",
    adhocEffective: params.adhocEffective ? params.adhocEffective : ""
  };

  backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${customer.externalId}/limits?draft=true`;
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateLimitConfigApi(params: any) {
  let customer: any = params.customerExternalId;
  let backendApiUrl = "";
  let apiData: any = {
    levelType: params.levelType ? params.levelType : "",
    productTypeId: params.productType?.id ? params.productType?.id : null,
    parentAggregateId: params.parentAggregateId ? params.parentAggregateId : null,
    currencyId: params.currencyId ? params.currencyId.id : null,
    normalSanctionedAmount: params.normalSanctionedAmount ? params.normalSanctionedAmount : "",
    normalOperativeAmount: params.normalOperativeAmount ? params.normalOperativeAmount : "",
    normalEffectiveUpto: params.normalEffectiveUpto ? params.normalEffectiveUpto : "",
    normalEffective: params.normalEffective ? params.normalEffective : "",
    adhocSanctionedAmount: params.adhocSanctionedAmount ? params.adhocSanctionedAmount : "",
    adhocEffectiveUpto: params.adhocEffectiveUpto ? params.adhocEffectiveUpto : "",
    adhocEffective: params.adhocEffective ? params.adhocEffective : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customer?.externalId}/limits/${params.limitExternalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/customers/${params.customer?.externalId}/limits/${params.limitExternalId}?draft=true`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}
function fetchLimitConfigVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits/${params.limitExternalId}/versions`;
  return api.get(`${baseUrl}`, params);
}



function fetchLimitConfigLatestVersionApi(params: any) {
  let flag = {
    modifyLatest: true
  }
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerExternalId}/limits/${params.limitExternalId}/latestVersion`;
  return api.get(`${baseUrl}`, flag);
}

function fetchLimitConfigVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${params.customerId}/limits/${params.externalID}/versions/${params.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function approveLimitConfigApi(params: updateLimitConfigVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalCustomerId = params.customerExternalId;
  let limitExternalId = params.limitExternalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalCustomerId}/limits/${limitExternalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectLimitConfigApi(params: updateLimitConfigVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalCustomerId = params.customerExternalId;
  let limitExternalId = params.limitExternalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalCustomerId}/limits/${limitExternalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockLimitConfigApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalCustomerId = params.customerExternalId;
  let limitExternalId = params.limitExternalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalCustomerId}/limits/${limitExternalId}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeLimitConfigApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalCustomerId = params.customerExternalId;
  let limitExternalId = params.limitExternalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalCustomerId}/limits/${limitExternalId}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeLimitConfigApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalCustomerId = params.customerExternalId;
  let limitExternalId = params.limitExternalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/customers/${externalCustomerId}/limits/${limitExternalId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchLimitConfigApi,
  createLimitConfigApi,
  fetchLimitConfigByCustomerExternalIdApi,
  fetchSingleLimitConfigApi,
  updateLimitConfigApi,
  fetchLimitConfigVersionApi,
  approveLimitConfigApi,
  rejectLimitConfigApi,
  blockLimitConfigApi,
  freezeLimitConfigApi,
  unFreezeLimitConfigApi,
  fetchSingleLimitConfigHistroyApi,
  fetchSingleAggregateLimitConfigHistroyApi,
  fetchLimitConfigVersionDetailsApi,
  fetchLimitConfigLatestVersionApi,
  deleteLimitConfigApi
};
