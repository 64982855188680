import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum DailyBalanceReduxTypes {
  FETCH_DAILY_BALANCES = "@@auth/FETCH_DAILY_BALANCES",
  FETCH_DAILY_BALANCES_SUCCESS = "@@auth/FETCH_DAILY_BALANCES_SUCCESS",
  FETCH_DAILY_BALANCES_ERROR = "@@auth/FETCH_DAILY_BALANCES_ERROR",

  FETCH_DAILY_BALANCE = "@@auth/FETCH_DAILY_BALANCE",
  FETCH_DAILY_BALANCE_SUCCESS = "@@auth/FETCH_DAILY_BALANCE_SUCCESS",
  FETCH_DAILY_BALANCE_ERROR = "@@auth/FETCH_DAILY_BALANCE_ERROR",

  RESET = "@@auth/RESET"
}

export type DailyBalanceActionDispatch = {
  type: DailyBalanceReduxTypes.FETCH_DAILY_BALANCES | DailyBalanceReduxTypes.FETCH_DAILY_BALANCE | DailyBalanceReduxTypes.RESET;
  payload: any;
};

export type dailyBalanceReducerDispatch = {
  type:
    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCES
    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_SUCCESS
    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCES_ERROR

    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCE
    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_SUCCESS
    | DailyBalanceReduxTypes.FETCH_DAILY_BALANCE_ERROR
    | DailyBalanceReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<dailyBalance>;
    error?: string;
  };
};

export type DailyBalanceReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<dailyBalance>;
  error?: string | null;
};

export type dailyBalance = {
  id: string;
  externalId: string;
  instrumentBatchId: string;
  counterPartyExternalId: string;
  currencyShortCode: string;
  corporateCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  dueDate: string;
  receivedDate: string;
  instrumentAmount: number;
  additionalDetails: string;
  docType: string;
  ifscCode: string;
  accountNumber: string;
  userRemarks: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  active: true;
  createdOn: Date;
  updatedOn: Date;
};

export type DailyBalanceFetchRequest = {
  payload: FilterMetadata;
  type: string;
};
