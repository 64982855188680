import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { createProduct, UpdateProductType } from "./types";

const api = new APICore();

function fetchProductsTypeApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type`;
  return api.get(`${baseUrl}`, params);
}

function fetchProductTypeApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params}`;
  return api.get(`${baseUrl}`, {});
}

function deleteProductTypeApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params}`;
  return api.delete(`${baseUrl}`);
}

function createProductTypeApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    productType: params.productType.label,

    chargePayer: params.chargePayer ? params.chargePayer.value : "",
    exposureOn: params.exposureOn ? params.exposureOn.value : "",
    anchorType: params.anchorType ? params.anchorType.value : "",

    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    isShowRepaymentAccount: params.isShowRepaymentAccount ? params.isShowRepaymentAccount.value : false,

    appropriationGroup: params?.appropriationType.value
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/product-type`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/product-type?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function updateProductTypeApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    productType: params.productType.label ? params.productType.label : params.productType,
    // instrumentType: params.instrumentType.value ? params.instrumentType.value : "",
    // chargePayerCode: params.chargePayerCode ? params.chargePayerCode : "",
    chargePayer: params.chargePayer ? params.chargePayer.value : "",
    // exposureOnCode: params.exposureOnCode,
    exposureOn: params.exposureOn ? params.exposureOn.value : "",
    anchorType: params.anchorType ? params.anchorType.value : "",
    // invoiceConcentrationAmount: params.invoiceConcentrationAmount ? params.invoiceConcentrationAmount : 0,
    // dailyTransactionLimit: params.dailyTransactionLimit ? params.dailyTransactionLimit : 0,
    // tenorDays: params.tenorDays ? params.tenorDays : 0,
    // staleDays: params.staleDays ? params.staleDays : 0,
    repaymentSettlementStrategy: params.repaymentSettlementStrategy ? params.repaymentSettlementStrategy.value : "",
    isMarginRefundApplicable: params.isMarginRefundApplicable ? params.isMarginRefundApplicable.value : false,
    isUniqueLoanAndVirtualAccountNumber: params.isUniqueLoanAndVirtualAccountNumber ? params.isUniqueLoanAndVirtualAccountNumber.value : false,

    isShowRepaymentAccount: params.isShowRepaymentAccount ? params.isShowRepaymentAccount.value : false,
    appropriationGroup: params?.appropriationType ? params?.appropriationType.value : params?.appropriationGroup
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/product-type/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/product-type/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.id}`;
  // return api.update(`${baseUrl}`, updateProductTypeData);
}

function fetchProductsTypeVersionApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}

function fetchProductsTypeLatestVersionApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}




function fetchProductsTypeVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function aproveProductTypeApi(params: any) {
  // console.log("params",params);
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };

  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectProductTypeApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockProductTypeApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeProductTypeApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeProductTypeApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/product-type/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

export {
  blockProductTypeApi,
  rejectProductTypeApi,
  aproveProductTypeApi,
  fetchProductsTypeVersionApi,
  fetchProductsTypeApi,
  fetchProductTypeApi,
  updateProductTypeApi,
  createProductTypeApi,
  freezeProductTypeApi,
  unFreezeProductTypeApi,
  fetchProductsTypeVersionDetailsApi,
  fetchProductsTypeLatestVersionApi,
  deleteProductTypeApi
};
