import { CreateGlAccountReduxState, GlAccountReducerDispatch, GlAccountReduxState, GlAccountReduxTypes } from "./types";

const GL_ACCOUNT_INIT_STATE = {
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  loading: false,
  error: null
};

const GlAccountReducer = (state: GlAccountReduxState = GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }

    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET: {
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};

const CREATE_GL_ACCOUNT_INIT_STATE = {
  data: {},
  loading: false,
  error: null
};

const CreateGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.CREATE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.CREATE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const GlAccountByIdReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID: {
      return {
        ...state,
        loading: true
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_BY_ID_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const GlAccountVersionDetailsReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS: {
      return {
        ...state,
        loading: true
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_DETAILS_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UpdateGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.UPDATE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.UPDATE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }

    case GlAccountReduxTypes.RESET_UPDATE_GL_ACCOUNT:
      return {
        ...state,
        data: {},
        loading: false,
        error: null
      };
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const GlAccountListVersionReducer = (state: GlAccountReduxState = GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET: {
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    }
    default:
      return { ...state };
  }
};


const GlAccountLatestVersionReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION: {
      return {
        ...state,
        loading: true
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FETCH_GL_ACCOUNT_LATEST_VERSION_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const AproveGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.APROVE_GL_ACCOUNT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case GlAccountReduxTypes.APROVE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.APROVE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const DeleteGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.DELETE_GL_ACCOUNT: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case GlAccountReduxTypes.DELETE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.DELETE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const RejectGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.REJECT_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case GlAccountReduxTypes.REJECT_GL_ACCOUNT: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.REJECT_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const BlockGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.BLOCK_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.BLOCK_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const FreezeGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.FREEZE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.FREEZE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

const UnFreezeGlAccountReducer = (state: CreateGlAccountReduxState = CREATE_GL_ACCOUNT_INIT_STATE, action: GlAccountReducerDispatch) => {
  switch (action.type) {
    case GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case GlAccountReduxTypes.UNFREEZE_GL_ACCOUNT_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case GlAccountReduxTypes.RESET:
      return {
        ...state,
        data: {},
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export {
  GlAccountReducer,
  CreateGlAccountReducer,
  GlAccountByIdReducer,
  UpdateGlAccountReducer,
  GlAccountListVersionReducer,
  AproveGlAccountReducer,
  RejectGlAccountReducer,
  BlockGlAccountReducer,
  FreezeGlAccountReducer,
  UnFreezeGlAccountReducer,
  GlAccountVersionDetailsReducer,
  GlAccountLatestVersionReducer,
  DeleteGlAccountReducer
};
