import { FilterMetadata } from "redux/types";
import { BaseRateActionDispatch, BaseRateReduxTypes, CreateBaseRate, UpdateBaseRate, updateBaseRateVersion } from "./types";

export const fetchBaseRate = (payload: FilterMetadata): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FETCH_BASE_RATES,
  payload: payload
});

export const createBaseRate = (payload: CreateBaseRate): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.CREATE_BASE_RATE,
  payload: payload
});

export const fetchBaseRateByExternalId = (payload: any): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FETCH_BASE_RATE,
  payload: payload
});

export const updateBaseRate = (payload: UpdateBaseRate): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.UPDATE_BASE_RATE,
  payload: payload
});

export const fetchBaseRateVersions = (payload: any): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION,
  payload: payload
});

export const fetchBaseRateVersionsDetails = (payload: any): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FETCH_BASE_RATE_VERSION_DETAILS,
  payload: payload
});

export const fetchBaseRateLatestVersion = (payload: any): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FETCH_BASE_RATE_LATEST_VERSION,
  payload: payload
});


export const deleteBaseRate = (payload: any): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.DELETE_BASE_RATE,
  payload: payload
});

export const approveBaseRateVersions = (payload: updateBaseRateVersion): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.APPROVE_BASE_RATE,
  payload: payload
});
export const rejectBaseRateVersions = (payload: updateBaseRateVersion): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.REJECT_BASE_RATE,
  payload: payload
});

export const blockBaseRate = (payload: updateBaseRateVersion): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.BLOCK_BASE_RATE,
  payload: payload
});

export const freezeBaseRate = (payload: updateBaseRateVersion): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.FREEZE_BASE_RATE,
  payload: payload
});

export const unFreezeBaseRate = (payload: updateBaseRateVersion): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.UNFREEZE_BASE_RATE,
  payload: payload
});

export const resetBaseRate = (): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.RESET,
  payload: {}
});

export const resetUpdateBaseRate = (): BaseRateActionDispatch => ({
  type: BaseRateReduxTypes.RESET_UPDATE_BASE_RATE,
  payload: {}
});
