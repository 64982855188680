import { combineReducers } from "redux";
import { Auth, LoginHistoryReducer, ChangePasswordReducer, ResetPasswordReducer } from "./auth/reducers";
import {
  FinancierUserListReducer,
  CreateFinancierUserReducer,
  FinancierUserReducer,
  UpdateFinancierUserReducer,
  FetchFinancierUserVesrions,
  ApproveFinancierUserReducer,
  RejectFinancierUserReducer,
  BlockFinancierUserReducer,
  FreezeFinancierUserReducer,
  UnFreezeFinancierUserReducer,
  FetchFinancierUserVesrionDetails,
  DeleteFinancierUserReducer,
  FetchFinancierUserLatestVesrionReducer
} from "./financierUser/reducers";

import {
  UserRoleListReducer,
  CreateUserRoleReducer,
  UserRoleReducer,
  UpdateUserRoleReducer,
  FetchUserRoleVesrions,
  ApproveUserRoleReducer,
  RejectUserRoleReducer,
  BlockUserRoleReducer,
  FreezeUserRoleReducer,
  UnFreezeUserRoleReducer,
  ModulePermissionListReducer,
  FetchUserRoleVesrionsDetails,
  FetchUserRoleLatestVesrionsReducer,
  DeleteUserRoleReducer
} from "./userRoles/reducers";
import {
  CurrencyVersionListReducer,
  aproveCurrencyReducer,
  rejectCurrencyReducer,
  blockCurrencyReducer,
  CurrencyListReducer,
  CreateCurrencyReducer,
  CurrencyReducer,
  UpdateCurrencyReducer,
  DeleteCurrencyReducer,
  FreezeCurrencyReducer,
  UnFreezeCurrencyReducer,
  CurrencyVersionDetailsReducer,
  FetchCurrencyLatestVerionReducer
} from "./currency/reducer";
import {
  BaseRateListReducer,
  CreateBaseRateReducer,
  BaseRateReducer,
  UpdateBaseRateReducer,
  FetchBaseRateVesrions,
  ApproveBaseRateReducer,
  RejectBaseRateReducer,
  BlockBaseRateReducer,
  FreezeBaseRateReducer,
  UnFreezeBaseRateReducer,
  FetchBaseRateVesrionsDetailsReducer,
  BaseRateReducerLatestVersionReducer,
  DeleteBaseRateReducer
} from "./baseRate/reducer";
import {
  HolidayListReducer,
  HolidayLatestVersionReducer,
  DeleteHolidayReducer,
  CreateHolidayReducer,
  HolidayReducer,
  UpdateHolidayReducer,
  FetchHolidayVesrions,
  ApproveHolidayReducer,
  RejectHolidayReducer,
  BlockHolidayReducer,
  FreezeHolidayReducer,
  UnFreezeHolidayReducer,
  HolidayVersionDetailsReducer
} from "./holiday/reducer";

import {
  HolidayBatchListReducer,
  CreateHolidayBatchReducer,
  HolidayBatchReducer,
  UpdateHolidayBatchReducer,
  FetchHolidayBatchVesrions,
  ApproveHolidayBatchReducer,
  RejectHolidayBatchReducer,
  BlockHolidayBatchReducer,
  FreezeHolidayBatchReducer,
  UnFreezeHolidayBatchReducer,
  PublishHolidayBatchReducer,
  HolidayBatchFilterReducer,
  HolidayBatchVersionDetailsReducer
} from "./holidayBatch/reducer";
import {
  BranchListReducer,
  CreateBranchReducer,
  BranchReducer,
  FetchBranchVesrions,
  ApproveBranchReducer,
  RejectBranchReducer,
  UpdateBranchReducer,
  BlockBranchReducer,
  FreezeBranchReducer,
  UnFreezeBranchReducer,
  BranchVersionDetailsReducer,
  BranchReducerLatestVerionReducer,
  DeleteBranchReducer
} from "./branch/reducer";
import Layout from "./layout/reducers";

import {
  CountryListReducer,
  CountryReducer,
  CreateCountryReducer,
  UpdateCountryReducer,
  CountryVersionListReducer,
  approveCountryReducer,
  rejectCountryReducer,
  blockCountryReducer,
  FreezeCountryReducer,
  UnFreezeCountryReducer,
  CountryVersionDetailsReducer,
  CountryReducerLatestVersionReducer,
  DeleteCountryReducer
} from "./country/reducers";
import {
  StateListReducer,
  CreateStateReducer,
  StateReducer,
  UpdateStateReducer,
  StateListVersionReducer,
  ApproveStateReducer,
  RejectStateReducer,
  BlockStateReducer,
  FreezeStateReducer,
  UnFreezeStateReducer,
  StateVersionDetailsReducer,
  StateLatestVersionReducer,
  DeleteStateReducer
} from "./state/reducers";
import {
  CityListReducer,
  CreateCityReducer,
  CityReducer,
  UpdateCityReducer,
  CityListVersionReducer,
  ApproveCityReducer,
  RejectCityReducer,
  BlockCityReducer,
  FreezeCityReducer,
  UnFreezeCityReducer,
  CityVersionDetailsReducer,
  CityReducerLatestVersionReducer,
  DeleteCityReducer
} from "./city/reducers";
import {
  RelationshipManagerListReducer,
  RelationshipMaanagerReducer,
  CreaterelationshipManagerReducer,
  UpdateRelationshipMaanagerReducer,
  RelationshipManagerListVersionReducer,
  AproveRelationshipManagerReducer,
  RejectRelationshipManagerReducer,
  BlockRelationshipManagerReducer,
  FreezeRelationshipManagerReducer,
  UnFreezeRelationshipManagerReducer,
  RelationshipMaanagerVersionDetailsReducer,
  RelationshipMaanagerLatestVersionReducer,
  DeleteRelationshipMaanagerReducer
} from "./relationshipManager/reducer";
import {
  LovListReducer,
  CreateLovListReducer,
  LovByIdReducer,
  UpdateLovReducer,
  LovListTenorReducer,
  LovListVersionReducer,
  AproveLovReducer,
  RejectLovReducer,
  BlockLovReducer,
  FreezeLovReducer,
  UnFreezeLovReducer,
  LovListVersionDetailsReducer,
  LovLatestVersionReducer,
  deleteLOVReducer
} from "./lov/reducer";
import {
  TaskIdByPanNumberReducer,
  TaskStatusByTaskIdReducer,
  TaskResultByTaskIdReducer,
  CustomerListReducer,
  CustomerReducer,
  CustomerValidateReducer,
  ApproveCustomerReducer,
  RejectCustomerReducer,
  CustomerBlockReducer,
  GstDetailReducer,
  CustomerVersionsReducer,
  CreateCustomerReducer,
  UpdateCustomerReducer,
  DeDuplicateCustomerReducer,
  CustomerFreezeReducer,
  CustomerUnFreezeReducer,
  CustomerVersionsDetailsReducer,
  CustomerLatestVersionsReducer,
  DeleteCustomerReducer
} from "./customer/reducer";
import {
  CreateCustomerContactReducer,
  CustomerContactListReducer,
  CustomerContactBYIdReducer,
  UpdateCustomerContactReducer,
  CreatePartnerContactReducer,
  CreateKeyManagmentContactReducer,
  KeyManagementContactListReducer,
  KeyManagementContactReducer,
  UpdateKeyManagementContactReducer,
  FetchPatnerContactListReducer,
  FetchPartnerContactBYIdReducer,
  UpdatePartnerContactReducer,
  CustomerContactListVersionReducer,
  AproveCustomerContactReducer,
  RejectCustomerContactReducer,
  BlockCustomerContactReducer,
  KeyManagementListVersionReducer,
  AproveKeyManagementReducer,
  RejectKeyManagementReducer,
  BlockKeyManagementReducer,
  PartnerContactListVersionReducer,
  AprovePartnerContactReducer,
  RejectPartnerContactReducer,
  BlockPartnerContactReducer,
  FreezeCustomerContactReducer,
  FreezePartnerContactReducer,
  FreezeKeyManagementReducer,
  UnFreezePartnerContactReducer,
  UnFreezeKeyManagementReducer,
  UnFreezeCustomerContactReducer,
  CustomerContactVersionDetailsReducer,
  DeleteCustomerContactReducer
} from "./customerContact/reducer";
import {
  CustomerAddressListReducer,
  CreateAddressReducer,
  CustomerAddressReducer,
  UpdateCustomerAddressReducer,
  AddressListVersionReducer,
  AproveCustomerAddressReducer,
  RejectCustomerAddressReducer,
  BlockCustomerAddressReducer,
  FreezeCustomerAddressReducer,
  UnFreezeCustomerAddressReducer,
  FetchAddressVersionDetailsReducer,
  FetchAddressLatestVersionReducer,
  DeleteAddressReducer
} from "./customerAddress/reducer";
import {
  CustomerCummunicationsListReducer,
  CustomerCummunicationsCreatereducer,
  CustomerCummunicationsBYIdReducer,
  UpdateCustomerCummunicationsReducer,
  CustomerCummunicationsListVersionReducer,
  AproveCummunicationsReducer,
  RejectCummunicationsReducer,
  BlockCummunicationsReducer,
  FreezeCummunicationsReducer,
  UnFreezeCummunicationsReducer,
  CustomerCummunicationsVersionDetailsReducer,
  CustomerCummunicationsLatestVersionReducer
} from "./customerCummications/reducers";
import {
  LimitConfigListReducer,
  CreateLimitConfigReducer,
  deleteLimitConfigReducer,
  FetchLimitConfigReducer,
  UpdateLimitConfigReducer,
  FetchLimitConfigVesrions,
  ApproveLimitConfigReducer,
  RejectLimitConfigReducer,
  BlockLimitConfigReducer,
  FreezeLimitConfigReducer,
  UnFreezeLimitConfigReducer,
  FetchLimitConfigHistroyReducer,
  FetchLimitConfigVersionDetailsReducer,
  FetchLimitConfigLatestVersionReducer,
  FetchAggregateLimitConfigHistroyReducer
} from "./limitConfig/reducer";
import { ProductLimitConfigListReducer, CreateProductLimitConfigReducer, UpdateProductLimitConfigReducer, FetchProductLimitConfigReducer } from "./productLimitConfig/reducer";
import {
  ProdcutTypeListReducer,
  ProdcutTypeReducer,
  UpdateProductTypeReducer,
  CreateProdcutTypeReducer,
  ProdcutTypeListVersionReducer,
  RejectProductTypeReducer,
  AproveProductTypeReducer,
  BlockProductTypeReducer,
  FreezeProductTypeReducer,
  UnFreezeProductTypeReducer,
  ProdcutTypeDetailsReducer,
  ProdcutTypeLatestVersionReducer,
  DeleteProductTypeReducer
} from "./productType/reducer";
import {
  CreateCustomerBeneficiaryReducer,
  CustomerBeneficiaryListReducer,
  CustomerBeneficiaryReducer,
  UpdateBeneficiaryReducer,
  CustomerBeneficiaryVersionListReducer,
  AproveBeneficiaryReducer,
  RejectBeneficiaryReducer,
  BlockBeneficiaryReducer,
  FreezeBeneficiaryReducer,
  UnFreezeBeneficiaryReducer,
  CustomerBeneficiaryVersionDetailsReducer,
  CustomerBeneficiaryLatestVersionReducer,
  DeleteCustomerBeneficiaryReducer
} from "./customerBeneficary/reducer";
import {
  ApproveFinancinProgramReducer,
  CreateFinancinProgramReducer,
  FinancingProgramListReducer,
  FinancingProgramValidateReducer,
  FinancingProgramVersionsReducer,
  FinancinProgramReducer,
  RejectFinancinProgramReducer,
  UpdateFinancinProgramReducer,
  FinancinProgramVersionDetailsReducer,
  FinancingProgramLatestVersionsReducer,
  DeleteFinancinProgramReducer,
  GenerateLoanAccountNumberFinancinProgramReducer,
  BlockFinancingProgramReducer,
  FreezeFinancingProgramReducer,
  UnFreezeFinancingProgramReducer,
  FinancingProgramIdsReducer,
  FinancingProgramConfigListReducer
} from "./financingPrograms/reducer";
import {
  BuyerSellersListReducer,
  BuyerSellerCreateRelationAccountNumberReducer,
  BuyerSellerVersionDetailsReducer,
  BuyerSellerLatestVersionReducer,
  DeleteBuyerSellerReducer,
  BuyerSellerReducer,
  BuyerSellerVersionsReducer,
  ApproveBuyerSellerReducer,
  RejectBuyerSellerReducer,
  BuyerSellerBlockReducer,
  UpdateBuyerSellerReducer,
  BuyerSellerValidateReducer,
  CreateBuyerSellerReducer,
  FreezeBuyerSellerReducer,
  UnFreezeBuyerSellerReducer,
  BuyerSellerPartialDisbursementAllowedReducer,
  BuyerSellerConfigValues
} from "./buyerSeller/reducer";

import {
  CustomerBeneficiaryProgrammeListReducer,
  CreateCustomerBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeReducer,
  UpdateBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeVersionListReducer,
  AproveBeneficiaryProgrammeReducer,
  RejectBeneficiaryProgrammeReducer,
  BlockBeneficiaryProgrammeReducer,
  FreezeBeneficiaryProgrammeReducer,
  UnFreezeBeneficiaryProgrammeReducer
} from "./customerBeneficaryProgrammme/reducer";

import {
  GlAccountReducer,
  CreateGlAccountReducer,
  GlAccountByIdReducer,
  UpdateGlAccountReducer,
  GlAccountListVersionReducer,
  AproveGlAccountReducer,
  RejectGlAccountReducer,
  BlockGlAccountReducer,
  FreezeGlAccountReducer,
  UnFreezeGlAccountReducer,
  GlAccountVersionDetailsReducer,
  DeleteGlAccountReducer,
  GlAccountLatestVersionReducer
} from "./glAccount/reducer";

import {
  ChargeListReducer,
  ChargeReducer,
  CreateChargeReducer,
  UpdateChargeReducer,
  ChargeListVersionReducer,
  ApproveChargeReducer,
  RejectChargeReducer,
  BlockChargeReducer,
  FreezeChargeReducer,
  UnFreezeChargeReducer,
  ChargeVersionDetailsReducer,
  ChargeLatestVersionReducer,
  DeleteChargeReducer
} from "./charge/reducers";
import { InstrumentBatchAuditActionsReducer, InstrumentBatchListReducer, InstrumentBatchReducer, UploadInstrumnetBatchReducer } from "./transaction/instrumentBatch/reducer";
import { InstrumentReducer, InstrumentsReducer } from "./transaction/instrument/reducer";
import {
  DisburseStagingBatchesReducer,
  StagingBatchListReducer,
  StagingBatchReducer,
  StagingBatchReprocessingReducer,
  StagingBatchSkipValidationReducer,
  StagingBatchVersionReducer,
  AproveStagingBatchesReducer,
  RejectStagingBatchesReducer,
  StagingBatchChildListReducer,
  MakerRemarkStagingBatchSkipValidationReducer,
  MakerRemarkDisburseStagingBatchesReducer,
  UpdateStagingBatchLimitTypeReducer,
  StagingBatchAuditActionsReducer,
} from "./transaction/stagingBatch/reducer";
import {
  SkipableValidationsStagingInstrumentsListReducer,
  StagingInstrumentReducer,
  StagingInstrumentsReprocessingReducer,
  ValidationErrorByStagingInstrumentIdReducer,
  RemoveStagingInstrumentsListReducer,
  StagingInstrumentsRemoveVersionListReducer,
  RemoveStagingInstrumentsReducer
} from "./transaction/stagingInstrument/reducer";
import { StagingInstrumentsListReducer, StagingInstrumentsSkipValidationReducer } from "./transaction/stagingInstrument/reducer";
import { PaymentInstrumentReducer, PaymentInstrumnetListReducer } from "./transaction/paymentInstrument/reducer";
import {
  PaymentBatchListReducer,
  PaymentBatchReducer,
  MarkeRemarkReProcessingPaymentBatchReducer,
  ApprovalPaymentBatchReducer,
  RejectPaymentBatchReducer,
  PaymentBatchVersionListReducer,
  PaymentBatchAuditActionsReducer
} from "./transaction/payment/reducer";

import {
  EodJobReducer,
  EodJobLoggedInUsers,
  ForceLogoutUsersReducer,
  GetEodJobStatusReducer,
  InitialUpdatebusinessDetailReducer,
  GetbusinessDetailReducer,
  UpdatebusinessDetailReducer
} from "./eodBod/reducers";
import { DisbursementsReducer } from "./transaction/disbursement/reducer";

import { IntrumenetInterestAccrualsReducer, IntrumenetInterestAccrualReducer } from "./transaction/interestAccrual/reducer";
import { InstrumentDailyBalanceReducer, InstrumentDailyBalancesReducer } from "./transaction/dailyBalances/reducer";
import { InstrumentDailyBalancesHistoryReducer, InstrumentDailyBalanceHistoryReducer } from "./transaction/instrumentDailyBalancesHistory/reducer";

import {
  RepaymentListReducer,
  UploadRepaymentReducer,
  RepaymentReducer,
  RepaymentListVersionReducer,
  ApproveRepaymentReducer,
  RejectRepaymentReducer,
  UploadRepaymentSettlementReducer,
  RepaymentFiltersReducer,
  RepaymentAdjustmentEntriesReducer,
  RepaymentAdjustmentEntrieReducer,
  RepaymentStagedAdjustmentEntrieReducer,
  CreateRepaymentRequestSettleReducer,
  RepaymentStagedAdjustmentEntriesReducer,
  CreateRepaymentRequestSettleManuallyReducer
} from "./Repayment/repaymentRequest/reducers";

import {
  RepaymentSettlementListReducer,
  RepaymentSettlementReducer,
  RepaymentSettlementFiltesReducer,
  RepaymentRequestIdSettlementFiltesReducer
} from "./Repayment/repaymentSettlement/reducers";

import {
  DisbursementAuthListReducer,
  CreateDisbursementAuthReducer,
  DisbursementAuthReducer,
  UpdateDisbursementAuthReducer,
  PublishDisbursementAuthReducer,
  FetchDisbursementAuthVesrions,
  ApproveDisbursementAuthReducer,
  RejectDisbursementAuthReducer,
  BlockDisbursementAuthReducer,
  FreezeDisbursementAuthReducer,
  UnFreezeDisbursementAuthReducer,
  DisbursementAuthAuditActionsReducer
} from "./DisbursementAuth/reducer";
import { CreateProgrammeSummaryReducer } from "./report/programmeSummary/reducer";

import {
  CreateDisbursementAdviceReducer
} from "./report/disbursementAdvice/reducer";
import { CreateLimitReportReducer } from "./report/limitReport/reducer";
import { CreateClientSummaryReducer } from "./report/clientSummary/reducer";

import { CreateInterestComputationReducer } from "./report/interestComputation/reducer";
import { CreateRepaymentAdviceReducer } from "./report/repaymentAdvice/reducer";
import { ExcessMoneyListReducer, ExcessMoneyReducer } from "./Repayment/excessMoney/reducer";

export default combineReducers({
  Auth,
  LoginHistoryReducer,
  ChangePasswordReducer,
  ResetPasswordReducer,
  Layout,

  FinancierUserListReducer,
  FinancierUserReducer,
  CreateFinancierUserReducer,
  UpdateFinancierUserReducer,
  FetchFinancierUserVesrions,
  ApproveFinancierUserReducer,
  RejectFinancierUserReducer,
  BlockFinancierUserReducer,
  FreezeFinancierUserReducer,
  UnFreezeFinancierUserReducer,
  FetchFinancierUserVesrionDetails,
  DeleteFinancierUserReducer,
  FetchFinancierUserLatestVesrionReducer,
  ModulePermissionListReducer,
  FetchUserRoleVesrionsDetails,
  FetchUserRoleLatestVesrionsReducer,
  FetchCurrencyLatestVerionReducer,
  DeleteUserRoleReducer,

  UserRoleListReducer,
  CreateUserRoleReducer,
  UserRoleReducer,
  UpdateUserRoleReducer,
  FetchUserRoleVesrions,
  ApproveUserRoleReducer,
  RejectUserRoleReducer,
  BlockUserRoleReducer,
  FreezeUserRoleReducer,
  UnFreezeUserRoleReducer,

  CurrencyListReducer,
  CreateCurrencyReducer,
  CurrencyReducer,
  UpdateCurrencyReducer,
  DeleteCurrencyReducer,
  CurrencyVersionListReducer,
  aproveCurrencyReducer,
  rejectCurrencyReducer,
  blockCurrencyReducer,
  FreezeCurrencyReducer,
  UnFreezeCurrencyReducer,
  CurrencyVersionDetailsReducer,

  BaseRateListReducer,
  BaseRateReducer,
  CreateBaseRateReducer,
  UpdateBaseRateReducer,
  FetchBaseRateVesrions,
  ApproveBaseRateReducer,
  RejectBaseRateReducer,
  BlockBaseRateReducer,
  FreezeBaseRateReducer,
  UnFreezeBaseRateReducer,
  FetchBaseRateVesrionsDetailsReducer,
  BaseRateReducerLatestVersionReducer,
  DeleteBaseRateReducer,
  HolidayListReducer,
  HolidayLatestVersionReducer,
  DeleteHolidayReducer,
  CreateHolidayReducer,
  HolidayReducer,
  UpdateHolidayReducer,
  FetchHolidayVesrions,
  ApproveHolidayReducer,
  RejectHolidayReducer,
  BlockHolidayReducer,
  FreezeHolidayReducer,
  UnFreezeHolidayReducer,
  HolidayVersionDetailsReducer,

  HolidayBatchListReducer,
  CreateHolidayBatchReducer,
  HolidayBatchReducer,
  UpdateHolidayBatchReducer,
  FetchHolidayBatchVesrions,
  ApproveHolidayBatchReducer,
  RejectHolidayBatchReducer,
  BlockHolidayBatchReducer,
  FreezeHolidayBatchReducer,
  UnFreezeHolidayBatchReducer,
  PublishHolidayBatchReducer,
  HolidayBatchFilterReducer,
  HolidayBatchVersionDetailsReducer,

  BranchListReducer,
  BranchVersionDetailsReducer,
  BranchReducerLatestVerionReducer,
  DeleteBranchReducer,

  CreateBranchReducer,
  BranchReducer,
  FetchBranchVesrions,
  ApproveBranchReducer,
  RejectBranchReducer,
  UpdateBranchReducer,
  BlockBranchReducer,
  FreezeBranchReducer,
  UnFreezeBranchReducer,

  CountryListReducer,
  CreateCountryReducer,
  CountryReducer,
  UpdateCountryReducer,
  CountryVersionListReducer,
  approveCountryReducer,
  rejectCountryReducer,
  blockCountryReducer,
  FreezeCountryReducer,
  UnFreezeCountryReducer,
  CountryVersionDetailsReducer,
  CountryReducerLatestVersionReducer,
  DeleteCountryReducer,

  StateListReducer,
  CreateStateReducer,
  StateReducer,
  UpdateStateReducer,
  StateListVersionReducer,
  ApproveStateReducer,
  RejectStateReducer,
  BlockStateReducer,
  FreezeStateReducer,
  UnFreezeStateReducer,
  StateVersionDetailsReducer,
  StateLatestVersionReducer,
  DeleteStateReducer,

  RelationshipManagerListReducer,
  CreaterelationshipManagerReducer,
  RelationshipMaanagerReducer,
  UpdateRelationshipMaanagerReducer,
  RelationshipManagerListVersionReducer,
  AproveRelationshipManagerReducer,
  RejectRelationshipManagerReducer,
  BlockRelationshipManagerReducer,
  FreezeRelationshipManagerReducer,
  UnFreezeRelationshipManagerReducer,
  RelationshipMaanagerVersionDetailsReducer,
  RelationshipMaanagerLatestVersionReducer,
  DeleteRelationshipMaanagerReducer,

  LovListReducer,
  CreateLovListReducer,
  LovByIdReducer,
  UpdateLovReducer,
  LovListTenorReducer,
  LovListVersionReducer,
  AproveLovReducer,
  RejectLovReducer,
  BlockLovReducer,
  FreezeLovReducer,
  UnFreezeLovReducer,
  LovListVersionDetailsReducer,
  LovLatestVersionReducer,
  deleteLOVReducer,

  GlAccountReducer,
  CreateGlAccountReducer,
  GlAccountByIdReducer,
  UpdateGlAccountReducer,
  GlAccountListVersionReducer,
  AproveGlAccountReducer,
  RejectGlAccountReducer,
  BlockGlAccountReducer,
  FreezeGlAccountReducer,
  UnFreezeGlAccountReducer,
  GlAccountVersionDetailsReducer,
  DeleteGlAccountReducer,

  CityListReducer,
  CreateCityReducer,
  CityReducer,
  UpdateCityReducer,
  CityListVersionReducer,
  ApproveCityReducer,
  RejectCityReducer,
  BlockCityReducer,
  FreezeCityReducer,
  UnFreezeCityReducer,
  CityVersionDetailsReducer,
  CityReducerLatestVersionReducer,
  DeleteCityReducer,
  CustomerListReducer,
  CustomerReducer,
  CustomerVersionsReducer,
  ApproveCustomerReducer,
  RejectCustomerReducer,
  TaskIdByPanNumberReducer,
  TaskStatusByTaskIdReducer,
  TaskResultByTaskIdReducer,
  GstDetailReducer,
  CustomerValidateReducer,
  CreateCustomerReducer,
  UpdateCustomerReducer,

  CreateCustomerContactReducer,
  CustomerContactListReducer,
  DeDuplicateCustomerReducer,
  CustomerFreezeReducer,
  CustomerUnFreezeReducer,
  CustomerVersionsDetailsReducer,
  CustomerLatestVersionsReducer,
  DeleteCustomerReducer,
  CustomerContactBYIdReducer,
  UpdateCustomerContactReducer,
  KeyManagementContactReducer,
  UpdateKeyManagementContactReducer,
  FetchPatnerContactListReducer,
  FetchPartnerContactBYIdReducer,
  UpdatePartnerContactReducer,
  CustomerBlockReducer,
  CustomerContactListVersionReducer,
  AproveCustomerContactReducer,
  RejectCustomerContactReducer,
  BlockCustomerContactReducer,
  KeyManagementListVersionReducer,
  AproveKeyManagementReducer,
  RejectKeyManagementReducer,
  BlockKeyManagementReducer,
  PartnerContactListVersionReducer,
  AprovePartnerContactReducer,
  RejectPartnerContactReducer,
  BlockPartnerContactReducer,
  CreatePartnerContactReducer,
  CreateKeyManagmentContactReducer,
  KeyManagementContactListReducer,

  FreezeCustomerContactReducer,
  FreezePartnerContactReducer,
  FreezeKeyManagementReducer,

  UnFreezePartnerContactReducer,
  UnFreezeKeyManagementReducer,
  UnFreezeCustomerContactReducer,
  CustomerContactVersionDetailsReducer,
  DeleteCustomerContactReducer,

  CustomerAddressListReducer,
  CreateAddressReducer,
  CustomerAddressReducer,
  UpdateCustomerAddressReducer,
  AddressListVersionReducer,
  AproveCustomerAddressReducer,
  RejectCustomerAddressReducer,
  BlockCustomerAddressReducer,
  FreezeCustomerAddressReducer,
  UnFreezeCustomerAddressReducer,
  FetchAddressVersionDetailsReducer,
  FetchAddressLatestVersionReducer,
  DeleteAddressReducer,

  CustomerCummunicationsListReducer,
  CustomerCummunicationsCreatereducer,
  CustomerCummunicationsBYIdReducer,
  UpdateCustomerCummunicationsReducer,
  CustomerCummunicationsListVersionReducer,
  AproveCummunicationsReducer,
  RejectCummunicationsReducer,
  BlockCummunicationsReducer,
  FreezeCummunicationsReducer,
  UnFreezeCummunicationsReducer,
  CustomerCummunicationsVersionDetailsReducer,
  CustomerCummunicationsLatestVersionReducer,

  LimitConfigListReducer,
  CreateLimitConfigReducer,
  deleteLimitConfigReducer,
  UpdateLimitConfigReducer,
  FetchLimitConfigVesrions,
  ApproveLimitConfigReducer,
  RejectLimitConfigReducer,
  BlockLimitConfigReducer,
  FreezeLimitConfigReducer,
  UnFreezeLimitConfigReducer,
  FetchLimitConfigHistroyReducer,
  FetchAggregateLimitConfigHistroyReducer,
  FetchLimitConfigVersionDetailsReducer,
  FetchLimitConfigLatestVersionReducer,
  ProductLimitConfigListReducer,
  CreateProductLimitConfigReducer,
  FetchProductLimitConfigReducer,
  UpdateProductLimitConfigReducer,

  ProdcutTypeListReducer,
  ProdcutTypeReducer,
  UpdateProductTypeReducer,
  CreateProdcutTypeReducer,
  ProdcutTypeListVersionReducer,
  RejectProductTypeReducer,
  AproveProductTypeReducer,
  BlockProductTypeReducer,
  FreezeProductTypeReducer,
  UnFreezeProductTypeReducer,
  ProdcutTypeDetailsReducer,
  ProdcutTypeLatestVersionReducer,
  DeleteProductTypeReducer,
  GlAccountLatestVersionReducer,
  ChargeListReducer,
  ChargeReducer,
  CreateChargeReducer,
  UpdateChargeReducer,
  ChargeListVersionReducer,
  ApproveChargeReducer,
  RejectChargeReducer,
  BlockChargeReducer,
  FreezeChargeReducer,
  UnFreezeChargeReducer,
  ChargeVersionDetailsReducer,
  ChargeLatestVersionReducer,
  DeleteChargeReducer,

  CustomerBeneficiaryListReducer,
  CreateCustomerBeneficiaryReducer,
  CustomerBeneficiaryVersionListReducer,
  AproveBeneficiaryReducer,
  RejectBeneficiaryReducer,
  BlockBeneficiaryReducer,
  FreezeBeneficiaryReducer,
  UnFreezeBeneficiaryReducer,
  CustomerBeneficiaryVersionDetailsReducer,
  CustomerBeneficiaryLatestVersionReducer,
  DeleteCustomerBeneficiaryReducer,

  FinancingProgramListReducer,
  FinancinProgramReducer,
  FinancingProgramVersionsReducer,
  ApproveFinancinProgramReducer,
  RejectFinancinProgramReducer,
  FinancingProgramValidateReducer,
  CreateFinancinProgramReducer,
  UpdateFinancinProgramReducer,
  FinancinProgramVersionDetailsReducer,
  FinancingProgramLatestVersionsReducer,
  DeleteFinancinProgramReducer,
  GenerateLoanAccountNumberFinancinProgramReducer,
  BlockFinancingProgramReducer,
  FreezeFinancingProgramReducer,
  UnFreezeFinancingProgramReducer,
  FinancingProgramIdsReducer,
  FinancingProgramConfigListReducer,

  CustomerBeneficiaryReducer,
  FetchLimitConfigReducer,
  UpdateBeneficiaryReducer,

  //buyerSeller
  BuyerSellersListReducer,
  BuyerSellerCreateRelationAccountNumberReducer,
  BuyerSellerVersionDetailsReducer,
  BuyerSellerLatestVersionReducer,
  DeleteBuyerSellerReducer,
  BuyerSellerReducer,
  BuyerSellerVersionsReducer,
  ApproveBuyerSellerReducer,
  RejectBuyerSellerReducer,
  BuyerSellerBlockReducer,
  UpdateBuyerSellerReducer,
  BuyerSellerValidateReducer,
  CreateBuyerSellerReducer,
  FreezeBuyerSellerReducer,
  UnFreezeBuyerSellerReducer,
  BuyerSellerPartialDisbursementAllowedReducer,
  BuyerSellerConfigValues,

  CustomerBeneficiaryProgrammeListReducer,
  CreateCustomerBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeReducer,
  UpdateBeneficiaryProgrammeReducer,
  CustomerBeneficiaryProgrammeVersionListReducer,
  AproveBeneficiaryProgrammeReducer,
  RejectBeneficiaryProgrammeReducer,
  BlockBeneficiaryProgrammeReducer,
  InstrumentBatchListReducer,
  UploadInstrumnetBatchReducer,
  InstrumentBatchReducer,
  InstrumentBatchAuditActionsReducer,
  InstrumentReducer,
  InstrumentsReducer,
  FreezeBeneficiaryProgrammeReducer,
  UnFreezeBeneficiaryProgrammeReducer,
  StagingBatchListReducer,
  StagingBatchReducer,
  StagingInstrumentsListReducer,
  StagingInstrumentReducer,
  StagingInstrumentsReprocessingReducer,
  StagingInstrumentsSkipValidationReducer,
  PaymentBatchListReducer,
  MarkeRemarkReProcessingPaymentBatchReducer,
  PaymentBatchAuditActionsReducer,
  ApprovalPaymentBatchReducer,
  RejectPaymentBatchReducer,
  PaymentBatchVersionListReducer,
  StagingBatchSkipValidationReducer,
  StagingBatchReprocessingReducer,
  PaymentInstrumnetListReducer,
  PaymentInstrumentReducer,
  PaymentBatchReducer,
  DisburseStagingBatchesReducer,
  StagingBatchVersionReducer,
  AproveStagingBatchesReducer,
  RejectStagingBatchesReducer,
  StagingBatchChildListReducer,
  MakerRemarkStagingBatchSkipValidationReducer,
  MakerRemarkDisburseStagingBatchesReducer,
  SkipableValidationsStagingInstrumentsListReducer,
  ValidationErrorByStagingInstrumentIdReducer,
  RemoveStagingInstrumentsListReducer,
  StagingInstrumentsRemoveVersionListReducer,
  UpdateStagingBatchLimitTypeReducer,
  RemoveStagingInstrumentsReducer,
  StagingBatchAuditActionsReducer,

  EodJobReducer,
  EodJobLoggedInUsers,
  GetbusinessDetailReducer,
  UpdatebusinessDetailReducer,
  GetEodJobStatusReducer,
  InitialUpdatebusinessDetailReducer,
  DisbursementsReducer,
  ForceLogoutUsersReducer,

  IntrumenetInterestAccrualsReducer,
  IntrumenetInterestAccrualReducer,
  InstrumentDailyBalanceReducer,
  InstrumentDailyBalancesReducer,

  //repayment
  RepaymentListReducer,
  UploadRepaymentReducer,
  RepaymentReducer,
  RepaymentListVersionReducer,
  ApproveRepaymentReducer,
  RejectRepaymentReducer,
  UploadRepaymentSettlementReducer,
  RepaymentFiltersReducer,
  RepaymentAdjustmentEntriesReducer,
  RepaymentAdjustmentEntrieReducer,
  RepaymentStagedAdjustmentEntrieReducer,
  CreateRepaymentRequestSettleReducer,
  CreateRepaymentRequestSettleManuallyReducer,
  RepaymentStagedAdjustmentEntriesReducer,
  InstrumentDailyBalancesHistoryReducer,
  InstrumentDailyBalanceHistoryReducer,
  RepaymentSettlementListReducer,
  RepaymentSettlementReducer,
  RepaymentSettlementFiltesReducer,
  RepaymentRequestIdSettlementFiltesReducer,
  ExcessMoneyListReducer,
  ExcessMoneyReducer,

  DisbursementAuthListReducer,
  CreateDisbursementAuthReducer,
  DisbursementAuthReducer,
  UpdateDisbursementAuthReducer,
  PublishDisbursementAuthReducer,
  FetchDisbursementAuthVesrions,
  ApproveDisbursementAuthReducer,
  RejectDisbursementAuthReducer,
  BlockDisbursementAuthReducer,
  FreezeDisbursementAuthReducer,
  UnFreezeDisbursementAuthReducer,
  CreateDisbursementAdviceReducer,
  CreateLimitReportReducer,
  CreateClientSummaryReducer,
  DisbursementAuthAuditActionsReducer,
  CreateInterestComputationReducer,
  CreateRepaymentAdviceReducer,
  CreateProgrammeSummaryReducer,
});
