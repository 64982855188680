import { FilterMetadata } from "redux/types";
import { CountryActionDispatch, CountryReduxTypes, CreateCountry, UpdateCountry } from "./types";

export const fetchCountries = (payload: FilterMetadata): CountryActionDispatch => ({
    type: CountryReduxTypes.FETCH_COUNTRIES,
    payload: payload
});

export const createCountry = (payload:CreateCountry): CountryActionDispatch => ({
    type: CountryReduxTypes.CREATE_COUNTRY,
    payload: payload,
});

export const fetchCountryById= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.FETCH_COUNTRY_BY_ID,
    payload: payload,
});

export const updateCountry = (payload:UpdateCountry): CountryActionDispatch => ({
    type: CountryReduxTypes.UPDATE_COUNTRY,
    payload: payload,
});


export const deleteCountry = (payload:any): CountryActionDispatch => ({
    type: CountryReduxTypes.DELETE_COUNTRY,
    payload: payload,
});

export const resetUpdateCountry = (): CountryActionDispatch => ({
    type: CountryReduxTypes.RESET_UPDATE_COUNTRY,
    payload: {},
});

export const resetCountry = (): CountryActionDispatch => ({
    type: CountryReduxTypes.RESET,
    payload: {},
});


export const fetchCountryVersions= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS,
    payload: payload,
});


export const fetchCountryVersionsDetails= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.FETCH_COUNTRIES_VERSIONS_DETAILS,
    payload: payload,
});



export const fetchCountryLatestVersions= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.FETCH_COUNTRIES_LATEST_VERSIONS,
    payload: payload,
});

export const approveCountry= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.APPROVE_COUNTRIES,
    payload: payload,
});

export const rejectCountry= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.REJECT_COUNTRIES,
    payload: payload,
});


export const blockCountry= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.BLOCK_COUNTRIES,
    payload: payload,
});


export const freezeCountry= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.FREEZE_COUNTRIES,
    payload: payload,
});


export const unFreezeCountry= (payload: any): CountryActionDispatch => ({
    type: CountryReduxTypes.UNFREEZE_COUNTRIES,
    payload: payload,
});






