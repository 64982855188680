import config from "config";
import { FilterMetadata } from "redux/types";
import { APICore } from "helpers/api/apiCore";
import { CreaterelationshipManager, UpdateRelationshipManager } from "./types";

const api = new APICore();

function fetchRelationshipManagerApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers`;
  return api.get(`${baseUrl}`, params);
}

function createRelationshipManagerApi(params: any) {
  let backendApiUrl = "";

  let apiData: any = {
    name: params.name ? params.name.trim() : "",
    email: params.email ? params.email : "",
    mobileNumber: params.mobileNumber ? params.mobileNumber : "",
    branchId: params?.branchId?.length ? params?.branchId[0]?.id : ""
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/relationship-managers`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/relationship-managers?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers`;
  // return api.create(`${baseUrl}`, params);
}

function fetchRelationshipManagerBYId(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params}`;
  return api.get(`${baseUrl}`, {});
}

function updateRelationshipManagerApi(params: any) {
  
  let backendApiUrl = "";

  let apiData: any = {
    name: params.name ? params.name.trim() : "",
    email: params.email ? params.email : "",
    mobileNumber: params?.mobileNumber ? params?.mobileNumber : "",
    branchId: params?.branchId ? params?.branchId : ""
  };


  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.externalId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.externalId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.id}`;
  // return api.update(`${baseUrl}`, updateRelationshipManagerData);
}

function fetchRelationshipManagerSearchFilter(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers`;

  return api.get(`${baseUrl}`, { "filters[name][$startsWithStr]": params.name });
}

function fetchRMVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params}/versions`;
  return api.get(`${baseUrl}`, params);
}



function deleteRMVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params}`;
  return api.delete(`${baseUrl}`);
}


function fetchRMLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}



function fetchRMVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}


function approveRMApi(params: any) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectRMApi(params: any) {
  // console.log("paramsApi",params);

  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockRMApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeRMApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeRMApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/relationship-managers/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchRelationshipManagerApi,
  createRelationshipManagerApi,
  fetchRelationshipManagerBYId,
  updateRelationshipManagerApi,
  fetchRelationshipManagerSearchFilter,
  fetchRMVersionApi,
  approveRMApi,
  rejectRMApi,
  blockRMApi,
  freezeRMApi,
  unFreezeRMApi,
  fetchRMVersionDetailsApi,
  fetchRMLatestVersionApi,
  deleteRMVersionApi
};
