import { APICore } from "helpers/api/apiCore";
import * as forge from "node-forge";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateFinancierUser, UpdateFinancierUser, updateFinancierUserVersion } from "./types";

const api = new APICore();

function fetchFinancierUsersApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users`;
  return api.get(`${baseUrl}`, params);
}

function createFinancierUsersApi(params: CreateFinancierUser) {
  let backendApiUrl = "";

  let apiData: any = {
    email: params.email,
    name: params.name.trim(),
    mobile: params.mobile,
    timezone: params.timezone ? params.timezone.value : "",
    locale: params.locale ? params.locale.value : "",
    roles: params.roles.map((data: any) => data.id)
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/users`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/users?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function createPasswordFinancierUsersApi(params: any) {
  var _publicKey =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkWMVe4Gs+Xr0V6kwxPFEozbikr6xaP5q/9eA/fvFGkx8kns63WoY2Dl/9FmuNmB/rVbvhryDvWlVRDZqRWcISqJwRsBJ6ObziIr+97DRRrDmPnHmVsxdUo5nEqJIhc47UfSpq6XmE3+w+Kk+iWYqCU1Wz53hGl3n9AWcgWFvE6QGct7mcHa7PD70spu5YUMi4K/ppdqepgzulzh10bZ/PlzmNTYO5io64Iz6jItus6CGMLpC6Ser1m6IQI9UduzNpk4g50jYTSBD5gLkK4uA/45KvFaBO+27QufBMpx82zVdwdNwcpr60V3ElfnR4zIE/JW9wwrMSE5LSiVxyAUCvQIDAQAB";
  var pem: any = `-----BEGIN PUBLIC KEY-----\n${_publicKey}\n-----END PUBLIC KEY-----`;
  var publicKey = forge.pki.publicKeyFromPem(pem);
  // var loginReq = JSON.stringify({ handle: "vay@email.com", handleType: "email", password: "secret" })
  var password = params?.password;
  var buffer = forge.util.createBuffer(password, "utf8");
  var bytes = buffer.getBytes();
  var encrypted = publicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");
  var b64Encoded = forge.util.encode64(encrypted);

  // var loginData = {
  //     encryptedPayload: b64Encoded
  // }

  let backendApiUrl = "";

  let apiData: any = {
    financierUserId: params?.externalId,
    password: b64Encoded
  };

  // console.log(apiData);

  backendApiUrl = `${config.BACKEND_BASE_URL}/users/creds`;

  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);
}

function passwordPasswordFinancierUsersApi(params: any) {
  var _publicKey =
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkWMVe4Gs+Xr0V6kwxPFEozbikr6xaP5q/9eA/fvFGkx8kns63WoY2Dl/9FmuNmB/rVbvhryDvWlVRDZqRWcISqJwRsBJ6ObziIr+97DRRrDmPnHmVsxdUo5nEqJIhc47UfSpq6XmE3+w+Kk+iWYqCU1Wz53hGl3n9AWcgWFvE6QGct7mcHa7PD70spu5YUMi4K/ppdqepgzulzh10bZ/PlzmNTYO5io64Iz6jItus6CGMLpC6Ser1m6IQI9UduzNpk4g50jYTSBD5gLkK4uA/45KvFaBO+27QufBMpx82zVdwdNwcpr60V3ElfnR4zIE/JW9wwrMSE5LSiVxyAUCvQIDAQAB";
  var pem: any = `-----BEGIN PUBLIC KEY-----\n${_publicKey}\n-----END PUBLIC KEY-----`;
  var publicKey = forge.pki.publicKeyFromPem(pem);
  // var loginReq = JSON.stringify({ handle: "vay@email.com", handleType: "email", password: "secret" })
  var password = params?.password;
  var buffer = forge.util.createBuffer(password, "utf8");
  var bytes = buffer.getBytes();
  var encrypted = publicKey.encrypt(bytes, "RSAES-PKCS1-V1_5");
  var b64Encoded = forge.util.encode64(encrypted);

  // var loginData = {
  //     encryptedPayload: b64Encoded
  // }

  let backendApiUrl = "";

  let apiData: any = {
    password: b64Encoded
  };

  backendApiUrl = `${config.BACKEND_BASE_URL}/users/creds/${params.externalUserId}`;

  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
}

function fetchFinancierUserApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/${params}`;
  return api.get(`${baseUrl}`, {});
}

function fetchFinancierUseVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/${params?.id}/versions`;
  return api.get(`${baseUrl}`, params?.searchFilters);
}

function deleteFinancierUseVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/${params}`;
  return api.delete(`${baseUrl}`);
}

function fetchFinancierUseVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function fetchFinancierUseLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/users/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}

function updateFinancierUsersApi(params: UpdateFinancierUser) {
  let backendApiUrl = "";

  let apiData: any = {
    email: params.email,
    name: params.name.trim(),
    mobile: params.mobile,
    roles: params.roles.map((data: any) => data.id),
    timezone: params.timezone ? params.timezone : "",
    locale: params.locale ? params.locale : "",
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/users/${params.externalUserId}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/users/${params.externalUserId}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/users/${params.externalUserId}`;
  // return api.update(`${baseUrl}`, updateFinancierUserData);
}

function approveFinancierUserApi(params: updateFinancierUserVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/users/${externalId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectFinancierUserApi(params: updateFinancierUserVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/users/${externalId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockFinancierUserApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/users/${externalUserId}/block`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function freezeFinancierUserApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/users/${externalUserId}/freeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function unFreezeFinancierUserApi(params: any) {
  let remarks = {
    inactiveReason: params.inactiveReason.trim()
  };
  let externalUserId = params.id;

  const baseUrl = `${config.BACKEND_BASE_URL}/users/${externalUserId}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, remarks);
}
export {
  fetchFinancierUsersApi,
  createFinancierUsersApi,
  fetchFinancierUserApi,
  updateFinancierUsersApi,
  fetchFinancierUseVersionApi,
  rejectFinancierUserApi,
  approveFinancierUserApi,
  blockFinancierUserApi,
  freezeFinancierUserApi,
  unFreezeFinancierUserApi,
  createPasswordFinancierUsersApi,
  passwordPasswordFinancierUsersApi,
  fetchFinancierUseVersionDetailsApi,
  fetchFinancierUseLatestVersionApi,
  deleteFinancierUseVersionApi
};
