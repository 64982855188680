import { FetchApiResponse, FilterMetadata } from "redux/types";

export enum InstrumentDailyBalanceHistoryReduxTypes {
  FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY",
  FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_SUCCESS = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_SUCCESS",
  FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_ERROR = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_ERROR",

  FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY",
  FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_SUCCESS = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_SUCCESS",
  FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_ERROR = "@@auth/FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_ERROR",

  RESET = "@@auth/RESET"
}

export type InstrumentDailyBalanceHistoryActionDispatch = {
  type: InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY | InstrumentDailyBalanceHistoryReduxTypes.RESET;
  payload: any;
};

export type InstrumentDailyBalanceHistoryReducerDispatch = {
  type:
    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY
    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_SUCCESS
    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_ERROR

    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY
    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_SUCCESS
    | InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_ERROR
    | InstrumentDailyBalanceHistoryReduxTypes.RESET;
  payload: {
    data?: FetchApiResponse<instrumentDailyBalanceHistory>;
    error?: string;
  };
};

export type InstrumentDailyBalanceHistoryReduxState = {
  loading?: boolean;
  data?: FetchApiResponse<instrumentDailyBalanceHistory>;
  error?: string | null;
};

export type instrumentDailyBalanceHistory = {
  id: string;
  externalId: string;
  instrumentBatchId: string;
  counterPartyExternalId: string;
  currencyShortCode: string;
  corporateCode: string;
  instrumentNumber: string;
  instrumentDate: string;
  dueDate: string;
  receivedDate: string;
  instrumentAmount: number;
  additionalDetails: string;
  docType: string;
  ifscCode: string;
  accountNumber: string;
  userRemarks: string;
  processingStatus: string;
  userStatus: string;
  errorText: string;
  active: true;
  createdOn: Date;
  updatedOn: Date;
};

export type InstrumentDailyBalanceHistoryFetchRequest = {
  payload: FilterMetadata;
  type: string;
};
