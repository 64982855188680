import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  approveCustomerBeneficialProgrammeApi,
  blockCustomerBeneficialProgrammeApi,
  createCustomerBeneficiaryProgrammeApi,
  fetchCustomerBeneficialProgrammeApi,
  fetchCustomerBeneficialProgrammeVersionApi,
  fetchCustomerBeneficiaryProgrammeApi,
  freezeCustomerBeneficialProgrammeApi,
  rejectCustomerBeneficialProgrammeApi,
  unFreezeCustomerBeneficialProgrammeApi,
  updateCustomerBeneficialProgrammeApi
} from "./api";
import { CreateCustomerBeneficiaryProgrammeRequest, CustomerBeneficiaryProgrammeFetchRequest, CustomerBeneficiaryProgrammeReduxTypes } from "./types";

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* fetchCustomerBeneficiaryProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficiaryProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* createCustomerBeneficiaryProgramme({ payload, type }: CreateCustomerBeneficiaryProgrammeRequest): SagaIterator {
  try {
    const response = yield call(createCustomerBeneficiaryProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialProgrammeApi, payload);
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* updateCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(updateCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* fetchCustomerBeneficiaryProgrammeVersion({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(fetchCustomerBeneficialProgrammeVersionApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    // yield put(financierUserApiResponseError(FinancierUserReduxTypes.FETCH_FINANCIER_USERS, error));
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION_ERROR,
      payload: { error: error }
    });
  }
}

function* aproveCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(approveCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* rejectCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(rejectCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* blockCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(blockCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* freezeCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(freezeCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

function* unFreezeCustomerBeneficialProgramme({ payload, type }: CustomerBeneficiaryProgrammeFetchRequest): SagaIterator {
  try {
    const response = yield call(unFreezeCustomerBeneficialProgrammeApi, payload);

    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_SUCCESS,
      payload: { data: response.data.data }
    });
  } catch (error: any) {
    yield put({
      type: CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME_ERROR,
      payload: { error: error }
    });
  }
}

export function* watchFetchCustomerBeneficaryProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICARY_PROGRAMME, fetchCustomerBeneficiaryProgramme);
}
export function* watchCreateCustomerBeneficaryProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.CREATE_CUSTOEMR_BENEFICIARY_PROGRAMME, createCustomerBeneficiaryProgramme);
}
export function* watchCreateCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME, fetchCustomerBeneficialProgramme);
}

export function* watchUpdateCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.UPDATE_CUSTOMER_BENEFICIAL_PROGRAMME, updateCustomerBeneficialProgramme);
}

export function* watchFetchCustomerBeneficialProgrammeVersion() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.FETCH_CUSTOMER_BENEFICIAL_PROGRAMME_VERSION, fetchCustomerBeneficiaryProgrammeVersion);
}

export function* watchAproveCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.APROVE_CUSTOMER_BENEFICIAL_PROGRAMME, aproveCustomerBeneficialProgramme);
}

export function* watchRejectCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.REJECT_CUSTOMER_BENEFICIAL_PROGRAMME, rejectCustomerBeneficialProgramme);
}

export function* watchBlockCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.BLOCK_CUSTOMER_BENEFICIAL_PROGRAMME, blockCustomerBeneficialProgramme);
}

export function* watchFreezeCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.FREEZE_CUSTOMER_BENEFICIAL_PROGRAMME, freezeCustomerBeneficialProgramme);
}

export function* watchUnFreezeCustomerBeneficialProgramme() {
  yield takeEvery(CustomerBeneficiaryProgrammeReduxTypes.UNFREEZE_CUSTOMER_BENEFICIAL_PROGRAMME, unFreezeCustomerBeneficialProgramme);
}

function* CustomerBeneficiaryProgrammeSaga() {
  yield all([
    fork(watchFetchCustomerBeneficaryProgramme),
    fork(watchCreateCustomerBeneficaryProgramme),
    fork(watchCreateCustomerBeneficialProgramme),
    fork(watchUpdateCustomerBeneficialProgramme),

    fork(watchFetchCustomerBeneficialProgrammeVersion),
    fork(watchAproveCustomerBeneficialProgramme),
    fork(watchRejectCustomerBeneficialProgramme),
    fork(watchBlockCustomerBeneficialProgramme),
    fork(watchFreezeCustomerBeneficialProgramme),
    fork(watchUnFreezeCustomerBeneficialProgramme)
  ]);
}

export default CustomerBeneficiaryProgrammeSaga;
