import { InstrumentDailyBalanceHistoryReducerDispatch, InstrumentDailyBalanceHistoryReduxState, InstrumentDailyBalanceHistoryReduxTypes } from "./types";

const INSTRUMNET_INIT_STATE = {
  loading: false,
  data: {
    metadata: {
      totalCount: 0,
      currentPageRecords: 0,
      totalPages: 0,
      links: {
        self: null,
        first: null,
        previous: null,
        next: null,
        last: null
      }
    },
    records: []
  },
  error: null
};

const InstrumentDailyBalancesHistoryReducer = (state: InstrumentDailyBalanceHistoryReduxState = INSTRUMNET_INIT_STATE, action: InstrumentDailyBalanceHistoryReducerDispatch) => {
  switch (action.type) {
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCES_HISTORY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};


const InstrumentDailyBalanceHistoryReducer = (state: InstrumentDailyBalanceHistoryReduxState = INSTRUMNET_INIT_STATE, action: InstrumentDailyBalanceHistoryReducerDispatch) => {
  switch (action.type) {
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY: {
      return {
        ...state,
        data: action.payload.data,
        loading: true
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loading: false
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.FETCH_INSTRUMENT_DAILY_BALANCE_HISTORY_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    case InstrumentDailyBalanceHistoryReduxTypes.RESET:
      return {
        ...state,
        data: {
          metadata: {
            totalCount: 0,
            currentPageRecords: 0,
            totalPages: 0,
            links: {
              self: null,
              first: null,
              previous: null,
              next: null,
              last: null
            }
          },
          records: []
        },
        loading: false,
        error: false
      };
    default:
      return { ...state };
  }
};

export { InstrumentDailyBalancesHistoryReducer,InstrumentDailyBalanceHistoryReducer };
