import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ExcessMoneyFetchRequest, ExcessMoneyTypes } from "./types";
import { fetchExcessMoneyByIdApi, fetchExcessMoneysApi } from "./api";
import { SagaIterator } from "@redux-saga/core";

function* fetchExcessMoneys({ payload, type }: ExcessMoneyFetchRequest): SagaIterator {
    try {
        const response = yield call(fetchExcessMoneysApi, payload);
        yield put({
            type: ExcessMoneyTypes.FETCH_EXCESS_MONEYS_SUCCESS,
            payload: { data: response.data.data }
        });
    } catch (error: any) {
        yield put({
            type: ExcessMoneyTypes.FETCH_EXCESS_MONEYS_FAIL,
            payload: { error: error }
        });
    }
}

function* fetchExcessMoneyById({ payload, type }: ExcessMoneyFetchRequest): SagaIterator {
    try {
        const response = yield call(fetchExcessMoneyByIdApi, payload);
        yield put({
            type: ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_SUCCESS,
            payload: { data: response.data.data }
        });
    } catch (error: any) {
        yield put({
            type: ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID_FAIL,
            payload: { error: error }
        });
    }
}


export function* watchFetchExcessMoneys() {
    yield takeEvery(ExcessMoneyTypes.FETCH_EXCESS_MONEYS, fetchExcessMoneys);
}

export function* watchFetchExcessMoneyById() {
    yield takeEvery(ExcessMoneyTypes.FETCH_EXCESS_MONEY_BY_ID, fetchExcessMoneyById);
}


function* ExcessMoneySaga() {
    yield all([
        fork(watchFetchExcessMoneys),
        fork(watchFetchExcessMoneyById),
    ])
}

export default ExcessMoneySaga;