import { CreateCustomerContactRequest, CustomerContactActionDispatch, CustomerContactsReduxTypes } from "./types";

export const createPromoterDirectorsContact = (payload: CreateCustomerContactRequest): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.CREATE_CUSTOEMR_CONTACT,
  payload: payload
});

export const deleteCustomerContact = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.DELETE_CUSTOEMR_CONTACT,
  payload: payload
});

export const fetchPromoterDirectorsList = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS,
  payload: payload
});

export const resetCustomerContact = (): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.RESET,
  payload: {}
});

export const fetchPromoterDirectorById = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACT,
  payload: payload
});

export const updatePromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UPDATE_CUSTOMER_CONTACTS,
  payload: payload
});

export const fetchPromoterDirectorsListVersion = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION,
  payload: payload
});


export const fetchPromoterDirectorsVersionDatails = (payload: any): CustomerContactActionDispatch => ({
    type: CustomerContactsReduxTypes.FETCH_CUSTOMER_CONTACTS_VERSION_DATAILS,
    payload: payload
  });

export const aprovePromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.ARPOVE_CUSTOEMR_CONTACT,
  payload: payload
});

export const rejectPromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.REJECT_CUSTOEMR_CONTACT,
  payload: payload
});

export const blockPromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.BLOCK_CUSTOEMR_CONTACT,
  payload: payload
});

export const freezePromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FREEZE_CUSTOEMR_CONTACT,
  payload: payload
});

export const unFreezePromoterDirectors = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UNFREEZE_CUSTOEMR_CONTACT,
  payload: payload
});

//
export const createKeyManagementContact = (payload: CreateCustomerContactRequest): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.CREATE_KEY_MANAGAMENT_CONTACT,
  payload: payload
});

export const fetchKeyManagementContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACTS,
  payload: payload
});

export const fetchKeyManagementContact = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_CONTACT,
  payload: payload
});

export const updateKeyManagementContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UPDATE_KEY_MANAGEMENT_CONTACTS,
  payload: payload
});

export const fetchKeyManagementListVersion = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_KEY_MANAGEMENT_VERSION,
  payload: payload
});

export const aproveKeyManagement = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.ARPOVE_KEY_MANAGEMENT,
  payload: payload
});

export const rejectKeyManagement = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.REJECT_KEY_MANAGEMENT,
  payload: payload
});

export const blockKeyManagement = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.BLOCK_KEY_MANAGEMENT,
  payload: payload
});

export const freezeKeyManagement = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FREEZE_KEY_MANAGEMENT,
  payload: payload
});

export const unFreezeKeyManagement = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UNFREEZE_KEY_MANAGEMENT,
  payload: payload
});

//

export const createPartnerContact = (payload: CreateCustomerContactRequest): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.CREATE_PARTNER_CONTACT,
  payload: payload
});

export const fetchPartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_PARTNER_CONTACTS,
  payload: payload
});

export const fetchPartnerContactById = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_PARTNER_CONTACT,
  payload: payload
});

export const updatePartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UPDATE_PARTNER_CONTACTS,
  payload: payload
});

export const fetchPartnerContactsListVersion = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FETCH_PARTNER_VERSION,
  payload: payload
});

export const aprovePartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.ARPOVE_PARTNER,
  payload: payload
});

export const rejectPartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.REJECT_PARTNER,
  payload: payload
});

export const blockPartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.BLOCK_PARTNER,
  payload: payload
});

export const freezePartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.FREEZE_PARTNER,
  payload: payload
});

export const unFreezePartnerContacts = (payload: any): CustomerContactActionDispatch => ({
  type: CustomerContactsReduxTypes.UNFREEZE_PARTNER,
  payload: payload
});
