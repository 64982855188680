import { APICore } from "helpers/api/apiCore";

import config from "../../config";
import { FilterMetadata } from "redux/types";
import { CreateHoliday, Holiday, updateHolidayVersion } from "./types";

const api = new APICore();

function fetchHolidayApi(params: FilterMetadata) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays`;
  return api.get(`${baseUrl}`, params);
}

function fetchHolidayByExternalIdApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params}`;
  return api.get(`${baseUrl}`, params);
}

function createHolidayApi(params: CreateHoliday) {
  let backendApiUrl = "";

  let apiData: any = {
    country: params.country,
    branch: params.branch,
    date: params.date,
    comments: params.comments.trim()
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/holidays`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/holidays?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.create(`${baseUrl}`, apiData);

  // const baseUrl = `${config.BACKEND_BASE_URL}/holidays`;
  // return api.create(`${baseUrl}`, params);
}

function updateHolidayApi(params: Holiday) {

  let backendApiUrl = "";

  let apiData: any = {
    country: params.country,
    branch: params.branch,
    date: params.date,
    comments: params.comments.trim()
  };

  if (!params.draft) {
    apiData.makerRemarks = params.makerRemarks ? params.makerRemarks.trim() : "";
    backendApiUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}`;
  } else {
    backendApiUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}?draft=${params.draft}`;
  }
  const baseUrl = backendApiUrl;
  return api.update(`${baseUrl}`, apiData);
  // const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}`;
  // return api.update(`${baseUrl}`, data);
}

function fetchHolidayVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params}/versions`;
  return api.get(`${baseUrl}`, {});
}

function fetchHolidayVersionDetailsApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params?.externalID}/versions/${params?.internalID}`;
  return api.get(`${baseUrl}`, {});
}

function deleteHolidayApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params}`;
  return api.delete(`${baseUrl}`,);
}

function fetchHolidayLatestVersionApi(params: any) {
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params}/latestVersion`;
  return api.get(`${baseUrl}`, {});
}


function approveHolidayApi(params: updateHolidayVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${externalHolidayId}/approve`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function rejectHolidayApi(params: updateHolidayVersion) {
  let remarks = {
    checkerRemarks: params.checkerRemarks.trim()
  };
  let externalHolidayId = params.externalId;

  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${externalHolidayId}/reject`;
  return api.updatePatch(`${baseUrl}`, remarks);
}

function blockHolidayApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}/block`;
  return api.updatePatch(`${baseUrl}`, param);
}

function freezeHolidayApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}/freeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

function unFreezeHolidayApi(params: any) {
  const param = {
    inactiveReason: params.inactiveReason.trim()
  };
  const baseUrl = `${config.BACKEND_BASE_URL}/holidays/${params.id}/unfreeze`;
  return api.updatePatch(`${baseUrl}`, param);
}

export {
  fetchHolidayApi,
  createHolidayApi,
  fetchHolidayByExternalIdApi,
  updateHolidayApi,
  fetchHolidayVersionApi,
  approveHolidayApi,
  rejectHolidayApi,
  blockHolidayApi,
  freezeHolidayApi,
  unFreezeHolidayApi,
  fetchHolidayVersionDetailsApi,
  fetchHolidayLatestVersionApi,
  deleteHolidayApi
};
